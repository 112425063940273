import React, {useState} from "react"

import { geryColor100, primaryColor100 } from '../../../sass/vars.scss';

function SearchIcon(props) {
  const [stroke, setStroke] = useState(geryColor100);
  const handleMouseEnter = ()=>{
    setStroke(primaryColor100);
  }

  const handleMouseLeave = ()=>{
    setStroke(geryColor100);
  }
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none" {...props}
      onMouseEnter= {handleMouseEnter}
      onMouseLeave= {handleMouseLeave}
    >
      <circle
        cx={11.5}
        cy={11.5}
        r={11}
        stroke={stroke}

      />
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.707 20L24 24.293"
      />
    </svg>
  )
}

export default SearchIcon
