import * as React from "react"
import {primaryColor100, white} from '../../../sass/vars.scss';

function Download__nofill(props) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
      <path
        stroke={props.stroke || white}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10 14V3M4.5 10l4.793 4.793a1 1 0 001.414 0L15.5 10M6 17h8"
      />
    </svg>
  )
}

export default Download__nofill
