import React from "react";
//icons
import ChevronUpSM from '../../icons/ChevronUpSM';
import ChevronDownSM from '../../icons/ChevronDownSM';
//sass
import { primaryColor100, greyColor10, greyColor70 , greyColor100} from '../../../sass/vars.scss'

export const  KPDropdownSelect__Single = props => {

  const { onClick, disabled, value, showOptions, placeholder, size } = props;

  const ifDisabledSelectStyle = disabled ? { backgroundColor: greyColor10 } : {};
  const ifDisabledTextStyle = disabled ? { color: greyColor70 } : {};
  const ifPlaceholderStyle = !value ? { color: greyColor100} : {};
  const ActiveChevron = showOptions ? ChevronUpSM : ChevronDownSM; 
  
  return (
    <div className={`kp-dd__select ${size}`} onClick={onClick ? onClick : undefined} style={ifDisabledSelectStyle}>
      <h4 className='input kp-dd__select-text' style={{...ifPlaceholderStyle, ...ifDisabledTextStyle}}>
        {value ? value.display : placeholder}
      </h4>
      <div className="kp-icon">
        <div className='icon-click-area-hack'></div>
        { !disabled &&  <ActiveChevron style={{ fill: primaryColor100}}/> }
      </div>
    </div>
  )
}

export const  KPDropdownSelect__Multi = props => {

  return(
    <div className={`kp-dd__select ${props.size}`} onClick={props.onClick}>
      <h4 className='h5 sans-serif kp-dd__select-text'>
      { props.selectedOptions.length > 0 ?
        props.selectedOptions.map(selec_op => selec_op.display).join(', ')
        :
        props.placeholder }
      </h4>
      <div className="kp-icon">
        <div className='icon-click-area-hack'></div>
        {props.showOptions ? <ChevronUpSM/> : <ChevronDownSM />}
      </div>
    </div> )

}
