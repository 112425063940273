// Hamburger Icons
import DistrictDashboardHamburgerIcon from "../../components/icons/DistrictDashboardIcon"



export const _DataTypes = [
  // {
  //   icon : DistrictDashboardHamburgerIcon,
  //   title : 'Data',
  //   description : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla semper placerat consectetur.',
  //   id : 'data',
  // },

]
