import React from "react"
import {primaryColor100} from '../../../sass/vars.scss';

function Location14(props) {
  return (
    <svg width={14} height={14} viewBox="0 0 14 14" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.702 12.275c1.336-1.36 4.218-4.535 4.218-6.582 0-2.665-2.186-4.826-4.882-4.826-2.697 0-4.883 2.16-4.883 4.826 0 2.047 2.882 5.221 4.218 6.582.369.376.96.376 1.33 0zM6.922 7.7a2 2 0 100-4 2 2 0 000 4z"
        fill={primaryColor100}
      />
      <path
        d="M7.702 12.275l.357.35-.357-.35zm-1.33 0l.358-.35-.357.35zm5.048-6.582c0 .404-.145.908-.422 1.484-.273.57-.657 1.175-1.09 1.77-.867 1.191-1.902 2.305-2.563 2.978l.714.7c.675-.688 1.749-1.841 2.657-3.09.455-.623.875-1.282 1.184-1.925.306-.637.52-1.298.52-1.917h-1zM7.038 1.367c2.426 0 4.382 1.942 4.382 4.326h1c0-2.947-2.415-5.326-5.382-5.326v1zM2.655 5.693c0-2.384 1.956-4.326 4.383-4.326v-1c-2.968 0-5.383 2.379-5.383 5.326h1zm4.075 6.232c-.66-.673-1.696-1.787-2.563-2.978-.433-.595-.817-1.2-1.09-1.77-.277-.576-.422-1.08-.422-1.484h-1c0 .619.215 1.28.52 1.917.309.643.73 1.302 1.184 1.926.908 1.248 1.982 2.401 2.657 3.09l.714-.701zm.615 0a.425.425 0 01-.615 0l-.714.7a1.424 1.424 0 002.043 0l-.714-.7zM8.421 5.7a1.5 1.5 0 01-1.5 1.5v1a2.5 2.5 0 002.5-2.5h-1zm-1.5-1.5a1.5 1.5 0 011.5 1.5h1a2.5 2.5 0 00-2.5-2.5v1zm-1.5 1.5a1.5 1.5 0 011.5-1.5v-1a2.5 2.5 0 00-2.5 2.5h1zm1.5 1.5a1.5 1.5 0 01-1.5-1.5h-1a2.5 2.5 0 002.5 2.5v-1z"
        fill="none"
      />
    </svg>
  )
}

export default Location14
