import React from "react";
//comps
import { KPCheckboxOption } from '../../inputs/KPCheckboxInput';


export const  KPDropdownOptions__Single = props => {
  
  const {
    options,
    showOptions,
    value,
    onClick,
    optionType
  } = props;

  return (
    <div className={`kp-dd__options-wrapper ${showOptions ? "show" : "hide"}`}>
      <ul className="kp-dd__options">
      { options.map(option => {
          let optionDisplay =  option.display || option.name; //we want to move away from option.name completely. we just want to have  option.value and option.display
          let optionValue = option.value;
          let optionDisabled = option.disabled === true || option.count === 0;
          return(
            <li
              key={optionValue}
              className={
                `input kp-dd__option
                ${(value && value.value === optionValue) ? 'selected' : ''}
                ${optionDisabled && 'disabled'}`
              }
              onClick={() => !optionDisabled && onClick(option)}
              >
            { optionType === 'single' 
              ? optionDisplay
              : optionType === 'singleWithCount' &&
                <div style={{display: 'flex'}}>
                  <h4 className='kp-dd__option-display-text'>{optionDisplay}</h4>
                  <h5 className='h5 italic kp-dd__option-count'>{option.count}</h5>
                </div> }
            </li> ) 
          }) }
      </ul>
    </div>
  )
}

export const KPDropdownOptions__Multi = props => (

  <div className={`kp-dd__options-wrapper kp-dd__options-wrapper--multi ${props.showOptions ? "show" : "hide"}`}>
    <ul className="kp-dd__options kp-dd__options--multi">
  { props.options.map(option => {
      let optionDisplay =  option.display || option.name; //we want to move away from option.name completely. we just want to have  option.value and option.display
      let optionDisabled = option.disabled === true || option.count === 0;
      return(
        <li
          key={option.value}
          className={
            `h4 kp-dd__option
            ${props.selectedOptions.some(selec_op => selec_op.value === option.value) === true && 'selected'}
            ${optionDisabled && 'disabled'}`}
          onClick={() => !optionDisabled && props.onClick({value : option.value, display: optionDisplay})}
          >
          { props.optionType === 'multi' ?
            <KPCheckboxOption
              option = {option}
              selectedOptions = {props.selectedOptions}
              />
            :
            props.optionType === 'multiWithCount' &&
            <div style={{display: 'flex'}}>
              <KPCheckboxOption option = {option} selectedOptions = {props.selectedOptions} />
              <h5 className='h5 italic kp-dd__option-count'>{option.count}</h5>
            </div>
          }
        </li> )
      }) }
    </ul>
  </div>

)
