import React from "react"
import {primaryColor100} from '../../../sass/vars.scss';

function EyeVisible(props) {
  return (
    <svg width={24} height={28} viewBox="0 0 24 28" fill="none" {...props}>
      <path
        d="M6.818 9.003a8 8 0 0110.016 0l5.056 4.06a1 1 0 010 1.559l-5.056 4.059a8 8 0 01-10.016 0l-5.056-4.06a1 1 0 010-1.559l5.056-4.059z"
        fill="#F0F8FE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.264 13.842l-5.056-4.06a7 7 0 00-8.764 0l-5.056 4.06 5.056 4.059a7 7 0 008.764 0l5.056-4.059zm-4.43-4.839a8 8 0 00-10.016 0l-5.056 4.06a1 1 0 000 1.559l5.056 4.059a8 8 0 0010.016 0l5.056-4.06a1 1 0 000-1.559l-5.056-4.059z"
        fill={primaryColor100}
      />
      <path
        d="M15.441 13.842a3.615 3.615 0 11-7.23 0 3.615 3.615 0 017.23 0z"
        fill={primaryColor100}
      />
    </svg>
  )
}

export default EyeVisible
