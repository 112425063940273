import * as React from "react"

function BoldBtn(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M8.443 17.611V7.49h3.493c.663 0 1.228.063 1.694.19.472.125.857.305 1.155.538.304.233.525.516.665.847.14.331.21.705.21 1.12 0 .238-.035.467-.105.686-.07.215-.18.418-.329.61a2.385 2.385 0 01-.56.51 3.315 3.315 0 01-.805.392c1.39.313 2.086 1.064 2.086 2.254 0 .43-.081.826-.245 1.19a2.658 2.658 0 01-.714.945 3.448 3.448 0 01-1.155.616c-.457.15-.98.224-1.568.224H8.443zm1.883-4.438v2.968h1.911c.35 0 .642-.042.875-.126.238-.084.427-.196.567-.336.145-.14.248-.303.308-.49a1.79 1.79 0 00.098-.595c0-.22-.035-.415-.105-.588a1.08 1.08 0 00-.329-.448 1.508 1.508 0 00-.574-.287 3.178 3.178 0 00-.847-.098h-1.904zm0-1.302h1.505c.64 0 1.125-.117 1.456-.35.332-.233.497-.604.497-1.113 0-.527-.15-.903-.448-1.127-.298-.224-.765-.336-1.4-.336h-1.61v2.926z"
        fill="#fff"
      />
    </svg>
  )
}

export default BoldBtn
