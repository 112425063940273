import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const PrivateRoute = ({
  component: Component,
  auth: { isAuthenticated, loading },
  location,
  match,
  ...rest
}) => {


  return(
    <Route
      {...rest}
      render={props =>
        !isAuthenticated && !loading ? (
          <Redirect to={{
              pathname: '/login_registration',
              state: {
                from: location.pathname+location.search,
                authType: 'login'
              }
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  )
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default withRouter(connect(mapStateToProps)(PrivateRoute));
