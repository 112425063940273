import React, { useState, useEffect, Fragment } from "react";

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { KPTextInput } from '../KPInputs';
import {KPBtn, ButtonSecondary} from '../../generalUI/KPButtons';
import ToggleBar from '../../generalUI/ToggleBar';
import ModalShell from '../../modals/ModalShell';
import ModalHeader from '../../modals/ModalHeader';
//actions
import {getPublishedListing} from '../../../actions/listings'
//sass
import {primaryColor100, primaryColor10, white} from '../../../sass/vars.scss'
//utils
import {injectHttps} from '../../../utils/general';
import {_GetContentTypeConfig} from '../../../_configs/contentTypes/config';

const KPLinkInput = ({
  id,
  compType, // link / button
  onConfirm,
  onClose,
  textInputProps,
  listings,
  getPublishedListing,
  linkType
}) => { //col settings must be the bootstrap codes

  const [linkInputOption, setLinkInputOption] = useState('internal');
  const [selectedLink, setSelectedLink] = useState({
    value: '',
    type: null
  })

  const handleSetSelectedLink = (v, type) => {
    setSelectedLink({
      value: type === 'internal' ? `/published-page/${v}` : v,
      type
    })
  }

  const handleToggle = (k,v) => {
    setLinkInputOption(v);
  }

  useEffect(()=>{
    _GetContentTypeConfig('tools') && getPublishedListing('tools', 'long');
  },[])

  const [displayText, setDisplayText] = useState('');

  return (
    <ModalShell>
      <ModalHeader title = 'Insert Link' onClose = {onClose} />
      { linkType === 'button' && 
        <KPTextInput
          placeholder = 'Write Button Text Here'
          value = {displayText}
          onChange = {(k,v)=>setDisplayText(v)}
        /> }
      <ToggleBar
        id = 'kp_link_input_toggle'
        options = {[
          {value: 'internal', name: 'Internal Resource'},
          {value: 'external', name: 'External Resource'}
        ]}
        onChange = {(k,v) => handleToggle(k,v)}
        />
      <div className='kp-link-input__body'>
      { linkInputOption === 'internal'
      ? <div className='kp-link-input__internal-link-options'>
        { listings.loading
        ? <div>loading...</div>
        : listings.toolsListing && listings.toolsListing.data.map(d => (
            <div
              onClick= {()=>handleSetSelectedLink(`${d.meta.kp_content_type}?id=${d._id}`, 'internal')}
              style={{
                cursor : 'pointer',
                borderBottom: '1px solid grey',
                padding: '0.5rem',
                backgroundColor: selectedLink.value.includes(d._id) ? primaryColor10 : white
              }}
              >
              <h4 className='h4 bold'>{d.main.kp_title && d.main.kp_title.blocks[0].text}</h4>
              <h4 className='h4'>{d.main.kp_subtitle && d.main.kp_subtitle.blocks[0].text}</h4>
            </div>
          ))
          }
        </div>
      : <KPTextInput
          { ...textInputProps }
          value = {selectedLink.value}
          onChange = {(k,v)=>handleSetSelectedLink(v, 'external')}
          /> }
      </div>
      <ButtonSecondary 
        onClick={() => onConfirm(
          id, 
          selectedLink.type === 'external' 
          ? injectHttps(selectedLink.value) 
          : selectedLink.value, 
          selectedLink.type, 
          displayText)
        }>
        Insert Link
      </ButtonSecondary>
    </ModalShell>
  )
}

KPLinkInput.propTypes = {
  listings: PropTypes.object.isRequired,
  getPublishedListing: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  listings: state.listings
});

export default connect(mapStateToProps, {getPublishedListing})(KPLinkInput);
