import React, { useState, useEffect, Fragment } from "react";
import axios from 'axios';

import ContentBlockShell from '../../generalUI/ContentBlockShell';
import Loader from '../../generalUI/Loader';
import KPLabel from '../../generalUI/KPLabel';
import {KPBtn, ButtonSecondary} from '../../generalUI/KPButtons';
import KPResourceSelectPopUp from '../../modals/KPResourceSelectPopUp';
//_config

import { _GetContentTypeConfig} from '../../../_configs/contentTypes/config';
import { _GetProfileTypeConfig} from '../../../_configs/profileTypes/config';
import store from '../../../store';

import ContentCard from '../../cards/ContentCard';
import ExternalLinkCard from '../../cards/ExternalLinkCard';

import {replaceEntityData} from '../../../utils/richInputUtils';
import {refreshCardsData} from '../../../utils/cardUtils';

const KPCardLinksDisplay = ({
  label, sublabel, errorMsgs, isRequired,
  id,
  value,
  type,
  contentTypeEmbedOptions,
  profileTypeEmbedOptions,
  cardsData : cardsDataFromParent, //for backwards compatibility. earlier value was called cardsData
  onChange : parentOnChange, //only passed if not inEditor
  readOnly,
  maxSelectLimit,
  allowNestedPublishing,
  forceParentOnChange,
  injectCardConfig,
  //specific to inRichEditorAtomicBlock
  isInEditor,
  editor,
  onEditorChange,
  handleSetTempReadOnly,
  block,
  entityKey,


}) => {

  const labelProps = { label, sublabel, errorMsgs, isRequired, id, readOnly };

  const [cardsData, setCardsData] = useState(value || cardsDataFromParent || {type /*from props*/, selectedAry: []} );
  
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if(forceParentOnChange) parentOnChange(id, cardsData);
    refreshCardsData(cardsData, setCardsData, setLoading) 
  },[])

  //SELECT MODAL TRIGGERED FROM THIS COMP IS ONLY FOR INTERNAL RESOURCES
  const [showSelectModal, setShowSelectModal] = useState(false);
  const handleCloseModal = () => setShowSelectModal(false);

  const onConfirm = (newCardsData) => {
    setCardsData(newCardsData);
    parentOnChange && parentOnChange(id, newCardsData); //prob dont need to do this. instead we write a function that fires a api call and refreshes the card config the minute it loads

    if(isInEditor === true){
      replaceEntityData(editor, entityKey, {value: newCardsData, contentTypeEmbedOptions, profileTypeEmbedOptions})
    }
  }

  const handleRemoveCard = _id => {
    let newCardsDataSelectedAry = cardsData.selectedAry.filter(d => d._id !== _id)
    let newCardsData = { ...cardsData, selectedAry: newCardsDataSelectedAry }
    setCardsData(newCardsData)
    parentOnChange && parentOnChange(id, newCardsData)

    //this only runs if comp is rendered inside rich editor
    if(isInEditor === true){
      replaceEntityData(editor, entityKey, {value: newCardsData, contentTypeEmbedOptions, profileTypeEmbedOptions})
    }
  }

  const generateCards = () => {

    if(cardsData.type === 'internal'){
      console.log('cardData', cardsData);
      return (
        <Fragment>
        { loading
          ? <Loader type='inlineLoader'/>
        : <Fragment>
          { cardsData.selectedAry.map(d => {
            let profileType = d.meta.profile_type;
            let contentType = d.meta.kp_content_type;
            let cardCardConfig = profileType 
                                 ? _GetProfileTypeConfig(profileType).cardCardConfig
                                 : _GetContentTypeConfig(contentType).cardCardConfig;
            let cardConfigToInject = injectCardConfig 
                                      ? (
                                        profileType 
                                        ? _GetProfileTypeConfig(profileType)[injectCardConfig]
                                        : _GetContentTypeConfig(contentType)[injectCardConfig]
                                      ) 
                                      : null;
            let profileId = profileType && (
              ['userProfiles', 'board'].indexOf(profileType) !== -1
              ? (d.user && d.user._id)
              : d._id
            )
            let cardLinkUrl = profileType
                              ? `/profile/${profileType !== 'board' ?  profileType : 'userProfiles' }/${profileId}`
                              : `/published-page/${contentType}?id=${d._id}`
            
            return(
            <ContentCard
              readOnly = {readOnly}
              onRemoveCard = {() => handleRemoveCard(d._id)}
              className = 'kp-listing__content-card kp-published-listing__content-card'
              cardType = {`published--${profileType || contentType}`}
              link = {{ type: 'external', url: cardLinkUrl }}
              { ...cardCardConfig(d) }
              colSetting = {`col-sm-6 kp-col` /*overiding CONFIG col settings*/ }
              { ...(cardConfigToInject ? cardConfigToInject(d) : {}) }
              />
          )})  }
          { !readOnly &&
            <div className= 'col-sm-6 kp-col' style={{alignSelf: 'center'}}>
            { cardsData.selectedAry.length !== maxSelectLimit &&
              <ButtonSecondary
                onClick={() => setShowSelectModal(true)}
                >
                { cardsData.selectedAry.length === 0 ? 'Embed Some Content From The Platform' : 'Add Another'}
              </ButtonSecondary> }
            </div> }
          </Fragment> }
        </Fragment>
      )

    }else{ //type === external
      let d = cardsData.data.data.data;
      let value = {
        title: d.ogTitle,
        desc: d.ogDescription,
        img: {imgData: [{secure_url: d.ogImage.url}] },
        url: d.ogUrl
      }
      return( <ExternalLinkCard value = {value} /> )
    }
  }

  return (
    <Fragment>
    { showSelectModal &&
      <KPResourceSelectPopUp
        allowNestedPublishing = {allowNestedPublishing}
        cardDisplayBlockId = {id}
        onCloseModal={handleCloseModal}
        onConfirm={onConfirm}
        contentTypeEmbedOptions = {contentTypeEmbedOptions}
        profileTypeEmbedOptions = {profileTypeEmbedOptions}
        linkTypesToShow = {['internal']}
        selectedAry = {cardsData.selectedAry}
        maxSelectLimit = {maxSelectLimit}
        />  }
      <ContentBlockShell
        richInputProps = {{
          isInEditor : isInEditor,
          editor: editor,
          block: block,
          handleSetTempReadOnly: handleSetTempReadOnly
        }}
        readOnly = { readOnly }
        className= {`kp-card-links-display `}
        >
        <KPLabel {...labelProps} />
        <div className={
            `${ cardsData && cardsData.type === 'internal' ? 'kp-row' : ''}
             ${ isInEditor === true ? `kp-in-editor-card-links-display--${cardsData && cardsData.type}` : '' }`}>
        {cardsData && generateCards()}
        </div>
      </ContentBlockShell>
    </Fragment>
  )
}

export default KPCardLinksDisplay;
