export const UPDATE_SCREEN_WIDTH = "UPDATE_SCREEN_WIDTH";
export const SET_MODAL_OVERLAY = "SET_MODAL_OVERLAY";

export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_AUTH_LOADING = "SET_AUTH_LOADING";
export const VERIF_TOKEN_SUCCESS = "VERIF_TOKEN_SUCCESS";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_ERROR = "REFRESH_TOKEN_ERROR"
export const VERIF_TOKEN_ERROR = "VERIF_TOKEN_ERROR";
export const RESET_VERIF_REL_DATA = "RESET_VERIF_REL_DATA";
export const PWD_RESET_MAIL_SEND_SUCCESS = "PWD_RESET_MAIL_SEND_SUCCESS";
export const PWD_RESET_MAIL_SEND_ERROR = "PWD_RESET_MAIL_SEND_ERROR";

export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_TPL = "GET_PROFILE_TPL";
export const GET_PROFILE_REL_CONTENT = "GET_PROFILE_REL_CONTENT";

export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";

export const PROFILE_LOADING = "PROFILE_LOADING";
export const CONTRS_BY_PROFILE_LOADING = "CONTRS_BY_PROFILE_LOADING";

export const PROFILE_SAVING = "PROFILE_SAVING";
export const HYDRATE_PROFILE_FROM_MY_PROFILE = "HYDRATE_PROFILE_FROM_MY_PROFILE";
export const RESET_USER_PROFILE_TPL = 'RESET_USER_PROFILE_TPL';
export const SAVE_CHANGES_TO_PROFILE = 'SAVE_CHANGES_TO_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_CLEAR_TO_PROCEED_USER_PROFILE = 'UPDATE_CLEAR_TO_PROCEED_USER_PROFILE';
export const UPDATE_MODE = 'UPDATE_MODE';
export const DELETE_PROFILE = 'DELETE_PROFILE';
export const DELETE_PROFILE_ERROR = 'DELETE_PROFILE_ERROR';

export const UPDATE_DRAFT_CONTRIBUTIONS_BY_PROFILE = "UPDATE_DRAFT_CONTRIBUTIONS_BY_PROFILE";
export const DELETE_DRAFT_ERROR = "DELETE_DRAFT_ERROR";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";
export const UPDATE_USER_LOADING = "UPDATE_USER_LOADING";

export const GET_TPL = "GET_TPL";
export const RESET_TPL = "RESET_TPL";
export const PROGRESS_TPL_SECTION = "PROGRESS_TPL_SECTION";
export const UPDATE_FLEXI_TPL = "UPDATE_FLEXI_TPL";

export const UPDATE_USER_CONTR = "UPDATE_USER_CONTR";
export const UPDATE_COMMENTS_IN_USER_CONTR = "UPDATE_COMMENTS_IN_USER_CONTR";
export const USER_CONTR_PUBLISHED = "USER_CONTR_PUBLISHED";

export const GEN_TEMPLATE_AND_SAVE_EMPTY_CONTRIBUTION = "GEN_TEMPLATE_AND_SAVE_EMPTY_CONTRIBUTION";
export const GEN_TPL_AND_SAVE_CONTR_ERROR = "GEN_TPL_AND_SAVE_CONTR_ERROR";
export const HYDRATE_USER_CONTR_AND_TPL = "HYDRATE_USER_CONTR_AND_TPL";
export const HYDRATE_USER_CONTR_AND_TPL_ERROR = "HYDRATE_USER_CONTR_AND_TPL_ERROR";
export const SET_HYDRATE_USER_CONTR_AND_TPL_LOADING = "SET_HYDRATE_USER_CONTR_AND_TPL_LOADING";

export const SET_POSTING_COMMENT = "SET_POSTING_COMMENT";
export const SET_DELETING_COMMENT = "SET_DELETING_COMMENT";
export const COMMENT_ACTION_ERROR = "COMMENT_ACTION_ERROR";
export const SET_BLOCK_ERRORS = "SET_BLOCK_ERRORS";

export const USER_CONTR_ERROR = "USER_CONTR_ERROR";
export const GET_TPL_ERROR = "GET_TPL_ERROR";

export const UPDATE_CLEAR_TO_PROCEED= "UPDATE_CLEAR_TO_PROCEED";

export const SET_TPL_LOADING = "SET_TPL_LOADING";
export const SET_TPL_LOADING_V2 = "SET_TPL_LOADING_V2";
export const SET_AUTO_SAVE = "SET_AUTO_SAVE";

export const MOD_TPL_LANG = "MOD_TPL_LANG";

export const GET_PARTICIPANTS_LISTING = "GET_PARTICIPANTS_LISTING";
export const PARTICIPANTS_LOADING = "PARTICIPANTS_LOADING";

//get published listing of a specific content type.
export const GET_PUBLISHED_LISTING = "GET_PUBLISHED_LISTING";
export const SKIP_GET_LISTING = "SKIP_GET_LISTING";
export const GET_PUBLISHED_LISTING_SHORT = "GET_PUBLISHED_LISTING_SHORT";

export const GET_USER_PROFILE_LISTING = "GET_USER_PROFILE_LISTING";
export const GET_USER_PROFILE_LISTING_SHORT = "GET_USER_PROFILE_LISTING_SHORT";

export const GET_USERS_LISTING = "GET_USERS_LISTING";

export const GET_MIXED_LISTING = "GET_MIXED_LISTING";

export const GET_TAGS_LISTING = "GET_TAGS_LISTING";
export const UPDATE_TAG_COUNT = "UPDATE_TAG_COUNT";
export const UPDATE_TAG_COUNT_ERROR = "UPDATE_TAG_COUNT_ERROR";
export const ADD_TAG = "ADD_TAG";
export const ADD_TAG_ERROR = "ADD_TAG_ERROR";
export const SET_ADD_TAG_LOADING = "SET_ADD_TAG_LOADING";

export const UPDATE_LISTING_DATA = "UPDATE_LISTING_DATA";
export const RESET_LISTING_TO_INIT_DATA = "RESET_LISTING_TO_INIT_DATA";

export const FETCH_LISTING_FROM_STORE = "FETCH_LISTING_FROM_STORE";

export const CLEAR_PUBLISHED_LISTING = "CLEAR_PUBLISHED_LISTING";
export const CLEAR_USER_PROFILE_LISTING = "CLEAR_USER_PROFILE_LISTING";
export const SET_LISTING_LOADING = "SET_LISTING_LOADING";
export const GET_LISTING_ERROR = "GET_LISTING_ERROR";
export const SET_KNOWLEDGE_API_QUEUE_LOADING = "SET_KNOWLEDGE_API_QUEUE_LOADING";
//ogs
export const GET_OGS_META = "GET_OGS_META";
export const SET_OGS_META_LOADING = "SET_OGS_META_LOADING";
export const OGS_META_ERROR = "OGS_META_ERROR";
export const RESET_OGS = "RESET_OGS";

//modQueue
export const GET_MOD_QUEUE = "GET_MOD_QUEUE";
export const MOD_ERROR = "MOD_ERROR";
export const SET_MOD_LOADING = "SET_MOD_LOADING";
export const CLEAR_MOD_QUEUE = "CLEAR_MOD_QUEUE";
export const ACCEPT_SUB = "ACCEPT_SUB";
export const GET_SUB_FOR_EDIT = "GET_SUB_FOR_EDIT";

//admin
export const SET_ADMIN_ACTION_LOADING = "SET_ADMIN_ACTION_LOADING";
export const ADMIN_ACTION_ERROR = "ADMIN_ACTION_ERROR";

//recommendations
export const GET_RECOMS = "GET_RECOMS";
export const GET_RECOMS_FROM_URL_PARAMS = "GET_RECOMS_FROM_URL_PARAMS";
export const RECOMS_ERROR = "RECOMS_ERROR";
export const SET_RECOMS_LOADING = "SET_RECOMS_LOADING";
export const RESET_RECOMS = "RESET_RECOMS";

//nested publishing
export const INIT_NESTED_PUBLISHING = "INIT_NESTED_PUBLISHING";
export const RESET_NESTED_PUBLISHING = "RESET_NESTED_PUBLISHING";

//sub resource 
export const REFETCH_SUB_RESOURCE = "REFETCH_SUB_RESOURCE";
export const SET_SUB_RESOURCE_LISTING = "SET_SUB_RESOURCE_LISTING";

export const SET_ANNOUNCEMENT = "SET_ANNOUNCEMENT";
export const REMOVE_ANNOUNCEMENT = "REMOVE_ANNOUNCEMENT";

// Client Config
export const SET_ENV_CONFIG = "SET_ENV_CONFIG";
export const ENV_CONFIG_LOADING = "ENV_CONFIG_LOADING";
export const SET_ENV_CONFIG_ERROR = "SET_ENV_CONFIG_ERROR";

export const SET_APP_ONLINE_STATUS = "SET_APP_ONLINE_STATUS";

export const SET_GROUPED_PUBLISHED_LISTING_LOADING = "SET_GROUPED_PUBLISHED_LISTING_LOADING";
export const GET_GROUPED_PUBLISHED_LISTING = "GET_GROUPED_PUBLISHED_LISTING";
export const GET_GROUPED_PUBLISHED_LISTING_ERROR = "GET_GROUPED_PUBLISHED_LISTING_ERROR";
export const RESET_GROUPED_PUBLISHED_LISTING = "RESET_GROUPED_PUBLISHED_LISTING";
export const UPDATE_GROUPED_LISTING_DATA = "UPDATE_GROUPED_LISTING_DATA";
export const RESET_GROUPED_LISTING_TO_INIT_DATA = "RESET_GROUPED_LISTING_TO_INIT_DATA";
export const FETCH_GROUPED_LISTING_FROM_STORE = "FETCH_GROUPED_LISTING_FROM_STORE";

export const SET_PARTICIPANTS_LOADING = "SET_PARTICIPANTS_LOADING";
export const SET_PARTICIPANTS = "SET_PARTICIPANTS"
export const SET_PARTICIPANTS_ERROR = "SET_PARTICIPANTS_ERROR"
export const UPDATE_PARTICIPANTS = "UPDATE_PARTICIPANTS"
export const GET_PARTICIPANTS_FROM_STORE = "GET_PARTICIPANTS_FROM_STORE"

export const SET_GROUPED_LISTING_ACTIVE_SORT = "SET_GROUPED_LISTING_ACTIVE_SORT";
export const SET_ACTIVE_SORT = "SET_ACTIVE_SORT";
