import {
  GET_TPL,
  GET_TPL_ERROR,

  PROGRESS_TPL_SECTION,
  RESET_TPL,
  UPDATE_FLEXI_TPL,

  GEN_TEMPLATE_AND_SAVE_EMPTY_CONTRIBUTION,
  GEN_TPL_AND_SAVE_CONTR_ERROR,

  UPDATE_USER_CONTR,
  UPDATE_COMMENTS_IN_USER_CONTR,
  USER_CONTR_PUBLISHED,
  HYDRATE_USER_CONTR_AND_TPL,
  HYDRATE_USER_CONTR_AND_TPL_ERROR,
  SET_HYDRATE_USER_CONTR_AND_TPL_LOADING,

  UPDATE_CLEAR_TO_PROCEED,
  SET_TPL_LOADING,
  SET_TPL_LOADING_V2, //currently only used to support loading on choosecontent type comp
  SET_AUTO_SAVE,
  MOD_TPL_LANG,

  SET_POSTING_COMMENT,
  SET_DELETING_COMMENT,
  COMMENT_ACTION_ERROR,
  SET_BLOCK_ERRORS

} from "../actions/types";

const initialState = {
  mode: 'inactive', //possible states: inactive | edit | view | editPublished | moderate
  draftRel: {
    settingsSecIdx: null,
    autoSaveStatus: {
      name: "inactive", //possible states: inactive | saving | failed | saved
      msg: 'Autosave inactive',
      type: 'inactive' //possible states: inactive | danger | success
    },
    isEditAuthorized: false,
    isUnderEdit: 'inactive' 
  },
  validationRel: {
    clearToProceed: {},
    emptyFields: null,
    highlightErrorSubSections: false
  },
  tpl: null,
  Contribution: { meta:{}, main:{} },
  loading: true,
  tplLoading: true, //currently only used in ChooseContentType comp
  postingComment: false,
  deletingComment: {
    deleting : false,
    id : null
  },
  error: null
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_TPL:
    return {
      ...state,
      mode: payload.mode,
      draftRel: {
        ...state.draftRel,
        settingsSecIdx: payload.settingsSecIdx
      },
      validationRel: {
        ...state.validationRel,
        clearToProceed: payload.clearToProceed,
      },
      tpl: payload.data,
      Contribution: {
        ...state.Contribution,
        meta : payload.contrMetaData
      }, //update the Contribution with the tpl's mongodb _id
      loading: false
    };
    case UPDATE_FLEXI_TPL:
    return {
      ...state,
      Contribution: {
        ...state.Contribution,
        meta : {
          ...state.Contribution.meta,
          kp_flexi_content_tpl_data: payload
        }
      }
    }
    case PROGRESS_TPL_SECTION:
    return {
      ...state,
      draftRel: {
        ...state.draftRel,
        settingsSecIdx: payload
      },
      loading: false
    };

    case UPDATE_COMMENTS_IN_USER_CONTR:
    return {
      ...state,
      Contribution : {
        ...state.Contribution,
        comments: payload
      },
      postingComment: false,
      deletingComment: {
        deleting: false,
        id: null
      }
    }

    case UPDATE_USER_CONTR:
    return {
      ...state,
      Contribution: payload,
      loading: false,
    };

    case USER_CONTR_PUBLISHED:
      return {
        ...state,
        loading: false
      }

    case GEN_TEMPLATE_AND_SAVE_EMPTY_CONTRIBUTION:
    return {
      ...state,
      draftRel: {
        ...state.draftRel,
        isEditAuthorized: {
          isEditAuth: true,
          msg: {
            name : "isEditAuth",
            message : "Current user is authorised to edit this resource"
          }
        }
      },
      Contribution: payload,
      loading: true,
      error: null
    }

    case HYDRATE_USER_CONTR_AND_TPL:
    return {
      ...state,
      mode: payload.mode,
      draftRel: {
        ...state.draftRel,
        isEditAuthorized: payload.data.currentUserInfo.isEditAuth,
        isUnderEdit : payload.data.isUnderEdit ? payload.data.isUnderEdit : 'inactive'
      },
      Contribution: payload.data.contribution,
      tpl: payload.data.tpl,
      loading: false
    };

    case RESET_TPL: //same as initial state. cant use initial state with spread operator cuz of some object prototype persistence screw ups
    return {
      ...state,
      mode: 'inactive', //possible states: inactive | draft | published | draftOfPublished
      draftRel: {
        settingsSecIdx: null,
        autoSaveStatus: "inactive", //possible states: inactive | saving | failed | saved
        isEditAuthorized: false,
        isUnderEdit: 'inactive'
      },
      validationRel: {
        clearToProceed: {},
        emptyFields: null,
        highlightErrorSubSections: false
      },
      tpl: null,
      Contribution: { meta:{}, main:{} },
      loading: true,
      error: null
    }

    case UPDATE_CLEAR_TO_PROCEED:
    return {
      ...state,
      validationRel: {
        ...state.validationRel,
        clearToProceed: {
          ...state.validationRel.clearToProceed,
          ...payload.clearToProceed
        },
        emptyFields : payload.emptyFields
      }
    }

    case SET_TPL_LOADING:
    case SET_HYDRATE_USER_CONTR_AND_TPL_LOADING:
    return {
      ...state,
      loading: payload
    }

    case SET_TPL_LOADING_V2:
    return {
      ...state,
      tplLoading: payload
    }

    case SET_POSTING_COMMENT:
    return {
      ...state,
      postingComment: payload
    }

    case SET_DELETING_COMMENT:
    return {
      ...state,
      deletingComment: {
        deleting : payload.deleting,
        id : payload.id
      }
    }

    case COMMENT_ACTION_ERROR:
    return {
      ...state,
      error: payload,
      postingComment: false,
      deletingComment: {
        deleting: false,
        id: null
      }
    }

    case SET_AUTO_SAVE:
    return {
      ...state,
      draftRel: {
        ...state.draftRel,
        autoSaveStatus: payload
      }
    }

    case GET_TPL_ERROR:
    case HYDRATE_USER_CONTR_AND_TPL_ERROR:
    case GEN_TPL_AND_SAVE_CONTR_ERROR:
    return {
      ...state,
      loading: false,
      error: payload
    };

    case SET_BLOCK_ERRORS:
    return {
      ...state,
      tpl : payload.tpl,
      validationRel: {
        ...state.validationRel,
        highlightErrorSubSections : payload.highlightErrorSubSections
      }
    }

    default:
      return state;
  }
}
