import * as React from "react"
import { primaryColor100 } from '../../../sass/vars.scss';

function SettingsSM(props) {
  return (
    <svg
      width={19}
      height={20}
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      
    >
      <path
        d="M4.691 2.11L7.554.925a.044.044 0 01.028-.001.037.037 0 01.02.015l.982 1.474.223.334H10.193l.223-.334.982-1.474a.038.038 0 01.02-.015.044.044 0 01.028 0l2.87 1.2h0l.007.002.003.003a.032.032 0 01.006.008l.002.004v.002l-.346 1.75-.078.393.283.283.41.41.283.283.393-.078 1.75-.346h.002c.002 0 .004 0 .008.003.01.005.017.013.021.022l1.187 2.863c.004.011.003.02.001.027a.037.037 0 01-.015.02l-.001.001-1.473.982-.334.223V10.364l.334.223 1.473.981s0 0 0 0c.01.007.014.014.016.021a.043.043 0 010 .028l-1.2 2.87h0l-.003.007s0 .002-.002.003a.034.034 0 01-.01.008h-.004l-1.75-.347-.393-.077-.283.283-.41.41-.283.283.078.393.346 1.75h0v.002a.053.053 0 01-.025.029l-2.851 1.182a.115.115 0 01-.035.006.05.05 0 01-.01 0s0 0 0 0l-.002-.001s0 0 0 0l-.003-.002a.076.076 0 01-.01-.013l-.982-1.473-.223-.334H8.807l-.223.334-.981 1.473h-.001a.037.037 0 01-.02.016.044.044 0 01-.028 0l-2.87-1.2h0l-.007-.003s-.002 0-.003-.002a.032.032 0 01-.008-.012V17.2l.346-1.75.078-.393-.283-.283-.41-.41-.283-.283-.393.077-1.75.347h0-.002a.053.053 0 01-.029-.025L.753 11.617a.044.044 0 01-.001-.028.038.038 0 01.015-.02l1.474-.982.334-.223V8.978l-.334-.223-1.474-.982a.037.037 0 01-.015-.02.043.043 0 010-.028l1.2-2.87h0l.003-.007.002-.004a.03.03 0 01.012-.007h.002l1.75.346.393.078.283-.283.41-.41.283-.283-.077-.393-.347-1.75h0v-.003s0-.003.003-.007a.053.053 0 01.022-.021zm12.343 12.396h0zm-12.369 2.7h0z"
        stroke={props.stroke || primaryColor100}
        strokeWidth={1.3}
      />
      <circle 
        cx={9.5} cy={9.671} r={3.12} 
        stroke={props.stroke || primaryColor100}
        strokeWidth={1.3} 
        />
    </svg>
  )
}

export default SettingsSM
