import React, { useState, Fragment, useEffect, useRef } from "react";
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
//generalUI
import { KPBtn, KPIconBtn, KPFileUploadBtn } from '../../generalUI/KPButtons';
import KPTooltip from '../../generalUI/KPTooltip';
//icons
import UploadImage from '../../icons/UploadImage';
import RichFormatToolBlockquote from '../../icons/RichFormatToolBlockquote';
import RichFormatToolH2 from '../../icons/RichFormatToolH2';
import RichFormatToolUnorderedList from '../../icons/RichFormatToolUnorderedList';
import RichFormatToolOrderedList from '../../icons/RichFormatToolOrderedList';
import LinkIcon from '../../icons/LinkIcon';
import PDFIcon from '../../icons/PDFIcon';
import Attachment from '../../icons/Attachment';
import Attachment2 from '../../icons/Attachment2';
import VideoEmbedIcon from '../../icons/VideoEmbedIcon';
import AudioEmbedIcon from '../../icons/AudioEmbedIcon';
import XScr from '../../icons/XScr';
import KPLinkInput from '../../inputs/KPLinkInput';
import { greyColor10, white } from '../../../sass/vars.scss';

import { richToolbar } from './config'

import { breakStringAtLineBreak, imageUploader } from '../../../utils/general';
import { prepFormData } from '../../../utils/fileUtils';

import {
  createAtomicBlockEntity,
  applyBlockFormatting,
  handleUploadChange
} from '../../../utils/richInputUtils';

import {
  promptForLink
} from '../../../utils/richInlineLinkUtils';

import { _DeploymentName } from '../../../_configs/_deployment/config';
import BodySans__M__Bold from "../../typo/BodySans__M__Bold";
import BodySans__M from "../../typo/BodySans__M";
import KebabMenu from "../../icons/KebabMenu";

const KPRichToolbar = ({ 
  editor,
  onEditorChange,
  editorFocussed,
  //setShowLinkInput
  //onConfirmFn
  handleShowCardLinkInput,
  superRichFormattable,
  formattingOptions,
  hintsExists,
  environment: { screenWidth },
  setTextLinkState,
  textLinkState,
  setLinkInputOnConfirmHandler 
}) => { //col settings must be the bootstrap codes

  const Icons = {
    RichFormatToolH2,
    RichFormatToolUnorderedList,
    RichFormatToolOrderedList,
    RichFormatToolBlockquote,
    UploadImage,
    PDFIcon,
    Attachment2,
    VideoEmbedIcon,
    AudioEmbedIcon
  }

  let toolbarRef = useRef(null);
  let advBtnRef = useRef(null);
  
  const calcTotSimpleBlocks = richToolbar => {
    let tot = 0;
    richToolbar.map(d => {
      if(!d.advancedBlock) tot = tot+1;
    })
    return tot;
  }

  const [spaceForSimpleBlocksInToolbar, setSpaceForSimpleBlocksInToolbar] = useState(null);

  const [showAdvancedBlocksList, setShowAdvancedBlocksList] = useState(false);
  const [showKebabMenu, setShowKebabMenu] = useState(false);
  const [totSimpleBlocks] = useState(calcTotSimpleBlocks(richToolbar))
  const [advBtn,setAdvBtn] = useState('KPBtn');

  const AdvBtns = { KPBtn, KPIconBtn};

  useEffect(() => {
    if (setShowAdvancedBlocksList && editorFocussed == false) {
      setShowAdvancedBlocksList(false);
    }
  }, [editorFocussed])

  useEffect(() => {
    if (toolbarRef.current && editorFocussed) {
      let toolbarWidth = toolbarRef.current.getBoundingClientRect().width;
      let hintsBtnWidth = hintsExists ? 120 : 0; //make that 120 a dynamic variable passed from sass and used to fix the width of the hints btn as well.
      let advancedBlockWidth = advBtnRef.current.getBoundingClientRect().width; //need to figure proper ref usage and do this properly
      
      let availSpace = toolbarWidth - hintsBtnWidth - advancedBlockWidth;
      let surplusSpace = availSpace - (totSimpleBlocks*50)
      if(surplusSpace > 160 && advBtn === 'KPIconBtn') setAdvBtn('KPBtn')
      setSpaceForSimpleBlocksInToolbar(availSpace);
    }
  }, [editorFocussed, screenWidth])

  const { editorRef, editorState } = editor;

  
  const BtnComps = {
    KPBtn,
    KPIconBtn,
    KPFileUploadBtn
  }


  const onClickHandlers = {
    applyBlockFormatting: (blockStyle) => {
      applyBlockFormatting(blockStyle, editorState, onEditorChange)
    },
    createAtomicBlockEntity: (compToRender) => {
      createAtomicBlockEntity(
        { editorState, onEditorChange },
        compToRender,
        'IMMUTABLE',
        { value: null }
      )
    },
    handleShowCardLinkInput: handleShowCardLinkInput,
    handleShowLinkInput: () => {
      setTextLinkState({...textLinkState, showLinkInput : true})
      setLinkInputOnConfirmHandler('handleInsertCTA')
    }
  }

  const onChangeHandlers = {
    handleUploadChange: (e, atomicBlockComp, folderName, allowedFormats) => {
      handleUploadChange(e, atomicBlockComp, folderName, allowedFormats, { ...editor, onEditorChange })
    }
  }

  const [xScrollIndicator, setXScrollIndicator] = useState(false);
  const [scrolledToolbar, setScrolledToolbar] = useState(0);
  

  const handleSetXScrollIndicator = node => {
    if (node && node.getBoundingClientRect().right > screenWidth) {
      setXScrollIndicator(true);
    } else {
      setXScrollIndicator(false);
    }
  }

  const genAdvBtn = () => {

    const BtnComp = AdvBtns[advBtn];
    return (
      <div ref={advBtnRef} style={{ flexShrink: 0, display: superRichFormattable || brokeAtIndex.length > 0 ? 'block' : 'none' }} >    
          <BtnComp
            onClick={() => setShowAdvancedBlocksList(!showAdvancedBlocksList)}
            className={`h5 regular kp-rich-format-toolbar__${advBtn === 'KPBtn' ? 'adv-block-menu-btn' : 'tool'} ${showAdvancedBlocksList ? 'selected' : ''}`}
            icon={<KebabMenu fill={white}/>}
            type='tertiary'
            iconAfterText={true}
            value='Advanced Blocks'
            >
          </BtnComp> 
        </div>
    )
  }

  const [showTooltip, setShowTooltip] = useState({ show: false, idx: -1 });

  
  const brokeAtIndex = [];
  
  const conditionsToRenderSimpleFormatOption = (d, i) => {
    let toReturn;
    switch(true){
      case d.advancedBlock :
      toReturn = false;
      break;
      case formattingOptions === 'textFormatting' && d.textFormatBlock !== true :
      toReturn = false;
      break;
      case formattingOptions === 'textAndImageFormatting' && d.textFormatBlock !== true && d.imageBlock !== true:
      toReturn = false;
      break;
      case spaceForSimpleBlocksInToolbar < (50 * (i + 1)) :
      toReturn = false;
      break;
      default :
      toReturn = true;
    }
    return toReturn;
  }

  const [showLinkInput, setShowLinkInput] = useState(false)

  return (
    <Fragment>
    <div className='kp-rich-format-toolbar-wrapper'>
      <div
        className="kp-rich-format-toolbar"
        style={{ display: editorFocussed ? "flex" : "none" }}
        onScroll={() => setScrolledToolbar(scrolledToolbar + 1)}
        ref={toolbarRef}
      >
        <div
          className="kp-rich-format-toolbar__tools-group"
          style={{
            display: 'flex',
            justifyContent: 'flexStart',
            alignItems: 'center'
          }}
        >
          {richToolbar.map((d, i) => {

            if ( conditionsToRenderSimpleFormatOption(d,i) ) {
              let BtnComp = BtnComps[d.btnComp]
              let Icon = Icons[d.icon];
              let onClickHandler = onClickHandlers[d.onClickHandler]
              let onChangeHandler = onChangeHandlers[d.onChangeHandler]
              return (
                <div key={d.id} className='kp-rich-format-toolbar__tool-wrapper'>
                  { showTooltip.idx === i && d.tooltipText &&
                    <KPTooltip className='kp-rich-format-toolbar__tool-tooltip' text={d.tooltipText} />
                  }
                  <BtnComp
                    className='kp-rich-format-toolbar__tool'
                    onClick={() => onClickHandler && onClickHandler(...d.clickHandlerArgs)}
                    onMouseEnter={() => setShowTooltip({ show: true, idx: i })}
                    onMouseLeave={() => setShowTooltip({ show: false, idx: -1 })}
                    onChange={(e) => onChangeHandler && onChangeHandler(...[e, ...d.changeHandlerArgs])}
                    multiple={d.multiple}
                    id={`${editor.editorId}_${d.id}`}
                  >
                    <Icon {...d.iconProps} />
                  </BtnComp>
                </div>
              )
            } else if (
              d.advancedBlock !== true &&
              spaceForSimpleBlocksInToolbar &&
              ( spaceForSimpleBlocksInToolbar < (50 * (i + 1)) ) 
              ) {
              if(advBtn === 'KPBtn') setAdvBtn('KPIconBtn');
              brokeAtIndex.push(d);
            }
          })}
        </div>
   
        { genAdvBtn() }

      </div>
      { showAdvancedBlocksList &&
        <div className='kp-advanced-blocks-list'>
          <div className='kp-adv-blocks-list-items-wrapper'>
            {richToolbar.map((d, i) => {
              if (superRichFormattable && d.advancedBlock ) {
                let onClickHandler = onClickHandlers[d.onClickHandler]
                let onChangeHandler = onChangeHandlers[d.onChangeHandler]
                return (
                  <div
                    key={d.id}
                    className='kp-adv-blocks-list-item'
                    onClick={() => {
                      onClickHandler && onClickHandler(...d.clickHandlerArgs);
                      setShowAdvancedBlocksList(false);
                    }
                    }>
                    <BodySans__M__Bold>{d.title}</BodySans__M__Bold>
                    <BodySans__M className='EditorToolbar__toolSubtitle'>{d.desc}</BodySans__M>
                  </div>
                )
              }else if(brokeAtIndex.some(simpleBlock => simpleBlock.id === d.id)){
                let BtnComp = d.btnComp === 'KPFileUploadBtn' ? BtnComps[d.btnComp] : BtnComps['KPBtn'];
                let btnProps = d.btnComp === 'KPFileUploadBtn' ? { comp: 'KPBtn' } : null;
                let Icon = Icons[d.icon];
                let onClickHandler = onClickHandlers[d.onClickHandler]
                let onChangeHandler = onChangeHandlers[d.onChangeHandler]
                return (
                  <div key={d.id} className='kp-simple-blocks-list-item'>
                    <BtnComp
                      className='kp-simple-blocks-btn'
                      onClick={() => {
                        onClickHandler && onClickHandler(...d.clickHandlerArgs);
                        setShowAdvancedBlocksList(false);
                      }}
                      onMouseEnter={() => setShowTooltip({ show: true, idx: i })}
                      onMouseLeave={() => setShowTooltip({ show: false, idx: -1 })}
                      onChange={(e) => {
                        onChangeHandler && onChangeHandler(...[e, ...d.changeHandlerArgs]);
                        setShowAdvancedBlocksList(false);
                      }}
                      multiple={d.multiple}
                      btnProps={btnProps}
                      id={`${editor.editorId}_${d.id}`}
                    >
                      {d.tooltipText}
                    </BtnComp>
                  </div>
                )
              }
            })}
          </div>
        </div>}
    </div>
    </Fragment>

  )
}

KPRichToolbar.propType = {
  environment: PropTypes.object.isRequired
}

const mapStateToProps = state => ({ environment: state.environment })

export default connect(mapStateToProps, {})(KPRichToolbar);
