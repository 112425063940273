import React, { Fragment } from "react";
import api from "../_configs/api";
import store from '../store';
import TitleSerif__M from '../components/typo/TitleSerif__M';
import TitleSerif__S from '../components/typo/TitleSerif__S';
import { setAlert } from '../actions/alert';
import ProfileImgDisplay from '../components/generalUI/ProfileImgDisplay'
import PageContainer from '../components/generalUI/PageContainer';
import PageRow from '../components/generalUI/PageRow';
import gridVars from '../sass/gridVars.scss';
import {getFromMultiValPaths, getVal} from './getters';
import {getDaysDiff} from './momentManipulate';

export const default_genSelectedComp = (d, renderFn, options) => {
  const { selectedCompProps } = options;
  return (
    <div className='kp-dy-search-selected-comp'>
      { !selectedCompProps //if no result comp props are passed, create the bare minimum comp
        ? <Fragment>
          <h4 className='h4 bold kp-dy-selected-comp__text'>{d}</h4>
          {renderFn()}
        </Fragment>
        : <Fragment>
          {selectedCompProps.img &&  //this passed prop is nothing but a 'valuePath'
            <ProfileImgDisplay
              className='kp-dy-selected-comp__img'
              size='4rem'
              avatar={set_getValOffQueryString('get', d, selectedCompProps.img)}
            />}
          {selectedCompProps.title &&
            <h4 className='h4 bold kp-dy-selected-comp__text'>
              {set_getValOffQueryString('get', d, selectedCompProps.title)}
            </h4>}
          {renderFn && renderFn()}
        </Fragment>}
    </div>)
}

export const getQueryParam = (string, query) => {
  let params = new URLSearchParams(string);
  return params.get(query);
}

export const createURLQueryString = (obj) => {
  
  var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return '?' + str.join("&");
  
}

export const prepHintsMatrix = (hintsAry) => {
  let masterAry = [];
  let childAry = [];
  hintsAry.map((hint, i) => {
    childAry.push(hint);
    if ((i + 1) % 3 === 0 || i + 1 === hintsAry.length) { //means 3 items are filled in the childAry OR we have hit the last element
      masterAry.push(childAry);
      childAry = [] //reset childAry;
    }
  })
  return masterAry;
}

export const getAryMatchIdx = (value, selectedOptions) => { //can optimize this code by using the findIndex function. this will return index when true & -1 when false. so the index itself can be used to do what isMatch is doing currently.
  let isMatch = false;
  let matchIdx = -1;

  matchIdx = selectedOptions.findIndex(op => op.value === value);
  if (matchIdx !== -1) isMatch = true;

  return { isMatch, matchIdx };
}

export const checkIfFileFormatAreAllowed = formData => {

  let allowedFormatsAry = formData.get('allowedFormats').split(',');

  for (var value of formData.values()) {

    if (value.type) { //if type property exists means its a file
      if (allowedFormatsAry.some(d => value.type.includes(d)) === false) { //if even 1 file doesnt have a format that is mentioned in the allowed formats array, then throw an err
        store.dispatch(setAlert(`you are only allowed to upload ${formData.get('allowedFormats')} format files`, 'danger'));
        return false;
      } else {
        return true;
      }
    }
  }
}

export const imageUploader = async (formData, options = null, callback = null) => {

  try {

    const config = { 
      headers: { "Content-Type": "application/json" },
      onUploadProgress: event => {
        // console.log({progress: Math.round((100 * event.loaded) / event.total)})
        options.handleUploadProgess && options.handleUploadProgess(Math.round((100 * event.loaded) / event.total))
      }
    };
    const urlParam = (options && options.type)
      ? options.type
      : 'files' //defaults to a basic file upload ( on the backend, a file upload simply uploads whats given without transformations)
    const res = await api.post(
      `/api/mediaUpload/${urlParam}`, 
      formData,
      config
    ); //PENDING : I think the config const needs to be passed into this POST as well...
      
    if (res.data.name === "Error" ) {
      store.dispatch(setAlert(res.data.message, "danger"));
      callback && callback("error", res.data);
    }else if(Array.isArray(res.data) && res.data.some(d => d.name === "Error")) {
      store.dispatch(setAlert(res.data.filter(d => d.name === 'Error')[0].msg, "danger"));
      callback && callback("error", res.data);
    }else{
      callback && callback("success", res.data);
    }
  } catch (err) { 
    if(err) console.log('err in utils > general > imageUploader', err.response ? err.response : err); 
    options.handleErrorFeedback && options.handleErrorFeedback('Error! Try Again'); //reset uploading status to false
    store.dispatch(setAlert(err.response ? err.response.data.msg : 'Server Error. Media Upload Failed', "danger"));
  }
}

export function checkObjEquivalent(a, b) {

  if (a) { //only do this thing if the the first value is not undefined

    // Create arrays of property names
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length != bProps.length) {
      return false;
    }

    for (var i = 0; i < aProps.length; i++) {
      var propName = aProps[i];

      // If values of same property are not equal,
      // objects are not equivalent
      if (a[propName] !== b[propName]) {
        return false;
      }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;

  } else {
    return false;
  }
}

export const set_getValOffQueryString = (action, Resource, valuePath, value) => { //action = 'get' or 'set'. value if not required if action is 'get'
  if (valuePath === null) return Resource;
  var ResourceRef = Resource;  // a moving reference to internal objects within Resource
 
  var pathArray = valuePath.split('.');
  // var pathArray = valuePath;
  var len = pathArray.length;
  for (var i = 0; i < len - 1; i++) {
    var elem = pathArray[i];
    if (!ResourceRef[elem]) {
      if (action === 'set') {
        ResourceRef[elem] = {}
      } else { //action === get
        break;
      }
    }
    ResourceRef = ResourceRef[elem]; //if elem is a number (as a string) the code recognizes it as an ary idx; so arrays also work with this func. !
  }
  if (action === 'set') {
    ResourceRef[pathArray[len - 1]] = value;
    return Resource;
  } else if (action === 'delete') {
    delete ResourceRef[pathArray[len - 1]];
    return Resource;
  } else {
    return ResourceRef[pathArray[len - 1]];
  }
}

export const setToValPath = (Resource, valuePath, value) => {
  if(typeof valuePath === 'string'){
    set_getValOffQueryString('set', Resource, valuePath, value);
    return;
  }
  //else it can only be an object with 2 properties:
  //pathToSet: , //pathToGet
  let valueChunk = set_getValOffQueryString('get', value, valuePath.pathToGet);
  set_getValOffQueryString('set', Resource, valuePath.pathToSet, valueChunk );
}



// get the name of option if a value is given.
//specifically used for KPDropdown, wherein, the data structure of an 'option' is { value : someVal, name : someName } //UPDATE: this has changed now. the DD data streucture has been standardized like all else— {value: xx, display: xx}
export const getOptionName = (options, value) => {
  let optionName = null;
  for (var i = 0; i < options.length; i++) {
    if (options[i].value === value) {
      optionName = options[i].name || options[i].display;
      break;
    }
  }
  
  return optionName;
}

export const getTplLangFromContrLang = (contentType, contrLang) => {
  const _TPL_LANG = store.getState().environment.envConfig.languages._TPL_LANG;
  let lang = null;
  if (!contrLang || (Array.isArray(contrLang) && contrLang.length === 0)) return lang;
  //else
  let tplLangIdx = _TPL_LANG[contentType].findIndex(d => d.value === contrLang[0].value);
  if (tplLangIdx !== -1) {
    lang = _TPL_LANG[contentType][tplLangIdx];
  }
  return lang;
}


//FORM VALIDATION

//parent function -- recursive. capable of checking validation at any nested section level
export const formValidationCheck = (blockArray, Resource, depthIdx, emptyFields = [], options={}) => {
  var emptyFieldFound = false;

  blockArray.map((block, i) => {
    let isSection = !!block.subSectionName;
    options.subSectionTree = depthIdx === 0 ? [] : options.subSectionTree;

    if (isSection === true) {

      let subSectionDetails = {
          subSectionName : block.subSectionName,
          subSectionTitle : block.subSectionTitle,
          subSectionSubtitle : block.subSectionSubtitle,
        }
  
      options.subSectionTree[depthIdx] = subSectionDetails

      if (formValidationCheck(block.blocks, Resource, depthIdx + 1, emptyFields, options).emptyFieldFound === true) {
        emptyFieldFound = true;
      };

      }else{

        if(runValidationConditions(block, Resource) === true){
          emptyFieldFound = true;
          emptyFields.push({block, subSectionTree : [...options.subSectionTree]});
          // return;
        }
      }
    
  })

  return { emptyFieldFound, emptyFields };
}

//child function
const runValidationConditions = (block, Resource) => {
  if( !block.readOnly /* < -- pretty sure we dont need this as well... or this ( not so certain about this though. ) --> */ && !block.displayOnly){
    
    // the actual 'is field populated' check. this will keep expanding as new content blocks are introduced
    if(block.isRequired === true){
      let BlockVal = set_getValOffQueryString('get', Resource, block.valuePath);
      if( !BlockVal ||
        ( BlockVal && block.comp === 'KPRichInput' && checkIfRichInputIsPopulated(BlockVal) === false ) ||
        ( BlockVal && ( BlockVal.length === 0 ) )  ||
        ( BlockVal && block.comp === 'KPLikePlacesSearchPlusVillage' && (!BlockVal.location || BlockVal.location.length === 0 )) ||
        ( BlockVal && block.comp === 'KPDynamicPlatformResourceInputv2' && BlockVal.internal.length === 0 && BlockVal.external.length === 0 )
      ){
        return true;
      }
    }

  }
}


export const blockIsPopulatedConditions = (block, value) => {
  return (
    (
      (
        value &&
        (
          (block.comp === "KPRichInput" && checkIfRichInputIsPopulated(value))
          ||
          (block.comp === "KPResourceUpload" &&
            value.length > 0)
          ||
          (block.comp === "KPDateTimePicker")
          ||
          (block.comp === "KPLinkAsButtonInput" &&
            value.length > 0)
          ||
          (block.comp === "KPVideoEmbed" &&
            value.length > 0)
          ||
          (block.comp === "KPTagsInput" &&
            value.length > 0)
          ||
          (block.comp === "KPTextInput")
          ||
          (block.comp === "KPTextArea" &&
            value.length > 0)
          ||
          (block.comp === 'KPLinkOrDownloadableInput')
          ||
          (block.comp === 'KPDynamicSearchInput' && value.length > 0)
          ||
          (block.comp === 'KPDynamicPlatformResourceInput')
          ||
          (block.comp === 'KPDynamicPlatformResourceInputv2' &&
            ((value.internal && value.internal.length > 0) || (value.external && value.external.length > 0)))
          ||
          (block.comp === 'KPDynamicProfileCardInput')
          ||
          (block.comp === 'KPMultiProfileCardInput' && checkMultiProfileCardIsPopulated(value))
          ||
          (block.comp === 'KPFAQComp' && checkFAQCompIsPopulated(value))
          ||
          (block.comp === 'KPExternalLinkInput')
          ||
          (block.comp === 'KPLikeContactChannelsBlock')
          ||
          (block.comp === 'KPLikeOrgAndDesignation' && (value.name.internal.length > 0 || value.name.external.length > 0))
          ||
          (block.comp === 'KPMultiProfilesDisplay' && checkMultiProfileCardIsPopulated(value))
          ||
          (block.comp === 'KPTempDataDb' && value !== 'none')
          ||
          (block.comp === 'KPDateToDate')
          ||
          block.comp === "KPTable"
          ||
          block.comp === "KPTable__NoMemo"
          ||
          block.comp === 'KPRadioInput'
          ||
          (block.comp === 'KPCheckboxInput' && value.length > 0)
          ||
          block.comp === 'KPDropdown'
          ||
          block.comp === 'DropdownSingle'
          ||
          (block.comp === 'DropdownMulti' && value.length > 0)
          ||
          block.comp === 'ComboboxSingle'
          ||
          (block.comp === 'ComboboxMulti' && value.length > 0)
          ||
          (block.comp === 'KPCardLinksDisplay' && value.selectedAry.length > 0)
          ||
          block.comp === "KPImageInput"
          ||
          block.comp === "AudioEmbed"
        )
      ) ||
      block.comp ==="ButtonTab"
      ||
      block.comp === "KPMetaPrimary"
      ||
      block.comp === "MetaSecondary"
      ||
      block.comp === "MetaBlock"
      ||
      block.comp === "MetaBlock__ToolsPage"
      ||
      block.comp === "PageIconBlock"
      ||
      block.comp === "KPCasesDataOverview"
      ||
      block.comp === "TplStaticText" && block.displayAlso === true
    )
  )
}

export const checkIfRichInputIsPopulated = value => {
  
  if (!value) return false;
  return (
    value.blocks.some(block => block.text.length > 0)
    ||

    (value.entityMap &&
      Object.values(value.entityMap).some(entity => (
        entity.data &&
        Object.values(entity.data).some(dataVal => propertyExists(dataVal))
      ))) //basically we check to see if there is any written text OR at least 1 entity has at the least 1 truthy value inside the data object
  )
}

export const checkFAQCompIsPopulated = value => {
  return (
    value &&
    value.length > 0 &&
    value.some(d => Object.values(d).every(dd => dd && dd.blocks[0].text.length > 0)) //basically we are saying, that at least 1 of the Q&A pairs in the value should have a value for both Q & A
  )
}

export const checkMultiProfileCardIsPopulated = value => {
  
  return (
    value &&
    value.length > 0 &&
    value.some(cardData => checkIfCardIsPopulated(cardData) === true) //basically we are saying that at least 1 card should be populated
  )
}

export const checkIfCardIsPopulated = (cardData) => {
  
  if (
    cardData &&
    (
      (cardData.img.imgData && cardData.img.imgData.length > 0) ||
      cardData.nameString.length > 0 ||
      (cardData.desc && cardData.desc.blocks[0] && cardData.desc.blocks[0].text.length > 0) ||
      cardData.selected.length > 0
    ) //basically we are saying, at least 1 field should be populated
  ) {
    return true;
  } else {
    return false;
  }
}
//temp use in contributeconfigurebody
export const populateLocale__newPropsInstance = (props, locales, language) => {
  let newProps = { ...props };

  Object.values(newProps).map((prop, i) => {
    let propKey = Object.keys(newProps)[i];
    if (Array.isArray(prop) && prop[0] === 'locale') {

      newProps[propKey] = set_getValOffQueryString(
        'get',
        locales[language.value],
        prop[1]
      );

    }
    // else if(Array.isArray(prop) && prop[0] !== 'locale'){

    //   prop = prop.map((childProp, i) => {
    //     if( typeof childProp === 'object' && childProp !== null ){ //if aryVal is obj
    //       return childProp = populateLocale(childProp, locales, language);
    //     }else if( typeof childProp === 'string'){ //basically hints written directly into the tpl without the locale setting
    //       return childProp = childProp;
    //     }
    //   })
    //   newProps[propKey] = prop;
    //   //PENDING: if aryVal is array
    // }
  })

  return newProps;
}

export const populateLocale = (initProps, props, locales, language) => {
  let newProps = props; //we are maintaining  direct reference to the og props and modifying it directly. cuz, otherwise, it will mess with React.memo usage
  
  Object.values(initProps).map((prop, i) => {
    let propKey = Object.keys(newProps)[i];

    switch(true){

      //old implementation. can remove after all tpls have been shifted to new local implementation
      case Array.isArray(prop) && prop[0] === 'locale':
      newProps[propKey] = set_getValOffQueryString(
        'get',
        locales[language.value],
        prop[1]
      );
      break;
      
      //new implementation
      case prop && !!prop.locale :
      newProps[propKey] = prop.locale[language.value]
      break;

      case Array.isArray(prop) && prop[0] !== 'locale':
      prop.map((childProp, j) => {
        if (typeof childProp === 'object' && childProp !== null) { //if aryVal is obj
          populateLocale(childProp, Object.values(props)[i][j], locales, language);
        }
      })
      break;

    }
  
  })
  
}

export const pickRandomNum = (max) => {
  return Math.floor(Math.random() * max); //generates random num between 0 to max-1
}

export const checkForUnpublishedClone = (publishedId, editPublishedArray) => {
  let cloneExists = false;
  editPublishedArray.map(d => {
    if (publishedId === d.kp_og_published_doc) {
      cloneExists = true;
      return;
    }
  })
  return cloneExists;
}

const genTitleModule = subSectionDetails => (
  <>
  { (subSectionDetails.subSectionTitle || subSectionDetails.subSectionSubtitle) &&
    <div className='Tpl__subsectionTitleModule'>
      { subSectionDetails.subSectionTitle && 
        <TitleSerif__M className='Tpl__subsectionTitle'>{subSectionDetails.subSectionTitle}</TitleSerif__M> }
      { subSectionDetails.subSectionSubtitle && 
        <TitleSerif__S className='Tpl__subsectionSubtitle' >{subSectionDetails.subSectionSubtitle}</TitleSerif__S> }
    </div> }
  </> )

const genSubSectionWrapper = (subSectionDetails, depthIdx, children) => {
  if(depthIdx === 0 ){
    return (
      <div className={`Tpl__subsection-container  ${subSectionDetails.subSectionClassName}-container`}>
        <div className={`Tpl__subsection-row  ${subSectionDetails.subSectionClassName}-row`}>
          { children }
        </div>
      </div>
    )
  }else{
    return children;
  }
}

export const drilldownSubSection = (blockData, generateContentBlock, depthIdx, options = {}) => {


  let html = blockData.map((block, blockI) => {
    let isSubSection = !!block.subSectionName; //double exclamation will return the truthy / false value of the variable : https://medium.com/better-programming/javascript-bang-bang-i-shot-you-down-use-of-double-bangs-in-javascript-7c9d94446054
    options.subSectionTree = depthIdx === 0 ? [] : options.subSectionTree;
    if (isSubSection === true) {

      const setLocalizedSubSectionTitle = value => {
        return (
          value && 
          (value.locale && options.tplLang
          ? value.locale[options.tplLang.value]
          : value)
        )
      }

      let subSectionDetails = {
        subSectionName : block.subSectionName,
        subSectionClassName : block.subSectionClassName,
        subSectionTitle : setLocalizedSubSectionTitle(block.subSectionTitle),
        subSectionSubtitle : setLocalizedSubSectionTitle(block.subSectionSubtitle),
      }

      options.subSectionTree[depthIdx] = subSectionDetails

      let shouldIndexSubSection = (
        options.indexType === 'subSection' &&
      ( options.hasOwnProperty('showIndexInContributeTpl') 
        ? options.showIndexInContributeTpl 
        : true ) &&
        depthIdx === 1 && //curently we oonly index second level subsections
        options.subSectionTree[0] &&
        options.subSectionTree[0].subSectionName === 'body'
      )
      
      if(shouldIndexSubSection) options.indexedBlocks.push(subSectionDetails);

      const blockIsConsideredPopulated = (block, valuePath) => {
        let value = valuePath && set_getValOffQueryString('get', options.Contribution, valuePath);
        let isConsideredPopulated = blockIsPopulatedConditions(block, value);
        return isConsideredPopulated;
      }

      const shouldGenerateSubSection = blocks => {
        let should = blocks && blocks.length > 0 && blocks.some(block => {
          return options.mode === 'input' 
                  ? block.displayOnly !== true 
                  : options.mode === 'display' &&  //explicitly check if mode === 'display', cuz its possible sometimes mode may equal to undefined, cuz drilldown is used for validation check as well.
                    block.inputOnly !== true &&
                    blockIsConsideredPopulated(block, block.valuePath)
                  
        })
        
        return should;
      }

      

      return (
        <Fragment key={block.subSectionName}>
          {shouldGenerateSubSection(block.blocks) &&
            <section
            className={`Tpl__subsection${depthIdx > 0 ? '__' : ''} ${block.subSectionClassName}`}
            id={`${block.subSectionName}`}
            ref={(el) => {
              if (shouldIndexSubSection && el !== null) options.indexedBlockRefs.push(el);
            }}
            >
          { genSubSectionWrapper(
              subSectionDetails,
              depthIdx,
              <Fragment>
              { genTitleModule(subSectionDetails)}
              { drilldownSubSection(block.blocks, generateContentBlock, depthIdx + 1, options) }
              </Fragment>
            ) }
          </section>} 
        </Fragment>
      )
           
    } else {
      return (
        <Fragment key={(block.props && block.props.id) ? block.props.id : blockI}>
          {generateContentBlock(block, [...options.subSectionTree])}
        </Fragment>
      ) 
    }
  })
  return html;
}



export const setActiveHeaderLink = (currLocationPath, routePath) => {
  if (routePath === currLocationPath) {
    return 'active'
  } else {
    return ''
  }
}

export const setActiveHeaderLinkInDropdown = (currLocationPath, menuOptions) => {
  
  let idx = menuOptions.findIndex(d => d.value === currLocationPath);
  if (idx === -1) {
    return null;
  } else {
    
    return menuOptions[idx];
  }
}

export const injectHttps = (value) => {
  if (['mailto:', 'tel:', 'http://', 'https://' ].every(d => typeof value === 'string' && !value.includes(d))) {
    return 'https://' + value;
  } else {
    return value;
  }
}

export const updateOnlyIdToParent = (key, value, parentOnChange) => {
  if (value && value.length > 0) {
    parentOnChange && parentOnChange(key, value.map(d => d._id));
  }
}

export const propertyExists = property => {

  return (!property ||
    (typeof property === 'object' && Object.keys(property).length === 0) ||
    (Array.isArray(property) && property.length === 0)
  ) === true ? false : true //return false is proerty is falsy OR empty obj OR empty array
}

export const injectClassName = (prefix, className) => {
  return className ? className.split(' ').map(d => prefix + d).join(' ') : ''
}

export const formatTitle = value => {
  if (!value) return null;

  let formattedTitleText = value.trim().split(' ').map(word => {
    return (
      word !== word.toUpperCase()
        ? word[0].toUpperCase() + word.slice(1)
        : word
    )
  }).join(' ')

  return formattedTitleText
}

export const formatText = {
  underscoreToTitle: (string) => {
    let newString = string.replace(/_/g, ' ');
    return newString[0].toUpperCase() + newString.slice(1);
  },
  textToCamelcase: (string) => {

    return string ? string.trim().split(' ').map((word, i) => {
      let reformattedWord = i === 0 ? word : word[0].toUpperCase() + word.slice(1);
      return reformattedWord
    }).join('') : '';
  }
  //other possibilities could be:
  //camelToTitle
  //camelToUpper
  //camelToLower
}

export const convertToRichText = val => {
  if (!val) return null;
  //else
  return ({
    blocks: [
      { text: val }
    ],
    entityMap: {}
  })
}

export const formatValueStringsToDisplayStrings = (valueString) => {
  return (valueString[0].toUpperCase() + valueString.slice(1)).replace('_', ' ')
}


export const isStringMatch = (string, subString, strValPathObj = null) => {
  let isMatch = false;
  // return string.toLowerCase().indexOf(subString.toLowerCase()) === 0; //returns true if substring occurs at the start of the string
  if (Array.isArray(string)) {
    
    let stringAry = string;
    for (var i = 0; i < stringAry.length; i++) {
      let stringToMatch = getVal( stringAry[i], strValPathObj.propertyPath);
      if (stringToMatch && stringToMatch.toLowerCase().indexOf(subString.toLowerCase()) !== -1) {
        isMatch = true;
        break;
      }
    }
    return isMatch;
  }
  //else
  isMatch = string.toLowerCase().indexOf(subString.toLowerCase()) !== -1 //returns true if substring occurs anywhere in the string
  return isMatch;
}

export const extractStringsAryFromErr = err => {
  
  return (
    err.response
      ? err.response.data.errors.map(d => d.msg ? d.msg : d.message)
      : ['error happened. server hasnt told us exactly what. please check the structure in which you are sending err responses from the api.']
  )
}

// export const convertMarkupToHtml = (string) => {
//   // string.split('**');
//   let regex = /(\*\*[^\*]*\*\*)/;
//   let teststr = "Ram **sita** laxman";
//   return string.split(regex).map(d => {
//             return (
//               <Fragment>
//               { regex.test(d)
//                 ? <strong>{d.replace(/^\*+|\*+$/g, '')}</strong>
//                 : <span>{d}</span> }
//               </Fragment>
//             )
//           });
// }

export const charLengthBasedClamp = (string, limit) => {
  let newStr = string;
  if (string.length > limit) {
    newStr = string.substring(0, limit) + '...';
  }
  return newStr;
}

export const isNotBlockedByMaintenence = (userEmail, contentTypeId, _UnderMaintenence) => {
  return (
    !_UnderMaintenence.block ||
    _UnderMaintenence.exceptions.indexOf(userEmail) !== -1 ||
    _UnderMaintenence.contentTypes.indexOf(contentTypeId) !== -1
  )
}

export const isObject = property => {
  return typeof property === 'object' && property !== null
}

export const segrigateDocs = (docs, sortByPath) => {
  let newSegrigatedObj = {};
  docs.map((d, i) => {
    let sortByValue = set_getValOffQueryString('get', d, sortByPath)
    if (!newSegrigatedObj[sortByValue]) {
      newSegrigatedObj[sortByValue] = [];
    }
    newSegrigatedObj[sortByValue].push(d);
  })
  return newSegrigatedObj;
}

export const makeAryFromLen = len => Array.from(Array(len).keys());

export const toArray = property => {
  if (!property) return []; //return an empty array if the property is falsy.
  if (property.constructor === Array) return property;
  //else
  return [property];
}

export const rmArray = property => {
  if (!property) return null; //return an empty array if the property is falsy.
  if (property.constructor !== Array) return property;
  //else
  return property[0];
}

export const convertToGcsStructure = value => { //backward compat --> shifting from cloudinary to gcs
  if (value.imgCropData && value.imgData) { //means this is the old structure
    if (value.imgData.length > 0) {
      //restructure fields
      const { imgData, imgCropData } = value
      let newImgData = {
        publicUrl: imgData[0].secure_url,
        id: imgData[0].public_id,
        cropX: imgCropData.x,
        cropY: imgCropData.y
      }
      return [
        { ...imgData[0], ...newImgData }
      ]
    } else {
      return []
    }

  }
  //else return as is
  return value
}

export const convertResToGcsStructure = value => { //backward compat --> shifting from cloudinary to gcs

  const extractFormat = url => {
    let urlParts = url.split('.');
    return urlParts[urlParts.length - 1];
  }

  if (value.resourceData) { //means this is the old structure
    if (value.resourceData.length > 0) {
      //restructure fields
      const { resourceData } = value
      let newResData = [];
      resourceData.map(d => {
        newResData.push({
          ...d,
          publicUrl: d.secure_url,
          id: d.public_id,
          mediaLink: d.resource_type === 'raw' ? d.secure_url : d.eager[0].secure_url,
          format: extractFormat(d.secure_url)
        })
      })

      return newResData;

    } else {
      return []
    }

  }
  //else return as is
  return value
}

export const breakStringAtLineBreak = string => {
  return string.split('\n');
}

export const mathRound = {
  two : (val) => Math.round(val*100)/100
}

export const convertPxStringToInt = val => {
  return parseInt(val.slice(0, -2));
}

export const getBreakpoint = (bp) => {
  return convertPxStringToInt(gridVars[`${bp}_bp`])
}

export const copyToClipboard = (inputId) => {
  let textElem = document.getElementById(inputId)
  textElem.select();
  document.execCommand('copy');
  alert('link successfully copied to clipboard!');
}

export const sortDataV2 = (data, config) => {
  //sort results from newest to oldest
  // const dateFilter = options.dateFilter || 'kp_date_published';

  const presetFns = {
    alphabetical : (a, b, aPath, bPath) => {
      let aTitle = getFromMultiValPaths( a, aPath);
      let bTitle = getFromMultiValPaths( b, bPath);
      return aTitle.localeCompare(bTitle)
    },
    date : (a,b, aPath, bPath) => {
      let aDate = getFromMultiValPaths( a, aPath);
      let bDate = getFromMultiValPaths( b, bPath);
      return getDaysDiff(aDate, bDate).count;
      // return new Date( bDate ) - new Date( aDate )
    }
  }

  data.sort((a, b) => { 
    const sortFnToUse = presetFns[config.fn];
    return sortFnToUse(a,b, config.aPath, config.bPath);
  }); //a-b === ascending ; b-a === descending ; ref MDN
}


