import * as React from "react"
import {primaryColor100} from '../../../sass/vars.scss';

function CType_Events(props) {
  return (
    <svg width={21} height={21} viewBox="0 0 21 21" fill="none" >
      <rect
        x={2.443}
        y={4.646}
        width={16}
        height={14}
        rx={0.5}
        stroke={props.stroke || primaryColor100}
      />
      <path d="M2.943 8.646h15" stroke={props.stroke || primaryColor100} />
      <rect
        x={6.328}
        y={11.523}
        width={3.934}
        height={3.934}
        rx={0.6}
        stroke={props.stroke || primaryColor100}
        strokeWidth={0.8}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        stroke={props.stroke || primaryColor100}
        strokeWidth={1.2}
        strokeLinecap="round"
        d="M14.043 2.746v3.8M6.543 2.746v3.8"
      />
    </svg>
  )
}

export default CType_Events
