import React, { Fragment } from 'react';
import Moment from 'moment';
import TitleSans__S__Bold from '../components/typo/TitleSans__S__Bold';

export const convertToISOString = datesAry => {
  return datesAry.map(date => {
          let inputMoment = Moment(date);

          return inputMoment.toISOString();
        })
}

export const convertISOStringToJSDate = datesAry => {
  return datesAry.map(date => new Date(date));
}

export const getDaysDiff = (inputDate, dateToCompare = null) => {

  const now = dateToCompare ? Moment(dateToCompare) : Moment();
  const inputMoment = Moment(inputDate);
  const daysDiff = now.diff(inputMoment, 'days', true);
  return {count : daysDiff, dateIsPassed : daysDiff > 0 ? true : false };
}

export const getCurrentDateInFormat = format => Moment().format(format);

export const createContentCardDate = (inputDate, altDate = null, prefix = null, options = {}) => { //alt date is only to deal with the blunder of not saving published date sometime back

  if(!inputDate && !altDate) return null;

  const inputMoment = inputDate ? Moment(inputDate) : Moment(altDate);

  const daysDiff = inputDate ? getDaysDiff(inputDate).count : getDaysDiff(altDate).count;

  let switchDisplayFormatAfter = options.switchDisplayFormatAfter || 7;

  if(Math.abs(daysDiff) > switchDisplayFormatAfter){
    return `${prefix ? prefix : ''} ${inputMoment.format('DD MMM YYYY')}`;
  }else{
    return inputMoment.fromNow();
  }
}

const DATE_FROM_to_TO_argsAry = (inputMomentFrom, inputMomentTo) => {
  let isYearMatch = inputMomentFrom.format('YYYY') === inputMomentTo.format('YYYY');
  let isMonthMatch = inputMomentFrom.format('MMM') === inputMomentTo.format('MMM');
  let dateToFormat = () => {
    let nowYear = Moment().format('YYYY');
    let inputYear = inputMomentFrom.format('YYYY')
    if(nowYear !== inputYear){
      return "DD MMM 'YY";
    } else {
      return 'DD MMM'
    }
  }
  return (
    inputMomentFrom.isSame(inputMomentTo, 'day')
    ? [inputMomentFrom.format(dateToFormat())]
    : [
        isYearMatch && isMonthMatch 
        ? inputMomentFrom.format('DD') + ' - ' + inputMomentTo.format(dateToFormat())
        : isYearMatch
          ? inputMomentFrom.format(dateToFormat()) 
          : inputMomentFrom.format(dateToFormat()) ,
        
        isYearMatch && isMonthMatch ? '' : '-', 
        
        isYearMatch && isMonthMatch ? '' : inputMomentTo.format(dateToFormat())
      ]
  )
}

const renderDateFns = {
  YEAR : (YEAR) => (
    <TitleSans__S__Bold className='cardDateText'>
      {YEAR}
    </TitleSans__S__Bold>
  ),
  DATE_FROM_to_TO : (FROM, to, TO) => (
    <div style={{display: 'flex'}}>
      <TitleSans__S__Bold className='cardDateText'>
        {FROM}
      </TitleSans__S__Bold>
    { TO && 
      <Fragment>
        <TitleSans__S__Bold className='cardDateText' style={{padding: "0 0.5rem"}}>
          {to}
        </TitleSans__S__Bold>
        <TitleSans__S__Bold className='cardDateText'>
          {TO}
        </TitleSans__S__Bold>
      </Fragment> }
    </div>
  ),
  DATE_ON : (ON) => (
    <div >
      <TitleSans__S__Bold className='cardDateText'>
        {ON.split(' ')[0] + ' ' + ON.split(' ')[1] + (ON.split(' ')[2] ? ' ' + ON.split(' ')[2] : '') }
      </TitleSans__S__Bold>
      
    </div>
  ),
  DATE_FROM_NOW : (FROM_NOW) => (
    <TitleSans__S__Bold className='cardDateText'>
      {FROM_NOW}
    </TitleSans__S__Bold>
  ),
  DATE_PREFIX_and_ON : (PREFIX, ON) => (
    <Fragment>
      <TitleSans__S__Bold className='cardDateText'>
        {PREFIX}
      </TitleSans__S__Bold>  
      <TitleSans__S__Bold className='cardDateText'>
        {ON}
      </TitleSans__S__Bold>  
    </Fragment>  
  )
}

export const createContentCardDateRange = (inputDateRange, altDate = null, prefix = null, useRenderFn = false) => { //alt date is only to deal with the blunder of not saving published date sometime back

  if(!inputDateRange) return null;

  const inputMomentFrom = inputDateRange[0] && Moment(inputDateRange[0]);
  const inputMomentTo = inputDateRange[1] && Moment(inputDateRange[1]);

  const daysDiffFromStart = inputDateRange[0] && getDaysDiff(inputDateRange[0]).count
  const daysDiffFromEnd = inputDateRange[1] && getDaysDiff(inputDateRange[1]).count

  if(daysDiffFromStart < -2){
    const toReturn = DATE_FROM_to_TO_argsAry(inputMomentFrom, inputMomentTo);
    return useRenderFn 
           ? toReturn.length > 1 ? renderDateFns.DATE_FROM_to_TO(...toReturn) : renderDateFns.DATE_ON(...toReturn) 
           : toReturn.join(' ');

  }else if(daysDiffFromStart < 0 && daysDiffFromStart > -2) {
    return useRenderFn 
           ? renderDateFns.DATE_FROM_NOW(inputMomentFrom.fromNow()) 
           : inputMomentFrom.fromNow();

  }else if(daysDiffFromStart > 0 && daysDiffFromEnd < 0) { //in progress
    return daysDiffFromEnd > -2 
                   ? useRenderFn 
                     ? renderDateFns.DATE_FROM_NOW(`ends ${inputMomentTo.fromNow()}`) 
                     : `ends ${inputMomentTo.fromNow()}` 
                   : useRenderFn
                     ? renderDateFns.DATE_PREFIX_and_ON('ends on', inputMomentTo.format(/*'DD MMM YYYY'*/ 'DD MMM'))
                     : `ends on ${inputMomentTo.format(/*'DD MMM YYYY'*/ 'DD MMM')}`;
    
  }else if(daysDiffFromEnd > 0 && daysDiffFromEnd < 2) {
    return useRenderFn 
           ? renderDateFns.DATE_FROM_NOW(inputMomentFrom.fromNow()) 
           : inputMomentFrom.fromNow();
           
  }else{
    
    const toReturn = DATE_FROM_to_TO_argsAry(inputMomentFrom, inputMomentTo );
    
    return useRenderFn 
           ? toReturn.length > 1 ? renderDateFns.DATE_FROM_to_TO(...toReturn) : renderDateFns.DATE_ON(...toReturn) 
           : toReturn.join(' ');
  }
}

export const createContentCardTime = (inputTime) => {
  let newInputTime = null;

  if(Array.isArray(inputTime) === true){ //means its a time range
    newInputTime = [...inputTime];
    newInputTime = newInputTime.map(t => Moment(t, 'HH:mm').format('hh:mm a')).join(' - ') //convert from military time to am-pm time
  }else{
    newInputTime = Moment(inputTime, 'HH:mm').format('hh:mm a'); //means its single time
  }

  return newInputTime;
}

export const manipulateDateRangeOnTimeRangeChange = (pickerType, val, value) => {
  if(pickerType === 'timeRange' && val){ // the start time just got defined. so force it into the dateRange in case the dateRange is already defined
    if(value.dateRange){
      if(val[0]){
        value.dateRange[0].setHours(
          val[0].split(':')[0],
          val[0].split(':')[1],
          '00'
        )
      };
      if(val[1]){
        value.dateRange[1].setHours(
          val[1].split(':')[0],
          val[1].split(':')[1],
          '00'
        )
      }     
      return { dateRange: value.dateRange, [pickerType] : val  };
    }
    
  }else if(pickerType === 'dateRange' && value.timeRange ){ //if date has been changed and start time has already been defined, try to force it into the date
    if(value.timeRange[0]){
      val[0] && val[0].setHours(
        value.timeRange[0].split(':')[0],
        value.timeRange[0].split(':')[1],
        '00'
      )
    }
    if(value.timeRange[1]){
      val[1] && val[1].setHours(
        value.timeRange[1].split(':')[0],
        value.timeRange[1].split(':')[1],
        '00'
      )
    }

    return { ...value, [pickerType] : val }
  }else{ //for everything else
    return { ...value, [pickerType] : val }
  }
}

export const manipulateDateOnTimeRangeChange = (pickerType, val, value) => {
  if(pickerType === 'timeRange' && val && val[0] ){ // the start time just got defined. so force it into the date in case the date is already defined
    value.date && value.date.setHours(
      val[0].split(':')[0],
      val[0].split(':')[1],
      '00'
    )
    return { date: value.date, [pickerType] : val,  };
  }else if(pickerType === 'date' && value.timeRange && value.timeRange[0] ){ //if date has been changed and start time has already been defined, try to force it into the date
    val && val.setHours(
      value.timeRange[0].split(':')[0],
      value.timeRange[0].split(':')[1],
      '00'
    )
    return { ...value, [pickerType] : val }
  }else{ //for everything else
    return { ...value, [pickerType] : val }
  }
}


//custom, non-moment dependent functions

export const genYearsArray = (fromYr, toYr) => {
  let yrsAry = [];
  for(var i = fromYr ; i <= toYr ; i++){ yrsAry.unshift(i.toString()); }
  return yrsAry;
}

export const genYearsArray_v2 = (fromYr, toYr) => {
  let yrsAry = genYearsArray(fromYr, toYr).map(d => { 
    return { display: d, value: d }
  })
  return yrsAry;
}

export const genMonthsArray = nameLength => {
  let form = {
    long : [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ],
    short : [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ]
  }
  return form[nameLength];
}

export const genMonthsArray_v2 = nameLength => {
  let monthsAry = genMonthsArray(nameLength).map(d => {
    return { display: d, value: d.toLowerCase()}
  })
  return monthsAry
}
