import * as React from "react"

function Alert__Danger(props) {
  return (
    <svg width={30} height={30} viewBox="0 0 30 30" fill="none" {...props}>
      <circle cx={15} cy={15} r={15} fill="#FF6A6A" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 21L9 10l1.414-1.414 11 11L20 21z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 10L10 21l-1.414-1.414 11-11L21 10z"
        fill="#fff"
      />
    </svg>
  )
}

export default Alert__Danger;
