import * as React from "react"

function Alert__Success(props) {
  return (
    <svg width={30} height={30} viewBox="0 0 30 30" fill="none" {...props}>
      <circle cx={15} cy={15} r={15} fill="#390" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.699 11.716l-9.962 9.718-4.471-4.847 1.47-1.356 3.076 3.335 8.49-8.282 1.397 1.432z"
        fill="#fff"
      />
    </svg>
  )
}

export default Alert__Success
