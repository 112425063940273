import React, { useState, Fragment, useEffect, useRef } from "react";
import _, {debounce} from 'lodash';
import KPLabel from '../../generalUI/KPLabel';
import { KPBtn, ButtonSecondary } from '../../generalUI/KPButtons';

import LinkIcon from '../../icons/LinkIcon';
import Mail24 from '../../icons/Mail24';
import Facebook_solid_SM from '../../icons/Facebook_solid_SM';
import Linkedin_solid_SM from '../../icons/Linkedin_solid_SM';
import Twitter_solid_SM from '../../icons/Twitter_solid_SM';
import Youtube_solid_SM from '../../icons/Youtube_solid_SM';
import Instagram_solid_SM from '../../icons/Instagram_solid_SM';
import Mail_solid_SM from '../../icons/Mail_solid_SM';
import Phone_solid_SM from '../../icons/Phone_solid_SM';
import Website_solid_SM from '../../icons/Website_solid_SM';
import Search from '../../icons/Search';
// Eye/ Visible Password Icon
import EyeStrike from '../../icons/Eye_strike';
import EyeVisible from '../../icons/Eye_visible';

//local functions
import { validateUrl, validateEmail, debounceValidateEmail } from './functions';
import { darkPrimary, primaryColor100, white, red, green } from '../../../sass/vars.scss';
import { injectHttps } from '../../../utils/general';
import { getLabelProps } from '../../../utils/contentBlock';

import DisplayCompForTextInputs from './DisplayCompForTextInputs'
import { useTrackDdFocus } from '../../../utils/customHooks';

import UploadImage from '../../icons/UploadImage';
import OkeGoogle from '../../icons/New/OkeGoogle';
import Setting_SM from '../../icons/SettingsSM'


import {
  PencilSimple,
  PencilLine,
  Plus,
  Lightbulb,
  UploadSimple,
  CaretLeft,
  CaretRight,
  XCircle,
  DownloadSimple,
  ArrowLineUpRight,
  User,
  MapPinLine,
  WarningCircle,
  Paperclip,
  SpeakerHigh,
  ArrowLeft,
  CalendarBlank,
  Trash,
  DotsThreeVertical,
  CaretUp,
  CaretDown,
  ShareNetwork,
  X,
  Eye,
  EyeSlash,
  MagnifyingGlass,
  LinkSimple
} from "phosphor-react";

const KPTextInput = (props) => {

  const
    {
      invert,
      inputType,
      id,
      name,
      className,
      value,
      placeholder,
      onChange: parentOnChange,
      onFocus: parentOnFocus,
      onBlur: parentOnBlur,
      onError : parentOnError,
      disabled,
      icon,
      iconProps,
      size,
      readOnly,
      isRequired,
      actionBtn,
      autoComplete,
      restrictValUpdate, //this is for the 'copy link' module inside KPShareActions comp.
      passChangeHandlerOps,
      suffix,
      fireChangeHandlerOnMount,
      maxNumLimiter // this is for number input if we dont want more than x numbers to be inputted. useful for phone numbers for example
    } = props;


  const icons = {
    LinkIcon,
    Mail24,
    Facebook_solid_SM,
    Linkedin_solid_SM,
    Twitter_solid_SM,
    Youtube_solid_SM,
    Instagram_solid_SM,
    Mail_solid_SM,
    Phone_solid_SM,
    Website_solid_SM,
    Search,
    // --------------//

    UploadImage,
    PencilLine,
    Plus,
    PencilSimple,
    Lightbulb,
    UploadSimple,
    CaretLeft,
    CaretRight,
    OkeGoogle,
    XCircle,
    DownloadSimple,
    ArrowLineUpRight,
    User,
    MapPinLine,
    WarningCircle,
    Paperclip,
    SpeakerHigh,
    ArrowLeft,
    CalendarBlank,
    Trash,
    DotsThreeVertical,
    CaretUp,
    CaretDown,
    ShareNetwork,
    X,
    MagnifyingGlass,
    LinkSimple
  }

  //PENDINGS:
  //even if url / email is invalid, i think it still gets passed as a value to the parent.
  //this should not happen. we might need to use local state for value to do this.

  //if number input is invalid, then the native behaviour is that 
  //the value does not pass through but need to cross check

  //   const val = value || "";
  const Icon = icon && icons[icon];
  const [passwordIsMasked, setPasswordIsMasked] = useState(true);

  
  const [localErrorMsgs, setLocalErrorMsgs] = useState([]);
  const [isFocussed, setIsFocussed] = useState(false);
  const inputRef = useRef(null);


  let isInverted = invert ? "input--invert" : "";
  // let isfocussed = focussed ? "focussed" : "";
  let eyeIconColor = isInverted ? white : primaryColor100;

  const eyeIcon = passwordIsMasked ? <Eye
    strokeWidth="0"
    size={24}
    color={eyeIconColor} /> :
    <EyeSlash
      strokeWidth="0"
      size={24}
      color={eyeIconColor}
    />

 
  useEffect(() => {

    if (inputType === "url") validateUrl(value, handleErrorMsgs);
    fireChangeHandlerOnMount && parentOnChange && parentOnChange(id, value, passChangeHandlerOps && props)

  }, [value]);

  const handleErrorMsgs = (msg = undefined) => {
    setLocalErrorMsgs(msg ? [msg] : [])
    parentOnError && parentOnError(id, msg);
  }

  const handleChange = (e) => {

    if (restrictValUpdate) return;
    if (inputType === 'number') {
      if (e.target.value.toString().length > maxNumLimiter) {
        e.target.value = value; //set e.target.value to previous value;
        handleErrorMsgs("Maximum Number Limit Reached!")
      }
      else if (inputType === 'number' && isNaN(e.target.value)) {
        handleErrorMsgs(["Input must be a number"])
      } else {
        handleErrorMsgs()
      }

    }
    
    if (inputType === "email") {
      debounceValidateEmail(e.target.value, handleErrorMsgs );
    }
    parentOnChange(id, e.target.value, passChangeHandlerOps && props,)

  }

  useEffect(() => {
    fireChangeHandlerOnMount && parentOnChange && parentOnChange(id, value, passChangeHandlerOps && props)
  }, [])

  const hasError = (localErrorMsgs) => {
    if(localErrorMsgs.length === 1 && localErrorMsgs[0].type === 'success') return false;
    //else
    if(localErrorMsgs.length > 0) return true;
  }

  const genInputComp = () => (
    <Fragment>
      <div
        className={`kp-input-wrapper ${isInverted} ${isFocussed ? 'focussed' : ''} ${hasError(localErrorMsgs) ? '-has-error-' : ''}`}
        onClick={() => {
          setIsFocussed(true);
          inputRef.current && inputRef.current.focus();
        }}
      >
        {/* <div className={`kp-input-pseudo-wrapper ${isInverted}`}> */}
        {Icon &&
          <div className='kp-input-icon'>
            <Icon
              strokeWidth="0"
              size={24}
              stroke={props.color}
              // style={{ marginRight: "1rem" }}
              className="input__icon"
              {...iconProps} />
          </div>
        }
        {inputType === 'password' &&
          <div
            className='kp-pwd-icon'
            onClick={(e) => setPasswordIsMasked(!passwordIsMasked)}
          // style={{ right: "2rem"}}
          >
            {eyeIcon}
          </div>
        }
        <input
          onFocus={(e) => {
            setIsFocussed(true);
            parentOnFocus && parentOnFocus(e)
          }}
          onBlur={(e) => {
            setIsFocussed(false)
            parentOnBlur && parentOnBlur(e)
          }}
          type={inputType === 'password'
            ? (passwordIsMasked ? "password" : "text")
            : (inputType === 'number' ? 'text' : inputType)}
          //off is supposed supposed to turn this off
          autoComplete={autoComplete}
          name={name}
          id={id}
          className={`inputText kp-input kp-${inputType}-input   ${size}`}
          value={value ? value : '' /* its important to do this in this way, because if the new value that comes into this comp is undefined, it doesnt refresh the older value. And im guessing if it is null, that would also create some strange issue. */}
          placeholder={placeholder}
          aria-labelledby={`${id}_label`}
          disabled={disabled}
          onChange={(e) => handleChange(e)}
          ref={inputRef}
        />
        {/* </div> */}
        {actionBtn &&
          <ButtonSecondary
            onClick={() => actionBtn.onClick()}
            invert={actionBtn.invert}
            disabled={
              inputType === 'url'
                ? (localErrorMsgs.some(d => d.id === 'invalidUrl') || !!value === false ? true : false)
                : !(!!value)
            }
            value={actionBtn.text}
            className="textInput--actionBtn"
          />

        }
      </div>
      {/* { validatorText.text &&
        <div className="url-status--wrapper">
          <h6
            className={`h5 medium url--status ${validatorText.type === 'danger' ? 'color-red' : 'color-green'}`}>
            <WarningCircle
              strokeWidth="0"
              size={20}
              style={{ marginRight: ".5rem", marginTop: "-.2rem" }}
              color={validatorText.type === 'danger' ? red : green}
            />
            {validatorText.text}
            {validatorText.type === 'success' &&
              <a className='h5' style={{ textDecoration: 'underline', color: primaryColor100, paddingLeft: '1rem' }} href={injectHttps(value)} target="_blank">test link</a>}
          </h6>
        </div>} */}
    </Fragment>
  )

  const genDisplayComp = () => (
    <DisplayCompForTextInputs
      id={id}
      className={className}
      value={value}
      suffix={suffix}
    />
  )

  return (
    <div className={`kp-input-container ${className} ${isFocussed ? 'focussed' : ''}`}>
      <KPLabel
        {...getLabelProps(props)}
        errorMsgs={
          getLabelProps(props).errorMsgs
            ? [
              ...getLabelProps(props).errorMsgs,
              ...localErrorMsgs
            ]
            : localErrorMsgs
        }
      />
      { !readOnly
        ? genInputComp()
        : genDisplayComp()}
    </div>
  );
};

KPTextInput.defaultProps = {
  inputType: 'text',
  disabled: false,
  size: 'large',
  restrictValUpdate: false,
  autoComplete: 'off',
  name: "Add a name via the 'name prop", //optional, i think...
  className: "", //optional
  placeholder: "Write Here", //optional
  // onChange and value need to be handled by the onChange function in the parent
}


export default KPTextInput; 