import React, {Fragment} from "react";


const TitleSans__S = (props) => {

  

  return (
    <h3 className={`titleSans--S ${props.className || ''}`} style={props.style} id={props.id || ''}>{props.children}</h3>
  );

}


export default TitleSans__S;
