import React, { Fragment, useRef } from "react";
import NotifListItem from '../NotifListItem';

import { greyColor5, white, primaryColor10,greyColor15 } from '../../../sass/vars.scss'
import { handleClickOutside } from '../../../utils/customHooks';
import TitleSans__S__Bold from '../../typo/TitleSans__S__Bold'

const NotifDropdown = ({ data, className, style , onClick}) => {

  //reverse order to get the newsrt first;
  let newDataAry = [];
  data && data.map(d => newDataAry.unshift(d));

  return (
    <div className={`kp-notif-dd ${className}`} style={style}>
    { (data && Array.isArray(data) && data.length > 0)
      ? newDataAry.map((d,i) => (
      <NotifListItem
        key={i}
        style={{
          borderBottom: i !== (data.length-1) ? `1px solid ${greyColor15}` : 'none',
          backgroundColor: d.kp_isRead ? white : primaryColor10
        }}
        onClick={onClick}
        title={d.kp_msg_title}
        desc={d.kp_msg_desc}
        link={d.kp_link}
        isRead={d.kp_isRead}
        type={d.kp_type}
        category={d.kp_category}
        date={d.kp_date}
        />
    ))
    : <TitleSans__S__Bold>{'You have 0 notifications!'}</TitleSans__S__Bold>
    }
    </div>
  );
}

export default NotifDropdown
