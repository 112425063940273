import api from './../_configs/api';
import {
  GET_OGS_META,
  SET_OGS_META_LOADING,
  OGS_META_ERROR,
  RESET_OGS
}
from './types';

export const getOgs = (url, callback = null) => async dispatch => {
  try{

    dispatch({ type: SET_OGS_META_LOADING, payload: true});
    const config = { headers: { "Content-Type": "application/json" }};
    
    const res = await api.post("/api/ogs", { url }, config);
    dispatch({ type: GET_OGS_META, payload: res.data });
    callback && callback(res.data);

  }catch(err){
    dispatch({ type: OGS_META_ERROR, payload: { errors: [{ msg: err.response }] } })
  }
}

export const resetOgs = () => dispatch => dispatch({ type: RESET_OGS})
