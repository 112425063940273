import * as React from "react"
import {primaryColor100} from '../../../sass/vars.scss';

function CType_Activities(props) {
  return (
    <svg width={21} height={21} viewBox="0 0 21 21" fill="none" >
      <path
        d="M12.943 3.384h3.5a1 1 0 011 1v13.638a1 1 0 01-1 1h-12a1 1 0 01-1-1V4.384a1 1 0 011-1h3.5"
        stroke={props.stroke || primaryColor100}
      />
      <path
        d="M13.043 4.396v.225h-5.2V3.183a3.88 3.88 0 00.855-1.41c.043-.13.074-.25.095-.352h3.3c.022.103.052.221.096.352.132.395.382.901.854 1.41v1.213z"
        stroke={props.stroke || primaryColor100}
        strokeWidth={0.8}
      />
      <path
        d="M5.445 15.688l.667.667 1.333-1.334M5.445 11.688l.667.667 1.333-1.334"
        stroke={props.stroke || primaryColor100}
        strokeWidth={0.7}
      />
      <path
        d="M9.945 15.722h5.5M9.945 11.722h5.5M9.945 7.722h5.5"
        stroke={props.stroke || primaryColor100}
        strokeLinecap="round"
      />
      <path
        d="M5.445 7.688l.667.667L7.445 7.02"
        stroke={props.stroke || primaryColor100}
        strokeWidth={0.7}
      />
    </svg>
  )
}

export default CType_Activities
