import uuid from 'uuid';
import {
  SET_ALERT,
  REMOVE_ALERT,
  SET_ANNOUNCEMENT,
  REMOVE_ANNOUNCEMENT
}
from './types';


export const setAnnouncement = (text, type, timeout = null, options = null) => dispatch => {
  dispatch({
    type: SET_ANNOUNCEMENT,
    payload: {
      text,
      type,
      options
    }
  });

  //only if timeout is defined then remove the announcement. else it stays.
  if(timeout){
    setTimeout(() => dispatch({
      type: REMOVE_ANNOUNCEMENT
    }), timeout);
  }
  
}

export const setAlert = (msg, alertType, timeout = 5000, options = null) => dispatch => {
  const id = uuid.v4();
  dispatch({
    type: SET_ALERT,
    payload: {
      msg,
      alertType,
      id,
      options
    }
  });

  setTimeout(() => dispatch({
    type: REMOVE_ALERT,
    payload: id
  }), timeout);
}

export const removeAlert = id => dispatch => dispatch({ type: REMOVE_ALERT, payload: id })
