import {
  GET_OGS_META,
  SET_OGS_META_LOADING,
  OGS_META_ERROR,
  RESET_OGS
} from '../actions/types';

const initialState = {
  loading : false,
  ogsMeta : null,
  error : null
};

export default function(state = initialState, action) {
  const {type, payload} = action

  switch (type) {
    case GET_OGS_META:
      return {
        ...state,
        ogsMeta : payload,
        error : null,
        loading : false
      };
    case SET_OGS_META_LOADING:
      return {
        ...state,
        loading : payload
      };
    case OGS_META_ERROR:
      return {
        ...state,
        ogsMeta : null,
        error : payload,
        loading : false
      };
    case RESET_OGS:
    return {
      ...state,
      loading : false,
      ogsMeta : null,
      error : null
    }
    default:
      return state;
  }
}
