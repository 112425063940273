import React, { useState, useEffect, Fragment } from "react";
import KPLabel from '../../generalUI/KPLabel';

import { greyColor15} from '../../../sass/vars.scss';
import {KPTextInput, DisplayCompForTextInputs} from '../../inputs/KPInputs';

import { getAryMatchIdx } from '../../../utils/general';
import {getLabelProps} from '../../../utils/contentBlock'
import CheckboxElement from "../../generalUI/CheckboxElement";

const KPCheckboxInput = props => { //col settings must be the bootstrap codes

  // console.log('RERENDER CHECKBOX : ' + props.id);
   
  const consolidateOptionsFromGroups = (optionGroups) => {
    let tempAry = []
    optionGroups.map(d => {
      tempAry = [...tempAry, ...Object.values(d)];
    })
    return tempAry;
  }

  const initVal = props.optionGroups
                  ? consolidateOptionsFromGroups(props.optionGroups)
                  : props.options

  const [options, setOptions] = useState(initVal);
  const [selectedOptions, setSelectedOptions] = useState(props.value || []);
  const [showTextInput, setShowTextInput] = useState(
    (props.value && props.value.some(d => d.value === 'other')) ? true : false
  );

  useEffect(() => {
    
    let newOptions = [...options];
    
    if(props.showOtherOption && props.injectOtherOption !== false ) { /*this is bit of jugaad having both of these things. we need to get rid of showOtherOptions eventually*/
      newOptions.push({ value: 'other', display: 'Other'})
    }
    
    setOptions(newOptions);
  },[])



  const handleClick = (inputId, clickedOption ) => {

    let tempSelectedOptions = [...selectedOptions];
    let { isMatch, matchIdx } = getAryMatchIdx(clickedOption.value, selectedOptions);
    
    clickedOption.value === 'other' && (
      isMatch === false ? setShowTextInput(true) : setShowTextInput(false) )
    if(isMatch === false){ //value isnt already selected, so select it
      tempSelectedOptions.push(clickedOption)
    }else{ //value is already selected, so deselect it
      tempSelectedOptions.splice(matchIdx, 1);
    }

    setSelectedOptions(tempSelectedOptions);
    props.onChange && props.onChange(inputId, tempSelectedOptions, props.passChangeHandlerOps && props);
  }

  const handleTextInputChange = (k,v) => {
    let newOptionVal = {value: 'other', value_desc: v, display: 'Other'};
    let newSelectedOptions = selectedOptions.map(d => d.value === 'other' ? {...d, value_desc: v} : d );
    setSelectedOptions(newSelectedOptions);
    props.onChange && props.onChange(props.id, newSelectedOptions, props.passChangeHandlerOps && props);
  }

  const setTextInputVal = (selOps) => {
    let idx = selOps.findIndex(d => d.value_desc && d.value_desc.length > 0);
    return idx !== -1 ? selOps[idx].value_desc : null
  }

  const generateOptions = (optionsAry) => {
    let tempOptionsAry = optionsAry;
    if(typeof optionsAry === 'object'){ //strangely mongodb is converting a nested array into an obj. this is a quick fix. debug this later.
      tempOptionsAry = Object.values(optionsAry);
    }
    
    return(
      <Fragment>
    { tempOptionsAry.map((option, i) => {
      
      return(
        <Fragment key={i}>
          <KPCheckboxOption

            option = {option}
            selectedOptions = {selectedOptions}
            onClick = {() => handleClick(props.id, option)}
            />
        </Fragment>
      )}) }
      </Fragment>
    )
  }

  const joinSelectedOptionsIntoString = (selectedOptions) => {
    if (selectedOptions.length === 0) return '';
    let selectedOptionTextToDisplay = selectedOptions.map(d => d.value_desc || d.display);
    if(selectedOptionTextToDisplay.length === 1) return selectedOptionTextToDisplay[0];
    //else
    return selectedOptionTextToDisplay.join(', ');
  };

  const genDisplayComp = () => (
    <DisplayCompForTextInputs
      value={joinSelectedOptionsIntoString(selectedOptions)}
    />
  )

  const genInputComp = () => (
    <Fragment>
  { props.optionGroups
    ? props.optionGroups.map((ops,i) => (
      <Fragment key={i}>
        <div className='kp-checkbox-input'>
          { generateOptions(ops) }
        </div>
      { i !== props.optionGroups.length-1 && <div style={{marginBottom: '2rem', marginTop: '1rem', borderBottom: `1px solid ${greyColor15}` }}></div> }
      </Fragment>
      ))
    : <div className='kp-checkbox-input'> { generateOptions(options) } </div> }
    { showTextInput &&
      <KPTextInput
        value={ setTextInputVal(selectedOptions)}
        placeholder='Please Specify Here'
        onChange={(k,v) => handleTextInputChange(k,v)}/> }
    </Fragment>
  )

  return (
    <div className={`kp-checkbox-input-wrapper ${props.className}`} id={props.id}>
      <KPLabel {...getLabelProps(props)} />
      {props.readOnly ? genDisplayComp() : genInputComp()}
    </div>
  )
}



export const KPCheckboxOption = props => (

  <div 
    onClick={props.onClick}
    className={`kp-checkbox-input__option`}
    >
    <CheckboxElement 
      isSelected={getAryMatchIdx(props.option.value, props.selectedOptions).isMatch === true}
      />
    <h5 className='h5 sans-serif kp-checkbox-input__option-text'>{props.option.display}</h5>
  </div>

)

KPCheckboxInput.defaultProps = {
  showOtherOption : true
}

export default React.memo(KPCheckboxInput);
