import React, { useState, useEffect, Fragment } from "react";
import KPLabel from '../../generalUI/KPLabel';
import {KPTextInput, DisplayCompForTextInputs} from '../../inputs/KPInputs';
import {formatText} from '../../../utils/general';
import {simpleBlockGenerator, getLabelProps} from '../../../utils/contentBlock';
import RadioInputElement from "../../generalUI/RadioInputElement";


const KPRadioInput = props => { //col settings must be the bootstrap codes

  // console.log('RERENDER RADIO : ' + props.id);

  const initVal = () => {
    if(!props.value) return { value: null, display: null};
    //else
    return typeof props.value === 'string' //backwards compatiblity. earlier using dropdowns, we stored only the 'value' and not the 'display'
    ? { value: props.value, display: formatText.underscoreToTitle(props.value) }
    : props.value;
  }

  const createOptions = (options) => {
    if(!props.injectOtherOption || props.options.some(d => d.value === 'other')) return options;
    //else
    options.push({ value: 'other', display: 'Other'})
  }

  const [options, setOptions] = useState(createOptions(props.options));
  const [selectedOption, setSelectedOption] = useState(initVal);
  const [showTextInput, setShowTextInput] = useState((props.value && props.value.value === 'other' && props.injectOtherOption) ? true : false);

  useEffect(() => {

    setSelectedOption(props.value || {value: null, display: null});
    setOptions(props.options)
  },[props.value, props.options])

  const handleClick = (inputId, clickedOption ) => {

    let newSelected = /**/clickedOption.value === 'other' ? {...selectedOption, ...clickedOption} :/**/ clickedOption ; //the stuff marked between the symbols might be unnecessary. need to investigate thoroughly

    setSelectedOption(newSelected);
    clickedOption.value === 'other' && props.injectOtherOption ? setShowTextInput(true) : setShowTextInput(false);
    props.onChange && props.onChange(inputId, newSelected, props.passChangeHandlerOps && props);
  }

  const handleTextInputChange = (k,v) => {
    let newOptionVal = {value: 'other', value_desc: v, display: 'Other'};
    setSelectedOption(newOptionVal)
    props.onChange && props.onChange(props.id, newOptionVal, props.passChangeHandlerOps && props);
  }

  const genInputComp = () => (
    <Fragment>
      { props.customComp && (props.customComp.json ? simpleBlockGenerator(props.customComp.json, props, 'kp-radio-input__custom-comp ' + props.customComp.className) : props.customComp.jsx) }
      <div className='kp-radio-input' style={props.style}>
      { options && options.map((option,i) => (
          <div 
            key={option.value} 
            onClick={() => handleClick(props.id, option)}  
            className='kp-radio-input__option' style={props.optionStyle}
            >
            <RadioInputElement
              isSelected = {selectedOption.value === option.value}
              />
          { props.optionsAsImages /* is this even being used anywhere? */
            ? <img src={option.display} alt={option.value}/>
            : <h4 className='kp-radio-input__btn-text h5 sans-serif'>{option.display}</h4> }
          </div> )) }
      </div>
      { showTextInput &&
        <KPTextInput
          placeholder='Please Specify Here'
          value={selectedOption.value_desc && selectedOption.value_desc }
          onChange={(k,v) => handleTextInputChange(k,v)}/> }
    </Fragment>
  )

  const genDisplayComp = () => (
    <DisplayCompForTextInputs
      value={props.value && props.value.display}
    />
  )

  return (
    <div className={`kp-radio-input-wrapper ${props.className}`} id={props.id}>
       <KPLabel {...getLabelProps(props)} />
    { props.readOnly ? genDisplayComp() : genInputComp() }
    </div>
  )
}

KPRadioInput.defaultProps = {
  injectOtherOption : true
}

export default React.memo(KPRadioInput);
