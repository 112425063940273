import React, { Fragment, useState, useEffect, useRef } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import store from "../../../store";

import { MagnifyingGlass, List, DotsThree } from "phosphor-react";

//KP components
import HamburgerMenu from "../HamburgerMenu";

import ProfileImgDisplay from "../../generalUI/ProfileImgDisplay";
import NotifDropdown from "../../notifications/NotifDropdown";

//redux
import { connect, useDispatch, useSelector } from "react-redux";
import { logout } from "../../../actions/auth";

//logo

//other icons
import { Dropdown } from "../../inputs/Dropdowns";

//actions
import { updateIsRead } from "../../../actions/notifications";
import { getPublishedListing } from "../../../actions/listings";

//utils
import {
  setActiveHeaderLink,
  setActiveHeaderLinkInDropdown,
} from "../../../utils/general";
import { getRichInputText } from "../../../utils/getters";
//config

import {
  _KnowledgeMenuOptions,
  _CommunityMenuOptions,
  _HeaderSubMenuOptions,
  genKnowledgeListingOption,
  _DataMenuOptions,
} from "../../../utils/nav";

import { greyColor100, primaryColorText } from "../../../sass/vars.scss";

import { getBreakpoint } from "../../../utils/general";
import { useTrackDdFocus } from "../../../utils/customHooks";
import TextLoader from "../../loaders/TextLoader";
import OkeBell from "../../icons/New/OkeBell";

const PrimaryHeader = ({
  environment: { screenWidth },
  auth: { isAuthenticated, loading, user },
  listings,
  logout,
}) => {
  const {
    deployment: {
      _ContributeCTAText,
      _ShowModerationFunctions,
      _EnableNotifications,
      _Logos,
    },
    _Nav,
  } = useSelector((state) => state.environment.envConfig);

  let location = useLocation();
  let history = useHistory();

  const dispatch = useDispatch();

  const [notifDdOpen, setNotifDdOpen] = useState(false);
  const [primaryMenuItems, setPrimaryMenuItems] = useState(
    _KnowledgeMenuOptions
  );
  const [primaryMenuKebabItems, setPrimaryMenuKebabItems] = useState([]);

  useEffect(() => {
    if (!_Nav.secondary) {
      document.body.setAttribute("class", "-six-six-");
    }
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const notifDdRef = useRef(null);
  const primaryLinksRef = useRef(null);

  useTrackDdFocus(notifDdRef, setNotifDdOpen);

  const myAccountMenuOptions = [
    {
      display: "My Profile",
      value: `/profile/userProfiles/${user && user._id}`,
    },
    {
      display: "My Drafts",
      value: {
        pathname: `/profile/userProfiles/${user && user._id}`,
        state: "showDrafts",
      },
    },
    { display: "Reset Password", value: `/reset-password` },

    ...(user && user.role === "superAdmin"
      ? [{ display: "Admin Dashboard", value: "/admin-dashboard" }]
      : []),
    // ...((user && user.role === 'superAdmin') ? superAdminMenuOptions : []),
    ...(user &&
    _ShowModerationFunctions &&
    ["moderator", "superAdmin"].indexOf(user.role) !== -1
      ? [{ display: "Moderation Dashboard", value: "/mod-queue" }]
      : []),
    { display: "Logout", value: "logout" },
  ];

  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  useEffect(() => {
    dispatch(getPublishedListing("staticPages", "long", listings));
  }, []);

  const handleHeaderMenuSelect = (key, route) => history.push(route.value);

  const handleMyAccountMenuSelect = (k, v) => {
    if (v.value === "logout") {
      logout();
    } else {
      history.push(v.value);
    }
  };

  const handleNotifBtnClick = () => {
    //PENDING: update isRead
    if (
      !notifDdOpen &&
      user &&
      user.notifications.some((d) => d.kp_isRead === false)
    ) {
      store.dispatch(updateIsRead(user.notifications, user._id));
    }
    setNotifDdOpen(!notifDdOpen);
  };

  const getTotUnreadNotifs = (notifData) => {
    return notifData.filter((d) => d.kp_isRead === false).length;
  };

  const genLogo = () => (
    <Link className="OKE-Header__logo" to="/">
      <img
        className="OKE-Header__logoImage"
        src={
          screenWidth >= getBreakpoint("lg") ? _Logos.logo : _Logos.logoShort
        }
      />
    </Link>
  );

  const genPublishButton = () => (
    <Link
      to="/contribute-config"
      className="OKE-Header__publishButton-linkWrapper"
    >
      <button className="OKE-Header__publishButton">
        {_ContributeCTAText || "Publish"}
      </button>
    </Link>
  );

  const genHamburger = () => (
    <Fragment>
      <button
        className="OKE-Header__hamburgerButton"
        onClick={() => setHamburgerOpen(!hamburgerOpen)}
      >
        <List size={24} weight="light" />
      </button>
      <HamburgerMenu
        hamburgerOpen={hamburgerOpen}
        setHamburgerOpen={(boolean) => setHamburgerOpen(boolean)}
      />
    </Fragment>
  );

  const genSecondaryLink = (thisPathname, display, className = "") => (
    <li
      className={`OKE-Header__secondaryLink ${className} ${setActiveHeaderLink(
        location.pathname + location.search,
        thisPathname
      )}`}
    >
      <Link
        className={`headerLink--secondary OKE-Header__secondaryLink-link`}
        to={thisPathname}
      >
        {display}
      </Link>
    </li>
  );

  const handleOverflowItems = (elem, thisPathname) => {
    if (elem) {
      let { width: elemWidth, right: elemRight } = elem.getBoundingClientRect();
      let idx = primaryMenuItems.findIndex((d) => d.value === thisPathname); //this items index
      let newItems = [...primaryMenuItems];
      let newPrimaryMenuKebabItems = [...primaryMenuKebabItems];

      if (elemRight > screenWidth - 60 && !newItems[idx].style) {
        newItems[idx] = {
          ...newItems[idx],
          style: { display: "none" },
          width: elemWidth,
        };
        newPrimaryMenuKebabItems.unshift(newItems[idx]);

        setPrimaryMenuItems(newItems);
        setPrimaryMenuKebabItems(newPrimaryMenuKebabItems);
      } else {
        if (
          primaryLinksRef.current &&
          primaryLinksRef.current.getBoundingClientRect().width +
            newItems[idx].width <
            screenWidth - 120 &&
          newItems[idx].value === primaryMenuKebabItems[0].value
        ) {
          delete newItems[idx].style;
          newPrimaryMenuKebabItems.splice(0, 1);

          setPrimaryMenuItems(newItems);
          setPrimaryMenuKebabItems(newPrimaryMenuKebabItems);
        }
      }
    }
  };

  const genPrimaryLink = (thisPathname, display, style, className = "") => (
    <li
      ref={(elem) => handleOverflowItems(elem, thisPathname)}
      className={`OKE-Header__primaryLink ${className} ${setActiveHeaderLink(
        location.pathname + location.search,
        thisPathname
      )}`}
      style={style ? style : {}}
    >
      <Link
        className={`headerLink--primary OKE-Header__primaryLink-link`}
        to={thisPathname}
      >
        {display}
      </Link>
    </li>
  );

  const genStaticPageLink = (pageId) => {
    const {
      staticPagesListing,
      staticPagesListing: { staticPagesLoading },
    } = listings;

    const getPageData = (pageId) => {
      let idx = staticPagesListing.initData.findIndex((d) => d._id === pageId);
      return staticPagesListing.initData[idx];
    };

    return staticPagesLoading ? (
      <TextLoader
        style={{ width: "5rem", height: "1.5rem", margin: "0 1rem" }}
      />
    ) : (
      genSecondaryLink(
        `/published-page/staticPages?id=${pageId}`,
        getRichInputText(getPageData(pageId).main.title)
      )
    );
  };

  const genStaticPageOption = (pageId) => {
    const {
      staticPagesListing,
      staticPagesListing: { staticPagesLoading },
    } = listings;

    const getPageData = (pageId) => {
      let idx = staticPagesListing.initData.findIndex((d) => d._id === pageId);
      return staticPagesListing.initData[idx];
    };

    return staticPagesLoading
      ? { loading: true }
      : {
          display: getRichInputText(getPageData(pageId).main.title),
          value: `/published-page/staticPages?id=${pageId}`,
        };
  };

  const genGlobalSearchLink = () => (
    <Link to="/search" className="OKE-Header__globalSearchLink">
      <MagnifyingGlass size={24} weight="light" />
    </Link>
  );

  //wassan customizations

  const genGlobalnCareersLink = () => {
    const careersMenuOptions = [
      {
        display: "Careers",
        value: `/careers`,
      },
    ];
    const placeholder = "Careers";
    const id = "hello123";

    return (
      <li className="OKE-Header__dropdown--secondary">
        <Dropdown
          id={id}
          placeholder={placeholder}
          value={setActiveHeaderLinkInDropdown(
            location.pathname + location.search,
            careersMenuOptions
          )}
          options={careersMenuOptions}
          onChange={(key, value) => handleHeaderMenuSelect(id, value)}
          caretColor={greyColor100}
          variant={"single"}
        />
      </li>
    );
  };

  const genResourceLinks = (menuConfigOptions, primary_secondary) => (
    <Fragment>
      {menuConfigOptions.map((d) => (
        <Fragment key={d.value}>
          {primary_secondary === "primary"
            ? genPrimaryLink(d.value, d.display, d.style)
            : genSecondaryLink(d.value, d.display)}
        </Fragment>
      ))}
    </Fragment>
  );

  const genResourceDropdown = (menuConfigOptions, options) => {
    const { placeholder, id } = options;
    return (
      <li className="OKE-Header__dropdown--secondary">
        <Dropdown
          id={id}
          placeholder={placeholder}
          value={setActiveHeaderLinkInDropdown(
            location.pathname + location.search,
            menuConfigOptions
          )}
          options={menuConfigOptions}
          onChange={(key, value) => handleHeaderMenuSelect(id, value)}
          caretColor={greyColor100}
          variant={"single"}
        />
      </li>
    );
  };

  const genNotifications = () => (
    <div ref={notifDdRef} className="OKE-Header__notifications">
      <button
        className="OKE-Header__notificationsButton"
        onClick={() => handleNotifBtnClick()}
      >
        <OkeBell
          size={24}
          weight="light"
          number={user && getTotUnreadNotifs(user.notifications)}
        />
      </button>
      {user && user.notifications && (
        <NotifDropdown
          className="OKE-Header__notificationsList"
          style={{ display: notifDdOpen ? "block" : "none" }}
          data={user && user.notifications}
          onClick={() => setNotifDdOpen(false)}
        />
      )}
    </div>
  );

  const genMyAccountMenu = () => {
    const genCustomSelectComp = () => (
      <button className="OKE-Header__myAccountMenuButton">
        <ProfileImgDisplay
          avatar={user && user.avatar}
          size={3}
          placeholderInitial={user && user.name.trim()[0]}
        />
      </button>
    );

    return (
      <Dropdown
        id="myAccountMenu"
        className="OKE-Header__myAccountMenu"
        genCustomSelectComp={genCustomSelectComp}
        value={setActiveHeaderLinkInDropdown(
          location.pathname,
          myAccountMenuOptions
        )}
        options={myAccountMenuOptions}
        onChange={(k, v) => handleMyAccountMenuSelect(k, v)}
        optionsAnchor="right"
      />
    );
  };

  const genAuthLinks = () => (
    <Fragment>
      {_EnableNotifications && genNotifications()}
      {genMyAccountMenu()}
    </Fragment>
  );

  const genGuestLinks = () => (
    <Fragment>
      {genSecondaryLink(
        {
          pathname: "/login_registration",
          state: { from: location.pathname + location.search },
        },
        "login",
        "OKE-Header__loginLink"
      )}
    </Fragment>
  );

  return (
    <div className="OKE-Header">
      <div className="OKE-Header1">
        <div className="OKE-Header1-content">
          <div className="OKE-Header1__module1">
            {screenWidth < getBreakpoint("lg") && genHamburger()}
            {genLogo()}
          </div>
          <div className="OKE-Header1__module2">
            {screenWidth >= getBreakpoint("lg") && (
              <Fragment>
                {_Nav.primary.map((config) => {
                  if (config.staticPage) {
                    return genStaticPageLink(config.staticPage);
                  } else if (config.subMenu) {
                    let options;
                    if (typeof config.subMenu === "string") {
                      options = _HeaderSubMenuOptions[config.subMenu];
                      console.log("oopopopopo", options);
                    } else {
                      options = [];
                      config.subMenu.map((d) => {
                        if (typeof d === "string") {
                          options = [...options, ..._HeaderSubMenuOptions[d]];
                        } else if (d.knowledgeListing) {
                          options.push(
                            genKnowledgeListingOption(
                              d.knowledgeListing,
                              d.display
                            )
                          );
                        } else if (d.staticPage) {
                          options.push(genStaticPageOption(d.staticPage));
                        }
                      });
                    }
                    return genResourceDropdown(options, {
                      id: config.id,
                      placeholder: config.display,
                    });
                  }
                })}
                {/* { _CommunityMenuOptions.length > 0 && genResourceDropdown(_CommunityMenuOptions, {id: 'community', placeholder: 'Community'}) }
          { genResourceLinks(_DataMenuOptions, 'secondary')} */}
              </Fragment>
            )}
            {/* //wassan customizations */}
            {genGlobalnCareersLink()}
            {genGlobalSearchLink()}
            {screenWidth >= getBreakpoint("sm") && genPublishButton()}
            {!loading && isAuthenticated ? genAuthLinks() : genGuestLinks()}
          </div>
        </div>
      </div>
      {_Nav.secondary && screenWidth >= getBreakpoint("lg") && (
        <div className="OKE-Header2">
          <div
            ref={primaryLinksRef}
            className="OKE-Header2__primaryLinks-wrapper"
          >
            {genResourceLinks(primaryMenuItems, "primary")}
            {primaryMenuKebabItems.length > 0 && (
              <Dropdown
                id="primaryLinksKebabMenu"
                className="OKE-Header__primaryLinksKebabMenu"
                genCustomSelectComp={() => (
                  <DotsThree
                    size={24}
                    weight="light"
                    color={primaryColorText}
                  />
                )}
                value={setActiveHeaderLinkInDropdown(
                  location.pathname,
                  primaryMenuKebabItems
                )}
                options={primaryMenuKebabItems}
                onChange={(k, v) => handleHeaderMenuSelect(k, v)}
                optionsAnchor="right"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

PrimaryHeader.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  environment: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  environment: state.environment,
  listings: state.listings,
});

export default connect(mapStateToProps, { logout })(PrimaryHeader);
