import * as React from "react"

function LinkIcon(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M12.519 16.377L9.597 19.3a3.099 3.099 0 01-4.382 0v0a3.099 3.099 0 010-4.382l2.922-2.922M11.51 8.623L14.43 5.7a3.099 3.099 0 014.383 0v0a3.099 3.099 0 010 4.382l-2.922 2.922M9.206 15.165l5.529-5.431"
        stroke={props.stroke || "#fff"}
        fill="none"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default LinkIcon
