export const chooseContentTypeBody = {

  events : {
    title : 'Events',
    desc : 'Share details about upcoming events here.',
    ctaText : 'Create An Event'
  },
  resources : {
    title : 'Research',
    desc : 'Share downloadable resources such as case studies, reports, policy briefs and others here',
    ctaText : 'Share A Research'
  },
  initiatives : {
    title : 'Initiatives',
    desc : 'Share reports about initiatives undertaken by the Network and its members over here',
    ctaText : 'Write about an Initiative'
  },
  stories : {
    title : 'Stories',
    desc : 'Share stories about a person, group or experiences from the field here',
    ctaText : 'Share A Story'
  },
  videoStories : {
    title : 'Films / Videos',
    desc : 'Share videos, films, documentaries about rainfed agriculture or from the field over here',
    ctaText : 'Share A Film / Video'
  },
  photoStories : {
    title : 'Photo Stories',
    desc : 'Share photo stories about a person, group or experiences from the field here',
    ctaText : 'Share'
  },
  activities : {
    title : 'Activities',
    desc : 'Share reports, minutes and updates from activities such as workshops, training sessions and meetings',
    ctaText : 'Write About An Activity'
  },
  news : {
    title : 'News',
    desc : 'Share links to news items relevant to the network here',
    ctaText : 'Post A News Article'
  },
  mediaCoverage : {
    title : 'Media Coverage',
    desc : 'Share links to news items relevant to the network here',
    ctaText : 'Post'
  },
  tools : {
    title : 'Tools & Guidelines',
    desc : 'Create new tools, experiences, guidelines, parcels of information that can be used as elements and components',
    ctaText : 'Contribute A Guideline Or Tool'
  },
  toolkits : {
    title : 'Toolkits',
    desc : 'Create a new toolkit around a process, technique or knowledge you want to share',
    ctaText : 'Put Together A Toolkit'
  },
  recommendations : {
    title : 'Recommendations',
    desc : '',
    ctaText : 'Create A Recommendation'
  },
  plans : {
    title : 'Village Action Plan',
    desc : '',
    ctaText : 'Create A Village Action Plan'
  },
  caseReports : {
    title : 'Case Reports',
    desc : '',
    ctaText : 'Report'
  },
  demographics : {
    title : 'Demographic Screening',
    desc : '',
    ctaText : 'Make An Entry'
  },
  IDIYM : {
    title : 'IDI Young Men',
    desc : '',
    ctaText : 'Create A Research Note Entry'
  },
  IDIGD : {
    title : 'IDI Game Developers',
    desc : '',
    ctaText : 'Create A Research Note Entry'
  },
  IDIAHE : {
    title : 'IDI Adolescent Health Experts',
    desc : '',
    ctaText : 'Create A Research Note Entry'
  },
  IDIFM : {
    title : 'IDI Family Members',
    desc : '',
    ctaText : 'Create A Research Note Entry'
  },
  COGAMEYM : {
    title : 'Co-Gameplay',
    desc : '',
    ctaText : 'Make Notes'
  },
  FGDYM : {
    title : 'FGD Guide : Young men',
    desc : '',
    ctaText : 'Make Notes'
  },
  DGMC : {
    title : 'Discussion Guide : Metaphor Cards',
    desc : '',
    ctaText : 'Make Notes'
  },
  DGME : {
    title : 'Discussion Guide : Mobile Ethnography',
    desc : '',
    ctaText : 'Make Notes'
  },
  DGHV : {
    title : 'Discussion Guide : Home Visit',
    desc : '',
    ctaText : 'Make Notes'
   }
}
