import * as React from "react"
import {black} from '../../../sass/vars.scss';

function Close_S(props) {
  return (
    <svg width={13} height={13} viewBox="0 0 13 13" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.693 1.785a.75.75 0 010 1.06L2.56 10.978a.75.75 0 11-1.06-1.06l8.131-8.132a.75.75 0 011.06 0z"
        fill={props.fill || black}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 1.785a.75.75 0 011.061 0l8.132 8.132a.75.75 0 11-1.06 1.06L1.5 2.847a.75.75 0 010-1.06z"
        fill={props.fill || black}
      />
    </svg>
  )
}

export default Close_S
