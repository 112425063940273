import * as React from "react"
import { primaryColor100 } from '../../../sass/vars.scss';

function Twitter_solid_SM(props) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" >
      <path
        d="M17.494 8.029a6.777 6.777 0 01-1.757.445c.632-.349 1.115-.897 1.341-1.557-.589.325-1.24.554-1.934.682a3.18 3.18 0 00-2.228-.891c-1.688 0-3.047 1.265-3.047 2.816 0 .223.02.438.07.642-2.535-.114-4.78-1.236-6.286-2.945a2.67 2.67 0 00-.418 1.423c0 .975.544 1.84 1.355 2.34-.49-.008-.97-.14-1.378-.347v.031c0 1.368 1.057 2.505 2.443 2.767a3.282 3.282 0 01-.8.092c-.195 0-.392-.01-.577-.048.396 1.115 1.517 1.935 2.85 1.962a6.46 6.46 0 01-3.781 1.2c-.25 0-.49-.01-.73-.038a9.113 9.113 0 004.679 1.263c5.612 0 8.68-4.291 8.68-8.011a6.64 6.64 0 00-.01-.364 5.849 5.849 0 001.528-1.462z"
        fill={props.fill || primaryColor100}
      />
    </svg>
  )
}

export default Twitter_solid_SM
