import {

  GET_PROFILE,
  GET_PROFILE_TPL,
  GET_PROFILE_REL_CONTENT,

  HYDRATE_PROFILE_FROM_MY_PROFILE,
  PROFILE_ERROR,

  CLEAR_PROFILE,
  PROFILE_LOADING,
  CONTRS_BY_PROFILE_LOADING,

  PROFILE_SAVING,

  SAVE_CHANGES_TO_PROFILE,
  UPDATE_CLEAR_TO_PROCEED_USER_PROFILE,
  UPDATE_PROFILE,

  UPDATE_MODE,

  UPDATE_DRAFT_CONTRIBUTIONS_BY_PROFILE,

  DELETE_PROFILE,
  DELETE_PROFILE_ERROR,

  DELETE_DRAFT_ERROR

 } from "../actions/types";

const initialState = {
  mode: 'inactive',
  editRel: {
    isEditAuth: false
  },
  validationRel: {
    clearToProceed: false,
    emptyFields: []
  },
  contributionsByProfile: null,
  tpl: null,
  Profile: null,
  loading: true,
  contributionsLoading: true,
  saving: false,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case HYDRATE_PROFILE_FROM_MY_PROFILE:
      return {
        ...state,
        Profile: payload,
        loading: false
      };
    case GET_PROFILE:
      return {
        ...state,
        mode: payload.mode,
        Profile: payload.Profile,
        tpl: payload.tpl,
        // contributionsByProfile: payload.contributionsByProfile,
        editRel: {
          isEditAuth: payload.isEditAuth
        },
        // validationRel: { //@NOTE: we have currently deactivated validation check on getProfile(); this might change in the future...
        //   clearToProceed: payload.clearToProceed,
        //   emptyFields: payload.emptyFields
        // },
        loading: false
      };
    case GET_PROFILE_REL_CONTENT:
      return{
        ...state,
        contributionsByProfile: payload,
        contributionsLoading: false
      }

    case GET_PROFILE_TPL:
      return {
        ...state,
        mode: payload.mode,
        tpl: payload.tpl,
        Profile: { meta: { tpl: payload.tpl._id }},
        loading: false
      };

    case UPDATE_PROFILE:
    return {
      ...state,
      Profile: payload,
      loading: false
    };
    case UPDATE_CLEAR_TO_PROCEED_USER_PROFILE:
    return{
      ...state,
      validationRel : {
        clearToProceed: payload.clearToProceed,
        emptyFields: payload.emptyFields
      }
    };
    case SAVE_CHANGES_TO_PROFILE:
    return{
      ...state,
      Profile: payload,
      saving: false
    };
    case UPDATE_MODE:
    return{
      ...state,
      mode: payload
    };
    case UPDATE_DRAFT_CONTRIBUTIONS_BY_PROFILE:
    return{
      ...state,
      contributionsByProfile: {
        ...state.contributionsByProfile,
        payload
      }
    };
    case DELETE_PROFILE:
    return{
      ...state,
      loading: false
    }
    case DELETE_DRAFT_ERROR:
    case DELETE_PROFILE_ERROR:
    return{
      ...state,
      error : payload,
      loading : false
    };
    case PROFILE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case CLEAR_PROFILE:
      return {
        ...state,
        mode: 'inactive',
        editRel: {
          isEditAuth: false
        },
        validationRel: {
          clearToProceed: false,
          emptyFields: []
        },
        contributionsByProfile: null,
        tpl: null,
        Profile: null,
        loading: true,
        error: {}
      };
    case PROFILE_SAVING:
      return {
        ...state,
        saving: payload
      };
    case PROFILE_LOADING:
      return {
        ...state,
        loading: payload
      };
    case CONTRS_BY_PROFILE_LOADING:
      return {
        ...state,
        contributionsLoading: payload
      }
    default:
      return state;
  }
}
