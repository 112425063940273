import api from "./../_configs/api";
import { setAlert } from "./alert";
import history from "../history";
import { getUserProfileByID } from "./profiles";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_MY_PROFILE,
  UPDATE_USER,
  UPDATE_USER_ERROR,
  SET_AUTH_LOADING,
  VERIF_TOKEN_SUCCESS,
  VERIF_TOKEN_ERROR,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_ERROR,
  RESET_VERIF_REL_DATA,
  PWD_RESET_MAIL_SEND_SUCCESS,
  PWD_RESET_MAIL_SEND_ERROR,
  RESET_TPL,
  CLEAR_PROFILE,
} from "./types";
import setAuthToken from "../utils/setAuthToken";

// Load User
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await api.get("/api/auth");
    dispatch({ type: USER_LOADED, payload: res.data });
  } catch (err) {
    let shouldDestroyLocalToken = false;
    if (err.response && err.response.data && err.response.data.name) {
      let errName = err.response.data.name;
      //destroy token only if the error caused in backend was because the supplied token was invalid for whatever reason.
      shouldDestroyLocalToken =
        ["TokenExpiredError", "JsonWebTokenError", "NotBeforeError"].indexOf(
          errName
        ) !== -1
          ? true
          : false;
    }
    shouldDestroyLocalToken && setAuthToken(null);
    dispatch({ type: AUTH_ERROR, payload: { shouldDestroyLocalToken } });
  }
};

// Register user
export const register =
  ({ name, email, password }) =>
  async (dispatch) => {
    dispatch({ type: SET_AUTH_LOADING, payload: true });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ name, email, password });

    try {
      const res = await api.post("/api/users", body, config); //payload only contains msg 'verif email sent' and email details which we dont need right now.

      dispatch({ type: REGISTER_SUCCESS, payload: res.data });
      history.push("/verifyEmail");
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) =>
          dispatch(setAlert(error.msg.message, "danger"))
        );
      }

      dispatch({ type: REGISTER_FAIL });
    }
  };

//send reset pwd mail
export const sendPwdResetMail = (email) => async (dispatch) => {
  try {
    dispatch({ type: SET_AUTH_LOADING, payload: true });
    const config = { headers: { "Content-Type": "application/json" } };
    const res = await api.post(
      "/api/users/resetPasswordMail",
      { email },
      config
    );
    dispatch({ type: PWD_RESET_MAIL_SEND_SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({ type: PWD_RESET_MAIL_SEND_ERROR, payload: err.response.data });
    // dispatch(setAlert(err.response.data.msg.message));
    if (err) console.log("err in sendPwdResetMail", err);
  }
};

//verifyToken
export const verifyToken = (pathname, token) => async (dispatch) => {
  try {
    dispatch({ type: SET_AUTH_LOADING, payload: true });
    const config = { headers: { "x-auth-token": token } };

    const res = await api.post(
      `/api/users${pathname.replace(`/${token}`, "")}`,
      {},
      config
    );
    dispatch({ type: VERIF_TOKEN_SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({ type: VERIF_TOKEN_ERROR, payload: err.response.data });
  }
};

//resend token
export const refreshToken = (token) => async (dispatch) => {
  try {
    dispatch({ type: SET_AUTH_LOADING, payload: true });
    const config = { headers: { "x-auth-token": token } };
    const res = await api.post(
      "/api/users/refreshToken?type=refreshVerifyEmail",
      {},
      config
    );
    dispatch({ type: REFRESH_TOKEN_SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({ type: REFRESH_TOKEN_ERROR, payload: err.response.data });
  }
};

export const resetVerifRelData = () => (dispatch) =>
  dispatch({ type: RESET_VERIF_REL_DATA });

// Store token after Google Signin // from pagetpls/GoogleAuth
export const googleAuthJWT = (token) => (dispatch) => {
  try {
    dispatch({ type: SET_AUTH_LOADING, payload: true });
    dispatch({ type: LOGIN_SUCCESS, payload: { token: token } });
    dispatch(loadUser());
    // window.location.replace('/'); // Go To Home Page // Google Signed in
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    } else {
      dispatch(setAlert("Oops. something went wrong. Try again.", "danger"));
    }
    dispatch({ type: LOGIN_FAIL });
  }
};

// Login user
export const login = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ email, password });

  try {
    dispatch({ type: SET_AUTH_LOADING, payload: true });
    const res = await api.post("/api/auth", body, config);
    dispatch({ type: LOGIN_SUCCESS, payload: res.data });
    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => {
        error.type && error.type === "invalidSigninMode"
          ? dispatch(
              setAlert(error.msg, "danger", 6000, {
                linkText: "“Forgot Password”",
                linkUrl: {
                  pathname: `/verifyResetPasswordMail`,
                  state: { msg: { name: "enterPwdResetMail" } },
                },
              })
            )
          : dispatch(setAlert(error.msg, "danger"));
      });
    } else {
      dispatch(setAlert("Oops. something went wrong. Try again.", "danger"));
    }
    dispatch({ type: LOGIN_FAIL });
  }
};

// Logout / Clear Profile
export const logout = () => (dispatch) => {
  setAuthToken(null);
  dispatch({ type: LOGOUT });
  dispatch({ type: RESET_TPL });
  dispatch({ type: CLEAR_PROFILE });
  history.push("/login_registration");
};

//Update user
export const updateUser =
  (formData, updateType = "name") =>
  async (dispatch) => {
    try {
      dispatch({ type: SET_AUTH_LOADING, payload: true });

      let headers = formData.token
        ? { "Content-Type": "application/json", "x-auth-token": formData.token }
        : { "Content-Type": "application/json" };

      delete formData.token;

      const config = { headers };

      const res = await api.post("/api/users/update", formData, config);

      dispatch({ type: UPDATE_USER, payload: res.data });

      if (updateType === "password") {
        dispatch(setAlert("Password Updated Successfully", "success"));
      } else {
        dispatch(setAlert("User Updated", "success"));
      }

      formData.redirectToLogin && history.push(`/login_registration`);
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }
      dispatch({
        type: UPDATE_USER_ERROR,
        payload: {
          msg: err.response.statusText,
          status: err.response.status,
        },
      });
    }
  };
