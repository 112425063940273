import React, { useState, Fragment } from "react";

const KPMetaHero = ({ className, data }) => {

  return (
    <div className={`kp-meta-hero-wrapper ${className}`}>
      <div className='kp-meta-hero'>
        {data}
      </div>

    </div>
  );
}

export default KPMetaHero
