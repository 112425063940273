import * as React from "react"
import { primaryColor100 } from '../../../sass/vars.scss';

function ChevronUpSM(props) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" >
      <path
        d="M15.5 12.75l-4.793-4.793a1 1 0 00-1.414 0L4.5 12.75"
        stroke={props.stroke || primaryColor100}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ChevronUpSM;



