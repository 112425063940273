import React, {Fragment} from "react";
import { useSelector } from 'react-redux';

import BodySans__M from "../../typo/BodySans__M";
import {KPBtn, ButtonSecondary} from "../../generalUI/KPButtons";

const Announcement = (props) => {

  const announcement = useSelector((state) => state.environment.announcement);

  console.log('sw', navigator.serviceWorker);
  console.log("annoucement.options)",announcement && announcement.options);

  let actions = {
    skipWaitingAndReload : (options) => {
      let skipWaitingMessageChannel = new MessageChannel();
      options.sw.postMessage({type: 'SKIP_WAITING_AND_RELOAD'}, [skipWaitingMessageChannel.port2]);

      skipWaitingMessageChannel.port1.onmessage = (event) => {
        switch(event.data.type){
          case "REFRESH_PAGE":
          window.location.reload();
          break;
        }
      }
      
    }
  }
  
  return (
    <Fragment>
    { announcement.text &&
      <div id="OKE_Announcement" className={`Announcement ${announcement.type || ''}`} >
        <BodySans__M className='Announcement__text'>{announcement.text}</BodySans__M> 
      { announcement.options && announcement.options.cta && 
        <ButtonSecondary 
          invert={true}
          className='Announcement__action'
          onClick={() => announcement.options.cta.action && actions[announcement.options.cta.action](announcement.options)}
          >
          {announcement.options.cta.text}
        </ButtonSecondary> }
      </div> }
    </Fragment>
  )
}

export default Announcement;
