import store from '../store';
import api from "./../_configs/api";
import {
  GET_PUBLISHED_LISTING,
  GET_PUBLISHED_LISTING_SHORT,
  SKIP_GET_LISTING,

  GET_USERS_LISTING,

  GET_USER_PROFILE_LISTING,
  GET_USER_PROFILE_LISTING_SHORT,

  GET_MIXED_LISTING,

  GET_TAGS_LISTING,
  UPDATE_TAG_COUNT,
  UPDATE_TAG_COUNT_ERROR,
  ADD_TAG,
  ADD_TAG_ERROR,
  SET_ADD_TAG_LOADING,

  UPDATE_LISTING_DATA,
  RESET_LISTING_TO_INIT_DATA,

  FETCH_LISTING_FROM_STORE,

  CLEAR_USER_PROFILE_LISTING,
  CLEAR_PUBLISHED_LISTING,
  SET_LISTING_LOADING,
  GET_LISTING_ERROR,

  GET_PARTICIPANTS_LISTING,
  PARTICIPANTS_LOADING,

  SET_KNOWLEDGE_API_QUEUE_LOADING,

  REFETCH_SUB_RESOURCE,
  SET_SUB_RESOURCE_LISTING,

  SET_GROUPED_PUBLISHED_LISTING_LOADING,
  GET_GROUPED_PUBLISHED_LISTING,
  GET_GROUPED_PUBLISHED_LISTING_ERROR,
  RESET_GROUPED_PUBLISHED_LISTING,
  UPDATE_GROUPED_LISTING_DATA,
  RESET_GROUPED_LISTING_TO_INIT_DATA,
  FETCH_GROUPED_LISTING_FROM_STORE,

  SET_ACTIVE_SORT,
  SET_GROUPED_LISTING_ACTIVE_SORT
} from "./types";

import { set_getValOffQueryString, set_getValOffQueryString as s_gVal, sortDataV2 } from '../utils/general';
import {getVal} from '../utils/getters';
import { consolidateAndSortMeta, filterExperiments } from '../utils/filterUtils';

import { _GetContentTypeConfig, _ContentTypeListingGroups } from '../_configs/contentTypes/config';
import { _GetProfileTypeConfig } from '../_configs/profileTypes/config';

export const setActiveSort = (activeSort, listingKey, options={}) => dispatch => {
  let dispatchTypes = {
    SET_ACTIVE_SORT,
    SET_GROUPED_LISTING_ACTIVE_SORT
  }

  //the dispatch type can be passed via the action function, but if not, we default to SET_ACTIVE_SORT
  dispatch({type: options.dispatchType ? dispatchTypes[options.dispatchType] : SET_ACTIVE_SORT, payload: activeSort, stateObj: listingKey })
}

export const sortDataInStore = (data, sortConfig, listingKey, options = {}) => dispatch => {
  let newData = [...data];
  let type = UPDATE_LISTING_DATA;
  let stateObj = listingKey;
  if(options.isGroupedListing){ // so listing key is actually a 2 part 'path' i.e groupedPublishedListing.<some groupKey>
    type = UPDATE_GROUPED_LISTING_DATA
  }
  
  sortDataV2(newData, sortConfig)
  dispatch({
    type,
    stateObj,
    payload: {
      data: newData,
      activeSort: sortConfig
    }
  })
}

export const getSubResourceListing = (resourceId, resourceType, subResourceValuePath) => async dispatch => {
  //currently only works for profile types
  
  try {
   
    dispatch({ type: REFETCH_SUB_RESOURCE, payload: true });
    let res;
    if(['userProfiles', 'organisations'].indexOf(resourceType) !== -1 ){
    res = await api.get(`/api/${resourceType}/${resourceId}`);
    }else{
      res = await api.get(`/api/groups/${resourceType}/${resourceId}`);
    }

    let subResource = s_gVal('get', res.data, subResourceValuePath);

    dispatch({
      type: SET_SUB_RESOURCE_LISTING,
      payload : subResource
    })  

  } catch (err) {
    if(err) console.log('err in getSubResourceListing', err);
  }
  
} 

export const setKnowledgeApiQueueLoading = bool => dispatch => dispatch({ type : SET_KNOWLEDGE_API_QUEUE_LOADING, payload: bool})

export const getGroupedPublishedListing = (groupKey, listings, options = {}) => async dispatch => {

  const _FiltersConfig = store.getState().environment.envConfig.filters._FiltersConfig;

  let locationState;
  if(options) locationState = options.locationState;
  
  if(
    listings && 
    listings.groupedPublishedListing[groupKey] &&
    listings.groupedPublishedListing[groupKey].data.length > 0 && 
    locationState !== 'contrPublishedOrSubmitted'
  ){ //locationState is the previous location from where the user navigated to the listingpage
    dispatch({type: FETCH_GROUPED_LISTING_FROM_STORE})
    return;
  }

  try {
    dispatch({ type: SET_GROUPED_PUBLISHED_LISTING_LOADING, payload: true});

    let groupData = [];
    const contentTypeConfigs = groupKey.split('+').map(d => _GetContentTypeConfig(d));
    let contentTypesToFetch = contentTypeConfigs.filter(d => {
      let {reduxListingKey} = d;
      let dataExistsInStore = listings[reduxListingKey].initData.length > 0
      if(dataExistsInStore){
        groupData = [...groupData, listings[reduxListingKey].initData]
      }
      return !dataExistsInStore;
    })

    let promises = contentTypesToFetch.map(async d => {
      try{
        let res = await api.get(`/api/published/view_list/${d.id}?res_type=long`);     
        groupData = [...groupData, ...res.data];
      }catch(err){
        if(err) throw 'error in getGroupedPublishedListing promise map' + err;
      }
    })

    let results = await Promise.all(promises);

    let {_SortConfig} = store.getState().environment.envConfig.filters;
    let initSortConfig = _SortConfig[groupKey] 
    ? _SortConfig[groupKey][0]
    : _SortConfig.default[0]

    sortDataV2(groupData, initSortConfig)

    // filterExperiments(res);
    
    let filtersData = [];
    _FiltersConfig[groupKey] && _FiltersConfig[groupKey].map(filter => {
      filtersData.push({
        ...filter,
        options : consolidateAndSortMeta(groupData, filter.propertyPath, filter.valuePath)
      })
    })

    //we do this to make sure that initFiltersData is no longer a reference to filterData obj. that way, if we want to reset filtersData to its og state, we can simply replace it with initFilterData
    let initFiltersDataString = JSON.stringify(filtersData);
    let initFiltersData = JSON.parse(initFiltersDataString);

    dispatch({
      type: GET_GROUPED_PUBLISHED_LISTING,
      stateObj: groupKey,
      payload: {
        initData: groupData,
        data: groupData,
        initFilters: initFiltersData,
        filters: filtersData
      }
    });

  }catch(err){
    if(err) console.log('err in getGROUPEDPublishedListing', err);
    dispatch({type: GET_GROUPED_PUBLISHED_LISTING_ERROR, payload: err})
  }
};


export const getPublishedListing = (contentType, resType, listings = null, options = null) => async dispatch => {

  const {_FiltersConfig, _SortConfig} = store.getState().environment.envConfig.filters;

  let showListingWhere, locationState;
  if(options){
    showListingWhere = options.showListingWhere;
    locationState = options.locationState;
  }

  const { showListingPage, reduxListingKey } = _GetContentTypeConfig(contentType);

  if(showListingPage === false && options && showListingWhere === 'listingPage'){
    dispatch({type: SKIP_GET_LISTING})
    return;
  }
  
  if(
    listings && 
    listings[reduxListingKey].data.length > 0 && 
    locationState !== 'contrPublishedOrSubmitted'
  ){ //locationState is the previous location from where the user navigated to the listingpage
    dispatch({type: FETCH_LISTING_FROM_STORE})
    return;
  }

  try {
    dispatch({ type: SET_LISTING_LOADING, payload: ""});

    let res = await api.get(`/api/published/view_list/${contentType}?res_type=${resType}`);      

    let initSortConfig = _SortConfig[contentType] 
    ? _SortConfig[contentType][0]
    : _SortConfig.default[0]

    sortDataV2(res.data, initSortConfig);

    // filterExperiments(res);

    if(resType === 'long'){
      //which means we need to extract the filters.
      //so...
      let filtersData = [];
      _FiltersConfig[contentType] && _FiltersConfig[contentType].map(filter => {
        filtersData.push({
          ...filter,
          options : consolidateAndSortMeta(res.data, filter.propertyPath, filter.valuePath)
        })
      })

      //we do this to make sure that initFiltersData is no longer a reference to filterData obj. that way, if we want to reset filtersData to its og state, we can simply replace it with initFilterData
      let initFiltersDataString = JSON.stringify(filtersData);
      let initFiltersData = JSON.parse(initFiltersDataString);

      dispatch({
        type: GET_PUBLISHED_LISTING,
        stateObj: reduxListingKey,
        payload: {
          contentType,
          initData: res.data,
          data: res.data,
          initFilters: initFiltersData,
          filters: filtersData
        }
      });

    }else{
      dispatch({
        type: GET_PUBLISHED_LISTING_SHORT,
        payload: { contentType, data: res.data }
      });
    }

  }catch(err){
    if(err) console.log('err in getPublishedListing', err);
    dispatch({type: GET_LISTING_ERROR, payload: err})
  }
};

export const getUsersListing = (thisListingData) => async dispatch => {

  if(thisListingData && thisListingData.length > 0) {
    dispatch({type: FETCH_LISTING_FROM_STORE})
    return;
  }

  try{

      dispatch({type: SET_LISTING_LOADING})
      let res = await api.get(`/api/users`);

      res.data.sort(function(a, b) {
        let textA = a.name.toUpperCase();
        let textB = b.name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });


      dispatch({
        type: GET_USERS_LISTING ,
        payload: {
          data: res.data
        }
      })


  }catch(err){
    dispatch({type: GET_LISTING_ERROR, payload: err})
  }
}

export const getProfilesListing = (profileType, resType, thisListingData, options = null) => async dispatch => {
  
  let filters = options ? options.filters : null;
  if(!filters && thisListingData && thisListingData.length > 0) {
    dispatch({type: FETCH_LISTING_FROM_STORE})
    return;
  }

  try{

      dispatch({type: SET_LISTING_LOADING})
      let res = null;
      let filtersQueryString = filters ? JSON.stringify(filters) : null
      if(['userProfiles', 'organisations'].indexOf(profileType) !== -1){
        res = await api.get(`/api/${profileType}?res_type=${resType}&filters=${filtersQueryString}`);
      }else if(profileType === 'board'){ //only for RRAN. redundant for other instances
        res = await api.get(`/api/userProfiles?res_type=${resType}&filters=${filtersQueryString}`);
      } else{
        res = await api.get(`/api/groups/${profileType}?res_type=${resType}&filters=${filtersQueryString}`);
      }

      if(profileType === 'userProfiles'){ //only for RRAN. redundant for other instances
        res.data = res.data.filter(d => !d.user.board);
      }

      if(profileType === 'board'){ //only for RRAN. redundant for other instances
        res.data = res.data.filter(d => d.user.board === true);
      }


      res.data.sort(function(a, b) {
        let textA = '';
        let textB = '';
        switch(profileType){
          case 'userProfiles':
          case 'board':
          textA = a.user.name.toUpperCase();
          textB = b.user.name.toUpperCase();
          break;
          case 'organisations':
          case 'workingGroups':
          case 'stateNetworks':
          //this long shit here is just to deal with the 1 strange case of state pages / district pages in PATH, where the name valuePath is name[0].description
          //find a cleaner way to do this later.
          textA = (a.name[0] && a.name[0].description && a.name[0].description.toUpperCase()) || a.name.toUpperCase(); 
          textB = (b.name[0] && b.name[0].description && b.name[0].description.toUpperCase()) || b.name.toUpperCase();
        }
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });

      if(resType === 'long'){
        dispatch({
          type: GET_USER_PROFILE_LISTING ,
          stateObj: _GetProfileTypeConfig(profileType).reduxListingKey,
          payload: {
            profileType,
            initData: res.data,
            data: res.data
          }
        })
      }else{
        dispatch({
          type: GET_USER_PROFILE_LISTING_SHORT ,
          stateObj: _GetProfileTypeConfig(profileType).reduxListingKey+'Short',
          payload: { profileType, data: res.data }
        })
      }

  }catch(err){
    console.log('err in getProfiles listing', err);
    dispatch({type: GET_LISTING_ERROR, payload: err})
  }
}
//pahse this out. we have getParticipants in actions/participants
export const getParticipantsListing = () => async dispatch => {

  try{

      dispatch({type: PARTICIPANTS_LOADING})      
      let res = await api.get(`/api/participants`);
      
      dispatch({
        type: GET_PARTICIPANTS_LISTING,
        payload: { data: res.data}
      })
    
  }catch(err){
    dispatch({type: GET_LISTING_ERROR, payload: err})
  }
}

export const getMixedListing = (resourceTypesAry, listings) => async dispatch => {

  let reduxListings = listings || store.getState().listings;
  let aryToMap = Array.isArray(resourceTypesAry)
                 ? resourceTypesAry
                 : Object.values(resourceTypesAry) //cuz mongodb converts nested array into obj for some reason
  try{
    let resultsAry = [];
    let promises = aryToMap.map( async rType => {
      let config = _GetContentTypeConfig(rType)
                   ? { ..._GetContentTypeConfig(rType), resourceGroup: 'knowledge' }
                   : { ..._GetProfileTypeConfig(rType), resourceGroup: 'community' };

      if(config.id){ //do the whole shebang of fetching only if that particular resourceType is activated for this particular instance. if id exists, it means its activated
        if(reduxListings[config.reduxListingKey].data.length > 0) {
          resultsAry = [ ...resultsAry, ...reduxListings[config.reduxListingKey].data];
          dispatch({type: FETCH_LISTING_FROM_STORE})

        }else{
          try{
            dispatch({type: SET_LISTING_LOADING})
            let res = null;
            if(config.resourceGroup === 'community'){
              if(['userProfiles', 'organisations'].indexOf(rType) !== -1){
                res = await api.get(`/api/${rType}?res_type=long`);
              }else{
                res = await api.get(`/api/groups/${rType}?res_type=long`);
              }
            }else{
              res = await api.get(`/api/published/view_list/${rType}?res_type=long`);
            }
            resultsAry = [ ...resultsAry, ...res.data];
          }catch(err){
            if(err) console.log('err in getMixedListing (nested tryCatch)', err);
          }
        }
      }

    })

    const results = await Promise.all(promises);

    dispatch({
      type: GET_MIXED_LISTING, payload: resultsAry
    });

  }catch(err){
    if(err) console.log('err in getMixedListing', err);
  }

}

export const getPlacesApiListing = (searchString) => async dispatch => {
  try{
    dispatch({type: SET_LISTING_LOADING});

    const res = await api.get(`/api/placesapi?search_string=${searchString}`);

     dispatch({
       type: GET_USER_PROFILE_LISTING_SHORT ,
       stateObj: 'placesApiListing',
       payload: { profileType : null, data: res.data.predictions }
     })
  }catch(err){

  }
}

export const getTagsListing = (tagType, listing) => async dispatch => {
  try{
    if(listing.length > 0){
      dispatch({type: FETCH_LISTING_FROM_STORE});
    }else{
      dispatch({type: SET_LISTING_LOADING})
      const res = await api.get(`/api/tags/${tagType}`);

      dispatch({
        type: GET_TAGS_LISTING,
        listingName: `${tagType}Listing`,
        payload: res.data
      })
    }
  }catch(err){
    dispatch({type: GET_LISTING_ERROR, payload: err})
  }
}

export const updateTag = ( tagType, _id, action, content_type) => async dispatch => {
  try{

      const res = await api.patch(`/api/tags/${tagType}/${_id}?action=${action}&content_type=${content_type}`);


      dispatch({
        type: UPDATE_TAG_COUNT,
        listingName: `${tagType}Listing`,
        payload: res.data
      })

  }catch(err){
    dispatch({type: UPDATE_TAG_COUNT_ERROR, payload: err})
  }
}

export const addTag = (tagType, tag, content_type, callback) => async dispatch => {
  try{
      const config = { headers: { "Content-Type": "application/json" }};
      const body = { kp_tag_name : tag };

      const res = await api.post(`/api/tags/${tagType}?content_type=${content_type}`, { kp_tag_name : tag }, config);

      dispatch({
        type: ADD_TAG,
        listingName: `${tagType}Listing`,
        payload: res.data
      })

      callback(res.data);

  }catch(err){
    dispatch({type: ADD_TAG_ERROR, payload: err.response})
  }
}

export const clearUserProfilesListing = () => dispatch => dispatch({ type: CLEAR_USER_PROFILE_LISTING });

export const clearPublishedListing = contentType => dispatch => {
  dispatch({
    type: CLEAR_PUBLISHED_LISTING,
    stateObj: _GetContentTypeConfig(contentType).reduxListingKey
  })
}


//** we need to feed data in , and perform every next filtration on the previously filtered data, which is stored by the data obj. the initData obj is only for quickly repopulating all cards if all filters are cleared.
export const filterPublishedListing2 = (
  listingKey,
  selectedFilter,
  selectedValue,
  initData,
  activeFilters,
  filters,
  initFilters,
  options={}
) => dispatch => {


  let newActiveFilters = {
    ...activeFilters,
    [selectedFilter.id] : { filter: selectedFilter, value: selectedValue }
  };

  let isFilterDeselect = Array.isArray(selectedValue) && selectedValue.length === 0;
  //if selectedValue is coming from a multiselect filter, and the value is empty (because of a deselect)
  if(isFilterDeselect){
    delete newActiveFilters[selectedFilter.id]
  }

  let activeFilterVals = Object.values(newActiveFilters);

  //if no filters are selected. Reset to initData and initFilters
  if(activeFilterVals.length === 0) {

    //we do this to make sure that filtersData does not become a reference to initfilterData obj
    let resetFiltersDataString = JSON.stringify(initFilters);
    let resetFiltersData = JSON.parse(resetFiltersDataString);

    dispatch({
      type: options.isGroupedListing ? RESET_GROUPED_LISTING_TO_INIT_DATA : RESET_LISTING_TO_INIT_DATA,
      stateObj: listingKey,
      payload: {
        activeFilters: {},
        data: [...initData],
        filters: resetFiltersData
      }
    })
    return; //break out of this action. we are done here.
  };

  //else if we do have some active filters then...

  //## 1 ## HANDLING DATA
  //filter out data based on activeFilters
  let newData = [...initData];
  
  let indicesToRemove = [];

  newData.map((d,i) => {

    let propertiesExists = activeFilterVals.every(f => s_gVal('get', d, f.filter.propertyPath) !== undefined); //check if every active filter property is present in d
    if(!propertiesExists){ //if properties dont exist, remove d from array
      indicesToRemove.push(i);
    }else{

      let valuesMatch = activeFilterVals.every(f => { //f is always going to be an array of values, if all filters are multiselect. -- currently we are considering this scenario and just writing code for it.
        let metaProperty = s_gVal('get', d, f.filter.propertyPath );

        if(Array.isArray(metaProperty)){ //if metaProp is an array
          return f.value.some(f_valX => { //considering multiselect filters, we want to show data results that have any of the selected options within s multi select and NOT all of them.
             return metaProperty.some(meta => getVal( meta, f.filter.valuePath) === f_valX.value ); //this is because the the multiselect dropdown is sending an array of objs not strings.
          })
        }else{ //meta property is either value or string. in which case, the filter.value will always be a string (of the selected option val)
          //in case of string, value path is passed as null, making the return val metaProperty itself
          return f.value.some(f_valX => getVal( metaProperty, f.filter.valuePath) === f_valX.value );

        }
      })
      if(!valuesMatch) indicesToRemove.push(i);
    }
  })

  let excludedData = [];
  //remove the elements that didnt match from the newData array
  indicesToRemove.sort((a,b) =>  b - a);
  indicesToRemove.map(d => {
    excludedData.push(newData[d]);
    newData.splice(d,1);
  })

  //## 2 ## HANDLING FILTERS
  //set count of all filters to 0, except the selectedFilter
  filters.map(f => {
    if(isFilterDeselect){ //if isFilterDeselect, then set that particular filter count also to zero, and then refill it. this way we reset individual filters.
      f.options.map(f_op => f_op.count = 0 )
    }else{
      if(f.id !== selectedFilter.id){
        f.options.map(f_op => f_op.count = 0 )
      }
    }
  })

  //cycle throgh the newData
  newData.map(d => {
    //cycle through all filters
    filters.map(f => {


        let metaProperty = s_gVal('get', d, f.propertyPath); //find that particular property in the newData d. (using property path)

        if(metaProperty){ //if property exists

          f.options.map(f_op => { //cycle through the options

            let f_opExistsAsMeta = false;

            if(Array.isArray(metaProperty)){ //if property is an array, do an array.some to check if option exists as meta using s_gVal
              f_opExistsAsMeta = metaProperty.some(meta => getVal( meta, f.valuePath) === getVal( f_op, f.valuePath));

            }else{ //else if property is an object || string . check if its value is equal to the option using s_gVal
              f_opExistsAsMeta = getVal( metaProperty, f.valuePath) === getVal( f_op, f.valuePath);
            }

            if(isFilterDeselect){
              if(f_opExistsAsMeta === true) f_op.count = f_op.count+1; //increase count if meta exists
            }else{
              if(f.id !== selectedFilter.id){
                if(f_opExistsAsMeta === true) f_op.count = f_op.count+1; //increase count if meta exists
              }
            }
          })
        }
    })
  })


  //cycle through excluded data, to pull out other OR possibilities for each filter, and add it to the respective option count.
  excludedData.map(ex_d => {
    // for each active filter (this),

    activeFilterVals.map(act_f => {
      let excludeCurrFilterAry = [...activeFilterVals];
      let idx = excludeCurrFilterAry.findIndex(f => f.filter.id === act_f.filter.id);
      excludeCurrFilterAry.splice(idx,1);

      let shouldIncludeInFilterCount = false;

      if(excludeCurrFilterAry.length > 0){

        shouldIncludeInFilterCount = excludeCurrFilterAry.every(other_f => {
          let metaProperty = s_gVal('get', ex_d, other_f.filter.propertyPath);
          if(metaProperty !== undefined){
            if(Array.isArray(metaProperty)){
              return other_f.value.some(other_f_val => {
                 return metaProperty.some(meta => getVal( meta, other_f.filter.valuePath) === other_f_val.value );
              })
            }else{
              return other_f.value.some(other_f_val => getVal( metaProperty, other_f.filter.valuePath) === other_f_val.value )
            }
          }else{
            return false;
          }
        })

      }
      //******THIS LINE BELOW IS V-IMP TO BUILD ON, TO REFINE THE LOGIC OF FILTERS******
      //*
      // if(shouldIncludeInFilterCount === true) console.log("should include", ex_d.meta.kp_other, s_gVal('get', ex_d, act_f.filter.propertyPath));
      //*
      //********************************************************************************
    })
      //if the ex_d has the values of all other active filters,
      //then get the value of THIS active filter, and add a +1 to the count of that filter value/option.
  })

  dispatch({
    type: options.isGroupedListing ? UPDATE_GROUPED_LISTING_DATA : UPDATE_LISTING_DATA,
    stateObj: listingKey,
    payload: {
      activeFilters: newActiveFilters,
      data: newData,
      filters
    }
  })
}

export const clearFilters = (listingKey, initData, initFilters, options={}) => dispatch => {

  //we do this to make sure that filtersData does not become a reference to initfilterData obj
  let resetFiltersDataString = JSON.stringify(initFilters);
  let resetFiltersData = JSON.parse(resetFiltersDataString);

  dispatch({
    type: options.isGroupedListing ? RESET_GROUPED_LISTING_TO_INIT_DATA : RESET_LISTING_TO_INIT_DATA,
    stateObj: listingKey,
    payload: {
      activeFilters: {},
      data: [...initData],
      filters: resetFiltersData
    }
  })

}
