import {getRichInputText} from '../../../utils/getters'

import {
  USER_PROFILES as USER_PROFILES_CARD,
  ORGANISATIONS as ORGANISATIONS_CARD,
  WORKING_GROUPS as WORKING_GROUPS_CARD,
  STATE_NETWORKS as STATE_NETWORKS_CARD,
} from './card';

export const USER_PROFILES = (d) => ({})

export const ORGANISATIONS = (d) => ({})

export const WORKING_GROUPS = (d) => ({})

export const STATE_NETWORKS = (d) => ({})

export const PARTNER_NETWORKS = (d) => ({})
