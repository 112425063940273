import React from "react"
import { primaryColor100 } from '../../../sass/vars.scss';

function UploadImage(props) {
  return (
    <svg width={props.size || 20} height={props.size || 20} viewBox="0 0 20 20" fill="none">
      <rect x={1} y={1} width={18} height={18} rx={2} stroke={props.stroke || primaryColor100} />
      <path
        d="M1.06 13.352L6.041 7.47a1 1 0 011.466-.065l2.521 2.492 2.207 2.18M10 9.041l1.346-1.581a1 1 0 011.459-.07l6.136 5.962"
        stroke={props.stroke || primaryColor100}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.499 5.473a1.118 1.118 0 100-2.236 1.118 1.118 0 000 2.236z"
        fill={props.stroke || primaryColor100}
      />
    </svg>
  )
}

export default UploadImage
