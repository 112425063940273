import React, {Fragment} from "react";
import { set_getValOffQueryString as s_gVal} from '../../../utils/general';

const KPList = ({value}) => {

  return(
    <Fragment>
  { value && 
    <ul className='kp-list'>
    { value.map(d => <li className='h4 medium kp-list__item'>{d.display}</li>)  } 
    </ul> }
    </Fragment>
  )
}



export default KPList;
