import React, { useState, Fragment } from "react";

const KPMetaSecondary = ({ className, data }) => {

  return (
    <div className={`kp-meta-secondary ${className}`}>
    { data.map(d => (
      <Fragment>
        <h6 className='h6 bold kp-meta-secondary__text kp-meta-secondary__text--key'>{d.key} :</h6>
        <h6 className='h6 bold kp-meta-secondary__text kp-meta-secondary__text--value'>{d.value}</h6>
      </Fragment>
    ))}
    </div>
  );
}

export default KPMetaSecondary
