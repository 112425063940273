import * as React from "react"
import {primaryColor100} from '../../../sass/vars.scss';

function CType_News(props) {
  return (
    <svg width={21} height={21} viewBox="0 0 21 21" fill="none">
      <path
        d="M15.461 4.076h2.32a1 1 0 01.971 1.24l-3.1 12.5a1 1 0 01-.971.76H8.06"
        stroke={props.stroke || primaryColor100}
        strokeLinejoin="round"
      />
      <rect
        x={1.56}
        y={1.576}
        width={14}
        height={17}
        rx={0.5}
        stroke={props.stroke || primaryColor100}
        strokeLinejoin="round"
      />
      <path
        d="M4.06 3.854h9M4.06 16.076h9"
        stroke={props.stroke || primaryColor100}
        strokeWidth={0.8}
        strokeLinecap="round"
      />
      <path
        d="M3.96 11.176v-5.2h9.2v5.2h-9.2z"
        stroke={props.stroke || primaryColor100}
        strokeWidth={0.8}
      />
      <path
        d="M4.06 13.576h9"
        stroke={props.stroke || primaryColor100}
        strokeWidth={0.8}
        strokeLinecap="round"
      />
    </svg>
  )
}

export default CType_News
