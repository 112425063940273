import React, { useState, Fragment } from "react";
import KPImageBlock from '../../inputs/KPImageBlock';
import {convertToGcsStructure} from '../../../utils/general';
import {greyColor100} from '../../../sass/vars.scss';

const ProfileImgDisplay = ({avatar, size, className, placeholderInitial, containerShape, style}) => {

  const imgData = avatar && (
    ( 
      avatar.userUploaded && 
      convertToGcsStructure(avatar.userUploaded).length !== 0 &&
      convertToGcsStructure(avatar.userUploaded)[0]
    )
    // || (
    //   avatar.gravatar &&
    //   { publicUrl : avatar.gravatar}
    // ) 
    || avatar
  ) 

  let sizeString = typeof size === 'number' ? size+'rem' : size
  const inverseDecay = ((1/size)*35)

  return (
  <Fragment>
  { imgData &&
    <div className={`kp-profile-img-display ${className}`} style={{width: sizeString, height: sizeString, ...style}}>
      <KPImageBlock
        readOnly={true}
        containerShape={containerShape}
        image = {imgData}
        placeholderInitial = {placeholderInitial}
        placeholderInitialSize = {typeof size === 'number' ? size * 2 + inverseDecay : undefined /*inverse decay : the delta of increment reduces as the size of container gets bigger */}
        placeholderStyle= {{backgroundColor: greyColor100}}
        
        />
    </div> }
  </Fragment> );
}

ProfileImgDisplay.defaultProps = {
  className: '',
  containerShape: 'shape-round'
}

export default ProfileImgDisplay
