import React, { Fragment } from 'react';
import ReactMarkdown from 'react-markdown'


const MarkdownParser = ({ children }) => {

  
  

  return( 
    <ReactMarkdown className='OKE-markdown'>{children}</ReactMarkdown>
  )
}

export default MarkdownParser;