import React, {Fragment, useEffect, useState, useRef, useLayoutEffect} from 'react';

import KPLabel from '../../generalUI/KPLabel';
import {KPTextInput} from '../../inputs/KPInputs';
import {useScroll, useTrackDdFocus} from '../../../utils/customHooks';
import InputText from '../../typo/InputText';
import {formatText} from '../../../utils/general';
import {getLabelProps} from '../../../utils/contentBlock';
import {__backCompat__dySearchSingle_to_dropdownSingle} from '../../../utils/backwardsCompatibility.js'

//local
import { Options } from './Options';
import { Select } from './Select';
import {handleSetYOrientation} from './functions';


export const Dropdown = props => {

  const { 
    value,
    id,
    onChange,
    className,
    injectOtherOption,
    passChangeHandlerOps,
    variant,
    readOnly
  } = props

  //1 INIT REFS
  const refName = `ddRef_${id}`;
  const ddRefObj = { 
    [refName] : useRef(null),
    [refName+'_select'] : useRef(null),
    [refName+'_options'] : useRef(null)
  } //need to use this strange object structure cuz, thats the only way to give a dynamic ref name. ( we need a dynamic unique ref name cuz there could be mutliple dropdowns on a page )
  const OKE_Dropdown__textInputREF = useRef(null);

  //2 INIT SHOW OPTIONS STATE
  const [showOptions, setShowOptions] = useState(false);

  //3 INIT DD FOCUS CUSTOM HOOK
  useTrackDdFocus(ddRefObj[refName], setShowOptions);

  //4 INIT ORIENTATION STATE AND CUSTOM HOOK
  const [yOrientation, setYOrientation] = useState('bottom');
  const scrollFn = () => handleSetYOrientation(ddRefObj[refName+'_options'] ,ddRefObj[refName+'_select'], setYOrientation);
  useScroll(scrollFn);

  //5 INIT SEARCHSTRING STATE
  const [searchString, setSearchString] = useState('');
  
  //7 single v/s multi diffrenciators and handlers
  const isMultiSelect = ['multi', 'combobox--multi', 'tags'].indexOf(variant) !== -1;
  const isCombobox = ['combobox--multi', 'combobox--single', 'tags'].indexOf(variant) !== -1;
  const isTagsInput = ['tags'].indexOf(variant) !== -1;

  const constructNewValue = (newVal, options={}) => {
    if(isMultiSelect){ 
      if(options.updateOtherDesc){
        let newValueAry = [...value];
        let idx = newValueAry.findIndex(v => v.value === 'other');
        newValueAry[idx] = newVal;
        return newValueAry
      }
      //else
      let shouldDeselect = value && value.some(d => d.value === newVal.value); //if value already exists in the selected array, then we should deselect
      return (
        shouldDeselect
        ? value.filter(d => d.value !== newVal.value)
        : [ ...(value ? value : []) /*prevVal*/, newVal ] 
      ) 
    } else
    //it is single select ( 'single', 'combobox--single' ) 
    { return newVal }
  }
  const syncSearchStringToValue = () => {
    if(!value) return; //else
    if(!isMultiSelect){
      setSearchString(value.display)
    }else{
      if(!showOptions) setSearchString('');
    }
  }

  //6 SYNC SEARCH STRING TO VALUE
  useEffect(() => {
    syncSearchStringToValue();
  },[value, showOptions])

  const isDynamicOtherOp = newVal => {
    return newVal && newVal.value === 'other' && injectOtherOption === true
  };
  const [showOtherTextInput, setShowOtherTextInput] = useState(value && isDynamicOtherOp(value) ? true : false)
  const handleOtherTextInputChange = (k,v) => {
    let newVal = {
      display: 'Other', 
      display_desc: v,
      value: 'other', 
      value_desc: formatText.textToCamelcase(v)
    };
    onChange && onChange(id, constructNewValue(newVal, {updateOtherDesc : true}), passChangeHandlerOps && props); 
  }

  const handleToggleOptions = (e) => {
    let clickedInsideTextInput = (
      OKE_Dropdown__textInputREF.current &&
      OKE_Dropdown__textInputREF.current.contains(e.target)
    )

    !showOptions && OKE_Dropdown__textInputREF.current && 
    OKE_Dropdown__textInputREF.current.focus();

    if(isCombobox && isMultiSelect){
      if(!clickedInsideTextInput){
        setShowOptions(!showOptions);    
      }else{
        if(!showOptions) setShowOptions(true);
      }
      return; 
    }else{
      setShowOptions(!showOptions);
    }
  }

  //5 PRIMARY CHANGE HANDLER
  const handleSelect = (id, newVal) => {
    
    let newValue = constructNewValue(newVal);

    if(isMultiSelect){
      newValue.some(v => isDynamicOtherOp(v)) &&
      (!value || !value.some(v => isDynamicOtherOp(v))) //other just got selected
      ? setShowOtherTextInput(true)
      : value && value.some(v => isDynamicOtherOp(v)) &&
        !newValue.some(v => isDynamicOtherOp(v)) && //other just fot deselected
        setShowOtherTextInput(false)
    }else{
      isDynamicOtherOp(newValue)
      ? setShowOtherTextInput(true)
      : isDynamicOtherOp(value) /*prev val*/ && 
        !isDynamicOtherOp(newValue) &&
        setShowOtherTextInput(false)
    }

    onChange && onChange(id, constructNewValue(newVal), passChangeHandlerOps && props);
    !isMultiSelect && setShowOptions(false);
  } 

  const genDisplayComp = () => {
    if(value){
      if(Array.isArray(value) && value.length > 0){
        return <InputText>{value.map(d => d.value_desc || d.display).join(', ')}</InputText>
      } else {
        return <InputText>{value.display}</InputText>
      }
    }
  }

  const setOtherTextInputValue = (value) => {
    if(isMultiSelect){
      let idx = value.findIndex(v => v.value === 'other' && injectOtherOption === true);
      return value[idx] && (value[idx].display_desc || '') 
    }
    //else
    return value.display_desc
  }

  const genInputComp = () => (
    <div ref={ddRefObj[refName]} className='OKE-Dropdown__ref-wrapper'>
        <div 
          ref={ddRefObj[refName+'_select']}
          className='OKE-Dropdown__Select-wrapper' 
          onClick={handleToggleOptions}
          >
        <Select 
          ref={OKE_Dropdown__textInputREF}
          searchString={searchString}
          setSearchString={setSearchString}
          showOptions={showOptions}
          handleSelect={handleSelect}
          isMultiSelect={isMultiSelect}
          {...props}
          />
        </div>
        <Options 
          ref={ddRefObj[refName+'_options']}
          searchString= {searchString}
          yOrientation={yOrientation}
          showOptions={showOptions}
          handleSelect={handleSelect}
          isMultiSelect={isMultiSelect}
          isCombobox={isCombobox}
          isTagsInput={isTagsInput}
          {...props} 
          />
      { showOtherTextInput &&
        <KPTextInput 
          placeholder='Please Specify'
          value={value && setOtherTextInputValue(value)}
          onChange={(k,v) => handleOtherTextInputChange(k,v)}
          /> }
      </div>
  )

  return (
    <div className={`OKE-Dropdown ${className}`}>
      <KPLabel {...getLabelProps(props)} />
      {readOnly ? genDisplayComp() :  genInputComp()}
      
    </div>
  );
}

// const BackwardsCompatibilityHOC = (Dd, bcValFn) => {
//   return class extends React.Component {
//     constructor(props) {
//       super(props);
//       this.BC = {
//         dropdownSingle : (v) => __backCompat__dySearchSingle_to_dropdownSingle(v)
//       }
//     }

//     render() {
//       return <Dd {...this.props} value={this.BC.dropdownSingle(this.props.value)}/>
//     }
//   }; 
// }

// export const Dropdown = BackwardsCompatibilityHOC(Dd);

//tested. works
// - how does one deselect a selected option?
export const DropdownSingle = props => (
  <Dropdown
    variant = {'single'}
    {...props}
    />
)

//tested. works satisfactorily
// - deselect all option could be good
// - some more thinking about the interactions around the 'selected' list within options. e.g. what happpens when all the options within selected are deselected? shoould that list disappear, and all those options reappear in the main options list? 
// - should the searchbar be wiped clean, if dropdown is focussed ( and the selected text is there in the searchbar)
export const DropdownMulti = props => (
  <Dropdown
    variant = {'multi'}
    {...props}
    />
)

//tested. works
// - how does one deselect a selected option?
export const ComboboxSingle = props => (
  <Dropdown
    variant = {'combobox--single'}
    {...props}
    />
)

//tested. works
// - should the search matching affect the selected options as well?
export const ComboboxMulti = props => (
  <Dropdown
    variant = {'combobox--multi'}
    {...props}
    />
)