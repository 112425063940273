import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  VERIF_TOKEN_SUCCESS,
  VERIF_TOKEN_ERROR,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_ERROR,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  UPDATE_USER,
  UPDATE_USER_LOADING,
  UPDATE_USER_ERROR,
  SET_AUTH_LOADING,
  RESET_VERIF_REL_DATA,
  PWD_RESET_MAIL_SEND_SUCCESS,
  PWD_RESET_MAIL_SEND_ERROR
} from "../actions/types";

const initialState = {
  token: localStorage.getItem("token"),
  verifEmail: null,
  isAuthenticated: null,
  loading: true,
  user: null,
  roles: {},
  errors: null
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload.user,
        roles: payload.roles,
        env: payload.env
      };

    case REGISTER_SUCCESS:
      return {
        ...state, //dont need a state change (for now)
        verifEmail : payload,
        loading: false
      };

    case LOGIN_SUCCESS:
      localStorage.setItem("token", payload.token);
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false
      };
    case REGISTER_FAIL:
    case LOGIN_FAIL:
    case LOGOUT:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false
      };
    
    case AUTH_ERROR: //thrown only if error occurs in loadUser()
      // in loadUser(), AUTH_ERROR is fired if error is thrown from backend. 
      // and earlier this destroyed the localstorage token each time, which is incorrect at times.
      // we want to destory the localstorage token in this case, ONLY IF, the err was thrown cuz of some token specific validation error.
      console.log("shouldDestroyLocalToken", payload.shouldDestroyLocalToken);
      payload.shouldDestroyLocalToken !== false &&
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false
      };
    case UPDATE_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload
        },
        loading: false
      };
    case UPDATE_USER_ERROR:
      return {
        ...state,
        errors: payload,
        loading: false
      };
    case REFRESH_TOKEN_SUCCESS:
    case VERIF_TOKEN_SUCCESS:
    case PWD_RESET_MAIL_SEND_SUCCESS:
      return {
        ...state,
        verifEmail : payload,
        loading: false,
        errors: null
      }
    case REFRESH_TOKEN_ERROR:
    case VERIF_TOKEN_ERROR:
    case PWD_RESET_MAIL_SEND_ERROR:
      return {
        ...state,
        errors: payload,
        loading: false
      }
    case RESET_VERIF_REL_DATA:
      return {
        ...state,
        verifEmail: null,
        errors: null
      }
    case SET_AUTH_LOADING:
    case UPDATE_USER_LOADING:
      return {
        ...state,
        loading: payload
      }
    default:
      return state;
  }
}
