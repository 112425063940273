import React from "react"

function RichFormatToolUnorderedList(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M4.57 7.142A1.57 1.57 0 104.57 4a1.57 1.57 0 000 3.142zM4.57 14.013a1.57 1.57 0 100-3.142 1.57 1.57 0 000 3.142zM4.57 20.296a1.57 1.57 0 100-3.142 1.57 1.57 0 000 3.142z"
        fill="#fff"
      />
      <path
        d="M8.861 5.57h12.574M8.861 12.442h12.574M8.861 18.725h12.574"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default RichFormatToolUnorderedList;
