import React, { useState, Fragment } from "react";
import { set_getValOffQueryString } from '../../../utils/general';
//icons
import Location14 from '../../icons/Location14';
import Person14 from '../../icons/Person14';
import ProfileImgDisplay from '../../generalUI/ProfileImgDisplay';
import KPMultiProfilesDisplay from '../../generalUI/KPMultiProfilesDisplay';
import { greyColor70, white, greyColor100 } from '../../../sass/vars.scss';
import Meta from '../../typo/Meta';

import { propertyExists } from '../../../utils/general';
import { createContentCardDate } from '../../../utils/momentManipulate';

import {MapPin} from 'phosphor-react'

const MetaBlock = props => {

  const Icons = {
    Location14,
    Person14,
    MapPin
  }

  // const metaObj = JSON.parse(props.metaObj);

  const Comps = { KPMultiProfilesDisplay }

  let data = props.data;
  if(props.metaObj){ //if the meta Obj is passed through it means the metaData is coming from the template data
    data = props.data.map(d => {

      d = d.text ? {...d, text: set_getValOffQueryString('get', JSON.parse(props.metaObj), d.text)} : d;
      d = d.date ? {...d, date: createContentCardDate(set_getValOffQueryString('get', JSON.parse(props.metaObj), d.date))} : d;
      d = d.img ? {...d, img: set_getValOffQueryString('get', JSON.parse(props.metaObj), d.img)} : d;
      d = d.block ? {...d, block: {...d.block, props: { ...d.block.props, value: set_getValOffQueryString('get', JSON.parse(props.metaObj), d.block.valuePath ) } }} : d;
      return d;
    })
  }

  const checkIfKeyHasValue = possValuesAry => {
    let hasValue = false;
    for(var i = 0 ; i < possValuesAry.length ; i++ ){
      if(propertyExists(possValuesAry[i])){
        hasValue = true;
        break;
      }
    }
    return hasValue;
  }

  const setColor = (invert) => invert ? white : greyColor100;

  return (
    <div className={`kp-meta-block  ${props.className}`}>
    { props.showSeparator &&
      <div className='kp-meta-block__separator'></div> }
      <div className='kp-meta-block-contents-wrapper'>
      { data.map((d, i) => {
        let Icon = d.icon && Icons[d.icon];
        let Comp = d.block && Comps[d.block.comp]
        return(
          <Fragment key={i}>
            <div className='kp-meta-block__meta-wrapper'>
              <div className='kp-meta-block__meta-text'>
              { d.label &&
                <h5
                  className='h5 semibold ls-10 u-case'
                  style={{marginRight: '1rem', color: greyColor70 }}
                  >
                  {d.label}
                </h5> }
              { d.icon
                ? <span className='kp-meta-block__meta-icon'>
                  <Icon size="24" strokeWidth="0" color={setColor(props.invert)} style={{marginRight:".5rem"}} /></span>
                : d.img
                  ? <ProfileImgDisplay avatar={d.img} size='2rem' className='kp-meta-block__meta-img'/>
                : d.key && checkIfKeyHasValue([d.text, d.block && d.block.props.value]) //basically no point generating d.key if the values below dont even exist
                  && <Meta className='text' style={{paddingRight: '0.5rem', color: setColor(props.invert)}}>{d.key}</Meta> }
              { d.text
                ? <Meta className='text' style={{color: setColor(props.invert)}}>{d.text}</Meta>
                : d.date
                  ? <Meta className='text'style={{color: setColor(props.invert)}}>{d.date}</Meta>
                  : d.block
                  && <Comp {...d.block.props} textStyle='h6' /> }
              </div>
            </div>
          { i !== data.length -1 &&
            <div className='kp-meta-block__separator' ></div> }
          </Fragment>
        )
        })
      }
      </div>
    </div>
  );
}

export default MetaBlock
