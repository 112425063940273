import React, { useState, useEffect, useRef, Fragment } from "react";
//comps
import KPLabel from '../../generalUI/KPLabel';
import { KPTextInput, DisplayCompForTextInputs } from '../../inputs/KPInputs';
import { KPDropdownSelect__Single } from '../KPDropdownSelects';
import { KPDropdownOptions__Single} from '../KPDropdownOptions';
import { KPDropdownSelect__Multi } from '../KPDropdownSelects';
import { KPDropdownOptions__Multi} from '../KPDropdownOptions';
//utils
import { getOptionName, getAryMatchIdx, formatText } from '../../../utils/general';
import { useTrackDdFocus, useParseContentBlockValue } from '../../../utils/customHooks';
import {getLabelProps} from '../../../utils/contentBlock';

export const KPDropdown = React.memo(props => {

  const {
    label, 
    sublabel, 
    id, 
    readOnly, 
    isRequired, 
    errorMsgs, 
    options, 
    optionType,
    injectOtherOption,
    value, 
    placeholder,  
    className, 
    onChange, 
    customSelect,
    size,
    passChangeHandlerOps,
    rowIdx,
    cellIdx,
    cellComp
  } = props;

  const sanitizeVal = val => val || null;
  let [val, setVal] = useParseContentBlockValue(value, sanitizeVal)

  const [ showOptions, setShowOptions ] = useState(false);
  const [showTextInput, setShowTextInput] = useState((value && value.value === 'other' && injectOtherOption) ? true : false);


  const refName = `ddRef_${id}`;
  const ddRefObj = { [refName] : useRef(null) } //need to use this strange object structure cuz, thats the only way to give a dynamic ref name. ( we need a dynamic unique ref name cuz there could be mutliple dropdowns on a page )
  useTrackDdFocus(ddRefObj[refName], setShowOptions);

  //primary handler
  const handleOptionClick = (id, newVal) => {
    setShowOptions(false);
    
    (newVal.value === 'other' && injectOtherOption)
    ? setShowTextInput(true)
    : val && val.value === 'other' && //previous val
      newVal.value !== 'other' && 
      setShowTextInput(false);

    onChange && onChange(id, newVal, passChangeHandlerOps && props); 
  }

  //secondary handlers
  const toggleShowOptions = () => setShowOptions(!showOptions)  

  const handleTextInputChange = (key, textVal) => {
    let newVal = {value: 'other', value_desc: formatText.textToCamelcase(textVal), display: 'Other', display_desc: textVal};
    onChange && onChange(id, newVal, passChangeHandlerOps && props); 
  }

  const genOptions = (options) => {
    return injectOtherOption ? [...options, {value: 'other', display: 'Other' }] : options
  }

  const genInputComp = () => (
    <Fragment>
      <div ref={ddRefObj[refName]} id={id}  className="kp-dd" aria-labelledby={`${id}_label`}>
        { customSelect
          ? <div onClick={toggleShowOptions}>{customSelect}</div>
          : <KPDropdownSelect__Single
              disabled = {genOptions(options).length <= 1 ? true : false}
              onClick = {genOptions(options).length > 1 && toggleShowOptions}
              value = {val}
              size = {size}
              showOptions = {showOptions}
              placeholder = {placeholder}
              /> }
        { genOptions(options).length > 1 &&
          <KPDropdownOptions__Single
            value = {val}
            options = {genOptions(options)}
            showOptions = {showOptions}
            optionType = {optionType}
            onClick = {(selectedOption) => handleOptionClick(id, selectedOption)}
            /> }
        </div>
      { showTextInput &&
        <KPTextInput
          placeholder='Please Specify Here'
          value={val && val.display_desc && val.display_desc }
          onChange={(k,v) => handleTextInputChange(k,v)}
          /> }
    </Fragment>
  )

  const genDisplayComp = () => (
    <DisplayCompForTextInputs
      value={
        props.value && (
        props.value.value_desc
        ? props.value.value_desc
        : props.value.display ) 
      }
    />
  )

  return (
      <div className={`kp-dd-wrapper ${className}`} >
        <KPLabel {...getLabelProps(props)} />
      { readOnly ? genDisplayComp() : genInputComp() }
      </div>
    
  )
})

//can get rid of optionType make it a custom component that can be passed to replace the default string. ( just like customSelect )
//the dropdown options needs to be updated to give some visual feedback about the selected option.
//need to check on wqn if the change in the tempdata dropdown has affected any thing.
// the dropdown on the data page is going to break. its a bit of a mess how its been used there. anyway, that whole page needs a full update.

KPDropdown.defaultProps = {
  size: "large",
  placeholder: "Select Something!",
  optionType: 'single'
}


export const  KPDropdown__Multi = ({label, sublabel, id, options, optionType, value, placeholder, className, onChange, size }) => {

  const ddRefObj = { 
    [`ddRef_${id}`] : useRef(null) 
  } //need to use a refObj because we want to dynamically attach a 'id' to the ref name, so that, if there are multiple dropdowns on the same page, the trackDDFocus hook doesnt misbehave.

  const [ selectedOptions, setSelectedOptions ] = useState( value || []);

  const [ showOptions, setShowOptions ] = useState(false);

  useTrackDdFocus(ddRefObj['ddRef_' + id ]);

  function useTrackDdFocus(ref){

    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) { //clicked outside editor
        setShowOptions(false);
      }
    }

    useEffect(() => {
      document.addEventListener("click", handleClickOutside);
      return () => document.removeEventListener("click", handleClickOutside);
    });

  }

  const handleOptionClick = (id, selectedOption) => {

    let tempSelectedOptions = [...selectedOptions];
    let { isMatch, matchIdx } = getAryMatchIdx(selectedOption.value, selectedOptions);

    if(isMatch === false){ //value isnt already selected, so select it
      tempSelectedOptions.push(selectedOption)
    }else{ //value is already selected, so deselect it
      tempSelectedOptions.splice(matchIdx, 1);
    }

    setSelectedOptions(tempSelectedOptions);

    onChange && onChange(id, tempSelectedOptions);
  }

  //everytime the value changes in parent, it updates the local state of selectedOptions, (which renders the 'selected/deselected' UI for the select box and the checkbox options)
  //althhough this does the same thing as setSelectedOptions(tempSelectedOptions) up in handleOptionClick,
  //this is useful when the selectedOptions state has to be updated by an external component. eg. Clear Filters Btn.
  useEffect(() => {
    setSelectedOptions(value || []);
  },[value])

  const toggleShowOptions = () => setShowOptions(!showOptions)

  return (
    <Fragment>
      <div className={`kp-dd-wrapper k-dd-wrapper--multi ${className}`} >
        <KPLabel
          id = {`${id}_label`}
          label = {label}
          sublabel = {sublabel}
          />
        <div ref={ddRefObj['ddRef_' + id ]} id={id}  className="kp-dd kp-dd--multi" aria-labelledby={`${id}_label`}>
          <KPDropdownSelect__Multi
            placeholder = {placeholder}
            onClick = {toggleShowOptions}
            selectedOptions = {selectedOptions}
            showOptions = {showOptions}
            size = {size}
            />
          <KPDropdownOptions__Multi
            selectedOptions = {selectedOptions}
            options = {options}
            showOptions = {showOptions}
            optionType = {optionType}
            onClick = {(selectedOption) => handleOptionClick(id, selectedOption)}
            />
        </div>
      </div>
    </Fragment>
  )
}

KPDropdown__Multi.defaultProps = {
  placeholder: "add via 'placeholder' prop",
  optionType: 'multi',
  size: 'large'
}

export const KPFilterDropdown__Multi = props => (
  <KPDropdown__Multi
    {...props}
    className={`kp-dd-short ${props.className}`}
    optionType='multiWithCount'
    />
)
