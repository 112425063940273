
import {rmArray} from './general';
import store from '../store';

export const genLanguagesArray = () => { //this has to be a function because thats how kpdynamicsearchresultsblock works
  const writeInLangs = store.getState().environment.envConfig.languages.writeInLangs;
  return writeInLangs;
}

export const setTplLang = (kp_language, contentType) => {
  const _TPL_LANG = store.getState().environment.envConfig.languages._TPL_LANG;
  const getLocaleLangFromTpl = langMeta => {
    if(!langMeta) return null;

    //else
    let langMetaObj = Array.isArray(langMeta) ? rmArray(langMeta) : langMeta;

    if(_TPL_LANG[contentType].some(d => d.value === langMetaObj.value)){
      return langMetaObj;
    }
    //else
    return null;
  }

  return (
    getLocaleLangFromTpl(kp_language) || 
    _TPL_LANG[contentType][0]
  )
}
