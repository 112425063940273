import api from '../_configs/api';
import {
    ENV_CONFIG_LOADING,
    SET_ENV_CONFIG_ERROR,
    SET_ENV_CONFIG,
}
from './types';

export const getClientConfig = () => async dispatch => {

    try {
      dispatch({ type: ENV_CONFIG_LOADING, payload: true})    // loading
      let res = await api.get("/api/config");
      dispatch({ type: SET_ENV_CONFIG, payload: res.data });  // send clientInstance in later Single CodeBase implementation.
      return res.data;
    } catch (err) {
    //   dispatch(setAlert('Oops! Something went wrong :(. Refresh the page and try again.', 'danger')) // temp error message. // change this later if needed.
      if(err.response){
        dispatch({
          type: SET_ENV_CONFIG_ERROR,
          payload: err.response.data
        })
      }else{
        console.log('Error Fetching Configurations',err);
      }
      return 'setConfigError'
    }
  }