import React, {Fragment} from "react";


const Label__M = (props) => {

  

  return (
    <h6 className={`label--M ${props.className || ''}`} style={props.style} id={props.id || ''}>{props.children}</h6>
  );

}



export default Label__M;
