import {
    set_getValOffQueryString as s_gVal,
    propertyExists,
    convertToGcsStructure,
    convertResToGcsStructure,
    formatTitle,
    formatText,
    injectHttps
  } from './general';

  import { createContentCardDateRange } from './momentManipulate'; 
  import { getVal, getSpeaker } from './getters'; 

  import {_GetContentTypeConfig} from '../_configs/contentTypes/config';
  
  export const __ExtractRichOrPlainText = property => {
    if(!property) return '';
    let val = '';

    if(typeof property === 'string') return property; //meaning the property isnt rich text but rather plain text.
    //else
    for (var i  = 0; i < property.blocks.length; i++){
      if(property.blocks[i] && property.blocks[i].text && property.blocks[i].text.length > 0 ){
        val = val + property.blocks[i].text;
        // break;
      }
    }
  
    return val;
  }

  export const __GetText = (property) => {
    if(!property) return '';
    let val = '';
    if(Array.isArray(property)){ //we can pass multiple properties as array, giving this function the option to pick any one that has a readable value in order of preference from first to last.
      for(var i = 0; i < property.length; i++){
        val = __ExtractRichOrPlainText(property[i]);
        if(val) break;
      }
  
    }else{
      val = __ExtractRichOrPlainText(property);
    }
  
    return val;
  }

  export const __GetValsFromValPaths = (d, valPath) => {
    //could be either string or array of strings.
    return (
      Array.isArray(valPath)
      ? valPath.map(path => s_gVal('get', d, path))
      : s_gVal('get', d, valPath)
    )  
  }

  export const __GetImageData = (data) => data && convertToGcsStructure(data);

  export const __GetCardImagePropData = (d, imageProps) => {
    if(!imageProps) return undefined;

    let data = s_gVal('get', d, imageProps.data ); 
    //imageProps.data is a valuePath. data will be the actually image data object

    if (!propertyExists(data)) return { data: undefined, heightRatio: imageProps.heightRatio}; //you want to still pass the height ratio for the placeholder height
    //else
    
    return { data : __GetImageData(data), heightRatio: imageProps.heightRatio }
  }

  export const __GetCardVideoPropData = (d, valPath) => {
    if(!valPath) return undefined;
    let val = s_gVal('get', d, valPath);
    if(!propertyExists(val)) return undefined;
    //else
    return {value : val};
  } 

  export const __GetContentTypeDisplay = (d) => {
    return _GetContentTypeConfig(d.meta.kp_content_type).listingsContent.title;
  }

  export const __GetFeaturedCardLabelPropData = (d, featuredCardLabel) => {
    if(!featuredCardLabel) return undefined;
    return 
  }

  export const __GetSubject = (d) => {
    const tplSubject = (d) => d.meta.subject || d.meta.kp_subject; //backwards compat

    return tplSubject(d) && (tplSubject(d).label || tplSubject(d).title || formatText.underscoreToTitle(tplSubject(d))) 
            
  }

  export const __GetResourceLink = (d, valPath, options = { type : 'linked'}) => {
    let property = s_gVal('get', d, valPath);
    if(!propertyExists(property)) return undefined;
    //else
    let uploadedResourceObj = (
      property.KPResourceUpload &&  
      convertResToGcsStructure(property.KPResourceUpload)[0]
    ); 
    
    let linkedResource = property.KPLinkAsButtonInput;
    // console.log({property, uploadedResourceObj, linkedResource})
    const getResourceLinkInfo = (d) => {
      switch (true) {
        case ['pdf'].indexOf(d.format) !== -1:
          return { value: d.publicUrl || d.mediaLink, type: d.publicUrl ? 'linked' : 'uploaded' }; //all conditionalities here are for backwards compat 
        case ['jpg', 'png', 'gif', 'webp'].indexOf(d.format) !== -1:
          return { value: d.publicUrl || d.mediaLink, type: d.publicUrl ? 'linked' : 'uploaded' };
        default: //meaning its a 'raw' resource type
          return { value: d.mediaLink, buttonComp: 'uploaded' };
      }
    }

    if(uploadedResourceObj){
      return getResourceLinkInfo(uploadedResourceObj);
    }else if(linkedResource){ 
      return { type: 'linked', value: injectHttps(linkedResource) };
    }else{ //the property itself is the link
      return { type: options.type, value: injectHttps(property)}
    }
  }

  export const __GetCardEventDatePropData = (d, valPath) => {
    let dateObj = s_gVal('get', d, valPath);
    return createContentCardDateRange(
      dateObj && (dateObj.dateRange || [dateObj.date, dateObj.date ]),
      null,  //alt date
      null, //prefix 
      true //renderDateFn
    ) //the or is to deal with backwards compatibility
  }

  export const __GetVal = (d, valPath, propertyPath, count) => {
    /**
     * reason for the 2 path args : valpath & propertyPath: 
     * imagine if, the valPath leads to an array of objects.
     * inside that object there is a particular property that we want to access based on certain conditions.
     * Hence, we need the valPath to access the array
     * then run those conditions, whatever they maybe
     * and then use the propertyPath to match against conditions and return a final value
     */

     let obj = s_gVal('get', d, valPath);
     return getVal(obj, propertyPath, count);
  }

  export const __GetSpeaker = (d, valPath) => {
    let obj = s_gVal('get', d, valPath);
    return getSpeaker(obj) ? 'Speaker : ' + getSpeaker(obj)  : ''
  }

  export const __CustomGetter = (d, prop) => {
    if(!prop || !prop.fn) return undefined;
    //else
    let getterFns = {
      __GetSubject,
      __GetVal,
      __GetSpeaker,
      __GetContentTypeDisplay
    }
    
    let args = [d, ...(prop.args ? prop.args : [])];
    let val = getterFns[prop.fn](...args)
    return val;
  }

