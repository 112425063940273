import React from "react"
import {primaryColor100} from '../../../sass/vars.scss';

function Delete(props) {
  return (
    <svg width={42} height={42} viewBox="0 0 42 42" fill="none" {...props}>

      <path
        d="M18.387 15.175h.05V13.05h5.41v2.075h.05v.05h4.762v1.046h-1.557l-.003.047-.974 12.682h-9.929l-.974-12.682-.003-.047H13.85v-1.046h4.537zm4.431-1.047v-.05H19.484v1.078H22.818v-1.028zm-5.684 13.71l.004.046H25.127l.004-.046.902-11.582.004-.053h-9.791l.005.053.883 11.582z"
        fill={ props.stroke || primaryColor100 }
        stroke={ props.stroke || "#E0F0FD" }
        strokeWidth={0.1}
      />
      <path
        d="M20.618 17.956h1.047v8.23h-1.047v-8.23zM23.774 26.219l-1.045-.057.447-8.235 1.045.056-.447 8.236zM18.508 26.219l-.447-8.236 1.046-.056.446 8.235-1.045.057z"
        fill={ props.stroke || primaryColor100 }
        stroke={ props.stroke || "#E0F0FD" }
        strokeWidth={0.1}
      />
    </svg>
  )
}

export default Delete
