import * as React from "react"

function VideoEmbedIcon(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <rect x={3.685} y={3.076} width={17} height={17} rx={1.5} stroke="#fff" />
      <path
        d="M10.185 9.5v5.08a.5.5 0 00.8.4l3.627-2.709a.5.5 0 00-.026-.82l-3.628-2.37a.5.5 0 00-.773.42z"
        fill="#fff"
      />
    </svg>
  )
}

export default VideoEmbedIcon
