import React, { Fragment, useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { ButtonGhost, KPIconBtn } from '../../generalUI/KPButtons';
import KPLink from '../../generalUI/KPLink';
import Close from '../../icons/Close';

import { white } from '../../../sass/vars.scss';
import TitleSans__L from "../../typo/TitleSans__L";

const ModalHeader = (props) => {

  return (
      <div className={`kp-modal-header ${props.className}`}>
        <div className='kp-modal-header__content'>
          { props.title && <TitleSans__L>{props.title}</TitleSans__L> }
          { props.headerLink && <KPLink style={{paddingLeft: '2rem'}} linkType={props.headerLink.linkType} link={props.headerLink.link} text={props.headerLink.text} /> }
          { props.cta && props.cta }
        </div>
        <div className='kp-modal-header__actions-group'>
          <ButtonGhost
            // className='kp-modal-header__action--close' 
            // type='tertiary' 
            icon="Close_S"
            onClick={props.onClose}/>
        </div>
      </div>
  );
};

export default ModalHeader;
