import {convertToRichText, makeAryFromLen} from '../../../utils/general';

export const genInitVal = (props) => {

    const { initValue, initRows, initCols, createSrNoCol, hasTableHeader, colContentBlocks, colContentBlockProps, typeOfRowData} = props;
    
    const getAryLength = ary => {
      return Array.isArray(ary) ? ary.length : Object.values(ary).length;
    }

    let matrix = [];
    let noOfRows = initValue ? Math.max(initValue.length, initRows) : initRows;
    let row1CellData = initValue[0].cellData || initValue[0];
    let noOfCells = (initValue && getAryLength(row1CellData) > initCols ? getAryLength(row1CellData) : initCols) + (createSrNoCol ? 1 : 0); 
    
    
    makeAryFromLen(noOfRows).map((d, rowI) => {
      let row = typeOfRowData === 'object' ? { cellData: [] } : [];
      makeAryFromLen(noOfCells).map((d, cellI) => {
        let initVal;
        let initValIsEditable; // if explicitly in the initValue prop, isEditable is passed, then we handle and store that here. ultimately used against the table cell's readOnly property
        let comp = 'KPRichInput'; //settings to default to begin with
        let props = null;
        if(createSrNoCol && cellI === 0){
          initVal = convertToRichText(rowI === 0 ? 'Sr No.' : `${rowI}.`);
          comp = 'KPRichInput';
        }else{
          let rowIdx = rowI;
          let cellIdx = createSrNoCol ? cellI - 1 : cellI;
          let cellData = (
            initValue && 
            initValue[rowIdx] && 
            initValue[rowIdx].cellData 
            ? initValue[rowIdx].cellData[cellIdx]
            : initValue[rowIdx][cellIdx] 
          )
          
          initVal = cellData ? cellData.value : null
          
          initValIsEditable = initVal ? cellData.isEditable : null
          
          if(cellData && cellData.comp ){ //meaning comps and props are defined within the initValue prop itslef
              comp = cellData.comp;
              props = cellData.props;
            }
            else if(rowIdx === 0 && hasTableHeader){ //if it is table header, then always set the comp to richinput
              initVal = convertToRichText(initVal);
              comp = 'KPRichInput'
            } else{

              switch(colContentBlocks[cellIdx]){ //else set comp as per definition in the props.
                case 'KPRichInput':
                initVal = convertToRichText(initVal);
                comp = 'KPRichInput'
                props = colContentBlockProps[cellIdx];
                break;
    
                case 'KPDropdown':
                comp = 'KPDropdown';
                props = colContentBlockProps[cellIdx];
                break;

                case 'KPRadioInput':
                comp = 'KPRadioInput';
                props = colContentBlockProps[cellIdx];
                break;

                case 'KPTextInput':
                comp = 'KPTextInput';
                props = colContentBlockProps[cellIdx];
                break;
                
                default:
                initVal = convertToRichText(initVal);
                comp = 'KPRichInput'
                props = colContentBlockProps[cellIdx];
                break;
              }
            }
                   
        } 

        (typeOfRowData === 'object' ? row.cellData : row).push({
          id : `r_${rowI}_c_${cellI}`,
          cellHover : false,
          edgeHover : null, // 'left' | 'right' | 'top' | 'bottom' | null
          width : 100/noOfCells,
          value : initVal,
          readOnly : initValIsEditable ? false : !!initVal,
          comp,
          props
        })
      })
      matrix.push(row);  
    })  
    
    return matrix;
  }


export const handleAddCol = (val, props) => {
    const { onChange, id, passChangeHandlerOps, typeOfRowData } = props;
    let newVal = [...val];
    newVal.map((row, rowI) => {
      (typeOfRowData === 'object' ? row.cellData : row).push({
        id : `r_${rowI}_c_${row.length}`,
        cellHover : false,
        edgeHover : null, // 'left' | 'right' | 'top' | 'bottom' | null
        width : 100/(row.length + 1),
        value : null,
        comp : 'KPRichInput' //by default new col is KPRichInput
      })
    })

    onChange && onChange(id, newVal, passChangeHandlerOps && props);
}

export const handleAddRow = (val, props) => {
    const { onChange, id, createSrNoCol, passChangeHandlerOps, typeOfRowData } = props;
    let newVal = [...val];
    let lastRowIdx = newVal.length-1;
    let newRowTotCells = newVal[lastRowIdx].length;
    let newRow = typeOfRowData === 'object' ? { cellData: []} : []; 
    makeAryFromLen(newRowTotCells).map((cell, cellI) => {
      let newCellValue = createSrNoCol && cellI === 0 ? convertToRichText(`${lastRowIdx+1}.`) : null;
      (typeOfRowData === 'object' ? newRow.cellData : newRow).push({
        id : `r_${newVal.length}_c_${cellI}`,
        cellHover : false,
        edgeHover : null, // 'left' | 'right' | 'top' | 'bottom' | null
        width : 100/newRowTotCells,
        value : newCellValue,
        readOnly : !!newCellValue,
        comp : val[lastRowIdx][cellI].comp, //must be the same as the cell above it *THIS WONT WORK IF hasTableHeader is true and THE LAST ROW IS THE HEADER ITSELF*
        props : val[lastRowIdx][cellI].props 
      })
    })

    newVal.push(newRow);
    
    onChange && onChange(id, newVal, passChangeHandlerOps && props); 
}


export const handleCellHover = ( bool, rowIdx, cellIdx, val ) => {
    let cellData = val[rowIdx].cellData 
    ? val[rowIdx].cellData[cellIdx] 
    : val[rowIdx][cellIdx];
    cellData.cellHover = bool;
}

export const handleEdgeHover = (e, rowIdx, cellIdx, val, props) => {
    const { id, cellBorderWidth, onChange, passChangeHandlerOps} = props;
    let newVal = [...val];
    let cellData = val[rowIdx].cellData 
    ? val[rowIdx].cellData[cellIdx] 
    : val[rowIdx][cellIdx];

    if(cellData.cellHover){
      let mX = e.clientX;
      let mY = e.clientY;
      let celDims = e.target.getBoundingClientRect();
      let cellR = celDims.right;
      let cellL = celDims.left;
      let cellT = celDims.top;
      let cellB = celDims.bottom;
      switch(true){
        case mX > (cellR - cellBorderWidth) :
          cellData.edgeHover = 'right';
          break;
        case mX < (cellL + cellBorderWidth) :
          cellData.edgeHover = 'left';
          break;
        case mY > (cellB - cellBorderWidth) :
          cellData.edgeHover = 'bottom';
          break;
        case mY < (cellT + cellBorderWidth) :
          cellData.edgeHover = 'top';
          break;
        default :
          cellData.edgeHover = null;
      }

      onChange && onChange(id, newVal, passChangeHandlerOps && props);
    }
}

export const genEdgeCursor = cell => {
    return ['top', 'bottom'].includes(cell.edgeHover)
    ? 'row-resize'
    : ['left', 'right'].includes(cell.edgeHover)
      ? 'col-resize'
      : 'text' 
}