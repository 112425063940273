import * as React from "react"

function LinkOutSM(props) {
  return (
    <svg width={21} height={21} viewBox="0 0 21 21" fill="none" {...props}>
      <path
        d="M8.86 3.532H5.332a2 2 0 00-2 2v10.461a2 2 0 002 2h10.21a2 2 0 002-2v-3.68M10.438 10.763l7.895-8.034"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
      />
      <path
        d="M13.597 2.73h4.737v4.736"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LinkOutSM
