import * as React from "react"
import { white} from '../../../sass/vars.scss';

function AudioEmbedIcon(props) {
  return (
    <svg
      width={21}
      height={20}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.536 7.318l.018-.012.016-.013 6.533-5.214c.033-.024.09-.034.15-.006a.117.117 0 01.05.038c.004.006.008.015.007.035v15.868a.083.083 0 01-.016.051.157.157 0 01-.064.05c-.068.03-.126.018-.158-.005L5.54 12.896l-.017-.013-.018-.012a1.156 1.156 0 00-.646-.207H1.115a.137.137 0 01-.032-.003.229.229 0 01-.02-.111V7.639c0-.065.02-.098.028-.107a.026.026 0 01.003-.004h.001l.004-.002a.075.075 0 01.016 0h3.744c.271 0 .52-.103.677-.208z"
        stroke={white}
      />
      <path
        d="M17.609 5.406a6.625 6.625 0 011.953 4.689 6.626 6.626 0 01-1.953 4.688M15.319 6.5a4.99 4.99 0 011.485 3.595c0 1.367-.547 2.617-1.485 3.594"
        stroke={white}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default AudioEmbedIcon
