import store from '../../store';
// Hamburger Icons
import CommunityHamburgerIcon from "../../components/icons/PType_UserProfiles"

import {
  USER_PROFILES as USER_PROFILES_CARD,
  ORGANISATIONS as ORGANISATIONS_CARD,
  WORKING_GROUPS as WORKING_GROUPS_CARD,
  STATE_NETWORKS as STATE_NETWORKS_CARD,
  PARTNER_NETWORKS as PARTNER_NETWORKS_CARD,
} from '../cards/profileCards/card';

import {
  USER_PROFILES as USER_PROFILES_ADMIN_DB,
  ORGANISATIONS as ORGANISATIONS_ADMIN_DB,
  WORKING_GROUPS as WORKING_GROUPS_ADMIN_DB,
  STATE_NETWORKS as STATE_NETWORKS_ADMIN_DB,
  PARTNER_NETWORKS as PARTNER_NETWORKS_ADMIN_DB,
} from '../cards/profileCards/adminDb';

import {
  USER_PROFILES as USER_PROFILES_LIST,
  ORGANISATIONS as ORGANISATIONS_LIST,
  WORKING_GROUPS as WORKING_GROUPS_LIST,
  STATE_NETWORKS as STATE_NETWORKS_LIST,
  PARTNER_NETWORKS as PARTNER_NETWORKS_LIST,
} from '../cards/profileCards/list';

import { communityListings } from '../../locales/en/listings';
//changed by vinay
 //const communityListings = store.getState().environment.envConfig.content.listingsPage.communityListings;

export const _GetProfileTypeConfig = profileTypeId => {
  return _ProfileTypes[_ProfileTypes.findIndex(d => d.id === profileTypeId)]
}

export const _ProfileTypes = [
  {
    icon : CommunityHamburgerIcon,
    title : 'People',
    description : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla semper placerat consectetur.',
    id : 'userProfiles',
    cardConfigKey : 'USER_PROFILE_CARD',
    reduxListingKey : 'userProfilesListing',
    listingsContent : communityListings.userProfiles,
    listCardConfig : USER_PROFILES_LIST,
    cardCardConfig : USER_PROFILES_CARD,
    adminDbCardConfig : USER_PROFILES_ADMIN_DB,
  },
  {
    icon : CommunityHamburgerIcon,
    title : 'Partner Organisations',
    description : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla semper placerat consectetur.',
    id : 'organisations',
    cardConfigKey : 'ORG_CARD',
    reduxListingKey : 'organisationsListing',
    listingsContent : communityListings.organisations,
    listCardConfig : ORGANISATIONS_LIST,
    cardCardConfig : ORGANISATIONS_CARD,
    adminDbCardConfig : ORGANISATIONS_ADMIN_DB,
  },
  {
    icon : CommunityHamburgerIcon,
    title : 'Working Groups',
    description : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla semper placerat consectetur.',
    id : 'workingGroups',
    cardConfigKey : 'GROUP_CARD',
    reduxListingKey : 'workingGroupsListing',
    listingsContent : communityListings.workingGroups,
    listCardConfig : WORKING_GROUPS_LIST,
    cardCardConfig : WORKING_GROUPS_CARD,
    adminDbCardConfig : WORKING_GROUPS_ADMIN_DB,
    adminDbTableCols : [
      { value: 'name', display: 'Name'},
      { value: 'admin', display: 'Convener'},
      { value: 'noOfMembers', display: 'Members'},
      { value: 'anchorOrgs', display: 'Anchor Orgs'}
    ],
    leaderDesig : 'Convener',
    adminCanEditRoles : false
    // primaryProfileTab : 'content'
  },
  {
    icon : CommunityHamburgerIcon,
    title : 'State Networks',
    description : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla semper placerat consectetur.',
    id : 'stateNetworks',
    cardConfigKey : 'GROUP_CARD',
    reduxListingKey : 'stateNetworksListing',
    listingsContent : communityListings.stateNetworks,
    listCardConfig : STATE_NETWORKS_LIST,
    cardCardConfig : STATE_NETWORKS_CARD,
    adminDbCardConfig : STATE_NETWORKS_ADMIN_DB,
    adminDbTableCols : [
      { value: 'name', display: 'Name'},
      { value: 'admin', display: 'Convener'},
      { value: 'noOfMembers', display: 'Members'},
      { value: 'anchorOrgs', display: 'Anchor Orgs'}
    ],
    leaderDesig : 'Convener',
    adminCanEditRoles : false
  },
  {
    icon : CommunityHamburgerIcon,
    title : 'Collaborating Networks',
    description : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla semper placerat consectetur.',
    id : 'partnerNetworks',
    cardConfigKey : 'GROUP_CARD',
    reduxListingKey : 'partnerNetworksListing',
    listingsContent : communityListings.partnerNetworks,
    listCardConfig : PARTNER_NETWORKS_LIST,
    cardCardConfig :  PARTNER_NETWORKS_CARD,
    adminDbCardConfig : PARTNER_NETWORKS_ADMIN_DB,
    adminDbTableCols : [
      { value: 'name', display: 'Name'},
      { value: 'admin', display: 'Convener'},
      { value: 'noOfMembers', display: 'Members'},
      { value: 'anchorOrgs', display: 'Anchor Orgs'}
    ],
    leaderDesig : 'Convener',
    adminCanEditRoles : false
  },
  {
    icon : CommunityHamburgerIcon,
    title : 'Our Board',
    description : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla semper placerat consectetur.',
    id : 'board',
    cardConfigKey : 'USER_PROFILE_CARD',
    reduxListingKey : 'boardListing', //creates a separate store for this in redux
    listingsContent : communityListings.board,
    listCardConfig : USER_PROFILES_LIST,
    cardCardConfig : USER_PROFILES_CARD,
    // adminDbCardConfig : BOARD_ADMIN_DB
  }

]
