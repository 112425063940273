import * as React from "react"
import {primaryColor100} from '../../../sass/vars.scss';

function PType_UserProfiles(props) {
  return (
    <svg width={16} height={19} viewBox="0 0 16 19" fill="none" {...props}>
      <rect
        x={1.462}
        y={0.842}
        width={13.909}
        height={17}
        rx={1}
        stroke={props.stroke || primaryColor100}
      />
      <circle cx={8.101} cy={8.627} r={1.877} stroke={props.stroke || primaryColor100} />
      <path d="M13.194 17.455a5.093 5.093 0 00-10.186 0" stroke={props.stroke || primaryColor100} />
    </svg>
  )
}

export default PType_UserProfiles
