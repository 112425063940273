import React, {Fragment} from "react";


const TitleSerif__S = (props) => {

  

  return (
    <h4 className={`titleSerif--S ${props.className || ''}`} style={props.style} id={props.id || ''}>{props.children}</h4>
  );

}


export default TitleSerif__S;
