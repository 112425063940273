import React from 'react';
import {Link } from 'react-router-dom';
import { _ContentTypes, _GetContentTypeConfig } from '../_configs/contentTypes/config';
import { _ProfileTypes } from '../_configs/profileTypes/config';
import { _DataTypes } from '../_configs/dataTypes/config';
import {__GetText} from '../utils/gettersV2';
import {setActiveHeaderLink} from '../utils/general';
import TextLoader from '../components/loaders/TextLoader';

let contentTypesToShowAsOptions = _ContentTypes.filter(d => d.showListingPage !== false);
let profileTypesToShowAsOptions = _ProfileTypes.filter(d => d.showListingPage !== false);

export const __GenNavLink = (thisPathname, display, location, loading = null, className='', onClick = null) => {
  if(loading) return <TextLoader style={{width: '5rem', height: '1.5rem', margin: '0 1rem'}} />
  return (
  <li className={`navLink ${className} ${setActiveHeaderLink(location.pathname + location.search, thisPathname)}`}>
    <Link
      className={`navLink__link`}
      to={thisPathname}
      onClick={onClick && onClick}
      >
      {display}
    </Link>
  </li>
)}


export const __GetStaticPageLinkData = (pageId, listings) => {
  
  const { staticPagesListing, staticPagesListing : { staticPagesLoading } } = listings;

  const getPageData = (pageId) => {
    let idx = staticPagesListing.initData.findIndex(d => d._id === pageId);
    return staticPagesListing.initData[idx];
  }

  return(
    staticPagesLoading
    ? { loading: true }
    : {
      display: __GetText(getPageData(pageId).main.title),
      value: `/published-page/staticPages?id=${pageId}`
    }
  ) 
}


export const _KnowledgeMenuOptions = contentTypesToShowAsOptions.map(d => {
  return {display: d.listingsContent.title, value: `/published-listing/${d.groupId ? d.groupId : d.id}`, icon: d.icon}
});

export const _CommunityMenuOptions = profileTypesToShowAsOptions.map(d => {
  return {display: d.title, value: `/community-listing/${d.id}`, icon: d.icon}
});

export const _HeaderSubMenuOptions = {
  COMMUNITY : _CommunityMenuOptions,
  KNOWLEDGE : _KnowledgeMenuOptions
}

export const genKnowledgeListingOption = (contentType, display = null) => {
  let isGroupKey = contentType.split('+').length > 1;
  let value = isGroupKey 
  ? `/grouped-published-listing/${contentType}` 
  : `/published-listing/${contentType}` 

  return {
    display: display || _GetContentTypeConfig(contentType).listingsContent.title,
    value
  }
}



export const _DataMenuOptions = _DataTypes.map(d => {
  return {display: d.title, value: `/${d.id}`, icon: d.icon}
});

export const superAdminMenuOptions = _ProfileTypes.map(d => {
    return {id: d.id, display: `Create A ${d.title} Page`, value: `/edit/${d.id}/create`}
}).filter(d => d.id !== 'userProfiles' );
