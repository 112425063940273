import store from '../store';
import api from "./../_configs/api";
import { debounce } from 'lodash';
import history from "../history"; //history object that allows for routing from outside components.
import { setAlert } from "./alert";
import { resetNestedPublishing } from './nestedPublishing'
import { checkObjEquivalent, propertyExists, rmArray, segrigateDocs, setToValPath } from "../utils/general";
import { modifierFunctions} from '../utils/tplModUtils';
import {drilldownSubSection} from '../utils/general';
import {
  SET_TPL_LOADING,
  SET_TPL_LOADING_V2,
  GET_TPL,
  GET_TPL_ERROR,

  RESET_TPL,
  PROGRESS_TPL_SECTION,
  UPDATE_FLEXI_TPL,

  GEN_TEMPLATE_AND_SAVE_EMPTY_CONTRIBUTION,
  GEN_TPL_AND_SAVE_CONTR_ERROR,
  UPDATE_USER_CONTR, USER_CONTR_PUBLISHED,
  HYDRATE_USER_CONTR_AND_TPL,
  SET_HYDRATE_USER_CONTR_AND_TPL_LOADING,
  HYDRATE_USER_CONTR_AND_TPL_ERROR,
  USER_CONTR_ERROR,

  UPDATE_CLEAR_TO_PROCEED,

  SET_AUTO_SAVE,

  SET_BLOCK_ERRORS

} from "./types";

import {checkAccessToContentType} from '../utils/accessControl'

import { formValidationCheck, set_getValOffQueryString as s_gVal, mathRound } from '../utils/general';
import { generateMultiSubjectTplData } from '../utils/contributionFlowUtils';

import {_GetContentTypeConfig} from '../_configs/contentTypes/config';
import { KPDropdownOptions__Single } from '../components/inputs/KPDropdownOptions';

export const setSaveStatusToLocalSave = () => dispatch => {
  if(store.getState().contributions.draftRel.autoSaveStatus.name === 'saving'){
    dispatch({
      type: SET_AUTO_SAVE,
      payload: {
        name: "savedToLocal",
        msg: 'Saved Locally',
        type: 'success'
      }
    })
  }
}

export const setSaveStatusToSavedToDb = () => dispatch => {
  if(store.getState().contributions.draftRel.autoSaveStatus !== 'inactive'){
    dispatch({
      type: SET_AUTO_SAVE,
      payload: {
        name: "savedToDb",
        msg: 'Saved To Database!',
        type: 'success'
      }
    })
  }
}

export const getUpdate = (contentType, parentResourceId, updateId, mode) => async dispatch => {

  try{
    const res = await api.get(`/api/updates/${contentType}/${parentResourceId}/${updateId}`);
    
    dispatch({
      type: HYDRATE_USER_CONTR_AND_TPL,
      payload: { mode, data: { ...res.data, tpl : res.data.tpl } }
    })

  }catch(err){
    console.log("error in get update!", err);
  }
  

}

const autosave = Contribution => async dispatch => {
  
  try{
    dispatch({
      type: SET_AUTO_SAVE,
      payload: {
        name: "saving",
        msg: 'Saving...',
        type: 'inactive'
      }
    });
    
    const config = { headers: { "Content-Type": "application/json" }};

    if( Contribution.meta.kp_content_sub_type === 'updates' ){
      const res = await api.post("/api/updates", { Contribution }, config)
      Contribution = res.data;
    }else{
      !Contribution.main && (Contribution.main = {}) //create an empty main object incase there is no main withing user contr. otherwise the model wont accept
      const res = await api.post("/api/contributions", { Contribution }, config);
      Contribution = res.data;
    }
    

    dispatch({
      type: SET_AUTO_SAVE,
      payload: {
        name: "saved",
        msg: 'Saved!',
        type: 'success'
      }
    });

  }catch(err){
    console.log("autosave fail msg",err.response.data);
    if(err && err.response && err.response.data && err.response.data.errors){
      err.response.data.errors.map(d => dispatch(setAlert("AUTOSAVE FAILED. "+d.msg, 'danger', 6000)))
    }
    dispatch({
      type: SET_AUTO_SAVE,
      payload: {
        name: "save failed",
        msg: 'Save Failed.',
        type: 'danger'
      }
    });
  }
}

const debouncedAutosave = debounce((passed) => store.dispatch(autosave(passed)), 1000, {leading:false, trailing:true} );

export const setTplLoading = (bool) => dispatch => dispatch({ type: SET_TPL_LOADING_V2, payload: bool })

export const getTPL = ( //get tpl for contributing
  contentType,
  clearToProceed,  /*pretty certain its redundant*/
  mode /*seems redundant*/,
  user,
  roles,
  redirectToSettingsPage = false,
  tplCategory = 'contribute',
  callback = null,
  options = {}
) => async dispatch => {

  try {
    dispatch({ type: SET_TPL_LOADING, payload: true});
    dispatch({ type: SET_TPL_LOADING_V2, payload: true});

    // ---- dealing with questionnaires -----

    const isFreeForAll = () => {
      let freeForAllTplCategories = ['questionnaires'];
      return freeForAllTplCategories.indexOf(tplCategory) !== -1;
    }
    
    //check if user has access to contribute this, and if not, they ain't going any further
    if(!isFreeForAll() && !checkAccessToContentType(user,contentType, roles, 'create')){
      dispatch({ type: SET_TPL_LOADING, payload: false});
      dispatch(setAlert('You do not have access to create this content. Please request the administrator if you would like access.', 'danger'));
      return;
    }

    // ---- END dealing with questionnaires -----


    const res = await api.get(`/api/tpl/${contentType}`);

    //------NEW ------ TPL SUB TYPE IMPLEMENTATION --------------//
    

    if(options.contentSubType){
      let tplIdx = res.data.findIndex(d => d.kp_content_sub_type === options.contentSubType);
      if(tplIdx === -1) throw `no tpl with the content sub type — ${options.contentSubType}, was found.`
      //else
      res.data = res.data[tplIdx];
    }else{ //else return the one that does not have a subtype defined ( which is the main tpl. )
      let tplIdx = res.data.findIndex(d => !!d.kp_content_sub_type === false);
      res.data = res.data[tplIdx];
    }

    //populate clearToProceed obj with the tot sections to proceed through. and set it all to false.
    let contentTypeConfig = _GetContentTypeConfig(contentType);
    // we cant do a direct destructure like: const { supportingTpl } = _GetContentTypeConfig(contentType);
    // because in the case of 'questionnaire' tpls, we arent adding them to the contenttype config (yet) so the direct destructure will throw an error
    if(contentTypeConfig && contentTypeConfig.supportingTpl){
      let { supportingTpl } = contentTypeConfig;
      const supportingTplRes = await api.get(`/api/tpl/${supportingTpl}`);
      res.data = { ...res.data, [supportingTpl] : supportingTplRes.data }
    }


    //set settings sections to false (if settings section exists)
    let clearToProceed = {};
    let tplSettingsAry = res.data.kp_settings;

    if(tplSettingsAry.length > 0){
      tplSettingsAry.map(section => {
        clearToProceed[section.sectionName] = false;
      })
    }

    //set tpl section to false
    if(res.data.kp_templates && res.data.kp_templates.sectionName ){
      clearToProceed[res.data.kp_templates.sectionName] = false;
    }

    //prep contribution meta data for reducer
    let contrMetaData = {
      kp_content_tpl : res.data._id ? res.data._id : null, //this is updated later by the subject selection KPRadioBlock (whose valuepath is 'meta.kp_content_tpl')
      kp_flexi_content_tpl_data : res.data.kp_tpl_type === 'flexible' ? res.data : null,
      kp_content_type : res.data.kp_content_type,
      kp_content_sub_type : res.data.kp_content_sub_type,
      parentResourceId : options.parentResourceId ? options.parentResourceId : null,
      kp_tpl_category : res.data.kp_tpl_category
    }

    const settingsSecIdx = tplSettingsAry.length > 0 ? 0 : null;

    let alreadyRedirected = false;
    if(options.contentSubType === 'updates'){
      history.push({pathname: '/contribute-config' })
      alreadyRedirected = true;
    }

    dispatch({
      type: GET_TPL,
      payload: {
        mode,
        settingsSecIdx,
        data : res.data,
        contrMetaData,
        clearToProceed
      }
    });

    if(!alreadyRedirected){
      if(tplSettingsAry.length === 0) {
        dispatch( generateTpl({
          meta: contrMetaData,
          main: {}
        }), user )
      }else if(redirectToSettingsPage === true){
        history.push({pathname: '/contribute-config' })
      }
    }
    

  }catch(err){

    console.log('err in getTPL', err);
    dispatch({ type: GET_TPL_ERROR, payload: err })
    if(callback) callback(err);
  }
};

export const resetTPL = () => dispatch => dispatch({ type: RESET_TPL })


export const progressSettingsSec = (Contribution, tpl, settingsSecIdx, progressDirection ) => async dispatch => {
  try {
    const settingsSection = tpl.kp_settings[settingsSecIdx];

    if(progressDirection === "forward"){
      if(formValidationCheck(settingsSection.blocks, Contribution, 0).emptyFieldFound === false){
        settingsSecIdx++
        dispatch({type: PROGRESS_TPL_SECTION, payload: settingsSecIdx })
      }
    }else{ //if progressDirection === "back"
      if(settingsSecIdx === 0) {
        dispatch(resetTPL());
        return;
      }
      settingsSecIdx--
      dispatch({type: PROGRESS_TPL_SECTION, payload: settingsSecIdx })
    }

  }catch(err){
    if(err) console.log('err in progressSettingsSec', err);
  }
}


export const updateContribution = (
  id, //this is redundant nd not needed here any more. valuepath does its job
  val,
  Contribution,
  valuePath,
  activeTplSectionName,
  activeTplSectionBlockArray,
  autoSaveStatusName = 'inactive',
  triggerSave = false,
  modifiers = null,
  setValuePath = null
) => async dispatch => {

  try {
    if(!Contribution.main) Contribution.main = {};  

    //in some cases you wanna define a separate bunch of valuePaths to set, but a single value path to get from, in this case, you define a setValuePath, which will always override the valuePath in updateContribution()
    //currently only used in the update flow, in the generateMultiSubjectTpl function
    if(setValuePath){
      Array.isArray(setValuePath)
      ? setValuePath.map(vPath => setToValPath(Contribution, vPath, val))
      : setToValPath(Contribution, setValuePath, val)
    }else{
      propertyExists(val) 
      ? s_gVal('set', Contribution, valuePath, val)
      : s_gVal('delete', Contribution, valuePath)
    }
    
       
    let {emptyFieldFound, emptyFields} = formValidationCheck(activeTplSectionBlockArray, Contribution, 0);
    
    drilldownSubSection( 
      activeTplSectionBlockArray, 
      (block) => {
        if(!emptyFields.some(emptyFieldData => emptyFieldData.block.props.id === block.props.id)){
          block.blockError = false;
        }
      }, 
      0 
    )
    

    dispatch({
      type: UPDATE_CLEAR_TO_PROCEED,
      payload: { 
        clearToProceed : {
          [activeTplSectionName] : !emptyFieldFound /* opposite of empty field found = IS clear to proceed */ 
        },
        emptyFields
      }
    })

    //check where this is used? i think in millets
    if(
      triggerSave && 
      (store.getState().environment.onlineStatus === 'offline' 
      ||
      autoSaveStatusName !== 'saving')) debouncedAutosave(Contribution);

    if(modifiers){

      modifiers.map(modifier => {
        let args = [Contribution, activeTplSectionBlockArray, ...modifier.args];
        modifierFunctions[modifier.function](...args)
      })
    }

    dispatch({
      type: UPDATE_USER_CONTR,
      payload: Contribution
    })

  }catch(err){
    if(err) console.log('err in updateContribution', err);
    if(err && err.response && err.response.data && err.response.data.errors){
      err.response.data.errors.map(d => dispatch(setAlert(d.msg, 'danger', 6000)))
    }
  }
}

//unused
export const updateFlexiTpl = (updatedTpl) => dispatch => {
  dispatch({ type: UPDATE_FLEXI_TPL, payload: updatedTpl})
}

//ACTIONS THAT ATTEMPT TO SAVE CONTRIBUTION TO DB -->

//#1 FIRST SAVE ATTEMPT IN THE DB
export const generateTpl = (
  Contribution, 
  user
  ) => async dispatch => {
  

  try{

    dispatch({ type: SET_TPL_LOADING, payload: true});
    const config = { headers: { "Content-Type": "application/json" }};

    const isAnUpdate = Contribution.meta.kp_content_sub_type === 'updates'

    if(isAnUpdate){
      const res = await api.post("/api/updates", { Contribution }, config)
      Contribution = res.data;
    }else{
      const res = await api.post("/api/contributions", { Contribution, user }, config);
      Contribution = res.data;
    }
    
    

    // * IMP : README 
    // *
    // * in this dispatch, we dont update loading to false in the reducer. because, this action redirects to edit contr page, 
    // * which makes its own call to get the contribution, and we want that call to succeed before we set loading to false. 
    // * because loading false, generates the page, AS WELL AS begins autosave. We do not want autosave to start until the proper Contribution object, which will be updated by 'updateContribution' action is available in the redux store.
    dispatch({
      type: GEN_TEMPLATE_AND_SAVE_EMPTY_CONTRIBUTION,
      payload: isAnUpdate ? Contribution.updates[0] : Contribution
    })
    history.push({
      pathname: `/edit/${Contribution.meta.kp_content_type}`,
      search: isAnUpdate ? `id=${Contribution.updates[0]._id}&parentResourceId=${Contribution._id}` : `id=${Contribution._id}`,
      state: 'ContributeTpl'
    });
    //the empty main object is disappearing. see if we can make it stay in backend.
  }catch(err){
    if(err) console.log("err in generateTpl", err);
    dispatch({
      type: HYDRATE_USER_CONTR_AND_TPL_ERROR,
      payload: err && err.response && err.response.data
    })
    if(err && err.response && err.response.data && err.response.data.errors){
      err.response.data.errors.map(d => dispatch(setAlert(d.msg, 'danger', 6000)))
    }
  }
}

//#3 PUBLISH Contribution
export const publishContribution = (
  contrId, 
  Contribution, 
  tpl, 
  clearToProceedTemplateSection, 
  userAction, 
  nestedPublishing = null
) => async dispatch => {
  
  try{

    let tplSubject = Contribution.meta.kp_subject || Contribution.meta.subject

    const tplBlockDataArray = tpl &&  // only used to pass into the publishContribution function, where this is only used for the validation check, and to highlight required fields that were left empty
                            (tplSubject 
                            ? tpl.kp_templates.data[typeof tplSubject === 'string' 
                              ? tplSubject 
                              : tplSubject.value] 
                            : tpl.kp_templates.data);

    let {emptyFieldFound, emptyFields} = formValidationCheck(tplBlockDataArray, Contribution, 0);
    if(emptyFieldFound){
      dispatch(setAlert('You have left some required fields empty. Please fill them out in order to proceed.', 'danger'))
      
      drilldownSubSection( 
        tplBlockDataArray, 
        (block) => {
          if(emptyFields.some(emptyFieldData => emptyFieldData.block.props.id === block.props.id)){
            block.blockError = true;
          }else{
            block.blockError = false;
          }
        }, 
        0 
      )
      
      dispatch({
        type: SET_BLOCK_ERRORS,
        payload: {
          tpl,
          highlightErrorSubSections : true
        }
      })
      return;

    }

    if(clearToProceedTemplateSection === true ){

      let isUpdate = Contribution.meta.kp_content_sub_type === 'updates';

      const config = { headers: { "Content-Type": "application/json" }};
      if( Contribution.meta.kp_content_sub_type === 'updates'){
        const res = await api.post("/api/updates?userAction=publish", { Contribution }, config)
        // Contribution = res.data;
        dispatch({type: RESET_TPL}); //we need to do this, because if we dont, the redirect happens, and the old contribution object is still in the store, and it creates all sorts of conflicts. 
        // need to look into this in more detail and bring some elegance in.
      }else{
        const res = await api.post(
          `/api/contributions?userAction=${nestedPublishing && nestedPublishing.mode === 'active' ? 'publish' : userAction}`, { Contribution }, config
        );
        Contribution = res.data;
      }

      let contentTypeConfig = _GetContentTypeConfig(Contribution.meta.kp_content_type);
      let contentTypeGroupId = _GetContentTypeConfig(Contribution.meta.kp_content_type).groupId;

      let listingRoute = contentTypeGroupId ? contentTypeGroupId : Contribution.meta.kp_content_type;

      let redirectHistoryObj = (nestedPublishing && nestedPublishing.mode === 'active')
                          ? { ...nestedPublishing.redirectPath, state: { nestedPblEmbedValue : { contentBlockId: nestedPublishing.contentBlockId, value: Contribution}}}
                          : contentTypeConfig.postPblRedirPath
                            ? contentTypeConfig.postPblRedirPath
                            : userAction === 'publish'
                              ? { pathname: `/published-listing/${listingRoute}`, state: 'contrPublishedOrSubmitted' }
                              : { pathname: `/profile/userProfiles/${Contribution.meta.kp_contributed_by._id}`, state: 'contrPublishedOrSubmitted'}


      let alreadyRedirected = false;
      if(isUpdate){ 
        history.push(redirectHistoryObj);
        alreadyRedirected = true;
      }

      
      dispatch({type: USER_CONTR_PUBLISHED, payload: Contribution }) //really dont think we need to do this . why update the Contribution object when we are going to erase it anyway?
      //PENDING: dispatch set alert (success!);

      //user action here, can be either publish or submit

      
                              
      
      if(!alreadyRedirected) history.push(redirectHistoryObj);

      let alertMsg;

      alertMsg = (userAction === 'publish') 
                  ? 'Your contribution was published successfully!'
                  : (nestedPublishing && nestedPublishing.redirectPath)
                    ? ( nestedPublishing.alertMsg 
                      ? nestedPublishing.alertMsg.success
                      : 'Your contribution was published successfully!' )
                    : userAction === 'publish'
                      ? ( contentTypeConfig.postPblAlertMsg
                        ? contentTypeConfig.postPblAlertMsg
                        : 'Your contribution was published successfully!' )
                      : 'Your contribution has been submitted for moderation. You will be notified once it has been approved or otherwise'
                   
      

      

      dispatch(setAlert(alertMsg, 'success', 6000))

    }else{
      throw("you have some required fields that have not been filled");
    }
  }catch(err){
    //PENDING : change the published flag back to "draft" in the redux store. IF the error happens while doing the post.
    if(err) console.log('err in publish contribution', err);
  }
}

export const getContribution = ( contentType, contrId, mode, Contribution ) => async dispatch => {
  try{
    dispatch({ type: SET_HYDRATE_USER_CONTR_AND_TPL_LOADING, payload: true});
      //later maybe we can optimize by fetching from redux store itself IF already available.
      const res = await api.get(`/api/contributions/${mode}/${contentType}?id=${contrId}`);
      
      let tplData = res.data.tpl;

      //@CLEANUP: this stuff is just to deal with the insertion of supporting tpl for questionnaires. there has to be a cleaner way of doing this. link saving a reference to the supporting tpl within the contribution itself...
      let contentTypeConfig = _GetContentTypeConfig(contentType);
      if(contentTypeConfig && contentTypeConfig.supportingTpl){
        let { supportingTpl } = contentTypeConfig;
        const supportingTplRes = await api.get(`/api/tpl/${supportingTpl}`);
        tplData = { ...tplData, [supportingTpl] : supportingTplRes.data }
      }

      dispatch({
        type: HYDRATE_USER_CONTR_AND_TPL,
        payload: { mode, data: { ...res.data, tpl : tplData }  }
      })

  }catch(err){
    if(err) console.log('err in getContribution',err);
    dispatch({
      type: HYDRATE_USER_CONTR_AND_TPL_ERROR,
      payload: err.response && err.response.data
    })
  }
}
