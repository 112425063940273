import React, { useState, Fragment } from "react";
import { set_getValOffQueryString } from '../../../utils/general';

import KPLabel from '../../generalUI/KPLabel';

const MetaBlock__Toolkits = props => {

    let data = props.data;
    if(props.metaObj){ //if the meta Obj is passed through it means the metaData is coming from the template data

      data = props.data.map(d => {
        const metaProperty = set_getValOffQueryString('get', JSON.parse(props.metaObj), d.text);

        switch(true){
          case Array.isArray(metaProperty) && metaProperty.length > 0 :
          let textString = typeof metaProperty[0] === 'string'
          ? metaProperty.join(', ')
          : metaProperty.map(d => d.display).join(', ');
          return { ...d, text : textString };

          case typeof metaProperty === 'object' && metaProperty !== null && Object.keys(metaProperty).length > 0  :
          return { ...d, text : metaProperty.display };

          case typeof metaProperty === 'string' :
          return { ...d, text : metaProperty };
        }

      })
    }

    return (
      <div className={`kp-meta-block--2-wrapper ${props.className}`}>
        <div className='kp-meta-block--2'>
        { data.map((d,i) => (
          <Fragment>
          { d && d.text &&
            <div className='kp-meta-block--2__meta' >
              <KPLabel label = {d.label} />
              <h5 className='h5 semibold kp-meta-block--2__meta-text'>
                {d.text}
              </h5>
            </div> }
          </Fragment>
        ))}
        </div>
      </div>
    )

  }

export default MetaBlock__Toolkits
