import * as React from "react"
import {primaryColor100} from '../../../sass/vars.scss';

function AddSM(props) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
      <path
        stroke={props.stroke || primaryColor100}
        strokeWidth={2}
        strokeLinecap="round"
        d="M10 1v18M1 9.526h18"
      />
    </svg>
  )
}

export default AddSM
