import * as React from "react"

function PDFIcon(props) {
  return (
    <svg width={25} height={25} viewBox="0 0 25 25" fill="none" {...props}>
      <path
        d="M8.32 14.378c.557 0 .96.116 1.213.348.256.232.384.552.384.96 0 .244-.052.474-.156.69-.104.212-.28.384-.528.516-.244.128-.576.192-.996.192H7.81v1.578h-.768v-4.284H8.32zm-.047.636H7.81v1.434h.348c.312 0 .552-.056.72-.168.172-.116.258-.304.258-.564 0-.236-.07-.412-.21-.528-.14-.116-.358-.174-.654-.174zm5.989 1.464c0 .724-.202 1.27-.606 1.638-.404.364-.968.546-1.692.546h-1.206v-4.284h1.332c.44 0 .822.08 1.146.24.328.16.58.396.756.708.18.308.27.692.27 1.152zm-.804.024c0-.512-.118-.888-.354-1.128-.232-.24-.57-.36-1.014-.36h-.564v3.006h.462c.98 0 1.47-.506 1.47-1.518zm2.474 2.16h-.756v-4.284h2.43v.642h-1.674v1.26h1.56v.642h-1.56v1.74z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.122 3.709H6.248a1 1 0 00-1 1v16a1 1 0 001 1h12a1 1 0 001-1V9.543h-4.626a1.5 1.5 0 01-1.5-1.5V3.709zm7.126 5.334v11.666a2 2 0 01-2 2h-12a2 2 0 01-2-2v-16a2 2 0 012-2h7.374l6.626 6.334zm-1.97-.5L14.121 4.57v3.473a.5.5 0 00.5.5h3.655z"
        fill="#fff"
      />
    </svg>
  )
}

export default PDFIcon;
