import React, {Fragment} from "react";


const BodySans__M = (props) => {

  

  return (
    <h5 className={`bodySans--M ${props.className || ''}`} style={props.style} id={props.id || ''}>
      {(props.children || props.value) + (props.suffix || '')} 
    </h5>
  );

}



export default BodySans__M;
