import _, {debounce} from 'lodash';
import {injectHttps} from '../../../utils/general';


export const validateUrl = (valueForParent, handleErrorMsgs ) => {
  const urlRegex = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
  if(!valueForParent){
    handleErrorMsgs()
  }else{
    if(urlRegex.test(valueForParent) === false) {
      handleErrorMsgs(
        {id: 'invalidUrl', text: 'URL Is Invalid', type: 'danger'}
      );
    }else{
      handleErrorMsgs({id: 'validUrl', text: 'Valid URL!', type: 'success', link: { url: injectHttps(valueForParent), text: 'Test Link'}});
    };
  }
}

export function validateEmail(email, handleErrorMsgs) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(email === ''){
      handleErrorMsgs(); 
      return;
    } //reset
    if(!re.test(String(email).toLowerCase())){ 
      handleErrorMsgs("Email is invalid");
      return "invalid";
    }
    else  handleErrorMsgs();
}

export const debounceValidateEmail = debounce((email, handleErrorMsgs)=> validateEmail(email, handleErrorMsgs), 300 );