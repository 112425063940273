import React from "react";
import { Editor } from "draft-js";

const KPRichEditor = React.forwardRef((props, ref) => {

  return (
    <div
      id={props.id}
      className={
        `kp-rich-editor
        ${( props.editorFocussed && !props.readOnly )
            ? "kp-rich-editor-focussed"
            : "" }` }
      aria-labelledby={`${props.id}_label`}
      style={{display: 'flex'}}
      >
      {props.editorPrefix && <span className='kp-editor-prefix'>{ props.editorPrefix }</span>}
      <Editor
        blockRendererFn={props.blockRendererFn}
        blockStyleFn={props.blockStyleFn}
        editorState={props.editorState}
        handleKeyCommand={props.handleKeyCommand}
        handleDroppedFiles={props.handleDroppedFiles}
        onChange={props.onChange}
        onBlur={props.onBlur}
        ref={ref}
        readOnly={props.readOnly}
        placeholder={props.placeholder}
        />
    </div>
  )
})

export default KPRichEditor;
