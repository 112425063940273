import React, { Fragment, useEffect, useState, useRef, useLayoutEffect, useMemo } from "react";
import ReactDOM from "react-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useSwipeable } from "react-swipeable";
import { KPIconBtn, KPBtn, ButtonSecondary } from '../../generalUI/KPButtons';

//icons
import HintsIcon from '../../icons/HintsIcon';
import SlideIndicator from '../../icons/SlideIndicator';
import ChevronLeft from '../../icons/ChevronLeft';
import ChevronRight from '../../icons/ChevronRight';
//sass
import { primaryColor100, greyColor10, white, toolbarHeight, overlayPgHeaderHeight_D } from '../../../sass/vars.scss';
//utils
import { prepHintsMatrix } from '../../../utils/general';




const KPHintsBlock = (props) => {

  const [hintsListOpen, setHintsListOpen] = useState(false);
  const [activeHintIdx, setActiveHintIdx] = useState(0);
  const [initScreenWidth, setInitScreenWidth] = useState(props.environment.screenWidth);
  let hintBlock_D_ref = useRef(null);
  let hintList_D_ref = useRef(null);
  const [hintBlockPosY, setHintBlockPosY] = useState(null);
  

  const setOverlayBoxOrientation = (hintsListOpen) => {
    if(initScreenWidth >= 768){ //breakpoint
      if (hintsListOpen) {
        let pgHeaderHeight = parseInt(overlayPgHeaderHeight_D)*10;
        let { x, y } = hintBlock_D_ref.current.getBoundingClientRect(); 
        let { width: listWidth, height: listHeight } = hintList_D_ref.current.getBoundingClientRect();
        let elemTop = y - listHeight; //we use a combination of 2 refs to calc the 'would be' top edge of the hintslist, cuz if we base it directly on the hintlist, then, the moment the hint list switches position, this calculation gets confused and we see a flicker.
        let topLimit =  pgHeaderHeight + 20; //20 === extra padding, to make the switch before the box touches the edge

        const switchYOrientation = elemTop <= topLimit ? true : false;
        if (switchYOrientation) setHintBlockPosY({ top: "5rem" });
        else setHintBlockPosY({ bottom: "5rem" });
      }
    }
  }

  useLayoutEffect(() => {
    const handleSetBoxOrientOnScroll = () => setOverlayBoxOrientation(hintsListOpen);
    window.addEventListener('scroll', handleSetBoxOrientOnScroll)

    return () => window.removeEventListener('scroll', handleSetBoxOrientOnScroll) //cleanup
  }, [hintsListOpen])

  useEffect(() => {
    if (
      (initScreenWidth >= 768 && props.environment.screenWidth < 768) ||
      (initScreenWidth < 768 && props.environment.screenWidth >= 768)
    ) {
      setActiveHintIdx(0);
      setInitScreenWidth(props.environment.screenWidth);
    }
  }, [props.environment.screenWidth])

  const toggleHintsList = () => {
    let newHintsListOpen = !hintsListOpen;
    newHintsListOpen && setOverlayBoxOrientation(newHintsListOpen);
    setHintsListOpen(newHintsListOpen)
  }

  const handleSlideLeft = () => {
    if (props.environment.screenWidth >= 768) {
      activeHintIdx + 1 !== prepHintsMatrix(props.hints).length && setActiveHintIdx(activeHintIdx + 1)
    } else {
      activeHintIdx + 1 !== props.hints.length && setActiveHintIdx(activeHintIdx + 1)
    }
  }

  const handleSlideRight = () => activeHintIdx !== 0 && setActiveHintIdx(activeHintIdx - 1);

  const handlers = useSwipeable({
    onSwipedLeft: () => handleSlideLeft(),
    onSwipedRight: () => handleSlideRight(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  const hintsListMobile =
    <div
      {...handlers}
      className={`kp-hints-list-wrapper--m kp-hints-list-wrapper--m-${hintsListOpen === true ? "open" : "closed"}`}
    >
      <ul className='kp-hints-ul--m'>
        {props.hints.map((hint, i) => (
          <li
            key={i}
            className='hintsText kp-hint-li--m'
            style={{ transform: `translateX(-${activeHintIdx * 100}%)` }}
          >{hint}</li>
        ))}
      </ul>
      <div className='kp-multi-slide-indicator--m' style={{ paddingBottom: '2rem' }}>
        {props.hints.map((hint, i) => (
          <span key={i} style={{ padding: '0 0.4rem' }}><SlideIndicator fill={activeHintIdx === i ? primaryColor100 : greyColor10} /></span>
        ))}
      </div>
    </div>

  // wrote by rahul

  const hintsListDesktop =
    <div
      {...handlers}
      className={`kp-hints-list-wrapper--d kp-hints-list-wrapper--d-${hintsListOpen === true ? "open" : "closed"}`}
      style={{ ...hintBlockPosY }}
      ref = {hintList_D_ref}
    >
      <ul className='kp-hints-ul-wrapper--d'>
        {prepHintsMatrix(props.hints).map((hintsAry, i) => (
          <ul
            key={i}
            className='kp-hints-ul--d'
            style={{ transform: `translateX(-${activeHintIdx * 100}%)` }}
          >
            { hintsAry.map((hint, i) => (
              <li key={i} className='hintsText kp-hints-li--d'>{hint}</li>
            ))}
          </ul>
        ))}
      </ul>
      <div className='kp-multi-slide-indicator--d' style={{ padding: '2rem 0' }}>
        <span style={{ padding: '0 2rem', cursor: 'pointer' }} onClick={() => handleSlideRight()}><ChevronLeft /></span>
        {prepHintsMatrix(props.hints).map((hint, i) => (
          <span key={i} style={{ padding: '0 0.4rem' }}><SlideIndicator fill={activeHintIdx === i ? primaryColor100 : greyColor10} /></span>
        ))}
        <span style={{ padding: '0 2rem', cursor: 'pointer' }} onClick={() => handleSlideLeft()}><ChevronRight /></span>
      </div>
    </div>

  return (
    <div
      className={`kp-hints-block-wrapper ${props.className}`}
      style={{ display: props.editorFocussed ? "block" : "none" }}
      ref={hintBlock_D_ref}
    >
      {props.environment.screenWidth >= 768 ? hintsListDesktop : hintsListMobile}
      <ButtonSecondary
        className="kp-hints-btn"
        onClick={toggleHintsList}
        value="Hints!"
        icon="Lightbulb"
      >
        {/* <HintsIcon stroke={white} /> */}
      </ButtonSecondary>
    </div>
  );
}

KPHintsBlock.propType = {
  environment: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  environment: state.environment
})

export default connect(mapStateToProps, {})(KPHintsBlock);


const breakhintsByThree = (hints) => {
  const hintDivided = [];
  let size = hints.length;
  for (let i = 0; i < hints.length; i = i + 3) {
    if (size >= 3) hintDivided.push(hints.slice(i, i + 3));
    else hintDivided.push(hints.splice(i));
    size = size - 3;
  }
  return hintDivided;
}
