import * as React from "react"
import {primaryColor100, white } from '../../../sass/vars.scss';

function UploadSM(props) {
  return (
    <svg width={21} height={21} viewBox="0 0 21 21" fill="none" {...props}>
      <path
        stroke={props.stroke || white}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.816 4.96v11M16.316 8.96l-4.793-4.793a1 1 0 00-1.414 0L5.316 8.96M14.816 17.96h-8"
      />
    </svg>
  )
}

export default UploadSM
