import { combineReducers } from "redux";

import environment from "./environment";
import alert from "./alert";
import auth from "./auth";
import profiles from "./profiles";
import contributions from "./contributions";
import listings from "./listings";
import ogs from "./ogs";
import modQueue from "./modQueue";
import recommendations from "./recommendations";
import participants from "./participants";

export default combineReducers({
  environment,
  alert,
  auth,
  profiles,
  contributions,
  listings,
  ogs,
  modQueue,
  recommendations,
  participants
});
