import React, { useState, Fragment, useRef, useEffect } from "react";
// import { Link } from "react-router-dom";
import history from "../../../history";
//comps
import MetaBlock from "../../generalUI/MetaBlock";
import MetaBlock__Toolkits from "../../generalUI/MetaBlock__Toolkits";
import CardTag from "../CardTag";
import { KPIconBtn, ButtonPrimary, ButtonSecondary, ButtonTertiary } from "../../generalUI/KPButtons";

import KPLabel from "../../generalUI/KPLabel";
import KPImageInput from "../../inputs/KPImageInput";
import KPVideoEmbed from '../../inputs/KPVideoEmbed';
import ProfileImgDisplay from '../../generalUI/ProfileImgDisplay';
import KPMultiProfilesDisplay from '../../generalUI/KPMultiProfilesDisplay';
import KPMetaPrimary from "../../generalUI/KPMetaPrimary";
import KPMetaSecondary from "../../generalUI/KPMetaSecondary";
import KPLink from '../../generalUI/KPLink';
import KPMetaHero from "../../generalUI/KPMetaHero";
import ExternalLinkCard from "../../cards/ExternalLinkCard";
import KPInlineAlert from '../../generalUI/KPInlineAlert';
//icons
import Delete from "../../icons/Delete";
import Close_S from "../../icons/Close_S";
import Edit from "../../icons/Edit";
import KPTagsInput from "../../inputs/KPTagsInput";
import Download__nofill from '../../icons/Download__nofill';
import LinkOutSM from '../../icons/LinkOutSM';

//sass
import { white } from "../../../sass/vars.scss";
import store from "../../../store";
//actions
import { deleteDraft } from "../../../actions/profiles";
import CardTitle from "../../typo/CardTitle";
import BodySerif__L from "../../typo/BodySerif__L";

const ListEl__Content = ({
  colSetting,
  className,
  link,
  image, // of the format {backgroundImage : x, backgroundPosition : y}
  videoEmbed,
  imgContainerStyle,
  icon,
  cardTag,
  listElemLabel,
  metaSecondaryPreTitle,
  title,
  metaPrimaryPostTitle,
  summary,
  metaBlockData, //of the format [{icon: xComp, text: y},...]
  metaSecondary,
  resourceLink,
  metaHero,
  bottomTags,
  bottomKPTags,
  cardActions,
  cardActionsBottom,
  tags,
  multiProfilesDisplayData,
  cardLink,
  style,
  readOnly,
  inlineAlert,
  onRemoveCard,
  customClickHandler
}) => {

  const MetaBlockComps = {
    MetaBlock,
    MetaBlock__Toolkits,
  };

  //for cardActions
  // const BtnComps = { KPBtn, KPIconBtn }

  const MetaBlockComp = metaBlockData && MetaBlockComps[metaBlockData.comp];

  const cardActionExists = resourceLink && resourceLink.displayType !== 'inline';
  const cardActionsRef = useRef(null);

  //check to see if click hasnt been on an action within the card. in which case, follow the card link.
  const handleCardClick = (e) => {
    if (!cardActionsRef.current || !cardActionsRef.current.contains(e.target)) {
      link.type === "internal"
        ? history.push(link.url)
        : window.open(link.url, "_blank");
    }
  };

  const genCardActions = () => (
    <div ref={cardActionsRef} className='Card__action'>
      <ButtonPrimary
        // shape="square"
        onClick={() => window.open(resourceLink.value, '_blank')}
        icon={resourceLink.type === 'uploaded' ? "DownloadSimple" : "ArrowLineUpRight"}
      >
        {/* {resourceLink.type === 'uploaded' ? <Download__nofill /> : <LinkOutSM />} */}
      </ButtonPrimary>
    </div>
  )

  const genRmCardBtn = (onRemoveCard) => (
    <ButtonPrimary
      // className='kp-content-card-wrapper__remove-btn'
      // type='danger'
      // size='small'
      onClick={onRemoveCard}
      icon="Close_S"
    >
      {/* <Close_S fill={white} /> */}
    </ButtonPrimary>
  )

  return (
    <div className={`${colSetting} ${className} ListEl-Wrapper--Content`} style={style}>
      <div className='ListEl--Content' onClick={ customClickHandler || handleCardClick }>
        
        <div className='ListEl__contentModule-1'>
        { listElemLabel &&
          <KPMetaPrimary className='ListEl__label' data={ listElemLabel } style={cardActionExists && {paddingRight: '4rem'}} /> }
        { metaHero && 
          <KPMetaHero className='ListEl__date' data={ metaHero } /> }
          <BodySerif__L className={`ListEl__title ${image || videoEmbed ? '-has-media-thumb-' : ''}`}><span className='ListEl__title-span'>{ title }</span></BodySerif__L>
        {/* { cardActionExists &&
          genCardActions() } */}
          {metaBlockData && (
            <MetaBlockComp
              className="ListEl__metaBlock"
              data={metaBlockData.data}
            />)}
        </div>

        <div className='ListEl__contentModule-2'>

          {videoEmbed &&
            <div className="ListEl__video-container">
              <KPVideoEmbed
                value={videoEmbed.value}
                readOnly={true}
                light={true}
              />
            </div>}
          {image &&
            <div className="ListEl__image-container" style={imgContainerStyle}>
              <KPImageInput
                value={image.data}
                heightRatio={image.heightRatio && image.heightRatio}
                placeholderColor={image.placeholderColor}
                containerShape={image.containerShape}
                height={image.height && image.height}
                width={image.width && image.width}
                imgBlockComp={image.imgBlockComp}
                backgroundSize={image.backgroundSize && image.backgroundSize}
                placeholderInitial={image.placeholderInitial}
                readOnly={true}
              />
            </div>}

        </div>
      </div>
    </div>

  );
};



export default ListEl__Content;
