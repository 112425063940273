import React, { useState, Fragment } from "react";
import Label__L from "../../typo/Label__L";
import TitleSerif__S from "../../typo/TitleSerif__S";
import Sublabel from "../../typo/Sublabel";

import KPInlineAlert from '../KPInlineAlert';
import {greyColor15, greyColor70} from '../../../sass/vars.scss';

const KPLabel = props => { //col settings must be the bootstrap codes

  const shouldCompBeRendered = () => {
    switch (true) {
      case !!props.label:
        return true;
      case !!props.sublabel && !props.readOnly:
        return true;
      case !!props.inputOnlyLabel && !props.readOnly:
        return true;
      case !props.readOnly && props.isRequired === false: //if optional tag needs to be shown
        return true;
      case !props.readOnly && props.errorMsgs && props.errorMsgs.length > 0:
        return true;
      default:
        return false;
    }
  }

  const getErrorMsgsText = (errorMsgsAry) => {
    return errorMsgsAry.map(d => typeof d === 'object' ? d.text : d).join(', ');
  }

  //right now, we are using this only for the 'test link' usecase. 
  // basically, if there is only one error msg in aray, 
  //and that msg is object, and it has a type, then set that type, else set the type to 'danger'
  const getErrorMsgsType = (errorMsgsAry) => {
    if(errorMsgsAry.length !== 1) return 'danger';
    //else
    if(!errorMsgsAry[0].type) return 'danger';
    //else
    return errorMsgsAry[0].type;
  }

  //right now, we are using this only for the 'test link' usecase. 
  //works only if there is 1 error msg
  const getErrorMsgsLink = (errorMsgsAry) => {
    if(errorMsgsAry.length !== 1) return undefined;
    //else
    return errorMsgsAry[0].link;
  }

  return (
    <Fragment>
      { shouldCompBeRendered() === true &&
        <div
          className={`kp-labels-wrapper ${props.className}-wrapper`}
          style={props.style}>
          {/* { props.srNo && <TitleSerif__S className='BlockLabel__srNo'>{props.srNo}</TitleSerif__S> } */}
          <div
            className={`kp-label ${props.className}`}>
            {(props.label || props.inputOnlyLabel) &&
              <Label__L
                style={{paddingRight: '1rem', color: props.invert ? greyColor15 : greyColor70 }}
                className='kp-label__text'
                id={`${props.id}_label`}>
                {`${props.srNo ? props.srNo + '. ' : ' '}${props.label || props.inputOnlyLabel}`}
              </Label__L>
            }
            {!props.readOnly &&
              props.isRequired === false &&
              <h5
                className='h5 italic light kp-optional-label-tag'
                style={{paddingRight: '1rem'}}
              >(optional)
            </h5>
            }
            {!props.readOnly &&
              props.errorMsgs &&
              props.errorMsgs.length > 0 &&
              <KPInlineAlert
                text={getErrorMsgsText(props.errorMsgs)}
                type={getErrorMsgsType(props.errorMsgs)}
                link={getErrorMsgsLink(props.errorMsgs)}
                invert={props.invert}
                />
            }
          </div>
          {
            !props.readOnly &&
            props.sublabel &&
            <Sublabel className='BlockLabel__sublabel'>{props.sublabel}</Sublabel>}
        </div>
      }
    </Fragment>
  )
}

export default KPLabel;
