import React, { useState, useRef, useEffect, Fragment } from 'react'
import { ButtonTertiary, KPIconBtn } from '../../generalUI/KPButtons';
import Delete from '../../icons/Delete';
import { darkPrimary, primaryColor100, primary100SL, white, greyColor5 } from '../../../sass/vars.scss';

const bgImgStyle = {
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat'
}

const KPBgImageBlock = ({ image, height, width, readOnly, removeImage, containerShape, backgroundSize }) => {

  const genDeleteImgBtn = () => (
    <ButtonTertiary
      onClick={() => removeImage(image.id)}
      // className='kp-delete-cover-img-btn'
      icon="Trash"
    >
      {/* <Delete /> */}
    </ButtonTertiary>
  )

  return (
    <Fragment>
      { !readOnly && genDeleteImgBtn() /* this needs to be outside for visibility purposes in the case of containerShape = round*/}
      <div
        className={`kp-bg-img-block ${containerShape}`}
        style={{
          ...bgImgStyle,
          backgroundImage: `url(${image.publicUrl})`,
          backgroundSize: backgroundSize || 'cover',
          backgroundColor: white,
          border: `1px solid ${greyColor5}`,
          height: height,
          width: width
        }}
      />
    </Fragment>

  )
}

KPBgImageBlock.defaultProps = {
  heightRatio: '100%',
  containerShape: 'shape-square'
}

export default KPBgImageBlock;
