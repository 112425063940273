import * as React from "react"
import {primaryColor100} from '../../../sass/vars.scss';

function Linkedin_solid_SM(props) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none">
      <path
        d="M5.953 7.411H2.62v10h3.333v-10zM14.984 7.528c-.037-.01-.073-.022-.112-.032a2.443 2.443 0 00-.143-.026 3.412 3.412 0 00-.632-.059c-2.055 0-3.358 1.38-3.787 1.912V7.411H6.786v10h3.524v-5.454s2.663-3.424 3.787-.91v6.364h3.522v-6.748c0-1.511-1.121-2.77-2.635-3.135zM4.286 6.577a1.667 1.667 0 100-3.334 1.667 1.667 0 000 3.334z"
        fill={props.fill || primaryColor100}
      />
    </svg>
  )
}

export default Linkedin_solid_SM
