import * as React from "react"
import {primaryColor100} from '../../../sass/vars.scss';

function Mail_solid_SM(props) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.88 4.616l7.303 6.226a1.75 1.75 0 002.27 0l7.303-6.227c.04.124.062.255.062.388v9.515c0 .33-.131.646-.366.879a1.254 1.254 0 01-.884.364h-14.5c-.33 0-.649-.131-.883-.364a1.238 1.238 0 01-.367-.879V5.003c0-.133.022-.263.063-.387zm.67-.743c.162-.073.338-.112.519-.112h14.499c.18 0 .356.038.517.111l-7.28 6.21-.358.167a.743.743 0 01-.615-.168L2.55 3.873zm7.897 6.376l-1.264.593 1.264-.593zm.357-.168l-.357.168a.75.75 0 00.357-.168z"
        fill={primaryColor100}
      />
    </svg>
  )
}

export default Mail_solid_SM
