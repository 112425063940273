import React, { useState, Fragment } from "react";

const ToggleBar = props => {

  const [selected, setSelected] = useState(props.defaultSelectedValue || props.options[0].value)

  const onChange = (key, val) => {
    setSelected(val);
    props.onChange(key, val);
  }

  return (
    <div className={`kp-toggle-bar ${props.className}`}>
      {
        props.options.map((option, i) => (
          <div
            key = {i}
            className= {`kp-toggle-bar__option ${option.value === selected ? 'selected' : ''}`}
            onClick={() => onChange(props.id, option.value)}
            >
            <h4 className= 'input kp-toggle-bar__option-text'>{option.name}</h4>
          </div>
        ))
      }
    </div>
  );
}

export default ToggleBar
