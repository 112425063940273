import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import PageContainer from "../../generalUI/PageContainer";
import PageRow from "../../generalUI/PageRow";
import KPLabel from "../../generalUI/KPLabel";
import KPLink from "../../generalUI/KPLink";
import { ButtonSecondary } from "../../generalUI/KPButtons";
import TplStaticText from "../../inputs/TplStaticText";

import KPLogoFooter from "../../../images/rran_logo_dark_bg.png";
import ooloiLogo from "../../../images/ooloi_logo_white.svg";

import {
  _KnowledgeMenuOptions,
  _CommunityMenuOptions,
  _DataMenuOptions,
  _HeaderSubMenuOptions,
  __GenNavLink,
  __GetStaticPageLinkData,
  genKnowledgeListingOption,
} from "../../../utils/nav";

import { greyColor100 } from "../../../sass/vars.scss";
import BodySans__M from "../../typo/BodySans__M";
import Label__M from "../../typo/Label__M";

const Footer = (props) => {
  const history = useHistory();

  const {
    deployment: {
      _Logos,
      _FooterMarkdown,
      _EnableNewsletter,
      _NwSignUpFormUrl,
      _Social,
    },
    _Nav,
  } = useSelector((state) => {
    return state.environment.envConfig;
  });

  const { listings } = useSelector((state) => state);

  const generateMenu = (_MenuConfigOptions) => (
    <Fragment>
      {_MenuConfigOptions.map((d, i) => (
        <KPLink
          key={d.value + i}
          iconFill="none"
          text={d.display}
          link={d.value}
          icon={d.icon}
          linkType="internal"
        />
      ))}
    </Fragment>
  );

  const [renderFooter, setRenderFooter] = useState(true);

  const location = useLocation();

  useEffect(() => {
    const noFooterPathnameSubstrings = [
      "/edit",
      "/moderate",
      "/contribute-config",
      "/search",
      "/profile/userProfiles",
    ];

    if (
      !noFooterPathnameSubstrings.some((substr) =>
        window.location.pathname.includes(substr)
      )
    ) {
      setRenderFooter(true);
    } else {
      setRenderFooter(false);
    }
  }, [location]);

  if (!renderFooter) return null;

  const linkSectionWrapper = (comp, className = "") => (
    <div className={`OKE-Footer__section ${className}`}>{comp}</div>
  );

  const regroupByColConfig = (headerPrimaryConfig) => {
    let newConfig = [[], [], []];
    headerPrimaryConfig.map((d, i) => {
      newConfig[d.footerCol || 0].push(d); //if footerCol is not set by mistake in config, then default is 0
    });
    return newConfig;
  };

  return (
    <div style={{ backgroundColor: greyColor100 }}>
      <PageContainer className="OKE-Footer">
        <img
          src={_Logos.logoFooter}
          style={{ height: "40px", marginBottom: "4rem" }}
          alt="Revitalising Rainfed Agriculture Network"
        />
        <PageRow className="OKE-Footer-Row">
          <div className="oke-col oke-col-lg-5 oke-col-sm-4 OKE-Footer__col OKE-Footer__col--about">
            <Label__M
              className="OKE-Footer__sectionTitle"
              style={{ padding: "0 0 1rem 0" }}
            >
              about
            </Label__M>
            <div className="OKE-Footer__aboutText-wrapper">
              <div className="OKE-Footer__markdown">
                <TplStaticText markdownText={_FooterMarkdown} />
              </div>
              <a
                style={{ marginTop: "1rem" }}
                href="https://www.ooloilabs.in"
                target="_blank"
              >
                <img
                  src={ooloiLogo}
                  alt="Ooloi Labs"
                  style={{ paddingTop: "1rem", width: "10rem" }}
                />
              </a>
            </div>
          </div>
          <div
            style={{ overflow: "hidden" }}
            className="oke-col oke-col-lg-7 oke-col-sm-2 OKE-Footer__col OKE-Footer__col--links"
          >
            <PageRow style={{ marginTop: "-1.5rem", marginBottom: "-1.5rem" }}>
              {_Nav.secondary && (
                <div
                  className="oke-col oke-col-lg-4"
                  style={{ paddingTop: "1.5rem", paddingBottom: "1.5rem" }}
                >
                  {linkSectionWrapper(
                    <Fragment>
                      <Label__M className="OKE-Footer__sectionTitle">
                        {"knowledge"}
                      </Label__M>
                      {_KnowledgeMenuOptions.map((op) => {
                        return __GenNavLink(op.value, op.display, location);
                      })}
                    </Fragment>
                  )}
                </div>
              )}
              {regroupByColConfig(_Nav.primary).map((d, colI) => (
                <div
                  className="oke-col oke-col-lg-4"
                  style={{ paddingTop: "1.5rem", paddingBottom: "1.5rem" }}
                >
                  {d.map((config, i) => {
                    if (config.staticPage) {
                      let { value, display, loading } = __GetStaticPageLinkData(
                        config.staticPage,
                        listings
                      );
                      return linkSectionWrapper(
                        __GenNavLink(value, display, location, loading)
                      );
                    } else if (config.subMenu) {
                      let options;

                      if (typeof config.subMenu === "string") {
                        options = _HeaderSubMenuOptions[config.subMenu];
                      } else {
                        options = [];
                        config.subMenu.map((d) => {
                          if (typeof d === "string") {
                            options = [...options, ..._HeaderSubMenuOptions[d]];
                          } else if (d.knowledgeListing) {
                            options.push(
                              genKnowledgeListingOption(
                                d.knowledgeListing,
                                d.display
                              )
                            );
                          } else if (d.staticPage) {
                            options.push(
                              __GetStaticPageLinkData(d.staticPage, listings)
                            );
                          }
                        });
                      }

                      return linkSectionWrapper(
                        <Fragment>
                          {config.display && (
                            <Label__M className="OKE-Footer__sectionTitle">
                              {config.display}
                            </Label__M>
                          )}
                          {options.map((op) => {
                            return __GenNavLink(op.value, op.display, location);
                          })}
                        </Fragment>
                      );
                    }
                  })}
                  {colI === regroupByColConfig(_Nav.primary).length - 1 && (
                    <Fragment>
                      {_Social.length > 0 && (
                        <Fragment>
                          <Label__M className="OKE-Footer__sectionTitle">
                            {"follow us on social media"}
                          </Label__M>
                          <div
                            className="OKE-Footer__socialLinks"
                            style={{ display: "flex", margin: "0 -0.5rem" }}
                          >
                            {_Social.map((d, i) => {
                              // Check if the social media platform is Facebook
                              if (d.icon === "Facebook_solid_SM") {
                                // Update the link for Facebook
                                d.link = "https://www.facebook.com/rranetwork/";
                              }

                              return (
                                <KPLink
                                  icon={d.icon}
                                  linkType="external"
                                  link={d.link}
                                  className="footerLink"
                                />
                              );
                            })}
                          </div>
                        </Fragment>
                      )}
                      {_EnableNewsletter && _NwSignUpFormUrl && (
                        <ButtonSecondary
                          onClick={() => history.push("/subscribe")}
                          className="OKE-Footer__joinMailingListBtn"
                        >
                          Join the Mailing List
                        </ButtonSecondary>
                      )}
                    </Fragment>
                  )}
                </div>
              ))}
            </PageRow>
            {/* <Label label='KNOWLEDGE' className='OKE-Footer__sectionTitle' />
            {generateMenu(_KnowledgeMenuOptions)} */}
          </div>

          {/* <div className='col-lg-3 kp-footer-col kp-footer-primary-links'>
            <KPLabel label='COMMUNITY' className='kp-footer__section-label' />
            {generateMenu(_CommunityMenuOptions)}  
          </div> */}
          {/* { _DataMenuOptions.length > 0 &&
            <div className='col-lg-3 kp-footer-col kp-footer-primary-links'>
            <KPLabel label='DATA' className='kp-footer__section-label' />
            {generateMenu(_DataMenuOptions)}  
          </div>} */}

          {/* <div className=' col-lg-2 kp-footer-col kp-footer-secondary-links'>
            <KPLabel label='other' className='kp-footer__section-label' />
            <KPLink
              text='About'
              link='/about'
              linkType='internal'

            /> */}
        </PageRow>
      </PageContainer>
    </div>
  );
};

export default Footer;
