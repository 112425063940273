import {
  SET_PARTICIPANTS_LOADING,
  SET_PARTICIPANTS,
  SET_PARTICIPANTS_ERROR,
  UPDATE_PARTICIPANTS,
  GET_PARTICIPANTS_FROM_STORE

} from '../actions/types';

const initialState = {
  loading : true,
  data : [],
  error : undefined
};

export default function(state = initialState, action) {
  const {type, payload} = action

  switch (type) {

    case SET_PARTICIPANTS_LOADING: 
      return {
        ...state,
        loading: payload
      }
    case SET_PARTICIPANTS:
    case UPDATE_PARTICIPANTS:
      return {
        ...state,
        data: payload,
        loading: false
      }
    case SET_PARTICIPANTS_ERROR:
      return {
        ...state,
        error : payload,
        loading : false
      }
    case GET_PARTICIPANTS_FROM_STORE:
      return { 
        ...state,
        loading: false 
      }
    default:
      return state;
  }
}
