import { set_getValOffQueryString, formatValueStringsToDisplayStrings } from './general';

//metsPropertyPath === location of the Metas property within the parent. e.g 'meta.kp_tags';
//metaValPath => if the value of the tag is not just a string, but a value instead e.g. {val : orange_gruit, display: Orange Fruit}, then the metaValPath is 'val' (in this case). If the tag is simply a string, then pass 'null' (required)

export const isStringMatch = ( string, subString, options ) => {
  return string.toLowerCase().includes(subString.toLowerCase());
}

export const consolidateAndSortMeta = (data, metaPropertyPath, metaValPath) => {
  let metaArray = [];
  data.map((d,i) => {
    let metaProperty = set_getValOffQueryString('get', d, metaPropertyPath);

    if(!Array.isArray(metaProperty)){
      metaProperty = metaProperty ? [metaProperty] : undefined;
    }

    metaProperty && metaProperty.map((meta, i) => {

      let standardizedMeta = typeof meta === 'string' //meaning meta is a string, so just add it as a value against metaKey
      ? { value : meta, display: formatValueStringsToDisplayStrings(meta) }
      : meta;
      
      const metaInAryIdx = metaArray.findIndex(t => t.value === standardizedMeta.value); //once meta has been pushed into the filter array, it will always be saved against the value property for consistency. at least for now

      if(metaInAryIdx === -1){ //means it is not in array
         metaArray.push({...standardizedMeta, count: 1});
      }else{
        let currentMetaCount = metaArray[metaInAryIdx].count;
        let newMetaCount = currentMetaCount+1;

        metaArray[metaInAryIdx] = {...standardizedMeta, count: newMetaCount};
      }

    }) //end metaProperty map

  }) //end data map

  metaArray.sort((a, b) => { return b.count - a.count }); //a-b === ascending ; b-a === descending ; ref MDN

  return metaArray;

}

export const filterExperiments = (res) => {
  //----------------- START experimenting -----------------//

  
  // EXAMPLE : 
  // let superTagsArray = consolidateAndSortMeta(res.data, 'meta.kp_other.super_tags', 'value');
  // console.log("super tags array", superTagsArray);


  //----------------- END experimenting -----------------//
}
