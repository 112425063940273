import api from './../_configs/api';
import store from '../store';
import {
    SET_PARTICIPANTS_LOADING,
    SET_PARTICIPANTS,
    SET_PARTICIPANTS_ERROR,
    UPDATE_PARTICIPANTS,
    GET_PARTICIPANTS_FROM_STORE
} from './types';



export const getParticipants = (filter = {}) => async dispatch => {
    try{
        dispatch({type: SET_PARTICIPANTS_LOADING, payload : true})
        if(store.getState().participants.data.length > 0){
            dispatch({type: GET_PARTICIPANTS_FROM_STORE})
            return;
        }
        let filterQueryString = `filter=${encodeURIComponent(JSON.stringify(filter))}`; 
        let res = await api.get(`/api/participants?${filterQueryString}`);
        dispatch({
            type: SET_PARTICIPANTS,
            payload: res.data
        })
    }catch(err){
        dispatch({
            type: SET_PARTICIPANTS_ERROR,
            payload : err.response ? err.response.data : 'oops something went wrong while fetching participant data' + err
        })
    }
}


export const updateParticipant = (updateId, updateBody, participantsData) => async dispatch => {
    try{
        dispatch({type: SET_PARTICIPANTS_LOADING, payload : true})
        

        let config = { headers : { "Content-Type" : "application/json"} }
        let res = await api.post(`/api/participants/${updateId}`, updateBody, config);
        let newParticipantsData = [...participantsData];
        console.log({res, newParticipantsData});
        let idx = newParticipantsData.findIndex(d => d._id === updateId);
        newParticipantsData[idx] = {
            ...newParticipantsData[idx],
            ...res.data //so that we dont overwrite the interviewsCompleted
        };

        dispatch({
            type: UPDATE_PARTICIPANTS,
            payload : newParticipantsData
        })

    }catch(err){
        dispatch({
            type: SET_PARTICIPANTS_ERROR,
            payload : err.response ? err.response.data : 'oops something went wrong while updating participant data'
        })
    }
}