export const prepFormData = (
  targetFiles,
  folderName,
  allowedFormats
) => {
  const files = Array.from(targetFiles);
  const formData = new FormData()
  files.forEach((file, i) => { formData.append(i, file) })
  formData.set("folderName", folderName);
  formData.set("allowedFormats", allowedFormats);

  return formData;
}
