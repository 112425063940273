import React, {Fragment, useState} from "react";
import { Link } from "react-router-dom";




const FullScreenMenuHeader = (props) => {

  return (
    <div className={`kp-full-screen-menu-header ${props.className}`}>
      <div className="kp-full-screen-menu-header__left-comp">
        {props.leftCompContent}
        Helloabcsacbjhajchva
      </div>
      <div className="kp-full-screen-menu-header__center-comp">
        {props.centerCompContent}
      </div>
      <div className="kp-full-screen-menu-header__right-comp">
        {props.rightCompContent}
      </div>
    </div>
  )
};

FullScreenMenuHeader.defaultProps = {
  className: ""
}


export default FullScreenMenuHeader;
