import React, { Fragment, useState, useEffect } from "react";
import axios from 'axios';
import { useSelector } from "react-redux";
import ModalShell from '../../modals/ModalShell';
import ModalHeader from '../../modals/ModalHeader';
import {KPTextInput} from '../../inputs/KPInputs';
import {KPBtn} from '../../generalUI/KPButtons';
import {injectHttps} from '../../../utils/general';
import TitleSerif__S from "../../typo/TitleSerif__S";

const ResourceDownloadForm = ({

  triggerForm,
  setTriggerForm,
  onFormSubmit,
  link
  
}) => {

  const _CaptureDownloaderDeets = useSelector((state) => {
    
    return state.environment.envConfig.deployment._CaptureDownloaderDeets;
  });


  useEffect(() => {
    if(triggerForm){
      if(user){
        _CaptureDownloaderDeets && 
        sendDownloaderDeetsToAdmin({
          profileId : `/profile/userProfiles/${user._id}`, 
          resourceDetails: window.location.href
        });
        window.open(injectHttps(link))  
        onFormSubmit();
      }else{
        setShowModal(true)
      }
    }
  },[triggerForm])

  const [showModal, setShowModal] = useState(false);
  const user = useSelector((state) => state.auth.user);

  const [downloaderDeets, setDownloaderDeets] = useState({
    name: '',
    email: '',
    organisation: ''
  })

  const [hasValidationErrors, setHasValidationErrors] = useState({
    name: false,
    email: false, 
    organisation: false
  });

  const handleOnError = (k, v) => {
    let newValidationErrors = {
      ...hasValidationErrors,
      [k]: v ? true : false
    }
    setHasValidationErrors(newValidationErrors);
  }
  
  const handleDownloaderFormInput = (k,v) => {
    
    let newDownloaderDeets = {
      ...downloaderDeets,
      [k] : v
    };
    
    setDownloaderDeets(newDownloaderDeets);
  }

  const sendDownloaderDeetsToAdmin = async (data) => {
    const config = {"headers": {'Content-Type': 'application/json'}}
    const body = data;
    try{
      const res = await axios.post('/api/resourceDownload', body, config);
      
    }catch(err){
      if(err) console.log('err in ResourceDownloadForm > sendDownloaderDeetsToAdmin', err)
    }
  }

  const handleOpenResource = () => {
    _CaptureDownloaderDeets && sendDownloaderDeetsToAdmin({...downloaderDeets, resourceDetails: window.location.href});
    window.open(injectHttps(link));
    setShowModal(false);
    onFormSubmit();
  }

 

  const handleBtnDisable = () => {
    let required = [
      'name', 'email'
    ];
    let disabled = false;
    
    let hasSomeError = Object.values(hasValidationErrors).some(d => d === true);
    
    if (hasSomeError) return true;
    
    for(var i = 0 ; i < required.length; i++){
      let d = required[i];
      if(!downloaderDeets[d]){
        disabled = true;
        break;
      }
    }
    return disabled;
  }


  return (
    <Fragment>
    {showModal &&
      <ModalShell className='resourceDownloadForm'>
      <ModalHeader 
        onClose={() => {setShowModal(false); setTriggerForm(false);} }
        />
      <div style={{padding: '2rem'}}>
        <TitleSerif__S>{'Please Provide A Few Details Before You Download this Resource'}</TitleSerif__S>
        <KPTextInput
          label = 'name'
          id = {'name'}
          placeholder={'name'}
          value={downloaderDeets.name}
          onChange={(k,v) => handleDownloaderFormInput(k,v)}
          />
        <KPTextInput
          label = 'email'
          placeholder = 'email'
          inputType = 'email'
          id = {'email'}
          value={downloaderDeets.email}
          onChange={(k,v) => handleDownloaderFormInput(k,v)}
          onError={(k,v) => handleOnError(k,v)}
          />
        <KPTextInput
          label = 'organisation' 
          placeholder = 'organisation' 
          id = {'organisation'}
          value={downloaderDeets.organisation}
          onChange={(k,v) => handleDownloaderFormInput(k,v)}
          />
        <KPBtn disabled={handleBtnDisable()} onClick={() => handleOpenResource()}>Go To Resouce</KPBtn>
      </div>
      
    </ModalShell>}
    </Fragment>
  );
}



export default ResourceDownloadForm ;
