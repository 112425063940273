import store from '../../store';

import StoriesIcon from "../../components/icons/CType_Stories"
import ActivitiesIcon from "../../components/icons/CType_Activities"
import InitiativesIcon from "../../components/icons/CType_Initiatives"
import EventsIcon from "../../components/icons/CType_Events"
import NewsIcon from "../../components/icons/CType_News"

import {
  STORIES as STORIES_LIST,
  VIDEO_STORIES as VIDEO_STORIES_LIST,
  PHOTO_STORIES as PHOTO_STORIES_LIST,
  RESOURCES as RESOURCES_LIST,
  ACTIVITIES as ACTIVITIES_LIST,
  INITIATIVES as INITIATIVES_LIST,
  EVENTS as EVENTS_LIST,
  NEWS as NEWS_LIST,
  TOOLS as TOOLS_LIST,
  RECOMMENDATIONS as RECOMMENDATIONS_LIST,
  TOOLKITS as TOOLKITS_LIST,
  PLANS as PLANS_LIST,
  CASE_REPORTS as CASE_REPORTS_LIST,
  DEMOGRAPHIC as DEMOGRAPHIC_LIST,
  IDIYM as IDIYM_LIST,
  IDIAHE as IDIAHE_LIST,
  IDIFM as IDIFM_LIST,
  IDIGD as IDIGD_LIST,
  COGAMEYM as COGAMEYM_LIST,
  DGHV as DGHV_LIST,
  DGMC as DGMC_LIST,
  DGME as DGME_LIST,
  FGDYM as FGDYM_LIST,
  MEDIA_COVERAGE as MEDIA_COVERAGE_LIST
} from '../cards/contentCards/list';

import {
  STORIES as STORIES_NW,
  VIDEO_STORIES as VIDEO_STORIES_NW,
  PHOTO_STORIES as PHOTO_STORIES_NW,
  RESOURCES as RESOURCES_NW,
  ACTIVITIES as ACTIVITIES_NW,
  INITIATIVES as INITIATIVES_NW,
  EVENTS as EVENTS_NW,
  NEWS as NEWS_NW,
  TOOLS as TOOLS_NW,
  TOOLKITS as TOOLKITS_NW,
  PLANS as PLANS_NW,
  MEDIA_COVERAGE as MEDIA_COVERAGE_NW

} from '../cards/contentCards/newsletter';

import {
  STORIES as STORIES_CARD,
  VIDEO_STORIES as VIDEO_STORIES_CARD,
  PHOTO_STORIES as PHOTO_STORIES_CARD,
  RESOURCES as RESOURCES_CARD,
  ACTIVITIES as ACTIVITIES_CARD,
  INITIATIVES as INITIATIVES_CARD,
  EVENTS as EVENTS_CARD,
  NEWS as NEWS_CARD,
  TOOLS as TOOLS_CARD,
  RECOMMENDATIONS as RECOMMENDATIONS_CARD,
  TOOLKITS as TOOLKITS_CARD,
  PLANS as PLANS_CARD,
  CASE_REPORTS as CASE_REPORTS_CARD,
  DEMOGRAPHIC as DEMOGRAPHIC_CARD,
  IDIYM as IDIYM_CARD,
  IDIAHE as IDIAHE_CARD,
  IDIFM as IDIFM_CARD,
  IDIGD as IDIGD_CARD,
  COGAMEYM as COGAMEYM_CARD,
  DGHV as DGHV_CARD,
  DGMC as DGMC_CARD,
  DGME as DGME_CARD,
  FGDYM as FGDYM_CARD,
  MEDIA_COVERAGE as MEDIA_COVERAGE_CARD
} from '../cards/contentCards/card';



import { set_getValOffQueryString } from '../../utils/general';

import {listings} from '../../locales/en/listings';
import {chooseContentTypeBody} from '../../locales/en/chooseContentTypeBody';

// var listings = store.getState().environment.envConfig.content.listingsPage.listings;
// var chooseContentTypeBody = store.getState().environment.envConfig.content.chooseContentTypePage;

export const _GetContentTypeConfig = contentTypeId => {
  return _ContentTypes[_ContentTypes.findIndex(d => (contentTypeId === d.groupId) || (contentTypeId === d.id))]
}

export const _ContentTypeListingGroups = {
  // allStories : [
  //   'stories',
  //   'videoStories'
  // ]
}

export const _ContentTypes = [
  {
    id: 'staticPages',
    // img : storiesImg,
    icon: EventsIcon,
    // homeSectionContent : {
    //   title : 'Recent Events'
    // },
    // listingsContent : listings.events,
    // chooseContentTypeBodyContent : chooseContentTypeBody.events,
    // cardConfigKey : 'EVENTS',
    accessSettingsFromTpl: true,
    reduxListingKey: 'staticPagesListing',
    showListingPage: false,
    showInChooseContentTypePage: false,
    allowModerate: false,
    allowComments: false,
    allowShare: false,
    postPblRedirPath: '/admin-dashboard?activeTab=staticPages',
    // nwCardConfig : EVENTS_NW,
    // listCardConfig : EVENTS_LIST,
    // cardCardConfig : EVENTS_CARD,
    indexType: 'richHeader'
  },
  {
    id: 'events',
    // img : storiesImg,
    icon: EventsIcon,
    homeSectionContent: {
      title: 'Recent Events'
    },
    listingsContent: listings.events,
    chooseContentTypeBodyContent: chooseContentTypeBody.events,
    cardConfigKey: 'EVENTS',
    accessSettingsFromTpl: true,
    reduxListingKey: 'eventsListing',
    nwCardConfig: EVENTS_NW,
    listCardConfig: EVENTS_LIST,
    cardCardConfig: EVENTS_CARD,
    indexType: 'block'
  },
  {
    id: 'resources',
    // img : storiesImg,
    icon: StoriesIcon,
    homeSectionContent: {
      title: 'Research'
    },
    listingsContent: listings.resources,
    chooseContentTypeBodyContent: chooseContentTypeBody.resources,
    cardConfigKey: 'RESOURCES',
    accessSettingsFromTpl: true,
    reduxListingKey: 'resourcesListing',
    listCardConfig: RESOURCES_LIST,
    nwCardConfig: RESOURCES_NW,
    cardCardConfig: RESOURCES_CARD,
    indexType: 'block'
  },
  {
    id: 'initiatives',
    // img : storiesImg,

    homeSectionContent: {
      title: 'Initiatives'
    },
    icon: InitiativesIcon,
    listingsContent: listings.initiatives,
    chooseContentTypeBodyContent: chooseContentTypeBody.initiatives,
    cardConfigKey: 'INITIATIVES',
    accessSettingsFromTpl: true,
    reduxListingKey: 'initiativesListing',
    nwCardConfig: INITIATIVES_NW,
    listCardConfig: INITIATIVES_LIST,
    cardCardConfig: INITIATIVES_CARD,
    indexType: 'block'
  },
  {
    id: 'stories',
    // groupId : 'allStories',
    // img : storiesImg,
    icon: StoriesIcon,
    homeSectionContent: {
      title: 'Stories'
    },
    listingsContent: listings.stories,
    chooseContentTypeBodyContent: chooseContentTypeBody.stories,
    cardConfigKey: 'STORIES',
    accessSettingsFromTpl: true,
    reduxListingKey: 'storiesListing',
    nwCardConfig: STORIES_NW,
    listCardConfig: STORIES_LIST,
    cardCardConfig: STORIES_CARD,
    indexType: 'block'
    // homeSectionCardConfig : STORIES_HOME_SECTION,
    // homeSectionFeaturedCardConfig : STORIES_HOME_SECTION_FEATURED
  },
  {
    id: 'videoStories',
    // groupId : 'allStories',
    // showListingPage : false,
    // img : storiesImg,
    icon: StoriesIcon,
    homeSectionContent: {
      title: 'Video Stories'
    },
    listingsContent : listings.videoStories,
    chooseContentTypeBodyContent : chooseContentTypeBody.videoStories,
    cardConfigKey : 'VIDEO_STORIES',
    accessSettingsFromTpl : true,
    reduxListingKey : 'videoStoriesListing',
    nwCardConfig : VIDEO_STORIES_NW,
    listCardConfig : VIDEO_STORIES_LIST,
    cardCardConfig : VIDEO_STORIES_CARD,
  },
  {
    id : 'photoStories',
    // groupId : 'allStories',
    // showListingPage : false,
    // img : storiesImg,
    icon : StoriesIcon,
    homeSectionContent : {
      title : 'Photo Stories'
    },
    listingsContent : listings.photoStories,
    chooseContentTypeBodyContent : chooseContentTypeBody.photoStories,
    cardConfigKey : 'PHOTO_STORIES',
    accessSettingsFromTpl : true,
    reduxListingKey : 'photoStoriesListing',
    nwCardConfig : PHOTO_STORIES_NW,
    listCardConfig : PHOTO_STORIES_LIST,
    cardCardConfig : PHOTO_STORIES_CARD,

  },
  {
    id: 'activities',
    // img : storiesImg,
    icon: ActivitiesIcon,
    homeSectionContent: {
      title: 'Activities'
    },
    listingsContent: listings.activities,
    chooseContentTypeBodyContent: chooseContentTypeBody.activities,
    cardConfigKey: 'ACTIVITIES',
    accessSettingsFromTpl: true,
    reduxListingKey: 'activitiesListing',
    nwCardConfig: ACTIVITIES_NW,
    listCardConfig: ACTIVITIES_LIST,
    cardCardConfig: ACTIVITIES_CARD,
    indexType: 'block'
    // homeSectionCardConfig : ACTIVITIES_HOME_SECTION,
    // homeSectionFeaturedCardConfig : ACTIVITIES_HOME_SECTION_FEATURED
  },
  {
    id: 'news',
    // img : storiesImg,
    icon: NewsIcon,
    homeSectionContent: {
      title: 'News'
    },
    listingsContent: listings.news,
    chooseContentTypeBodyContent: chooseContentTypeBody.news,
    cardConfigKey: 'NEWS',
    accessSettingsFromTpl: true,
    reduxListingKey: 'newsListing',
    nwCardConfig: NEWS_NW,
    listCardConfig: NEWS_LIST,
    cardCardConfig: NEWS_CARD,



  },
  {
    id: 'mediaCoverage',
    // img : storiesImg,
    icon: NewsIcon,
    homeSectionContent: {
      title: 'Media Coverage'
    },
    listingsContent: listings.mediaCoverage,
    chooseContentTypeBodyContent: chooseContentTypeBody.mediaCoverage,
    cardConfigKey: 'MEDIACOVERAGE',
    accessSettingsFromTpl: true,
    reduxListingKey : 'mediaCoverageListing',
    nwCardConfig : MEDIA_COVERAGE_NW,
    listCardConfig : MEDIA_COVERAGE_LIST,
    cardCardConfig : MEDIA_COVERAGE_CARD,

  },
  // {
  //   id: 'tools',
  //   // img : storiesImg,
  //   icon: StoriesIcon,
  //   homeSectionContent: {
  //     title: 'Tools'
  //   },
  //   listingsContent: listings.tools,
  //   chooseContentTypeBodyContent: chooseContentTypeBody.tools,
  //   cardConfigKey: 'TOOLS',
  //   accessSettingsFromTpl: true,
  //   reduxListingKey: 'toolsListing',
  //   nwCardConfig: TOOLS_NW,
  //   listCardConfig: TOOLS_LIST,
  //   cardCardConfig: TOOLS_CARD,

  // },
  // {
  //   id: 'toolkits',
  //   // img : storiesImg,
  //   icon: StoriesIcon,
  //   homeSectionContent: {
  //     title: 'Toolkits'
  //   },
  //   listingsContent: listings.toolkits,
  //   chooseContentTypeBodyContent: chooseContentTypeBody.toolkits,
  //   cardConfigKey: 'TOOLKITS',
  //   accessSettingsFromTpl: true,
  //   reduxListingKey: 'toolkitsListing',
  //   nwCardConfig: TOOLKITS_NW,
  //   listCardConfig: TOOLKITS_LIST,
  //   cardCardConfig: TOOLKITS_CARD,
  //   // homeSectionCardConfig : TOOLKITS_HOME_SECTION,
  //   // homeSectionFeaturedCardConfig : TOOLKITS_HOME_SECTION_FEATURED
  // },
  // {
  //   id: 'recommendations',
  //   supportingTpl: 'milletsPlugin_questions',
  //   // img : storiesImg,
  //   icon: StoriesIcon,
  //   listingsContent: listings.recommendations,
  //   chooseContentTypeBodyContent: chooseContentTypeBody.recommendations,
  //   cardConfigKey: 'RECOMMENDATIONS',
  //   accessSettingsFromTpl: true,
  //   reduxListingKey: 'recommendationsListing',
  //   // nwCardConfig : RECOMMENDATIONS_NW,
  //   listCardConfig: RECOMMENDATIONS_LIST,
  //   cardCardConfig: RECOMMENDATIONS_CARD,
  //   indexType: 'block'
  // },
  // {
  //   id: 'plans',
  //   // img : storiesImg,
  //   icon: StoriesIcon,
  //   homeSectionContent: {
  //     title: 'Plans'
  //   },
  //   listingsContent: listings.plans,
  //   chooseContentTypeBodyContent: chooseContentTypeBody.plans,
  //   cardConfigKey: 'PLANS',
  //   accessSettingsFromTpl: true,
  //   reduxListingKey: 'plansListing',
  //   nwCardConfig: PLANS_NW,
  //   listCardConfig: PLANS_LIST,
  //   cardCardConfig: PLANS_CARD,
  //   indexType: 'subSection'
  //   // homeSectionCardConfig : TOOLKITS_HOME_SECTION,
  //   // homeSectionFeaturedCardConfig : TOOLKITS_HOME_SECTION_FEATURED
  // },
  // {
  //   id: 'caseReports',
  //   // img : storiesImg,
  //   icon: StoriesIcon,
  //   homeSectionContent: {
  //     title: 'Case Reports'
  //   },
  //   listingsContent: listings.caseReports,
  //   chooseContentTypeBodyContent: chooseContentTypeBody.caseReports,
  //   cardConfigKey: 'CASE_REPORTS',
  //   accessSettingsFromTpl: true,
  //   reduxListingKey: 'caseReportsListing',
  //   listCardConfig: CASE_REPORTS_LIST,
  //   cardCardConfig: CASE_REPORTS_CARD,
  //   allowComments: false,
  //   allowShare: false,
  //   showListingPage: false,
  //   postPblRedirPath: { pathname: `/community-listing/workingGroups` },
  //   postPblAlertMsg: "Case Report Published! You can find these listed in their respective community pages.",
  //   subType: {
  //     has: true,
  //     name: 'updates'
  //   },
  //   indexType: 'block'
  //   // homeSectionCardConfig : TOOLKITS_HOME_SECTION,
  //   // homeSectionFeaturedCardConfig : TOOLKITS_HOME_SECTION_FEATURED
  // },
  // {
  //   id: 'demographics',
  //   // img : storiesImg,
  //   icon: StoriesIcon,
  //   homeSectionContent: {
  //     title: 'Demographic'
  //   },
  //   listingsContent: listings.demographics,
  //   chooseContentTypeBodyContent: chooseContentTypeBody.demographics,
  //   // cardConfigKey : 'CASE_REPORTS',
  //   accessSettingsFromTpl: true,
  //   accessSettingsOnMod: false,
  //   accessSettingsOnEditPbl: false,
  //   reduxListingKey: 'demographicListing',
  //   listCardConfig: DEMOGRAPHIC_LIST,
  //   cardCardConfig: DEMOGRAPHIC_CARD,
  //   allowComments: false,
  //   allowShare: false,
  //   showListingPage: true,
  //   subType: {
  //     has: true,
  //     category: 'update'
  //   },
  //   indexType: 'subSection', //alt is 'block'
  //   showIndexInContributeTpl: true,
  //   showTitleInHeader: {
  //     title: true,
  //     subtitle: true,
  //     subtitle_valuePathOverride: 'meta.participantId'
  //   },
  //   // homeSectionCardConfig : TOOLKITS_HOME_SECTION,
  //   // homeSectionFeaturedCardConfig : TOOLKITS_HOME_SECTION_FEATURED
  // },
  // {
  //   id: 'IDIYM',
  //   // img : storiesImg,
  //   icon: StoriesIcon,
  //   homeSectionContent: {
  //     title: 'IDI Young Men'
  //   },
  //   listingsContent: listings.IDIYM,
  //   chooseContentTypeBodyContent: chooseContentTypeBody.IDIYM,
  //   accessSettingsFromTpl: true,
  //   reduxListingKey: 'IDIYMListing',
  //   listCardConfig: IDIYM_LIST,
  //   cardCardConfig: IDIYM_CARD,
  //   allowComments: false,
  //   allowShare: false,
  //   showListingPage: true,
  //   indexType: 'subSection', //alt is 'block'
  //   showIndexInContributeTpl: true,
  //   showTitleInHeader: {
  //     title: true,
  //     subtitle: true
  //   }
  // },
  // {
  //   id: 'IDIGD',
  //   // img : storiesImg,
  //   icon: StoriesIcon,
  //   homeSectionContent: {
  //     title: 'IDI Game Developers'
  //   },
  //   listingsContent: listings.IDIGD,
  //   chooseContentTypeBodyContent: chooseContentTypeBody.IDIGD,
  //   accessSettingsFromTpl: true,
  //   reduxListingKey: 'IDIGDListing',
  //   listCardConfig: IDIGD_LIST,
  //   cardCardConfig: IDIGD_CARD,
  //   allowComments: false,
  //   allowShare: false,
  //   showListingPage: true,
  //   indexType: 'subSection', //alt is 'block'
  //   showIndexInContributeTpl: true,
  //   showTitleInHeader: {
  //     title : true,
  //     subtitle : true
  //   },
  //   draftTitleValuePath : 'main.subtitle'
  // },
  // {
  //   id: 'IDIFM',
  //   // img : storiesImg,
  //   icon: StoriesIcon,
  //   homeSectionContent: {
  //     title: 'IDI Family Members'
  //   },
  //   listingsContent: listings.IDIFM,
  //   chooseContentTypeBodyContent: chooseContentTypeBody.IDIFM,
  //   accessSettingsFromTpl: true,
  //   reduxListingKey: 'IDIFMListing',
  //   listCardConfig: IDIFM_LIST,
  //   cardCardConfig: IDIFM_CARD,
  //   allowComments: false,
  //   allowShare: false,
  //   showListingPage: true,
  //   indexType: 'subSection', //alt is 'block'
  //   showIndexInContributeTpl: true,
  //   showTitleInHeader: {
  //     title: true,
  //     subtitle: true
  //   }
  // },
  // {
  //   id: 'IDIAHE',
  //   // img : storiesImg,
  //   icon: StoriesIcon,
  //   homeSectionContent: {
  //     title: 'IDI Adolescent Health Experts'
  //   },
  //   listingsContent: listings.IDIAHE,
  //   chooseContentTypeBodyContent: chooseContentTypeBody.IDIAHE,
  //   accessSettingsFromTpl: true,
  //   reduxListingKey: 'IDIAHEListing',
  //   listCardConfig: IDIAHE_LIST,
  //   cardCardConfig: IDIAHE_CARD,
  //   allowComments: false,
  //   allowShare: false,
  //   showListingPage: true,
  //   indexType: 'subSection', //alt is 'block'
  //   showIndexInContributeTpl: true,
  //   showTitleInHeader: {
  //     title: true,
  //     subtitle: true
  //   }
  // },
  // {
  //   id: 'COGAMEYM',
  //   // img : storiesImg,
  //   icon: StoriesIcon,
  //   homeSectionContent: {
  //     title: 'Co-Gameplay'
  //   },
  //   listingsContent: listings.COGAMEYM,
  //   chooseContentTypeBodyContent: chooseContentTypeBody.COGAMEYM,
  //   accessSettingsFromTpl: true,
  //   reduxListingKey: 'COGAMEYMListing',
  //   listCardConfig: COGAMEYM_LIST,
  //   cardCardConfig: COGAMEYM_CARD,
  //   allowComments: false,
  //   allowShare: false,
  //   showListingPage: true,
  //   indexType: 'subSection', //alt is 'block'
  //   showIndexInContributeTpl: true,
  //   showTitleInHeader: {
  //     title: true,
  //     subtitle: true
  //   }
  // },
  // {
  //   id: 'FGDYM',
  //   // img : storiesImg,
  //   icon: StoriesIcon,
  //   homeSectionContent: {
  //     title: 'FGD Guide : Young men'
  //   },
  //   listingsContent: listings.FGDYM,
  //   chooseContentTypeBodyContent: chooseContentTypeBody.FGDYM,
  //   accessSettingsFromTpl: true,
  //   reduxListingKey: 'FGDYMListing',
  //   listCardConfig: FGDYM_LIST,
  //   cardCardConfig: FGDYM_CARD,
  //   allowComments: false,
  //   allowShare: false,
  //   showListingPage: true,
  //   indexType: 'subSection', //alt is 'block'
  //   showIndexInContributeTpl: true,
  //   showTitleInHeader: {
  //     title: true,
  //     subtitle: true
  //   }
  // },
  // {
  //   id: 'DGMC',
  //   // img : storiesImg,
  //   icon: StoriesIcon,
  //   homeSectionContent: {
  //     title: 'Discussion Guide : Metaphor Cards'
  //   },
  //   listingsContent: listings.DGMC,
  //   chooseContentTypeBodyContent: chooseContentTypeBody.DGMC,
  //   accessSettingsFromTpl: true,
  //   reduxListingKey: 'DGMCListing',
  //   listCardConfig: DGMC_LIST,
  //   cardCardConfig: DGMC_CARD,
  //   allowComments: false,
  //   allowShare: false,
  //   showListingPage: true,
  //   indexType: 'subSection', //alt is 'block'
  //   showIndexInContributeTpl: true,
  //   showTitleInHeader: {
  //     title: true,
  //     subtitle: true
  //   }
  // },
  // {
  //   id: 'DGME',
  //   // img : storiesImg,
  //   icon: StoriesIcon,
  //   homeSectionContent: {
  //     title: 'Discussion Guide : Mobile Ethnography'
  //   },
  //   listingsContent: listings.DGME,
  //   chooseContentTypeBodyContent: chooseContentTypeBody.DGME,
  //   accessSettingsFromTpl: true,
  //   reduxListingKey: 'DGMEListing',
  //   listCardConfig: DGME_LIST,
  //   cardCardConfig: DGME_CARD,
  //   allowComments: false,
  //   allowShare: false,
  //   showListingPage: true,
  //   indexType: 'subSection', //alt is 'block'
  //   showIndexInContributeTpl: true,
  //   showTitleInHeader: {
  //     title: true,
  //     subtitle: true
  //   }
  // },
  // {
  //   id: 'DGHV',
  //   // img : storiesImg,
  //   icon: StoriesIcon,
  //   homeSectionContent: {
  //     title: 'Discussion Guide : Home Visit'
  //   },
  //   listingsContent: listings.DGHV,
  //   chooseContentTypeBodyContent: chooseContentTypeBody.DGHV,
  //   accessSettingsFromTpl: true,
  //   reduxListingKey: 'DGHVListing',
  //   listCardConfig: DGHV_LIST,
  //   cardCardConfig: DGHV_CARD,
  //   allowComments: false,
  //   allowShare: false,
  //   showListingPage: true,
  //   indexType: 'subSection', //alt is 'block'
  //   showIndexInContributeTpl: true,
  //   showTitleInHeader: {
  //     title: true,
  //     subtitle: true
  //   }
  // }

]
