import * as React from "react"
import {primaryColor100} from '../../../sass/vars.scss';

function CType_Initiatives(props) {
  return (
    <svg width={21} height={21} viewBox="0 0 21 21" fill="none">
      <path
        d="M1.943 6.95a.5.5 0 01.5-.5h3.002a.5.5 0 01.5.5v4a.5.5 0 01-.5.5H2.443a.5.5 0 01-.5-.5v-4zM8.943 11.694v6.322h-2v-3.272l-.146-.146-.854-.854v-2.221H7.443c.349 0 .816.059 1.214.123.103.016.2.033.286.048z"
        stroke={props.stroke || primaryColor100}
      />
      <path
        d="M5.943 6.95a.52.52 0 01.514-.513c6.776-.163 10.666-1.934 12.34-2.937.009-.006.047-.018.101.01a.116.116 0 01.042.035l.003.004v10.798s0 0 0 0v.001s0 0 0 0v.002l-.003.004a.117.117 0 01-.042.034c-.054.028-.092.016-.102.01-1.673-1.003-5.563-2.774-12.34-2.937a.521.521 0 01-.513-.513V6.95z"
        stroke={props.stroke || primaryColor100}
      />
      <path d="M9.444 6.45v5.053" stroke={props.stroke || primaryColor100} strokeLinecap="round" />
    </svg>
  )
}

export default CType_Initiatives
