import React, { useState, useEffect, useRef, Fragment, useImperativeHandle, forwardRef } from "react";

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
//comps
import { KPIconBtn, ButtonTertiary } from '../../generalUI/KPButtons';
import KPLabel from '../../generalUI/KPLabel';
import Close_S from '../../icons/Close_S';
import { KPTextInput } from '../KPInputs';
import Loader from '../../generalUI/Loader';
import MetaBlock from '../../generalUI/MetaBlock';
import KPDynamicSearchResultsBlock from '../KPDynamicSearchResultsBlock';
import ProfileImgDisplay from '../../generalUI/ProfileImgDisplay';
import {getLabelProps} from '../../../utils/contentBlock';
//actions
import { getPublishedListing } from '../../../actions/listings'
//utils
import { set_getValOffQueryString as s_gVal, default_genSelectedComp } from '../../../utils/general';
import { useTrackDdFocus } from '../../../utils/customHooks';

const KPDynamicSearchInput = forwardRef((props, ref) => { //value needs to be an array of objects that at least needs the _id property

  const {
    label,
    sublabel,
    errorMsgs,
    isRequired,
  
    className,
    id,
    size,
    value,
    hideOverflowContent,
    readOnly,
    searchString: searchStringVal,
    placeholder,
    selectLimit,
    staticListing,
    onChange,
    genResultComp,
    resultCompProps,
    genSelectedComp,
    selectedCompProps,
    displayComp,
    listingName,
    getListingActionName,
    getListingArgsArray,
    listingPropertyToMatch,
    onSearchResultsChange,
    onSearchStringChange,
    hideOverflow,
    excludeThisResFromSearchList, // this can be confusing. let me explain with example. say you have a dynamic resource input in an org page called 'THIS ORG', that allows user to tag org pages. Now, if you dont the user to be able to tag "THIS ORG" within itself, then you set this prop to true
    useDefaultRenderer,
    showResultsCompOnFocus,
    filterListingConfig,
    externalResourcesOnly,
    disabled,
    noResultsText
  } = props;

  const Components = {
    MetaBlock
  }

  const dynamicSearchInputContainerRef = useRef(null);



  const [containerFocussed, setContainerFocussed] = useState(false);
  const [showResultsComp, setShowResultsComp] = useState(false);
  const [searchString, setSearchString] = useState(searchStringVal || '');
  const [selected, setSelected] = useState(value || []);
  const [showInput, setShowInput] = useState(
    selectLimit === 'single' && value && value.length === 1
      ? false
      : typeof selectLimit === 'number' && value && value.length === selectLimit
        ? false
        : true
  );

  useTrackDdFocus(dynamicSearchInputContainerRef, setShowResultsComp);

  useEffect(() => {
    searchStringVal ? setSearchString(searchStringVal) : setSearchString('');
  }, [searchStringVal])

  useEffect(() => {
    let newValue = value ? value : [];
    setSelected(newValue);
    newValue.length === 0 && setShowInput(true);
    setShowInput(
      selectLimit === 'single' && value && value.length === 1
        ? false
        : typeof selectLimit === 'number' && value && value.length === selectLimit
          ? false
          : true
    )

  }, [value])

  const [containerRight, setContainerRight] = useState(null);

  useEffect(() => {
    setContainerRight(dynamicSearchInputContainerRef.current && dynamicSearchInputContainerRef.current.getBoundingClientRect().right);
  }, [])

  const handleStringChange = (k, v) => {

    !disabled && !externalResourcesOnly && (v || staticListing || showResultsCompOnFocus)
      ? setShowResultsComp(true)
      : setShowResultsComp(false);

    setSearchString(v);
    onSearchStringChange && onSearchStringChange(v);

  }

  useImperativeHandle(ref, () => ({
    handleSelect: (d) => handleSelect(d)
  }));

  const handleSelect = (d) => {

    // const ID = d.id ? 'id' : '_id'; //our kp apis pass '_id'. external apis like google places pass 'id'. this is to uniformly deal with both of them.

    //handling the different possibilities of what the unique identifier could be for the 3 diff types: namesly: internal resources (_id), palcesAPi (place_id), and static lists (the string itself)
    let ID = d._id
      ? "_id"
      : d.place_id
        ? "place_id"
        : null;

    let newSelected = selectLimit !== 'single' ? [...selected] : [];

    let valIdx = selected.findIndex(v => s_gVal('get', v, ID) === s_gVal('get', d, ID));
    if (valIdx !== -1) { //if value is already found in selected array, then it must be deselected
      newSelected.splice(valIdx, 1);
      selectLimit === 'single' && setShowInput(true);
    } else {
      newSelected = [...newSelected, d];
      selectLimit === 'single' && setShowInput(false); //hide the input if its a single select input AND a value is selected (rather than desleected)
    }

    setSelected(newSelected);
    setSearchString('') //reset search string
    setShowResultsComp(false);



    onChange && onChange(id, newSelected);
  }

  const default_genResultComp = (d) => {

    return (
      <div className='kp-dy-search-result'>
        { !resultCompProps //if no result comp props are passed, create the bare minimum comp
          ? <h4 className='inputTitle kp-dy-search-result__title'>{d}</h4>
          : <Fragment>
            {resultCompProps.img &&
              <ProfileImgDisplay
                className='kp-dy-search-result__img'
                size='4rem'
                avatar={s_gVal('get', d, resultCompProps.img)}
              />}
            {(resultCompProps.title || resultCompProps.desc) &&
              <div className='kp-dy-search-result__text-group'>
                {resultCompProps.title &&
                  <h4 className='inputTitle kp-dy-search-result__title'>
                    {s_gVal('get', d, resultCompProps.title)}
                  </h4>}
                {resultCompProps.desc &&
                  <h4 className='inputDescription kp-dy-search-result__desc'>
                    {s_gVal('get', d, resultCompProps.desc)}
                  </h4>}
              </div>}
          </Fragment>}

      </div>
    )
  }

  const default_genSelectedComp = (d, renderFn) => (
    <div className='kp-dy-search-selected-comp'>
      { !selectedCompProps //if no result comp props are passed, create the bare minimum comp
        ? <Fragment>
          <h4 className='inputTitle kp-dy-selected-comp__text'>{d}</h4>
          {renderFn()}
        </Fragment>
        : <Fragment>
          {selectedCompProps.img &&  //this passed prop is nothing but a 'valuePath'
            <ProfileImgDisplay
              className='kp-dy-selected-comp__img'
              size='4rem'
              avatar={s_gVal('get', d, selectedCompProps.img)}
            />}
          {selectedCompProps.title &&
            <h4 className='inputTitle kp-dy-selected-comp__text'>
              {s_gVal('get', d, selectedCompProps.title)}
            </h4>}
          {renderFn()}
        </Fragment>}
    </div>)

  const genCloseBtnOnSelectedComp = (d) => ( //is a callback function in genSelectedComp
    <Fragment>
      { !readOnly &&
        <ButtonTertiary
          // className='kp-selected__btn--rm-selected'
          // size='small'
          // type='tertiary'
          icon="Close_S"
          onClick={() => handleSelect(d)}>

        </ButtonTertiary>
      }
    </Fragment>
  )

  //For the Selected Component & the Result component
  //if custom comp is passed by parent use it. else use our default comp
  const selectedCompRenderer = genSelectedComp && !useDefaultRenderer ? genSelectedComp : default_genSelectedComp;
  const resultCompRenderer = genResultComp && !useDefaultRenderer ? genResultComp : default_genResultComp;

  const genContent = () => (
    <div
      className={`kp-dynamic-search-input-container ${className} ${!readOnly ? 'not-read-only' : ''} ${containerFocussed ? 'focussed' : ''}`}
      ref={dynamicSearchInputContainerRef}
      onFocus={() => setContainerFocussed(true)}
      onBlur={() => setContainerFocussed(false)}
    >
      <div className='kp-dynamic-search-input-wrapper'>
        <div className='kp-selected-comps-group'>
          {selected.map((d, i) => (
            <div key={i} ref={(node) => {

              if (readOnly && hideOverflow) {
                if (node && containerRight && node.getBoundingClientRect().right > (containerRight - 20)) {

                  node.classList.add('hide');
                }
              }
            }}>
              { selectedCompRenderer(d, () => genCloseBtnOnSelectedComp(d), { selectedCompProps })}
            </div>)
          )}
        </div>
      { !readOnly && showInput &&
        <KPTextInput
          id={id}
          size={size}
          onFocus={() => {
            ( (staticListing && staticListing.type === 'static') 
              || 
              showResultsCompOnFocus )
              && setShowResultsComp(true)}
          } 
          className="kp-dynamic-search-input"
          value={searchString}
          autoComplete='off'
          placeholder={placeholder}
          onChange={(k,v) => handleStringChange(k,v)}
          disabled = {disabled}
          /> }
    </div>
    { showResultsComp &&
      <KPDynamicSearchResultsBlock
        listingName= {listingName}
        staticListing={staticListing}
        getListingActionName= {getListingActionName}
        getListingArgsArray= {getListingArgsArray}
        listingPropertyToMatch={listingPropertyToMatch}
        searchString={searchString}
        resultCompRenderer = {resultCompRenderer}
        selected = {selected}
        onSelect = {(d) => handleSelect(d)}
        onSearchResultsChange = {onSearchResultsChange && onSearchResultsChange}
        excludeThisResFromSearchList = {excludeThisResFromSearchList}
        filterListingConfig = {filterListingConfig}
        noResultsText = {noResultsText}
        /> }
    </div>
  )

  const genDisplayComp = () => {
    const Component = Components[displayComp.comp];
    const val = s_gVal('get', selected, displayComp.getValuePath);
    const props = s_gVal('set', displayComp.props, displayComp.setValuePath, val);

    return (
      <Fragment>
        <Component {...props} />
      </Fragment>
    )
  }


  return (
    <div className={`kp-dynamic-search-input-master-container ${className}`}>
      <KPLabel {...getLabelProps(props)} />
      { (readOnly && displayComp) ? genDisplayComp() : genContent()}
    </div>
  )
})



KPDynamicSearchInput.defaultProps = {
  // genResultComp: (d) => default_genResultComp(d),
  // genSelectedComp: (d, renderFn) => default_genSelectedComp(d, renderFn),
  listingName: 'userProfilesListingShort',
  getListingActionName: 'getProfilesListing',
  getListingArgsArray: ['userProfiles', 'short'],
  listingPropertyToMatch: 'user.name',
  placeholder: 'dynamic search input',
  selectLimit: 'multiple',
  size: 'large'
}

export default KPDynamicSearchInput;
