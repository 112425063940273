import api from './../_configs/api';

import {
  UPDATE_USER,
  UPDATE_USER_LOADING
} from './types';


export const updateIsRead = (notifsAry, userId) => async dispatch => {
  try{
    // dispatch({
    //   type: UPDATE_USER_LOADING,
    //   payload: true
    // })

    
    const res = await api.patch(`/api/notifications/set_is_read/${userId}`);

    dispatch({
      type: UPDATE_USER,
      payload: {
        notifications : notifsAry.map(d => {
                          return { ...d, kp_isRead : true }
                        })
      }
    })
  }catch(err){
    if(err) console.log('err from notifications > updateIsRead', err.response ? err.response.data : err );
  }

}
