
export const checkAccessToContentType = (user, contentType, roles, userAction) => {
    return roles[user.role].knowledge.contentTypes[contentType].includes(userAction)
  }
  
  export const _GetPublishOrSubmitAction = (user, contentType, roles) => {
  
    switch(true){
  
      case checkAccessToContentType(user, contentType, roles, 'submit' ) === true:
      return {
        action: 'submit',
        display: { onDraft: 'Submit', onEditPublished: 'Re-Submit' }
      }
  
      case checkAccessToContentType(user, contentType, roles, 'publish' ) === true:
      return {
        action: 'publish',
        display: { onDraft: 'Publish', onEditPublished: 'Publish Changes' }
      }
  
      default:
      return 'Error in get publish / submit action'
  
    }
  }
  