import React from "react"
import {primaryColor100} from '../../../sass/vars.scss';

function Phone24(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.248 15.649c3.92 4.582 8.05 5.691 12.392 3.33a1.47 1.47 0 00.749-1.072 1.439 1.439 0 00-.417-1.234l-1.898-1.872a1.49 1.49 0 00-2.004-.077l-1.86 1.569c-2.453-.523-4.575-2.609-6.368-6.256.662-.666 1.2-1.205 1.62-1.616a1.447 1.447 0 00.005-2.06l-.003-.003L7.1 4.026a1.48 1.48 0 00-1.164-.421 1.492 1.492 0 00-1.08.602c-2.265 3.114-1.47 6.926 2.391 11.442z"
        fill="#E0F0FD"
        stroke={primaryColor100}
      />
    </svg>
  )
}

export default Phone24
