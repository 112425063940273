export const listings = {
  events : {
    title : 'Events',
    desc : 'Upcoming Events organised by the network or its members and documentation from past events',
    ctaText : 'Create An Event'
  },
  resources : {
    title : 'Research',
    desc : 'Find, Filter and Download Resources such as Event Reports, Papers, Reports and Case Studies',
    ctaText : 'Share'
  },
  initiatives : {
    title : 'Initiatives',
    desc : 'Reports from past and ongoing Initiatives supported by the Network and its members relevant to our goals around Rainfed Agriculture',
    ctaText : 'Write about an Initiative'
  },
  stories : {
    title : 'Stories',
    desc : 'Voices from the ground about people, groups and experiences',
    ctaText : 'Share A Story'
  },
  videoStories : {
    title : 'Films / Videos',
    desc : 'Videos, films & documentaries about rainfed agriculture',
    ctaText : 'Share A Film / Video'
  },
  photoStories : {
    title : 'Photo Stories',
    desc : 'Photos of voices from the ground about people, groups and experiences',
    ctaText : 'Share'
  },
  activities : {
    title : 'Activities',
    desc : 'Latest Reports from Activities undertaken by Network Members, Working Groups and State Networks',
    ctaText : 'Write About An Activity'
  },
  news : {
    title : 'News',
    desc : 'The latest news relevant to you as a network member',
    ctaText : 'Post A News Article'
  },
  mediaCoverage : {
    title : 'Media Coverage',
    desc : 'The latest news relevant to you as a network member',
    ctaText : 'Post'
  },
  tools : {
    title : 'Tools & Guidelines',
    desc : 'A Directory Of The Various Tools, Guidelines And Experiences That Have Been Found to be Informative, and Important in Taking Action Around Water Quality.',
    ctaText : 'Contribute A Guideline Or Tool'
  },
  toolkits : {
    title : 'Toolkits',
    desc : 'Each Toolkit On This Page Collects Specific Tools, Guidelines And Experiences To Learn, Help You Plan And Take Action Around Water Quality In the Communities You Work With.',
    ctaText : 'Put Together A Toolkit'
  },
  recommendations : {
    title : 'Recommendations',
    desc : '',
    ctaText : 'Create A Recommendation'
  },
  plans : {
    title : 'Village Action Plan',
    desc : '',
    ctaText : 'Create A Village Action Plan'
  },
  caseReports : {
    title : 'Case Reports',
    desc : '',
    ctaText : 'Report'
  },
  demographics : {
    title : 'Demographic Screening',
    desc : '',
    ctaText : 'Make An Entry'
  },
  IDIYM : {
    title : 'IDI Young Men',
    desc : '',
    ctaText : 'Create A Research Note Entry'
  },
  IDIGD : {
    title : 'IDI Game Developers',
    desc : '',
    ctaText : 'Create A Research Note Entry'
  },
  IDIAHE : {
    title : 'IDI Adolescent Health Experts',
    desc : '',
    ctaText : 'Create A Research Note Entry'
  },
  IDIFM : {
    title : 'IDI Family Members',
    desc : '',
    ctaText : 'Create A Research Note Entry'
  },
  COGAMEYM : {
    title : 'Co-Gameplay',
    desc : '',
    ctaText : 'Make Notes'
  },
  FGDYM : {
    title : 'FGD Guide : Young men',
    desc : '',
    ctaText : 'Make Notes'
  },
  DGMC : {
    title : 'Discussion Guide : Metaphor Cards',
    desc : '',
    ctaText : 'Make Notes'
  },
  DGME : {
    title : 'Discussion Guide : Mobile Ethnography',
    desc : '',
    ctaText : 'Make Notes'
  },
  DGHV : {
    title : 'Discussion Guide : Home Visit',
    desc : '',
    ctaText : 'Make Notes'
  }
}


export const communityListings = {
  userProfiles: {
    title : 'People',
    desc : 'Find members from the Network and see their contributions to the platform.',
  },
  organisations: {
    title : 'Organisations',
    desc : 'Find the organisations affiliated to the Network and their contributions to the platform.',
  },
  workingGroups: {
    title : 'Working Groups',
    desc : 'Learn about the Working Groups of the RRA Network, members, activities and related knowledge.',
  },
  stateNetworks: {
    title : 'State Networks',
    desc : 'Learn about the State Networks of the RRA Network, members, activities and related knowledge.',
  },
  partnerNetworks: {
    title : 'Partner Networks',
    desc : 'Learn about the Partner Networks of the RRA Network, members, activities and related knowledge.',
  },
  board: {
    title : 'Board',
    desc : 'Meet the members of the Governing Board of the RRA Network.',
  }
}
