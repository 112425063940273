import * as React from "react"
import { primaryColor100 } from '../../../sass/vars.scss';

function Youtube_solid_SM(props) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.093 6.17a3.307 3.307 0 00-3.082 2.984c-.101 1.079-.188 2.29-.188 3.273 0 .977.086 2.182.186 3.258.153 1.628 1.47 2.88 3.102 2.989 1.832.122 4.244.253 6.212.253 1.66 0 3.66-.116 5.246-.232a3.3 3.3 0 003.064-2.978c.102-1.084.19-2.303.19-3.29 0-.982-.087-2.194-.188-3.273a3.307 3.307 0 00-3.083-2.984c-1.709-.12-3.913-.243-5.73-.243-1.815 0-4.02.123-5.729.243zm8.755 6.257l-5-3v6l5-3z"
        fill={props.fill || primaryColor100}
      />
    </svg>
  )
}

export default Youtube_solid_SM
