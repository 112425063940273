
import React,{useState} from 'react';

import {CaretDown, CaretUp, X} from 'phosphor-react';
import InputText from '../../typo/InputText';
import {greyColor100} from '../../../sass/vars.scss';
import ProfileImgDisplay from '../../generalUI/ProfileImgDisplay'

export const Select = React.forwardRef((props, ref) => {
    
    const {
        //from root
        id,
        variant,
        value,
        placeholder, 
        caretColor,
        genCustomSelectComp,
        //from Dropdown
        searchString, 
        setSearchString,
        showOptions,
        isMultiSelect,
        handleSelect
    } = props;

    const [isFocussed, setIsFocussed] = useState(false);
    const handleFocus = (bool) => {
      setIsFocussed(bool);
      if(!value) return;
      // if(isMultiSelect){
      //   bool 
      //   ? setSearchString('') 
      //   : setSearchString(value.length + ' Selected')
      // }
    }

    const setDefaultSelectDisplay = () => {
      if(!value || value.length === 0) return placeholder || 'Select'; //else
      return isMultiSelect
              ? value.length + ' Selected'
              : value.display 
    }

    const showOptionsSelectedFeedback = isMultiSelect && value && value.length > 0 && !showOptions;

    const genDefaultSelect = () => (
      <div className={`OKE-Dropdown__Select ${showOptions ? 'focussed' : ''}`}>
        <InputText className={`OKE-Dropdown__SelectText ${!value && placeholder}`}>
        { setDefaultSelectDisplay() }
        </InputText>
        <div className='OKE-Dropdown__Caret'>
        { showOptions 
          ? <CaretUp size={12} color={caretColor || greyColor100} weight="bold" /> 
          : <CaretDown size={12} color={caretColor || greyColor100} weight="bold" /> }
          <div className='OKE-Dropdown__CaretClickArea'></div>
        </div>
      </div>
    )

    const genComboboxSelect = () => (
      <div 
        className={`OKE-Dropdown__ComboboxSelect ${isFocussed ? 'focussed' : ''}`} 
        onFocus={() => handleFocus(true)} //maybe this should be in the input element. maybe we should avoid event bubbling.
        onBlur={() => handleFocus(false)}
        >
      { <InputText 
          className='OKE-Dropdown__optionsSelectedFeedback'
          style={{
            display : showOptionsSelectedFeedback ? 'block' : 'none',
            flex: '1 0 auto'
          }}
          >
          { value && (value.length + ' Selected')}
        </InputText> }
      { <input 
          ref={ref}
          className='OKE-Dropdown__textInput inputText' 
          placeholder={showOptionsSelectedFeedback ? '' : (placeholder || 'Type to Search')}
          style={{ display : showOptionsSelectedFeedback ? 'none' : 'block'}}
          type="text" 
          value={searchString} 
          onChange={(e) => setSearchString(e.target.value)}
          /> }
        <div className='OKE-Dropdown__Caret'>
        { showOptions 
          ? <CaretUp size={12} color={caretColor || greyColor100} weight="bold" /> 
          : <CaretDown size={12} color={caretColor || greyColor100} weight="bold" /> }
          <div className='OKE-Dropdown__CaretClickArea'></div>
        </div>
      </div>
    )

    const genTagComp = (d) => (
      <div className='OKE-Dropdown__tag'>
        <div className='OKE-Dropdown__tagContent'>
      {   d.image && 
          <ProfileImgDisplay 
            avatar={d.image} 
            size={'2rem'} 
            style={{marginRight: '1rem'}}
            placeholderInitial={d.display[0]}
            /> }
          <InputText className='OKE-Dropdown__tagText'>{d.display}</InputText>
        </div>
        <div 
          className='OKE-Dropdown__removeTagBtn'
          onClick={() => handleSelect(id, d)}
          >
          <X size={16} color={greyColor100} weight="bold"/>
        </div>
      </div>
    )

    const genTagsSelect = () => (
      <div 
        className={`OKE-Dropdown__TagsSelect ${isFocussed ? 'focussed' : ''}`} 
        onFocus={() => handleFocus(true)} //maybe this should be in the input element. maybe we should avoid event bubbling.
        onBlur={() => handleFocus(false)}
        >
        <div className='OKE-Dropdown__tags-wrapper'>
          { value && value.map(d => genTagComp(d)) }
          { <input 
              ref={ref}
              className='OKE-Dropdown__textInput inputText' 
              placeholder={'Type to Search'}
              type="text" 
              value={searchString} 
              onChange={(e) => setSearchString(e.target.value)}
              /> }
        </div>
        <div className='OKE-Dropdown__Caret'>
        { showOptions 
          ? <CaretUp size={12} color={caretColor || greyColor100} weight="bold" /> 
          : <CaretDown size={12} color={caretColor || greyColor100} weight="bold" /> }
          <div className='OKE-Dropdown__CaretClickArea'></div>
        </div>
      </div>
    )



    const genSelectComp = () => {
        if(props.genCustomSelectComp){
            return genCustomSelectComp();
        } 
        //else
        switch(variant){
            case 'single': 
            case 'multi':
            return genDefaultSelect();
            
            case 'combobox--single':
            case 'combobox--multi':
            return genComboboxSelect();
            
            case 'tags':
            return genTagsSelect();
            // default:
            // return genDefaultSelect();
        }
    }

    return genSelectComp();
})
