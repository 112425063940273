import React, { useState, Fragment } from "react";

import CheckboxCheck from '../../icons/CheckboxCheck';

const CheckboxElement = ({ isSelected, style }) => {

  return (
    <button className={`checkboxElement ${isSelected ? 'selected' : ''}`} style={style} >
      { isSelected &&
        <div className='checkboxElement__check'><CheckboxCheck/></div> }
    </button>
  );
}

export default CheckboxElement
