import React, { useState, useEffect, Fragment } from "react";
import { withRouter, useLocation } from "react-router-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import store from '../../../store';
import {KPDropdown} from '../../inputs/KPDropdowns';
import { KPTextInput } from '../../inputs/KPInputs';
import {KPBtn} from '../../generalUI/KPButtons';
import ToggleBar from '../../generalUI/ToggleBar';
import ModalShell from '../../modals/ModalShell';
import ModalHeader from '../../modals/ModalHeader';
import Loader from '../../generalUI/Loader';

import ModalButtonGroup from '../../modals/ModalButtonGroup';

//cards
import ContentCard from '../../cards/ContentCard';
import ListEl__Content from '../../cards/ListEl__Content';
//actions
import { getPublishedListing, getProfilesListing } from '../../../actions/listings';
import {setAlert} from '../../../actions/alert'
import { triggerNestedPublishing } from '../../../actions/nestedPublishing'
import { saveChanges } from '../../../actions/profiles';
//config
import { _GetContentTypeConfig, _ContentTypes } from '../../../_configs/contentTypes/config';
import {_GetProfileTypeConfig } from '../../../_configs/profileTypes/config';
import { _ProfileTypes } from '../../../_configs/profileTypes/config';
//utils
import { __CardPropsGenerator } from '../../../utils/cardUtils';
//sass
import {primaryColor100, primaryColor10, white} from '../../../sass/vars.scss'


const KPInternalResSelect = ({
  id,
  allowNestedPublishing,
  cardDisplayBlockId,
  onConfirm : parentOnConfirm,
  selectedAry : parentSelectedAry,
  onCloseModal,
  contentTypeEmbedOptions,
  profileTypeEmbedOptions,
  maxSelectLimit,
  //coming directly from redux
  listings,
  profiles : { Profile, validationRel : { clearToProceed }, editRel: { isEditAuth } },
  getPublishedListing,
  getProfilesListing,
  //router props
  match
}) => { //col settings must be the bootstrap codes

  //--api call / fetch from store as per config
  //searchbar to search through list
  //dropdown to select specific content type or all
  //prop to set max limit of select
  //selected preview

  //--parent comp that allow to trigger this modal
  //--parent 'value' state var, which gets updated when selections here are confirmed
  //--appropriate cards rendered as per 'value'

  const [selectedAry, setSelectedAry] = useState(parentSelectedAry || [])

  let toggleOps = [];

  let contentTypeOptions = contentTypeEmbedOptions || _ContentTypes.map(contentType => contentType.id).filter(id => id !== 'staticPages')
  let profileTypeOptions = profileTypeEmbedOptions || undefined;

  contentTypeOptions.map(contentType => {
    let { id : contentTypeId, listingsContent : { title } } = _GetContentTypeConfig(contentType);
    toggleOps.push({value: contentTypeId, name: title})
  })
  //1  profiles embed
  if(profileTypeOptions){
    profileTypeOptions.map(profileType => {
      let { id : profileTypeId, title } = _GetProfileTypeConfig(profileType);
      toggleOps.push({value: profileTypeId, name: title})
    }) 
  }

  toggleOps.push({value: 'userProfiles', name: 'User Profiles'})

  const [activeTab, setActiveTab] = useState(contentTypeOptions[0]);

  const onToggleBarChange = (value) => setActiveTab(value)

  const genToggleBar = (toggleOps) => (
    <ToggleBar
      className='kp-profile__content-types-toggle'
      options={ toggleOps }
      onChange={ (key, value) => onToggleBarChange(value) }
      />
  )

  const handleSetSelectedAry = (d) => {
    let newSelectedAry = [];
    if(selectedAry.some(datum => datum._id === d._id)){
      newSelectedAry = selectedAry.filter(datum => datum._id !== d._id)
    }else{
      if(selectedAry.length === (maxSelectLimit) ){
        store.dispatch(
          setAlert(
            `you have already hit the maximum of ${maxSelectLimit} resources that you can embed.`,
            'neutral'
          )
        )
        newSelectedAry = selectedAry;
      }else{
        newSelectedAry = [...selectedAry, d];
      }

    }
    setSelectedAry(newSelectedAry);
  }

  const handleConfirm = () => {
    parentOnConfirm({type: 'internal', selectedAry}); //unfortunately we have to call this key selectedAry, because thats the key being used in cards display and that is saved to db in the case of richeditor card embeds.
    onCloseModal()
  }

  useEffect(()=>{
    contentTypeOptions.map(contentType => {
      if(listings[_GetContentTypeConfig(contentType).reduxListingKey].data.length === 0){ //if data DOESNT already exist in store, then...
        getPublishedListing( contentType , 'long');
      }
    })
    

  },[])

  useEffect(() => {
    //2 profiles embed
    profileTypeOptions && profileTypeOptions.map(profileType => {    
      if(listings[_GetProfileTypeConfig(profileType).reduxListingKey].data.length === 0){
        getProfilesListing(profileType, 'long');
      }
    })
  },[])

  const handleTriggerNestedPublishing = async (
    contentType
  ) => {
    //save changws is temp will be removed when we add autosave to profiles
    try{

      let newProfileData = null;

      await store.dispatch(
        saveChanges(
          Profile._id,
          Profile,
          clearToProceed,
          match.params.profileType,
          isEditAuth,
          true,
          (err, result) => {
            
            if(err) throw err;
            //else
            newProfileData = result;
          }
        )
      )

      store.dispatch( triggerNestedPublishing( contentType, newProfileData, cardDisplayBlockId ) ) //we gotta pass profileType seprately and not just pull it from the Profile obj, cuz in the case of 'create' profile_type doesnt exist in the Profile obj

    }catch(err){
      if(err) console.log('err in KPInternalResSelect > handleTriggerNestedPublishing', err);
    }
  }

  const genContentList = () => {

    return (
      <Fragment>
      { toggleOps.length > 1 && genToggleBar(toggleOps) }
      { contentTypeOptions.map(contentType => {
        let { reduxListingKey, id : contentTypeId } = _GetContentTypeConfig(contentType);
        if(contentTypeId === activeTab){
          return (
          <Fragment>
            {listings[reduxListingKey].data.map((d, i) =>  {
              
              let selectStyle = selectedAry.some(datum => datum._id === d._id) ? primaryColor10 : white
              return(
                <ListEl__Content
                  style={{backgroundColor: selectStyle}}
                  customClickHandler = {() => handleSetSelectedAry(d)}
                  { ...__CardPropsGenerator(d) }
                  colSetting = 'col-12'
                  />
              )
            })}
          </Fragment> )
        }

      })}
      
      { //3 profiles emb3d : LAST
      profileTypeOptions && profileTypeOptions.map(profileType => {
        let { listCardConfig, reduxListingKey, id : profileTypeId } = _GetProfileTypeConfig(profileType);
        if(profileTypeId === activeTab){
          return (
          <Fragment>
          { listings[reduxListingKey].data.map(d => {
              // let profileType = d.meta.kp_content_type;
              let { cardCardConfig, listCardConfig }  = _GetProfileTypeConfig(profileType)
              let selectStyle = selectedAry.some(datum => datum._id === d._id) ? primaryColor10 : white
              return(

                <ContentCard
                  cardType = {`published--${profileType} list-card--long list-card--long-${profileType}`}
                  style = {{ backgroundColor: selectStyle }}
                  customClickHandler = {() => handleSetSelectedAry(d)}
                  { ...cardCardConfig(d) }
                  { ...listCardConfig(d) }
                  colSetting = 'col-12'
                  />
              )
            }) }
          </Fragment> )
        }

      })}
      </Fragment>
    )
  }

  const createNestedPblCTA = () => {
    if(contentTypeOptions.length === 1){
      const {chooseContentTypeBodyContent : { ctaText }} = _GetContentTypeConfig(contentTypeOptions[0])
      return (
        <KPBtn
          style={{paddingLeft: '1rem'}}
          onClick={() => handleTriggerNestedPublishing(contentTypeOptions[0]) }
          >
          { ctaText }
        </KPBtn>
      )
    }else{
      const options = contentTypeOptions.map(d => {
        const { chooseContentTypeBodyContent : {ctaText}, id } = _GetContentTypeConfig(d)
        return { display: ctaText, value: id};
      })

      return (
        <KPDropdown
          options = {options}
          onChange = {(k,v) => handleTriggerNestedPublishing(v.value)}
          />
      )
    }
  }

  return (
      <Fragment>
        <div className='kp-internal-resource-select'>
          <ModalHeader
            className='kp-internal-resource-select__header'
            title = 'Choose Content'
            cta = { allowNestedPublishing && createNestedPblCTA() }
            onClose = {onCloseModal}
            />
        { listings.loading
        ? <Loader type='inline-loader' />
      : <Fragment>
          <div className='kp-internal-resource-select__resource-list' style={{padding : '6rem 0' /*to account for the ModalButtonGroup*/ }}>
          { genContentList() }
          </div>
          <ModalButtonGroup btnConfig={{ accept: () => handleConfirm(), acceptBtnText : 'Insert Link' }} />
          {/*<div className='kp-internal-resource-select__insert-btn-wrapper'>
            <KPBtn
              className='kp-internal-resource-select__insert-btn'
              disabled={selectedAry.length === 0}
              onClick={() => handleConfirm() }
              >
              Insert Link
            </KPBtn>
          </div>*/}
        </Fragment> }
        </div>

      </Fragment>
  )
}

KPInternalResSelect.propTypes = {
  listings: PropTypes.object.isRequired,
  profiles : PropTypes.object.isRequired,
  getPublishedListing: PropTypes.func.isRequired,
  getProfilesListing: PropTypes.func.isRequired
};

KPInternalResSelect.defaultProps = {
  maxSelectLimit : 100000000000, //some ridiculous amount basically suggesting no select limit.
  allowNestedPublishing : false
}

const mapStateToProps = state => ({
  listings: state.listings,
  profiles : state.profiles
});

export default withRouter(
  connect(
    mapStateToProps,
    { getPublishedListing, getProfilesListing }
  )(KPInternalResSelect)
);
