import React, { useState, useEffect, Fragment } from "react";

import PropTypes from 'prop-types';
import {KPTextInput} from '../KPInputs';
import {KPIconBtn, ButtonTertiary, ButtonSocialGroup, ButtonGhost} from '../../generalUI/KPButtons';
import KPLink from '../../generalUI/KPLink';
import KPLabel from '../../generalUI/KPLabel';

import {primaryColor100,  white} from '../../../sass/vars.scss'

import {injectHttps} from '../../../utils/general';

import ContentBlockShell from '../../generalUI/ContentBlockShell';
import {replaceEntityData} from '../../../utils/richInputUtils'
import {getLabelProps} from '../../../utils/contentBlock';

const KPLikeContactChannelsBlock = (props) => { //col settings must be the bootstrap codes

  // const labelProps = {
  //   id,  //string
  //   label, //string
  //   sublabel, //string
  //   readOnly, //bool
  //   errorMsgs, //array
  //   isRequired //bool
  // }

  const {
      label, sublabel, errorMsgs, isRequired,
      fieldsData,
      id,
      className,
      value,
      readOnly,
      onChange,
      invert,
      isInEditor,
      editor,
      entityKey,
      block,
      handleSetTempReadOnly
  } = props
  

  /*NOTE:
  * can use the property 'showText : true' against each object in field data, 
  * if we want to expose the text string and not just show icon
  * eg. for emails and phonenumbers
  */

  const defaultFieldsData = [
    {
      "id": "twitter",
      "icon": "TwitterLogo",
      "placeholder": "enter twitter handle"
    },
    {
      "id": "facebook",
      "icon": "FacebookLogo",
      "placeholder": "enter facebook link"
    },
    {
      "id": "linkedin",
      "icon": "LinkedinLogo",
      "placeholder": "enter linkedin link"
    },
    {
      "id": "youtube",
      "icon": "YoutubeLogo",
      "placeholder": "enter youtube link"
    },
    {
      "id": "instagram",
      "icon": "InstagramLogo",
      "placeholder": "enter instagram link"
    }
  ]

  let fieldsDataToUse = fieldsData || defaultFieldsData;

  const createEmptyValObj = (data) => {
    let valObj = {};
    data.map(d => valObj[d.id] = '');
    return valObj;
  }

  const initVal = value || createEmptyValObj(fieldsDataToUse);

  const [ val, setVal ] = useState(initVal);

  const handleChange = (k,v) => {
    let newVal = { ...val, [k] : v };
    console.log('new val', newVal);
    setVal(newVal);
    
    onChange && onChange( id, newVal );
    if(isInEditor === true){
      replaceEntityData(editor, entityKey, {value: newVal })
    }
  }

  const genInputComp = () => (
    <div className='kp-like-contact-channels-input-group'>
    { fieldsDataToUse.map(d => (
      <KPTextInput
        id={d.id}
        icon={d.icon}
        size="small"
        className='kp-like-contact-channels-input'
        placeholder={d.placeholder}
        onChange={(k,v) => handleChange(k,v)}
        inputType={d.input_type || 'url'}
        value={value && value[d.id]}
        />
    )) }
    </div>
  )

  const genDisplayComp = () => (
    <div className='kp-like-contact-channels--display'>
      <div className='kp-like-contact-channels--display__exposed-link-group'>
      { fieldsDataToUse.map(d => {
        let linkString = value[d.id] && (d.link_prefix ? d.link_prefix+value[d.id] : injectHttps(value[d.id]));
        return (
          value[d.id] && d.showText &&
          <ButtonGhost 
            linkType='external'
            link={linkString}
            icon={d.icon}
            invert = {invert}
            value={value[d.id]}
            className= {`${d.showText ? '-show-text-icon-':''} socialLink--button` }
            />
      )}) }
      </div>
      <div className='kp-like-contact-channels--display__icons-group'>
        
      { fieldsDataToUse.map(d => {

        return(
        <Fragment>
        { value[d.id]  &&
          <ButtonTertiary
            icon = {d.icon}
            invert = {invert}
            // type = 'tertiary'
            className= {`socilaLinkIcon--button ${d.showText ? '-show-icon-':''}`}
            onClick={() => {
                window.open(
                  d.link_prefix ? d.link_prefix+value[d.id] : injectHttps(value[d.id]),
                  '_blank'
                )
              }
            }
            /> }
        </Fragment>
      )}) }
      </div>
    
    </div>
  )


  return (
    <ContentBlockShell
      richInputProps = {{
        isInEditor,
        editor,
        block,
        handleSetTempReadOnly
      }}
      readOnly = { readOnly }
      className={`${className}`}
      id={id}
      >
      <div className={`kp-like-contact-channels`}>
      <KPLabel {...getLabelProps(props)} />
      { readOnly ? genDisplayComp() : genInputComp() }
      </div>
    </ContentBlockShell>
  )
}

KPLikeContactChannelsBlock.defaultProps = {};

KPLikeContactChannelsBlock.propTypes = {};


export default KPLikeContactChannelsBlock;
