import React from "react"
import { black } from '../../../sass/vars.scss';

function HintsIcon(props) {
  return (
    <svg width={props.size || 20} height={props.size || 20} viewBox="0 0 20 20" fill="none">
      <path
        d="M16.807 7.712c-.005-1.85-.788-3.67-2.156-4.925-1.374-1.255-3.25-1.932-5.115-1.76-1.829.168-3.561 1.051-4.7 2.509-.58.743-1.02 1.579-1.255 2.496A6.829 6.829 0 003.5 9.017c.363 1.908 1.64 3.622 3.332 4.566.19.105.19-.068.19.097v3.516a1.8 1.8 0 001.798 1.8l2.543.003a1.8 1.8 0 001.8-1.8v-.967-2.53c0-.153-.117.04.017-.03a6.81 6.81 0 003.38-4.174c.16-.579.242-1.188.242-1.789.003-.414.008.446.005.003z"
        stroke={props.stroke || black}
      />
      <path d="M7.04 15.502h6.122" stroke={props.stroke || black} strokeLinecap="square" />
      <path
        clipRule="evenodd"
        d="M7.586 7.805l2.737 1.36 2.555-1.36-2.745 7.164-2.547-7.164z"
        stroke={props.stroke || black}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}



export default HintsIcon
