import {
  getImgData,
  getLocation,
  getRichInputText,
  getAvatar,
  getName,
  getShortenedName,
  getVal,
  getResourceLink,
  getSpeaker,
  getUrl,
  getPlacesAPILocation,
  getVillageAndPlaces
} from '../../../utils/getters';

import { createContentCardDateRange, createContentCardDate } from '../../../utils/momentManipulate';
import { formatTitle, formatText } from '../../../utils/general';


import { _GetContentTypeConfig } from '../../contentTypes/config';

const contentTypeDisplay = (d) => _GetContentTypeConfig(d.meta.kp_content_type).listingsContent.title;

const tplSubject = (d) => d.meta.subject || d.meta.kp_subject; //backwards compat

export const STORIES = d => ({
  // colSetting : 'col-sm-3',
  image : {
    data : getImgData(d.main.kp_cover_image),
    heightRatio : '61.804%'
  },
  metaPrimary : {
    value: tplSubject(d)
            ? (tplSubject(d).label || tplSubject(d).title || formatText.underscoreToTitle(tplSubject(d)))
            : contentTypeDisplay(d)
  },
  featuredCardLabel : { value: contentTypeDisplay(d) }, 
  title : formatTitle(getRichInputText(d.main.kp_title || d.main.title)),
  shareSummary : getRichInputText([d.main.kp_subtitle, d.main.kp_summary]),
  metaBlockData : {
    comp : 'MetaBlock',
    data : [
      { text: getShortenedName(d.meta.kp_contributed_by) },
      { text: createContentCardDate(d.kp_date_published, d.kp_date_created) }
    ]
  }
})

export const VIDEO_STORIES = d => ({
  // colSetting : 'col-sm-3',
  videoEmbed : {
    value : d.main.kp_video
  },
  metaPrimary : {
    value: contentTypeDisplay(d)
  },
  featuredCardLabel : { value: contentTypeDisplay(d) }, 
  title : formatTitle(getRichInputText(d.main.kp_title)),
  shareSummary : getRichInputText(d.main.kp_summary),
  metaBlockData : {
    comp : 'MetaBlock',
    data : [
      { text: getShortenedName(d.meta.kp_contributed_by) },
      { text: createContentCardDate(d.kp_date_published, d.kp_date_created) }
    ]
  }
})

export const PHOTO_STORIES = d => ({
  // colSetting : 'col-sm-3',
  image : {
    data : getImgData(d.main.images),
    heightRatio : '61.804%'
  },
  metaPrimary : {
    value: contentTypeDisplay(d)
  },
  featuredCardLabel : { value: contentTypeDisplay(d) }, 
  title : formatTitle(getRichInputText(d.main.title)),
  shareSummary : getRichInputText(d.main.description),
  metaBlockData : {
    comp : 'MetaBlock',
    data : [
      { text: getShortenedName(d.meta.kp_contributed_by) },
      { text: createContentCardDate(d.kp_date_published, d.kp_date_created) }
    ]
  }
})

export const ACTIVITIES = d => ({
  // colSetting : 'col-sm-3',
  image : {
    data : getImgData(d.main.kp_cover_image),
    heightRatio : '61.804%'
  },
  metaPrimary : {
    key: contentTypeDisplay(d),
    value: getPlacesAPILocation(d.meta.kp_location)
  },
  featuredCardLabel : { value: contentTypeDisplay(d) }, 
  title : formatTitle(getRichInputText(d.main.kp_title)),
  shareSummary : getRichInputText([d.main.kp_subtitle, d.main.kp_summary]),
  metaBlockData : {
    comp : 'MetaBlock',
    data : [
      { text: getShortenedName(d.meta.kp_contributed_by) },
      { text: createContentCardDate(d.meta.kp_date && d.meta.kp_date.date || d.kp_date_published || d.kp_date_created) }
      
    ]
  }
})

export const RESOURCES = d => ({
  // colSetting : 'col-sm-3',
  image : {
    data : getImgData(d.main.kp_cover_image),
    heightRatio : '61.804%'
  },
  title : formatTitle(getRichInputText(d.main.kp_title || d.main.title)),
  shareSummary : getRichInputText(d.main.kp_summary),
  shareImage : {
    data: getImgData(d.main.kp_cover_image)
  },
  metaPrimary : {
    key: contentTypeDisplay(d),
    value: getVal(d.meta.kp_type, ['value_desc', 'display', null])
  },
  featuredCardLabel : { value: contentTypeDisplay(d) }, 
  metaBlockData : {
    comp : 'MetaBlock',
    data : [
      { text: getShortenedName(d.meta.kp_contributed_by) },
      { text: createContentCardDate(
        d.meta.kp_date_of_publication && d.meta.kp_date_of_publication.date, 
        d.kp_date_published
      ) }
    ]
  },
  resourceLink : getResourceLink(d.main.kp_download_link)
})

export const INITIATIVES = d => ({
  // colSetting : 'col-sm-3',
  metaPrimary : {
    value: getVal(d.meta.kp_super_tags, 'display', 1)
  },
  featuredCardLabel : { value: contentTypeDisplay(d) },
  image : {
    data : getImgData(d.main.kp_cover_image),
    heightRatio : '61.804%'
  },
  title : formatTitle(getRichInputText(d.main.kp_title)),
  shareSummary : getRichInputText(d.main.kp_summary),
  metaBlockData : {
    comp : 'MetaBlock',
    data : [
      { text: getShortenedName(d.meta.kp_contributed_by) },
      { text: createContentCardDate(
        d.meta.kp_date_to_date && d.meta.kp_date_to_date.dateFrom && d.meta.kp_date_to_date.dateFrom.date, 
        d.kp_date_published
      ) }
    ]
  }
})


export const EVENTS = d => ({
  // colSetting : 'col-sm-3',
  metaHero : createContentCardDateRange(
    d.main.kp_date_time && (d.main.kp_date_time.dateRange || [d.main.kp_date_time.date, d.main.kp_date_time.date ]),
    null,  //alt date
    null, //prefix 
    true //renderDateFn
  ), //the or is to deal with backwards compatibility
  metaPrimary : {
    value: getVal(d.meta.kp_event_type, ['value_desc', 'display'])
  },
  featuredCardLabel : { value: contentTypeDisplay(d) }, 
  title : formatTitle(getRichInputText(d.main.kp_title)),
  shareSummary : getRichInputText(d.main.kp_about),
  metaBlockData : {
    comp : 'MetaBlock',
    data : [
      { 
        text: getSpeaker(d.main.kp_speaker) 
        ? `Speaker : ${getSpeaker(d.main.kp_speaker)}` 
        : ""
      },
    ]
  }
})

export const NEWS = d => ({
  // colSetting : 'col-sm-3',
  // link : { type: 'external', url: getUrl(d.main.kp_link) }, //override
  image : d.main.kp_link && {
    data : getImgData(d.main.kp_link.img),
    heightRatio : '100%'
  },
  metaPrimary : {
    key: contentTypeDisplay(d),
    value: getVal(d.meta.kp_super_tags, 'display', 1 )
  },
  featuredCardLabel : { value: contentTypeDisplay(d) }, 
  // shareSummary : getRichInputText(d.main.kp_summary),
  // metaSecondaryPreTitle : getRichInputText(d.main.kp_byline),
  title : getVal(d.main.kp_link, 'title'),
  metaBlockData : {
    comp : 'MetaBlock',
    data : [
      { text: getShortenedName(d.meta.kp_contributed_by) },
      { text: d.kp_date_published ? createContentCardDate(d.kp_date_published) : createContentCardDate(d.kp_date_created) }
    ]
  },
  resourceLink: {
    // displayType: 'inline',
    type: 'linked',
    value: getVal(d.main.kp_link, 'url')
  }
  // cardLink : {
  //   mode: 'display',
  //   value: {
  //     ...getVal(d.main.kp_link),
  //     source: getRichInputText(d.main.kp_byline)
  //   }
  // },
  // tags : { value : getVal(d.meta.kp_tags) },

})

export const MEDIA_COVERAGE = d => ({
  // colSetting : 'col-sm-3',
  // link : { type: 'external', url: getUrl(d.main.kp_link) }, //override
  image : d.main.kp_link && {
    data : getImgData(d.main.kp_link.img),
    heightRatio : '100%'
  },
  metaPrimary : {
    key: contentTypeDisplay(d),
    value: getVal(d.meta.kp_super_tags, 'display', 1 )
  },
  featuredCardLabel : { value: contentTypeDisplay(d) }, 
  // shareSummary : getRichInputText(d.main.kp_summary),
  // metaSecondaryPreTitle : getRichInputText(d.main.kp_byline),
  title : getVal(d.main.kp_link, 'title'),
  metaBlockData : {
    comp : 'MetaBlock',
    data : [
      { text: getShortenedName(d.meta.kp_contributed_by) },
      { text: d.kp_date_published ? createContentCardDate(d.kp_date_published) : createContentCardDate(d.kp_date_created) }
    ]
  },
  resourceLink: {
    // displayType: 'inline',
    type: 'linked',
    value: getVal(d.main.kp_link, 'url')
  }
  // cardLink : {
  //   mode: 'display',
  //   value: {
  //     ...getVal(d.main.kp_link),
  //     source: getRichInputText(d.main.kp_byline)
  //   }
  // },
  // tags : { value : getVal(d.meta.kp_tags) },

})

export const TOOLS = d => ({
  // colSetting : 'col-sm-3',
  
  bottomTags : d.meta.super_tags && d.meta.super_tags.map(d => d.display),
  metaPrimary : {
    value: getVal(d.meta.content_sub_type, 'display') || contentTypeDisplay(d)
  },
  featuredCardLabel : { value: contentTypeDisplay(d) }, 
  title : getRichInputText([d.main.kp_title, d.main.title]),
  shareSummary : getRichInputText([d.main.kp_subtitle, d.main.subtitle]),
  metaBlockData : {
    comp : 'MetaBlock',
    data : [
      { text: getShortenedName(d.meta.kp_contributed_by) },
      { text: d.kp_date_published ? createContentCardDate(d.kp_date_published) : createContentCardDate(d.kp_date_created) }
    ]
  }
})

export const RECOMMENDATIONS = d => ({
  // colSetting : 'col-sm-3',
  metaPrimary : {
    value: contentTypeDisplay(d)
  },
  title : getRichInputText(d.main.kp_title),
  metaBlockData : {
    comp : 'MetaBlock',
    data : [
      { text: getShortenedName(d.meta.kp_contributed_by) },
      { text: d.kp_date_published ? createContentCardDate(d.kp_date_published) : createContentCardDate(d.kp_date_created) }
    ]
  },
  shareSummary : getRichInputText(d.main.kp_title)
})

export const TOOLKITS = d => ({
  // colSetting : 'col-sm-4',
  metaPrimary : {
    value: getVal(d.meta.content_sub_type, 'display') || contentTypeDisplay(d)
  },
  featuredCardLabel : { value: contentTypeDisplay(d) }, 
  title : getRichInputText(d.main.kp_title || d.main.title),
  metaBlockData : {
    comp : 'MetaBlock',
    data : [
      { text: getShortenedName(d.meta.kp_contributed_by) },
      { text: d.kp_date_published ? createContentCardDate(d.kp_date_published) : createContentCardDate(d.kp_date_created) }
    ]
  },
})


export const PLANS = d => ({
  // colSetting : 'col-sm-3',
  title : formatTitle(d.main.villageName),
  metaPrimary : {
    value: contentTypeDisplay(d)
  },
  metaBlockData : {
    comp : 'MetaBlock',
    data : [
      { text: getShortenedName(d.meta.kp_contributed_by) },
      { text: d.kp_date_published ? createContentCardDate(d.kp_date_published) : createContentCardDate(d.kp_date_created) }
    ]
  },
})

export const CASE_REPORTS = d => { 
  return({
  // colSetting : 'col-sm-3',
  title : formatTitle(d.meta.caseNumber),
  summary : getVal(d.meta.typeOfViolation, 'display', 'all' ),
  metaPrimary : {
    value: getVal( d.meta.status && d.meta.status.display )
  },
  metaBlockData : {
    comp : 'MetaBlock',
    data : [
      { img: getAvatar(d.meta.kp_contributed_by), text: getShortenedName(d.meta.kp_contributed_by) },
      { text: d.kp_date_published ? createContentCardDate(d.kp_date_published) : createContentCardDate(d.kp_date_created) }
    ]
  }
})}

export const DEMOGRAPHIC = d => { 
  return({
    metaPrimary : {
      value: contentTypeDisplay(d),
    },
    title : d.meta.participantId,
    metaBlockData : {
      comp : 'MetaBlock',
      data : [
        { text: d.meta.interviewer.display },
        { text: createContentCardDate(d.kp_date_published) }
      ]
    }
  })
}

export const IDIYM = d => { 
  return({
    metaPrimary : {
      value: contentTypeDisplay(d),
    },
    title : 'xxx',
    metaBlockData : {
      comp : 'MetaBlock',
      data : [
        { text: 'xxx' },
        { text: createContentCardDate(d.kp_date_published) }
      ]
    }
  })
}

export const IDIGD = d => { 
  return({
    metaPrimary : {
      value: contentTypeDisplay(d),
    },
    title : 'xxx',
    metaBlockData : {
      comp : 'MetaBlock',
      data : [
        { text: 'xxx' },
        { text: createContentCardDate(d.kp_date_published) }
      ]
    }
  })
}


export const IDIAHE = d => { 
  return({
    metaPrimary : {
      value: contentTypeDisplay(d),
    },
    title : 'xxx',
    metaBlockData : {
      comp : 'MetaBlock',
      data : [
        { text: 'xxx' },
        { text: createContentCardDate(d.kp_date_published) }
      ]
    }
  })
}


export const IDIFM = d => { 
  return({
    metaPrimary : {
      value: contentTypeDisplay(d),
    },
    title : 'xxx',
    metaBlockData : {
      comp : 'MetaBlock',
      data : [
        { text: 'xxx' },
        { text: createContentCardDate(d.kp_date_published) }
      ]
    }
  })
}

export const COGAMEYM = d => { 
  return({
    metaPrimary : {
      value: contentTypeDisplay(d),
    },
    title : 'xxx',
    metaBlockData : {
      comp : 'MetaBlock',
      data : [
        { text: 'xxx' },
        { text: createContentCardDate(d.kp_date_published) }
      ]
    }
  })
}

export const FGDYM = d => { 
  return({
    metaPrimary : {
      value: contentTypeDisplay(d),
    },
    title : 'xxx',
    metaBlockData : {
      comp : 'MetaBlock',
      data : [
        { text: 'xxx' },
        { text: createContentCardDate(d.kp_date_published) }
      ]
    }
  })
}

export const DGMC = d => { 
  return({
    metaPrimary : {
      value: contentTypeDisplay(d),
    },
    title : 'xxx',
    metaBlockData : {
      comp : 'MetaBlock',
      data : [
        { text: 'xxx' },
        { text: createContentCardDate(d.kp_date_published) }
      ]
    }
  })
}

export const DGME = d => { 
  return({
    metaPrimary : {
      value: contentTypeDisplay(d),
    },
    title : 'xxx',
    metaBlockData : {
      comp : 'MetaBlock',
      data : [
        { text: 'xxx' },
        { text: createContentCardDate(d.kp_date_published) }
      ]
    }
  })
}

export const DGHV = d => { 
  return({
    metaPrimary : {
      value: contentTypeDisplay(d),
    },
    title : 'xxx',
    metaBlockData : {
      comp : 'MetaBlock',
      data : [
        { text: 'xxx' },
        { text: createContentCardDate(d.kp_date_published) }
      ]
    }
  })
}

