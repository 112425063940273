import * as React from "react"

function Attachment2(props) {
  return (
    <svg width={25} height={27} viewBox="0 0 25 27" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.264 26.023a2.51 2.51 0 01-2.504-2.53v-8.016c0-.924.688-1.648 1.596-1.648.909 0 1.596.724 1.596 1.648v7.368a.3.3 0 01-.299.299.3.3 0 01-.298-.3v-7.367c0-.57-.392-1.05-.999-1.05s-.998.48-.998 1.05v8.016c0 1.052.847 1.933 1.906 1.933a1.909 1.909 0 001.907-1.933v-8.172c0-1.545-1.263-2.84-2.815-2.84-1.55 0-2.814 1.268-2.814 2.84v7.524a.3.3 0 01-.299.299.3.3 0 01-.299-.3v-7.523c0-1.904 1.512-3.438 3.412-3.438s3.412 1.534 3.412 3.438v8.147c0 1.413-1.125 2.555-2.504 2.555z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.672 23.493a2.597 2.597 0 002.592 2.618c1.43 0 2.592-1.184 2.592-2.644V15.32c0-1.952-1.55-3.526-3.5-3.526-1.949 0-3.5 1.574-3.5 3.526v7.523c0 .213.175.387.387.387a.388.388 0 00.387-.387v-7.523c0-1.525 1.226-2.752 2.726-2.752 1.502 0 2.727 1.254 2.727 2.752v8.172a1.82 1.82 0 01-1.819 1.844c-1.009 0-1.818-.84-1.818-1.844v-8.016c0-.53.36-.963.91-.963s.91.434.91.963v7.367a.388.388 0 00.774 0v-7.368c0-.97-.724-1.735-1.684-1.735s-1.684.766-1.684 1.736v8.016zm.088 0a2.51 2.51 0 002.504 2.53c1.38 0 2.504-1.142 2.504-2.555V15.32c0-1.904-1.512-3.438-3.412-3.438s-3.412 1.534-3.412 3.438v7.524a.3.3 0 00.3.299.3.3 0 00.298-.3v-7.523c0-1.572 1.265-2.84 2.814-2.84 1.552 0 2.815 1.295 2.815 2.84v8.172c0 1.08-.849 1.933-1.907 1.933-1.06 0-1.906-.881-1.906-1.933v-8.016c0-.57.391-1.05.998-1.05s.999.48.999 1.05v7.368a.3.3 0 00.298.299.3.3 0 00.3-.3v-7.367c0-.924-.688-1.648-1.597-1.648-.908 0-1.596.724-1.596 1.648v8.016z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.248 4.197h6.874v4.334a1.5 1.5 0 001.5 1.5h4.626v11.166a1 1 0 01-1 1h-2.473v1h2.473a2 2 0 002-2V9.531l-6.626-6.334H6.248a2 2 0 00-2 2v16a2 2 0 002 2h.787v-1h-.787a1 1 0 01-1-1v-16a1 1 0 011-1zm7.874.862l4.155 3.972h-3.655a.5.5 0 01-.5-.5V5.059z"
        fill="#fff"
      />
    </svg>
  )
}

export default Attachment2
