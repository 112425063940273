import React, { Fragment, useEffect, useState, Suspense } from "react";
import { Router, Route, Switch } from "react-router-dom";
import history from "./history";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga";
//bootstrap
import "bootstrap/dist/css/bootstrap.min.css";

import PrimaryHeader from "./components/nav/PrimaryHeader";
import Footer from "./components/nav/Footer";
import PrivateRoute from "./components/routing/PrivateRoute";
import AdminRoute from "./components/routing/AdminRoute";
import TitleSans__L from "./components/typo/TitleSans__L";
import TitleSans__S from "./components/typo/TitleSans__S";

//content
import Announcement from "./components/generalUI/Announcement";
import Alert from "./components/generalUI/Alert";
import Loader from "./components/generalUI/Loader";
import Grid from "./components/generalUI/Grid";
import { KPBtn } from "./components/generalUI/KPButtons";

//redux
import store from "./store";
import { loadUser } from "./actions/auth";
import { getClientConfig } from "./actions/config";
import setAuthToken from "./utils/setAuthToken";
import { updateScreenWidth } from "./actions/environment";

import { _ContentTypes } from "./_configs/contentTypes/config";

import "./App.scss";

console.log("REACT VERSION: ", React.version);

const Data = React.lazy(() => import("./pageTpls/Data"));
const AdminDashboard = React.lazy(() => import("./pageTpls/AdminDashboard"));
const Home = React.lazy(() => import("./pageTpls/Home"));
const Playground = React.lazy(() => import("./pageTpls/Playground"));
const ContributeConfigure = React.lazy(() =>
  import("./pageTpls/ContributeConfigure")
);
const Search = React.lazy(() => import("./pageTpls/Search"));
const Subscribe = React.lazy(() => import("./pageTpls/Subscribe"));
const ContributeTpl = React.lazy(() => import("./pageTpls/ContributeTpl"));
const ViewProfile = React.lazy(() => import("./pageTpls/ViewProfile"));
const ViewProfile_New = React.lazy(() =>
  import("./pageTpls/ViewProfile/index_new")
);

const EditProfile = React.lazy(() => import("./pageTpls/EditProfile"));
const UserProfilesListing = React.lazy(() =>
  import("./pageTpls/UserProfilesListing")
);
const AboutUs = React.lazy(() => import("./pageTpls/AboutUs"));
const GroupedPublishedListing = React.lazy(() =>
  import("./pageTpls/GroupedPublishedListing")
);
const PublishedListing = React.lazy(() =>
  import("./pageTpls/PublishedListing")
);
const PublishedPage = React.lazy(() => import("./pageTpls/PublishedPage"));
const ResetPwdPg = React.lazy(() => import("./pageTpls/ResetPwdPg"));
const RecommendationsPage = React.lazy(() =>
  import("./pageTpls/RecommendationsPage")
);
const MediaUploader = React.lazy(() => import("./pageTpls/MediaUploader"));

const Login_Registration = React.lazy(() =>
  import("./pageTpls/Login_Registration")
);
const VerifEmail = React.lazy(() => import("./pageTpls/VerifEmail"));
const VerifyResetPwd = React.lazy(() => import("./pageTpls/VerifyResetPwd"));
const BatchRegister = React.lazy(() => import("./pageTpls/BatchRegister"));
const AutoTpl = React.lazy(() => import("./pageTpls/AutoTpl"));
const ModQueue = React.lazy(() => import("./pageTpls/ModQueue"));
const GoogleAuth = React.lazy(() => import("./pageTpls/GoogleAuth"));
const OKE404 = React.lazy(() => import("./pageTpls/OKE404"));

const PlaygroundRahul = React.lazy(() => import("./pageTpls/Playground_rahul"));
const Careers = React.lazy(() => import("./wassan/Careers"));

const ENDPOINT = "/";

ReactGA.initialize("UA-173518242-1");
ReactGA.pageview(window.location.pathname + window.location.search);

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = ({ updateScreenWidth, modalOverlay, auth, envConfigLoading }) => {
  const handleResize = () => updateScreenWidth(window.innerWidth);

  let _DeploymentDisplayName, _UnderMaintenence, logo, _Env;

  useSelector((state) => {
    if (!envConfigLoading) {
      let deploymentConfig = state.environment.envConfig.deployment;
      _DeploymentDisplayName = deploymentConfig._DeploymentDisplayName;
      _UnderMaintenence = deploymentConfig._UnderMaintenence.is;
      logo = deploymentConfig._Logos.logo;
      _Env = deploymentConfig._Env;
    }
  });

  useEffect(() => {
    store.dispatch(getClientConfig());
    store.dispatch(loadUser());
    window.addEventListener("resize", handleResize); //add listener to get window resize
    updateScreenWidth(window.innerWidth);
    return () => {
      window.removeEventListener("resize", handleResize);
      // socket.disconnect();
    };
  }, []);

  const [showOkeDevTools, setShowOkeDevTools] = useState(false);
  const [showWarningScreen, setShowWarningScreen] = useState(true);

  return (
    <Fragment>
      {["staging"].indexOf(_Env) !== -1 && showWarningScreen && (
        <div className="OKE-WarningScreen">
          <img src={logo} style={{ height: "50px" }} />
          <TitleSans__L>
            This website is for testing purposes only.
          </TitleSans__L>
          <TitleSans__L style={{ marginBottom: "1rem" }}>
            Any data entered here is likely to be erased.
          </TitleSans__L>
          <TitleSans__S>
            {`If you intended to visit the live '${_DeploymentDisplayName}' platform, please go here : `}
            <a className="OKE-WarningScreen__link" href="#">
              {"<PENDING PROD URL CONFIG>"}
            </a>
          </TitleSans__S>
          <TitleSans__S>
            Else, if you know why you are here,{" "}
            <span
              className="OKE-WarningScreen__link"
              onClick={() => setShowWarningScreen(false)}
            >
              click here to proceed
            </span>
          </TitleSans__S>
        </div>
      )}
      {envConfigLoading ? (
        <Loader />
      ) : (
        <Router history={history}>
          {_UnderMaintenence ? (
            <div className="kp-container-fluid" style={{ textAlign: "center" }}>
              <img src={logo} alt={_DeploymentDisplayName} />
              <h1 className="h1 semibold">
                New exciting updates are on their way!
              </h1>
              <h3 className="h3 semibold">
                Come back here on 29th July, post 10AM IST. We shall be up and
                running!
              </h3>
            </div>
          ) : (
            <Fragment>
              {showOkeDevTools && <Grid />}
              {auth && auth.env === "dev" && (
                <KPBtn
                  className="show-dev-tools-btn"
                  onClick={() => setShowOkeDevTools(!showOkeDevTools)}
                >
                  Show Grid
                </KPBtn>
              )}
              <Announcement />
              <PrimaryHeader
                style={
                  modalOverlay === true ? { zIndex: 0 } : { zIndex: 1000000 }
                }
              />
              <div className="kp-content">
                <Alert />
                <Suspense fallback={<Loader />}>
                  <Switch>
                    <Route exact={true} path="/" component={Home} />
                    <Route
                      exact={true}
                      path="/login_registration"
                      component={Login_Registration}
                    />
                    <Route
                      exact={true}
                      path="/login_registration/:authType"
                      component={Login_Registration}
                    />
                    <Route
                      exact={true}
                      path="/subscribe"
                      component={Subscribe}
                    />
                    <PrivateRoute
                      exact={true}
                      path="/reset-password"
                      component={ResetPwdPg}
                    />
                    <AdminRoute
                      exact={true}
                      path="/admin-dashboard"
                      component={AdminDashboard}
                    />
                    <Route
                      exact={true}
                      path="/verifyEmail"
                      component={VerifEmail}
                    />
                    <Route
                      exact={true}
                      path="/verifyEmail/:token"
                      component={VerifEmail}
                    />
                    <Route
                      exact={true}
                      path="/verifyResetPasswordMail"
                      component={VerifyResetPwd}
                    />
                    <Route
                      exact={true}
                      path="/verifyResetPasswordMail/:token"
                      component={VerifyResetPwd}
                    />
                    <Route exact={true} path="/data" component={Data} />
                    <PrivateRoute
                      exact={true}
                      path="/mod-queue"
                      component={ModQueue}
                    />
                    <Route
                      exact={true}
                      path="/playground"
                      component={Playground}
                    />
                    <PrivateRoute
                      exact={true}
                      path="/media-uploader"
                      component={MediaUploader}
                    />
                    <Route
                      exact={true}
                      path="/grouped-published-listing/:groupKey"
                      component={GroupedPublishedListing}
                    />
                    <Route
                      exact={true}
                      path="/published-listing/:contentType"
                      component={PublishedListing}
                    />
                    <Route
                      exact={true}
                      path="/published-page/:content_type"
                      component={PublishedPage}
                    />
                    <PrivateRoute
                      exact={true}
                      path="/batch-register"
                      component={BatchRegister}
                    />
                    <Route exact={true} path="/auto-tpl" component={AutoTpl} />
                    <Route
                      exact={true}
                      path="/profile/:profileType/:id"
                      component={ViewProfile_New}
                    />
                    <Route
                      exact={true}
                      path="/community-listing/:profileType"
                      component={UserProfilesListing}
                    />
                    <Route
                      exact={true}
                      path="/recommendations-page/:recoObjectIds/:answerFilters"
                      component={RecommendationsPage}
                    />
                    <Route exact={true} path="/search" component={Search} />
                    <Route exact={true} path="/about-us" component={AboutUs} />
                    <Route
                      exact={true}
                      path="/googleAuth/:token"
                      component={GoogleAuth}
                    />
                    <PrivateRoute
                      exact={true}
                      path="/contribute-config"
                      component={ContributeConfigure}
                    />
                    <PrivateRoute
                      exact={true}
                      path="/edit/:content_type"
                      component={ContributeTpl}
                    />
                    <PrivateRoute
                      exact={true}
                      path="/moderate/:content_type"
                      component={ContributeTpl}
                    />
                    <PrivateRoute
                      exact={true}
                      path="/edit/:profileType/:id"
                      component={EditProfile}
                    />
                    <Route
                      exact={true}
                      path="/rahul"
                      component={PlaygroundRahul}
                    />
                    <Route exact={true} path="/careers" component={Careers} />
                    <Route component={OKE404} />
                    {/* //wassan customization */}
                  </Switch>
                </Suspense>
              </div>
              <Footer />
            </Fragment>
          )}
        </Router>
      )}
    </Fragment>
  );
};

App.propTypes = {
  updateScreenWidth: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  envConfigLoading: state.environment.envConfigLoading,
  modalOverlay: state.environment.modalOverlay,
  auth: state.auth,
});
export default connect(mapStateToProps, { updateScreenWidth })(App);
