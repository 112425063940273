import {
  getRichInputText,
  getVal,
  getImgUrl,
  getResourceLink,
  getSpeaker
} from '../../../utils/getters'

import { _GetContentTypeConfig} from '../../contentTypes/config';

//overrides on og card config
const getContentTypeName = (d) => _GetContentTypeConfig(d.meta.kp_content_type).chooseContentTypeBodyContent.title;

export const STORIES = (d) => ({
  image : getImgUrl(d.main.kp_cover_image),
  metaPrimary : {
    key: getContentTypeName(d)
  },
  metaSecondary : getVal(d.meta.kp_contributed_by, 'name') && `Author: ${getVal(d.meta.kp_contributed_by, 'name')}`
})

export const VIDEO_STORIES = (d) => ({
  metaPrimary : {
    key: getContentTypeName(d)
  }
  //nothing to override as of now.
})

export const PHOTO_STORIES = (d) => ({
  image : getImgUrl(d.main.images),
  metaPrimary : {
    key: getContentTypeName(d)
  }
  //nothing to override as of now.
})

export const RESOURCES = (d) => ({
  image : getImgUrl(d.main.kp_cover_image),
  metaPrimary : {
    key: getContentTypeName(d)
  },
  summary : getRichInputText(d.main.kp_summary),
  resourceLink : { displayType: 'inline', ...getResourceLink(d.main.kp_download_link)}
})

export const ACTIVITIES = (d) => ({
  image : getImgUrl(d.main.kp_cover_image),
  metaPrimary : {
    key: getContentTypeName(d)
  },
  metaSecondary : getVal(d.meta.kp_contributed_by, 'name') && `Author: ${getVal(d.meta.kp_contributed_by, 'name')}`
})



export const INITIATIVES = (d) => ({
  image : getImgUrl(d.main.kp_cover_image),
  metaPrimary : {
    key: getContentTypeName(d)
  },
  summary : getRichInputText(d.main.kp_summary),
  resourceLink : { displayType: 'inline', ...getResourceLink(d.main.kp_download_link)},

})

export const EVENTS = (d) => ({
  metaPrimary : {
    key: getVal(d.meta.kp_event_type, 'display')
  },
  // metaSecondary : getSpeaker(d.main.kp_speaker) && `Speaker: ${getSpeaker(d.main.kp_speaker)}`
})

export const NEWS = (d) => ({
  image : getImgUrl(d.main.kp_link.img),
  metaPrimary : {
    key: getVal(d.meta.kp_super_tags, 'display', 2)
  },
  metaBlockData : {
    data: [
      { text: getRichInputText(d.main.kp_byline) }
    ]
  }
})

export const MEDIA_COVERAGE = (d) => ({
  image : getImgUrl(d.main.kp_link.img),
  metaPrimary : {
    key: getVal(d.meta.kp_super_tags, 'display', 2)
  },
  metaBlockData : {
    data: [
      { text: getRichInputText(d.main.kp_byline) }
    ]
  }
})

export const TOOLS = (d) => ({
  metaPrimary : {
    key: _GetContentTypeConfig(d.meta.kp_content_type).chooseContentTypeBodyContent.title
  }
})

export const TOOLKITS = (d) => ({
  metaPrimary : {
    key: _GetContentTypeConfig(d.meta.kp_content_type).chooseContentTypeBodyContent.title
  }
})

export const PLANS = (d) => ({
  metaPrimary : {
    key: _GetContentTypeConfig(d.meta.kp_content_type).chooseContentTypeBodyContent.title
  }
})

export const CASE_REPORTS = (d) => ({
  metaPrimary : {
    key: _GetContentTypeConfig(d.meta.kp_content_type).chooseContentTypeBodyContent.title
  }
})

export const DEMOGRAPHIC = (d) => ({
  metaPrimary : {
    key: _GetContentTypeConfig(d.meta.kp_content_type).chooseContentTypeBodyContent.title
  }
})

export const RESEARCH_NOTES = (d) => ({
  metaPrimary : {
    key: _GetContentTypeConfig(d.meta.kp_content_type).chooseContentTypeBodyContent.title
  }
})
