
export const handleSetYOrientation = (elemRef, anchorRef, setYOrientation) => {
        
    if(!anchorRef.current) return;
    // - - we calculate the bottom edge y pos of the options, if it opens downward
    let viewportHeight = window.innerHeight;
    let {bottom: anchorPoint_YPos_BOTTOM, top : anchorPoint_YPos_TOP} = anchorRef.current.getBoundingClientRect();
    let elemHeight = elemRef.current.getBoundingClientRect().height;
    let elemBottomEdge = anchorPoint_YPos_BOTTOM + elemHeight;
    // - - then we calculate the top edge y pos of the options, if it opens upward
    let elemTopEdge = anchorPoint_YPos_TOP - elemHeight;
    
    // - - if bottom is not popping out of the screen, set open downward (default)
    if(elemBottomEdge < viewportHeight){ setYOrientation('bottom') } else
    // - - if both of them are popping out the screen, then set open downward (default)
    if(elemBottomEdge >= viewportHeight && elemTopEdge <= 0){ setYOrientation('bottom')} else
    // - - only one option left, bottom is popping out of screen, but top is NOT. so set open upward
    { setYOrientation('top') }      
  
}