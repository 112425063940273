import React, { useState, Fragment, useRef, useEffect } from "react";
// import { Link } from "react-router-dom";
import history from "../../../history";
//comps
import MetaBlock from "../../generalUI/MetaBlock";
import MetaBlock__Toolkits from "../../generalUI/MetaBlock__Toolkits";
import CardTag from "../CardTag";
import { KPIconBtn, KPBtn, ButtonPrimary, ButtonSecondary, ButtonTertiary } from "../../generalUI/KPButtons";

import KPLabel from "../../generalUI/KPLabel";
import KPImageInput from "../../inputs/KPImageInput";
import KPVideoEmbed from '../../inputs/KPVideoEmbed';
import ProfileImgDisplay from '../../generalUI/ProfileImgDisplay';
import KPMultiProfilesDisplay from '../../generalUI/KPMultiProfilesDisplay';
import KPMetaPrimary from "../../generalUI/KPMetaPrimary";
import KPMetaSecondary from "../../generalUI/KPMetaSecondary";
import KPLink from '../../generalUI/KPLink';
import KPMetaHero from "../../generalUI/KPMetaHero";
import ExternalLinkCard from "../../cards/ExternalLinkCard";
import KPInlineAlert from '../../generalUI/KPInlineAlert';

//icons
import Delete from "../../icons/Delete";
import Close_S from "../../icons/Close_S";
import Edit from "../../icons/Edit";
import KPTagsInput from "../../inputs/KPTagsInput";
import Download__nofill from '../../icons/Download__nofill';
import LinkOutSM from '../../icons/LinkOutSM';
//sass
import {  white } from "../../../sass/vars.scss";
import store from "../../../store";
//actions
import { deleteDraft } from "../../../actions/profiles";

const ContentCard = ({
  key,
  colSetting,
  className,
  cardType, // possible types : published_generic, published_featured, draft_generic
  elemStyle,
  link,
  image, // of the format {backgroundImage : x, backgroundPosition : y}
  videoEmbed,
  profileImage, //of the format { userUploaded: xx, gravatar: xx} //should get rid of gravatar, will simplify life in many ways
  imgContainerStyle,
  icon,
  cardTag,
  metaPrimary,
  metaSecondaryPreTitle,
  title,
  metaPrimaryPostTitle,
  summary,
  metaBlockData, //of the format [{icon: xComp, text: y},...]
  metaSecondary,
  resourceLink,
  metaHero,
  bottomTags,
  bottomKPTags,
  cardActions,
  cardActionsBottom,
  tags,
  multiProfilesDisplayData,
  cardLink,
  style,
  readOnly,
  inlineAlert,
  onRemoveCard,
  customClickHandler
}) => {

  const MetaBlockComps = {
    MetaBlock,
    MetaBlock__Toolkits,
  };
  //for cardActions
  const BtnComps = {
    KPBtn,
    KPIconBtn
  }

  const MetaBlockComp = metaBlockData && MetaBlockComps[metaBlockData.comp];

  const cardActionsRef = useRef(null);

  //check to see if click hasnt been on an action within the card. in which case, follow the card link.
  const handleCardClick = (e) => {
    if (!cardActionsRef.current || !cardActionsRef.current.contains(e.target)) {
      link.type === "internal"
        ? history.push(link.url)
        : window.open(link.url, "_blank");
    }
  };

  const genCardResourceRelActions = () => (
    <div ref={cardActionsRef} className='kp-content-card__actions'>
      <div className='kp-content-card__actions-contents-wrapper'>
      { resourceLink.type === 'uploaded'
        ? <ButtonPrimary
           icon="DownloadSimple" 
            onClick={() => window.open(resourceLink.value, '_blank')}>
            {/* <Download__nofill/> */}
          </ButtonPrimary>
        : <ButtonPrimary 
           icon="ArrowLineUpRight"
            onClick={() => window.open(resourceLink.value, '_blank')}>
            {/* <LinkOutSM/> */}
          </ButtonPrimary> 
      }
      </div>
    </div>
  )

  const genTagsInputComp = tags => {
    
    return (

      <KPTagsInput
        className="kp-content-card__tags"
        tagsColor={ tags.bgColor &&
          { bg: tags.bgColor, color: tags.color }
        }
        hideOverflow={true}
        value={tags.value}
        readOnly={true}
      /> )
  }

  const genExternalLinkCardComp = cardLink => (
    <ExternalLinkCard
      className="kp-content-card__card_link"
      value={cardLink.value}
      mode={cardLink.mode}
      noHover={true}
      backgroundColor={cardLink.backgroundColor}
    /> )


  return (
    <div
      key={key}
      className={`${colSetting} kp-content-card-wrapper ${cardType} ${elemStyle} ${className}`}
    >
      { onRemoveCard && !readOnly && //only show if a remove card function is passed AND we are not in read-only mode
        <ButtonPrimary
          className='kp-content-card-wrapper__remove-btn'
          // type='danger'
          // size='small'
          onClick={onRemoveCard}
          icon="Close_S"
          >
          {/* <Close_S fill={white} /> */}
        </ButtonPrimary>
       }
      <div
        onClick={
          customClickHandler
          ? customClickHandler
          : (e) => handleCardClick(e) }
        className={`kp-content-card`}
        style={style}
      >
        <div className="kp-content-card__content">
          { profileImage &&
            <ProfileImgDisplay
              className='kp-content-card__profile-img'
              avatar = {profileImage.avatar}
              size = {'10rem'}
              placeholderInitial = {profileImage.placeholderInitial}
              containerShape = {profileImage.containerShape}
              /> }
          { videoEmbed &&
            <KPVideoEmbed
              value={videoEmbed.value}
              readOnly={true}
              light={true}
              /> }
          { image &&
            <div className="kp-content-card__img-container" style={imgContainerStyle}>
              <KPImageInput
                value={image.data}
                heightRatio={image.heightRatio && image.heightRatio}
                placeholderColor={image.placeholderColor}
                containerShape={image.containerShape}
                height={image.height && image.height}
                width={image.width && image.width}
                imgBlockComp={image.imgBlockComp}
                backgroundSize={image.backgroundSize && image.backgroundSize}
                placeholderInitial = {image.placeholderInitial}
                readOnly={true}
              />
          </div> }
          { icon &&
            <div className="kp-content-card__icon-container">
              <img className="kp-content-card__icon" src={icon} />
            </div> }

          

          <div className="kp-content-card__text-wrapper">
            <div className="kp-content-card__text-group">
            { inlineAlert && <KPInlineAlert className='kp-content-card__inline-alert' text={inlineAlert.text} type={inlineAlert.type} />}
            
            { metaPrimary && <KPMetaPrimary className='kp-content-card__meta-primary' data={ metaPrimary } /> }
            { metaHero && <KPMetaHero data={ metaHero } /> }
            { metaSecondaryPreTitle && <h5 className="h5 kp-content-card__meta-secondary">{metaSecondaryPreTitle}</h5> }
            { title && <h4 className="h4 kp-content-card__title">{ title }</h4> }
            { metaPrimaryPostTitle && <KPMetaPrimary className='kp-content-card__meta-primary-post-title' data={ metaPrimaryPostTitle } /> }
            { summary && (
              Array.isArray(summary)
              ? <div className='kp-content-card__summary-blocks-group'>
                { summary.map((sumBlk,i) => (
                  <Fragment>
                  { sumBlk.text &&
                    <div key={i} className={`kp-content-card__summary-block ${sumBlk.className || ''}`}>
                      <KPLabel className='kp-content-card__summary-label' label={sumBlk.label} />
                      <h4 className="h4 kp-content-card__summary">{ sumBlk.text }</h4>
                    </div> }
                  </Fragment> ) )}
                </div>
              : <h4 className="h4 kp-content-card__summary">{ summary }</h4> ) }


            { tags && genTagsInputComp(tags) }
            { cardLink && genExternalLinkCardComp(cardLink) }
            </div>
            <div className="kp-content-card__meta-group">
              <div className='kp-content-card__meta-group-child'>
              { metaBlockData && (
                <MetaBlockComp
                  className="kp-content-card__meta-block"
                  data={metaBlockData.data}
                  /> ) }
              { metaSecondary && (
                <h5 className="h5 kp-content-card__meta-secondary">
                  {metaSecondary}
                </h5> ) }
              </div>
            { resourceLink && resourceLink.displayType === 'inline' &&
              genCardResourceRelActions() }
            </div>
            { multiProfilesDisplayData && 
              <div style={{paddingTop: '2rem'}}>
                <KPMultiProfilesDisplay className='kp-content-card__multi-profiles-display' { ...multiProfilesDisplayData } /> 
              </div> }
            { bottomKPTags && genTagsInputComp({ value : bottomKPTags } ) }
            {bottomTags && (
              <CardTag className='kp-content-card__bottom-tags' value={bottomTags} style={{ position: "relative" }} />
            )}
          </div>
        </div>

        <div className='kp-content-card__actions-wrapper'>
        { resourceLink && resourceLink.displayType !== 'inline' &&
          genCardResourceRelActions()
        }
        { cardActions &&
          <div ref={cardActionsRef} className='kp-content-card__actions'>
          { cardActions.map((d,i) => {
            let BtnComp = BtnComps[d.comp];
            return (
              <BtnComp
                className={d.comp === 'KPIconBtn' ? 'kp-content-card__action-icon-btn' : 'kp-content-card__action-text-btn' }
                {...d.props}>
              </BtnComp>
            )
          })}
          </div> }
        </div>
      </div>
    </div>
  );
};



export default ContentCard;
