import React, { Fragment } from "react";
import {Link} from 'react-router-dom';
import { primaryColor10, lightRed, lightGreen, lightYellow  } from '../../../sass/vars.scss';

import { createContentCardDate } from '../../../utils/momentManipulate';

const NotifListItem = ({ key, title, desc, link, isRead, type, category, date, style, onClick}) => {

  const bgColor = {
    neutral : lightYellow,
    danger : lightRed,
    success : lightGreen
  }



  return (
      <Link key={key} to={link || '#'} onClick={onClick}>
        <div className='kp-notif-list-item' style={style}>
          <div className={`kp-notif-list-item__type-icon-container color`} style={{backgroundColor: type ? bgColor[type] : primaryColor10}}>
            <h3 className='h3 kp-notif-list-item__type-icon'>{category && category[0].toUpperCase()}</h3>
          </div>
          <div className='kp-notif-list-item__text-group'>
            <h5 className='h5 medium kp-notif-list-item__date'>{createContentCardDate(date)}</h5>
            <h4 className='h4 bold kp-notif-list-item__title'>{title}</h4>
            <h4 className='h5 medium kp-notif-list-item__desc'>{desc}</h4>
          </div>
        </div>
      </Link>

  );
}

export default NotifListItem
