import {
  getRichInputText,
  getVal,
  getImgData,
  getResourceLink
} from '../../../utils/getters'


//overrides on og card config

export const STORIES = (d) => ({
  metaPrimary : { key: getVal(d.meta.kp_content_type) }
})

export const VIDEO_STORIES = (d) => ({
  metaPrimary : { key: getVal(d.meta.kp_content_type) }
  //nothing to override as of now.
})

export const PHOTO_STORIES = (d) => ({
  metaPrimary : { key: getVal(d.meta.kp_content_type) }
  //nothing to override as of now.
})

export const RESOURCES = (d) => ({
  image : {
    data : getImgData(d.main.kp_cover_image),
    heightRatio : '130%'
  },
  metaPrimary : { key: getVal(d.meta.kp_content_type) },
  resourceLink : { displayType: 'inline', ...getResourceLink(d.main.kp_download_link)}
})

export const ACTIVITIES = (d) => ({
  metaPrimary : { key: getVal(d.meta.kp_content_type) }
})



export const INITIATIVES = (d) => ({
  image : {
    data : getImgData(d.main.kp_cover_image),
    heightRatio : '130%'
  },
  metaPrimary : { key: getVal(d.meta.kp_content_type) },
  // resourceLink : { displayType: 'inline', ...getResourceLink(d.main.kp_download_link)}
})

export const EVENTS = (d) => ({
  metaPrimary : { key: getVal(d.meta.kp_content_type), value: getVal(d.meta.kp_event_type, 'display') }
})

export const NEWS = (d) => ({
  metaPrimary : {
     key: getVal(d.meta.kp_content_type),
     value: getRichInputText(d.main.kp_byline)
  },
  metaSecondaryPreTitle : null
})

export const MEDIA_COVERAGE = (d) => ({
  metaPrimary : {
     key: getVal(d.meta.kp_content_type),
     value: getRichInputText(d.main.kp_byline)
  },
  metaSecondaryPreTitle : null
})

export const TOOLS = (d) => ({
  metaPrimary : { key: getVal(d.meta.kp_content_type) }
})

export const RECOMMENDATIONS = (d) => ({
  metaPrimary : { key: getVal(d.meta.kp_content_type) }
})

export const TOOLKITS = (d) => ({
  metaPrimary : { key: getVal(d.meta.kp_content_type) }
})

export const PLANS = (d) => ({
  metaPrimary : { key: getVal(d.meta.kp_content_type) }
  //nothing to override as of now.
})

export const CASE_REPORTS = (d) => ({
  metaPrimary : { key: getVal(d.meta.kp_content_type) }
  //nothing to override as of now.
})

export const DEMOGRAPHIC = (d) => ({
  metaPrimary : { key: getVal(d.meta.kp_content_type) }
  //nothing to override as of now.
})

export const IDIYM = (d) => ({
  metaPrimary : { key: getVal(d.meta.kp_content_type) }
  //nothing to override as of now.
})

export const IDIAHE = (d) => ({
  metaPrimary : { key: getVal(d.meta.kp_content_type) }
  //nothing to override as of now.
})

export const IDIFM = (d) => ({
  metaPrimary : { key: getVal(d.meta.kp_content_type) }
  //nothing to override as of now.
})

export const IDIGD = (d) => ({
  metaPrimary : { key: getVal(d.meta.kp_content_type) }
  //nothing to override as of now.
})

export const COGAMEYM = (d) => ({
  metaPrimary : { key: getVal(d.meta.kp_content_type) }
  //nothing to override as of now.
})

export const FGDYM = d => ({
  metaPrimary : { key: getVal(d.meta.kp_content_type) }
})

export const DGMC = d => ({
  metaPrimary : { key: getVal(d.meta.kp_content_type) }
})
export const DGME = d => ({
  metaPrimary : { key: getVal(d.meta.kp_content_type) }
})
export const DGHV = d => ({
  metaPrimary : { key: getVal(d.meta.kp_content_type) }
})
