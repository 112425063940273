import * as React from "react"
import uuid from 'uuid'
let id = uuid.v4();

function RichFormatToolOrderedList(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <g clipPath={`url(#prefix__clip0__${id})`}>
        <path
          d="M9.217 12.356H21.79M9.217 5.785H21.79M9.217 18.927H21.79"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.302 7h-.906V4.522c0-.104.002-.24.006-.408.008-.168.014-.316.018-.444a5.47 5.47 0 01-.312.294l-.492.396-.438-.546 1.38-1.098h.744V7zM5.058 14H2.064v-.63l1.074-1.086c.216-.224.39-.41.522-.558.132-.152.228-.288.288-.408a.897.897 0 00.09-.396c0-.172-.048-.3-.144-.384a.54.54 0 00-.378-.126c-.164 0-.324.038-.48.114a2.59 2.59 0 00-.492.324l-.492-.582c.124-.108.254-.208.39-.3.14-.092.3-.166.48-.222.184-.06.404-.09.66-.09.28 0 .52.052.72.156a1.102 1.102 0 01.636 1.002c0 .236-.048.452-.144.648-.092.196-.228.39-.408.582-.176.192-.39.404-.642.636l-.552.516v.042h1.866V14zM4.89 17.676c0 .296-.09.532-.27.708a1.366 1.366 0 01-.654.36v.018c.344.04.604.144.78.312.18.168.27.394.27.678 0 .248-.062.472-.186.672-.12.196-.308.352-.564.468-.252.112-.578.168-.978.168-.464 0-.876-.078-1.236-.234v-.768a2.685 2.685 0 001.14.282c.324 0 .55-.056.678-.168.132-.112.198-.27.198-.474 0-.12-.03-.22-.09-.3-.06-.084-.166-.146-.318-.186a2.295 2.295 0 00-.624-.066h-.324v-.696h.33c.264 0 .464-.024.6-.072.14-.052.234-.12.282-.204a.578.578 0 00.078-.3.445.445 0 00-.144-.354c-.092-.088-.248-.132-.468-.132-.204 0-.382.036-.534.108a2.934 2.934 0 00-.378.204l-.42-.624c.168-.12.364-.22.588-.3.228-.08.498-.12.81-.12.44 0 .788.09 1.044.27.26.176.39.426.39.75z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id={`prefix__clip0__${id}`}>
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default RichFormatToolOrderedList
