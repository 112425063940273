 import React, { useState, useEffect, Fragment, useRef } from "react";

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
//comps
import KPDynamicSearchInput from '../KPDynamicSearchInput';
import { ButtonTertiary } from '../../generalUI/KPButtons';
import KPLabel from '../../generalUI/KPLabel';
import Meta from '../../typo/Meta'
//icons
import AddSM from '../../icons/AddSM';
//actions
import { updateTag, addTag } from '../../../actions/listings'
import {handleDynamicInputKeyPress} from '../../../utils/contentBlock';


const KPTagsInput = ({
  updateTag,
  addTag,
  contentType,
  id,
  value,
  className,
  onChange,
  placeholder,
  listingName,
  getListingActionName,
  getListingArgsArray,
  listingPropertyToMatch,
  label,
  sublabel,
  showLabel,
  readOnly,
  isRequired,
  errorMsgs,
  tagsColor,
  tagType,
  hideOverflow
}) => {

  const [selected, setSelected] = useState(value || []);
  const [showAddTagBtn, setShowAddTagBtn] = useState(false);
  const [searchString, setSearchString] = useState('');
  const handleSelectRef = useRef();

  // useEffect(() => { //update parent value on mount
  //   if(value && value.length > 0){
  //     let payloadForParent = value.map(s => s._id);
  //     onChange && onChange(id, payloadForParent);
  //   }
  // },[])

  useEffect(() => {
    if(readOnly) setSelected(value); //this is to help page search to refresh tags when search results change
  },[value])

  const calcTotTagCount = (tagCountObj) => {
    let totTagCount = 0;
    Object.values(tagCountObj).map(v => {
      totTagCount = totTagCount + v;
    })
    return totTagCount;
  }

  const handleSearchStringChange = (string) => setSearchString(string);
  const handleSearchResultsChange = r => r.length === 0 ? setShowAddTagBtn(true) : setShowAddTagBtn(false)

  //call back after add tag api succeeds
  const makeAddedTagSelected = tagData => handleSelectRef.current.handleSelect(tagData)

  const handleChange = newSelected => { //dySearchInput does all required calcs and passes through an array of new selected data.
    let newElem, rmElem = null;
    if(newSelected.length > selected.length){ //we have an addition
      newElem = newSelected.filter(ns => selected.some(s => ns._id === s._id ) === false);
    }else{ //we have a removal
      rmElem = selected.filter(s => newSelected.some(ns => s._id === ns._id ) === false);
    }

    newElem
    ? updateTag(tagType, newElem[0]._id, 'add', contentType )
    : updateTag(tagType, rmElem[0]._id, 'remove', contentType);

    setSelected(newSelected);
    // let payloadForParent = newSelected.map(s => s._id);
    onChange && onChange(id, newSelected);
  }


  //passed to dynamic search input comp

  const genSelectedComp = (d, renderFn) => (
    <div className='kp-tags-selected-comp' style={tagsColor && { backgroundColor : tagsColor.bg, color: tagsColor.color}}>
      <Meta className='kp-tags-selected-comp__text'>{d.kp_tag_name}</Meta>
      {renderFn()}
    </div>
  )

  const genResultComp = (d) => (
    <div style={{display:'flex'}}>
      <h4 className='h5 semibold'>{d.kp_tag_name}</h4>
      <h4 className='h5'>{calcTotTagCount(d.kp_tag_count)}</h4>
    </div>
  )

  

  const handleKeyPress = e => {
    handleDynamicInputKeyPress(
      e,
      showAddTagBtn, //allow Key Press or not //basically, we allow 'adding a new tag through key press, only if there arent existing tag results in the results comp'
      searchString,
      (stringToAdd) => addTag(tagType, stringToAdd, contentType, makeAddedTagSelected)
    )
  }

  return (
    <div className={`kp-dynamic-tags-input-wrapper ${className}`}>
    { 
    // (!readOnly || showLabel === true)  && 
      /* in the case of tags, we hide the entire label if readOnly and if it hasnt been explicityly specified to show label*/
      <KPLabel
        id={id}
        label={label}
        sublabel={sublabel}
        readOnly={readOnly}
        isRequired={isRequired}
        errorMsgs={errorMsgs}
        /> }
      <div style={{ position: 'relative' }} onKeyDown={(e) => handleKeyPress(e)}>
        <KPDynamicSearchInput
          ref= {handleSelectRef}
          className= 'kp-dynamic-tags-input'
          id= {id}
          value= {selected}
          readOnly= {readOnly}
          hideOverflowContent= {true}
          placeholder= {placeholder}
          onChange= {(k, newSelected) => handleChange(newSelected)}
          genResultComp= {genResultComp}
          genSelectedComp= {genSelectedComp}
          listingName= {`${tagType}Listing`}
          getListingActionName= {getListingActionName}
          getListingArgsArray= {[tagType]}
          listingPropertyToMatch= {listingPropertyToMatch}
          onSearchResultsChange= {(results) => handleSearchResultsChange(results)}
          onSearchStringChange= {(string) => handleSearchStringChange(string)}
          hideOverflow={hideOverflow}
          />
      { showAddTagBtn &&
        <ButtonTertiary 
          // className='temp-add-tag-btn' 
          // type='tertiary' 
          icon="Plus"
          onClick={() => addTag(tagType, searchString, contentType, makeAddedTagSelected)}/>
           }
      </div>
    </div>
  )
}


KPTagsInput.defaultProps = {
  id: 'kp_dynamic_tags_input',
  placeholder: 'tags input',
  tagType: 'tags', //this is what populates the listingName & listingArgsArray
  getListingActionName: 'getTagsListing',
  listingPropertyToMatch: 'kp_tag_name',
  contentType: 'uncategorized' //this is a required field ideally. it is used to categorise the tag count on the backend. but if we forget to add it, then at least the count gets stored under the uncategorized property.
}

KPTagsInput.propTypes = {
  updateTag: PropTypes.func.isRequired,
  addTag: PropTypes.func.isRequired,
  contentType: PropTypes.string.isRequired
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, {updateTag, addTag})(KPTagsInput);
