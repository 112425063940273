
import { white} from '../../../sass/vars.scss'

export const richToolbar = [
  {
    id: 'format__header_two',
    btnComp: 'KPIconBtn',
    icon: 'RichFormatToolH2',
    tooltipText: 'Title Text',
    onClickHandler: 'applyBlockFormatting',
    clickHandlerArgs: [
      'header-four'
    ],
    textFormatBlock: true
  },
  {
    id: 'format__unordered_list_item',
    btnComp: 'KPIconBtn',
    icon: 'RichFormatToolUnorderedList',
    tooltipText: 'Bullet List',
    onClickHandler: 'applyBlockFormatting',
    clickHandlerArgs: [
      'unordered-list-item' //block format style
    ],
    textFormatBlock: true
  },
  {
    id: 'format__ordered_list_item',
    btnComp: 'KPIconBtn',
    icon: 'RichFormatToolOrderedList',
    tooltipText: 'Numbered List',
    onClickHandler: 'applyBlockFormatting',
    clickHandlerArgs: [
      'ordered-list-item'
    ],
    textFormatBlock: true
  },
  {
    id: 'format__blockquote',
    btnComp: 'KPIconBtn',
    icon: 'RichFormatToolBlockquote',
    tooltipText: 'Pull Quote',
    onClickHandler: 'applyBlockFormatting',
    clickHandlerArgs: [
      'blockquote'
    ],
    textFormatBlock: true
  },
  {
    id: 'upload__inline_image',
    btnComp: 'KPFileUploadBtn',
    icon: 'UploadImage',
    iconProps: {stroke : white},
    tooltipText: 'Insert Image',
    multiple: false,
    onChangeHandler: 'handleUploadChange',
    changeHandlerArgs: [
      'KPRichInlineImage', //comp to render
      `images`, //folderName
      ['jpg', 'jpeg', 'png'] //allowedFormats
    ],
    imageBlock: true
  },
  {
    id: 'upload__inline_pdf',
    btnComp: 'KPFileUploadBtn',
    icon: 'PDFIcon',
    tooltipText: 'Insert PDF',
    multiple: false,
    onChangeHandler: 'handleUploadChange',
    changeHandlerArgs: [
      'KPPDFDisplay',
      `pdfs`,
      ['pdf']
    ]
  },
  {
    id: 'atomic__resource_upload',
    btnComp: 'KPIconBtn',
    icon: 'Attachment2',
    tooltipText: 'Add Attachments',
    onClickHandler: 'createAtomicBlockEntity',
    clickHandlerArgs: [
      'KPResourceUpload'
    ]
  },
  {
    id: 'atomic__video_embed',
    btnComp: 'KPIconBtn',
    icon: 'VideoEmbedIcon',
    tooltipText: 'Embed Video',
    onClickHandler: 'createAtomicBlockEntity',
    clickHandlerArgs: [
      'KPVideoEmbed'
    ]
  },
  {
    id: 'atomic__audio_embed',
    btnComp: 'KPIconBtn',
    icon: 'AudioEmbedIcon',
    tooltipText: 'Embed Audio',
    onClickHandler: 'createAtomicBlockEntity',
    clickHandlerArgs: [
      'AudioEmbed'
    ]
  },
  {
    id: 'atomic__card_link',
    advancedBlock: true,
    title: 'Embed Content Cards',
    desc: 'Embed cards that link to other stories, tools, profiles, (anything really on this platform really! ). You can embed external links as well.',
    onClickHandler: 'handleShowCardLinkInput',
    clickHandlerArgs: [] //meed to pass an empty array for no args, else well have an error
  },
  {
    id: 'atomic__faq_comp',
    advancedBlock: true,
    title: 'FAQ Block',
    desc: 'Use this block to neatly document Q&A sessions & frequenty asked questions.',
    onClickHandler: 'createAtomicBlockEntity',
    clickHandlerArgs: [
      'KPFAQComp'
    ]
  },
  {
    id: 'atomic__ppt_embed',
    advancedBlock: true,
    title: 'Embed Google Slides',
    desc: 'Use this to embed a google slide presentation within your contribution. A user will be able to interact with it, without having to this page.',
    onClickHandler: 'createAtomicBlockEntity',
    clickHandlerArgs: [
      'KPPPTEmbed'
    ]
  },
  {
    id: 'atomic__call_to_action',
    advancedBlock: true,
    title: 'Add A Call To Action Button',
    desc: 'Lorem Ipsum.',
    onClickHandler: 'handleShowLinkInput',
    clickHandlerArgs: []
  },
  {
    id: 'atomic__contact_channels',
    advancedBlock: true,
    title: 'Add Contact Channels Component',
    desc: 'Lorem Ipsum.',
    onClickHandler: 'createAtomicBlockEntity',
    clickHandlerArgs: [
      "KPLikeContactChannelsBlock"
    ]
  }

]
