import api from '../../src/_configs/api';
import store from '../store';
import {
  getShortenedName,
  getVal,
  
} from './getters';

import { createContentCardDate } from './momentManipulate';
import { formatTitle, set_getValOffQueryString as s_gVal } from './general';
import {
  __GetText, 
  __GetValsFromValPaths, 
  __GetCardImagePropData, 
  __GetCardVideoPropData, 
  __GetContentTypeDisplay,
  __CustomGetter,
  __GetCardEventDatePropData,
  __GetResourceLink
} from './gettersV2';


// export const cardConfig = {
//   stories : {
//     metaPrimary : { fn : '__GetSubject' },
//     title : ['main.kp_title', 'main.title'],
//     image : { data: 'main.kp_cover_image', heightRatio: '61.804%' },
//     featuredCardLabel : true, //by passing true, the default card label, i.e content type name is displayed
//     shareSummary : ['main.kp_subtitle', 'main.kp_summary'],
//     metaBlockData : [
//       'meta.kp_contributed_by', 
//       ['kp_date_published', 'kp_date_created']
//     ]
//   },
//   videoStories : {
//     metaPrimary: true,
//     title : 'main.kp_title',
//     shareSummary : 'main.kp_summary',
//     featuredCardLabel : true,
//     video : 'main.kp_video',
//     metaBlockData : [
//       'meta.kp_contributed_by',
//       ['kp_date_published', 'kp_date_created']
//     ]
//   },
//   photoStories : {
//     image : {data: 'main.images', heightRatio: '61.804%'},
//     metaPrimary : true,
//     featuredCardLabel : true,
//     title : 'main.title',
//     shareSummary : 'main.description',
//     metaBlockData : [
//       'meta.kp_contributed_by',
//       ['kp_date_published', 'kp_date_created']
//     ]
//   },
//   activities : {
//     metaPrimary : true,
//     featuredCardLabel : true,
//     title : 'main.kp_title',
//     shareSummary : ['main.kp_subtitle', 'main.kp_summary'],
//     metaBlockData : [
//       'meta.kp_contributed_by',
//       ['kp_date_published', 'kp_date_created']
//     ]
//   },
//   resources : {
//     title : ['main.kp_title', 'main.title'],
//     shareSummary : 'main.kp_summary',
//     metaPrimary : { 
//       fn: '__GetVal', 
//       args: [
//         'meta.kp_type',
//         ['value_desc', 'display', null]
//       ] 
//     },
//     featuredCardLabel : true,
//     metaBlockData : [
//       'meta.kp_contributed_by',
//       ['meta.kp_date_of_publication.date', 'kp_date_published', 'kp_date_created']
//     ],
//     resourceLink : 'main.kp_download_link'
//   },
//   initiatives: {
//     metaPrimary : {fn: '__GetVal', args: [ 'meta.kp_super_tags', 'display', 1 ]},
//     featuredCardLabel : true,
//     image : {data: 'main.kkp_cover_image', heightRatio: '61.804%'},
//     title : 'main.kp_title',
//     shareSummary : 'main.kp_summary',
//     metaBlockData : [
//       'meta.kp_contributed_by',
//       ['meta.kp_date_to_date.dateFrom.date', 'kp_date_published', 'kp_date_created']
//     ],
//   },
//   events : {
//     metaHero : 'd.main.kp_date_time',
//     metaPrimary : {fn: '__GetVal', args: ['meta.kp_event_type', ['value_desc', 'display'] ] },
//     featuredCardLabel : true,
//     title : 'main.kp_title',
//     shareSummary : 'main.kp_about',
//     metaBlockData : [
//       { fn: '__GetSpeaker', args: ['main.kp_speaker']}
//     ]
//   },
//   news : {
//     image : {data: 'main.kp_link.img', heightRatio: '100%'},
//     metaPrimary: { fn: '__GetVal', args: ['meta.kp_super_tags', 'display', 1]},
//     featuredCardLabel : true,
//     title: 'main.kp_link.title',
//     metaBlockData : [
//       'meta.kp_contributed_by',
//       ['kp_date_published', 'kp_date_created']
//     ],
//     resourceLink : 'main.kp_link.url'
//   },
//   mediaCoverage : {
//     image : {data: 'main.kp_link.img', heightRatio: '100%'},
//     metaPrimary: { fn: '__GetVal', args: ['meta.kp_super_tags', 'display', 1]},
//     featuredCardLabel : true,
//     title: 'main.kp_link.title',
//     metaBlockData : [
//       'meta.kp_contributed_by',
//       ['kp_date_published', 'kp_date_created']
//     ],
//     resourceLink : 'main.kp_link.url'
//   },
//   tools: {
//     metaPrimary : {fn : '__GetVal', args : ['meta.content_sub_type', 'display'] },
//     featuredCardLabel : true,
//     title : ['main.kp_title', 'main.title'],
//     shareSummary : ['main.kp_subtitle', 'main.subtitle'],
//     metaBlockData : [
//       'meta.kp_contributed_by',
//       ['kp_date_published', 'kp_date_created']
//     ]
//   },
//   toolkits: {
//     metaPrimary : {fn : '__GetVal', args : ['meta.content_sub_type', 'display'] },
//     featuredCardLabel : true,
//     title : ['main.kp_title', 'main.title'],
//     metaBlockData : [
//       'meta.kp_contributed_by',
//       ['kp_date_published', 'kp_date_created']
//     ]
//   },
//   recommendations : {
//     metaPrimary : true,
//     title : 'main.kp_title',
//     metaBlockData : [
//       'meta.kp_contributed_by',
//       ['kp_date_published', 'kp_date_created']
//     ],
//     shareSummary : 'main.kp_title',
//   },
//   plans : {
//     title : 'main.villageName',
//     metaPrimary : true,
//     metaBlockData : [
//       'meta.kp_contributed_by',
//       ['kp_date_published', 'kp_date_created']
//     ]
//   },
//   caseReports : {
//     title : 'meta.caseNumber',
//     metaPrimary : { fn: '__GetVal', args: ['meta.status', 'display']},
//     metaBlockData : [
//       'meta.kp_contributed_by',
//       ['kp_date_published', 'kp_date_created']
//     ]
//     //this property used to be there, but we plan to remove now
//     //summary : getVal(d.meta.typeOfViolation, 'display', 'all' ),
//   },
//   demographics : {
//     metaPrimary : true,
//     title : 'meta.participantId',
//     metaBlockData : [
//       'meta.kp_contributed_by',
//       ['kp_date_published', 'kp_date_created']
//     ]
//   },
//   IDIYM : {
//     metaPrimary : true,
//     title : 'meta.participantId',
//     metaBlockData : [
//       'meta.kp_contributed_by',
//       ['kp_date_published', 'kp_date_created']
//     ]
//   },
//   IDIFM : {
//     metaPrimary : true,
//     title : 'meta.participantId',
//     metaBlockData : [
//       'meta.kp_contributed_by',
//       ['kp_date_published', 'kp_date_created']
//     ]
//   },
//   IDIGD : {
//     metaPrimary : true,
//     title : 'meta.participantId',
//     metaBlockData : [
//       'meta.kp_contributed_by',
//       ['kp_date_published', 'kp_date_created']
//     ]
//   },
//   IDIAHE : {
//     metaPrimary : true,
//     title : 'meta.participantId',
//     metaBlockData : [
//       'meta.kp_contributed_by',
//       ['kp_date_published', 'kp_date_created']
//     ]
//   },
//   COGAMEYM : {
//     metaPrimary : true,
//     title : 'meta.participantId',
//     metaBlockData : [
//       'meta.kp_contributed_by',
//       ['kp_date_published', 'kp_date_created']
//     ]
//   },  
//   FGDYM : {
//     metaPrimary : true,
//     title : 'meta.participantId',
//     metaBlockData : [
//       'meta.kp_contributed_by',
//       ['kp_date_published', 'kp_date_created']
//     ]
//   },
//   DGMC : {
//     metaPrimary : true,
//     title : 'meta.participantId',
//     metaBlockData : [
//       'meta.kp_contributed_by',
//       ['kp_date_published', 'kp_date_created']
//     ]
//   },
//   DGME : {
//     metaPrimary : true,
//     title : 'meta.participantId',
//     metaBlockData : [
//       'meta.kp_contributed_by',
//       ['kp_date_published', 'kp_date_created']
//     ]
//   },
//   DGHV : {
//     metaPrimary : true,
//     title : 'meta.participantId',
//     metaBlockData : [
//       'meta.kp_contributed_by',
//       ['kp_date_published', 'kp_date_created']
//     ]
//   }
// }

export const __CardPropsGenerator = d => {
  let { _ContentCardsConfig } = store.getState().environment.envConfig.cards
  let contentType = d.meta.kp_content_type;  
  let {
    title, 
    image, 
    video, 
    featuredCardLabel, 
    listElemLabel,
    metaPrimary, 
    metaHero,
    metaBlockData, 
    shareSummary, 
    resourceLink
  } = _ContentCardsConfig[contentType];

  return ({
    title : title 
      ? title.fn 
        ? __CustomGetter(d, title) 
        : formatTitle(__GetText(__GetValsFromValPaths(d, title))) //required
      : undefined,
    image : image 
      ? image.fn
        ? __CustomGetter(d, image) 
        : __GetCardImagePropData(d, image)
      : undefined,
    videoEmbed : video 
      ? video.fn
        ? __CustomGetter(d, video) 
        : __GetCardVideoPropData(d, video)
      : undefined,
    featuredCardLabel : featuredCardLabel
      ? featuredCardLabel.fn
        ? __CustomGetter(d, featuredCardLabel)
        : { value: __GetContentTypeDisplay(d) }
      : undefined,
    listElemLabel : listElemLabel
    ? {
        key : listElemLabel.key && listElemLabel.key.fn ? __CustomGetter(d, listElemLabel.key) : undefined,
        value : listElemLabel.value && listElemLabel.value.fn ? __CustomGetter(d, listElemLabel.value) : __GetContentTypeDisplay(d),
      }
    : undefined,
    metaPrimary : metaPrimary
      ? __CustomGetter(d, metaPrimary)
        ? { value : __CustomGetter(d, metaPrimary) }
        : { value: __GetContentTypeDisplay(d) }
      : undefined,
    metaHero : metaHero
      ? metaHero.fn
        ? __CustomGetter(d, metaHero) 
        : __GetCardEventDatePropData(d, metaHero) 
      : undefined,
    shareSummary : shareSummary 
      ? shareSummary.fn
        ? __CustomGetter(d, shareSummary) 
        : __GetText(__GetValsFromValPaths(d, shareSummary))
      : undefined, //we add this undefined inorder to not pass this prop if this prop isnt defined at all in the cardConfig
    metaBlockData : !metaBlockData
      ? undefined
      : {
          comp : 'MetaBlock',
          data : [
            ...(
              metaBlockData[0] 
              ? [{ 
                  text: metaBlockData[0].fn
                  ? __CustomGetter(d, metaBlockData[0]) 
                  : getShortenedName(s_gVal('get', d, metaBlockData[0])) 
                }]
              : []
            ),
            ...(
              metaBlockData[1]
              ? [{ 
                  text: metaBlockData[1].fn 
                  ? __CustomGetter(d, metaBlockData[1]) 
                  : createContentCardDate(getVal(d, metaBlockData[1])) 
                }]
              : []
            )
            
          ]
        },
    resourceLink : resourceLink
      ? resourceLink.fn
        ? __CustomGetter(d, resourceLink)
        : __GetResourceLink(d, resourceLink)
      : undefined
  })
}

export const refreshCardsData = async (cardsData, setCardsData, setLoading) => {
  if(cardsData && cardsData.type === 'internal' && cardsData.selectedAry.length > 0){
    const promises = cardsData.selectedAry.map( async d => {
      try{
        // console.log('d', d)
        let contentType = d.meta.kp_content_type;
        let profileType = d.meta.profile_type;
        if(profileType){
          let id = ['userProfiles', 'board'].indexOf(profileType) !== -1
                    ? (d.user && d.user._id)
                    : d._id
          let res;
          if(['userProfiles', 'organisations'].indexOf(profileType) !== -1 ){
            res = await api.get(`/api/${profileType}/${id}`);
          }else{
            res = await api.get(`/api/groups/${profileType}/${id}`);
          }
          return res.data.profile;
        }else{
          const res = await api.get(`/api/contributions/view/${contentType}?id=${d._id}`);
          return res.data.contribution;  
        }
        
        
      }catch(err){
        if(err) console.log('err in cardUtils > refreshCardsData', err);
      }
    })
    const results = await Promise.all(promises);
    
    setCardsData({ ...cardsData, selectedAry: results});
    setLoading(false);
  }else{
    setLoading(false);
  }
}
