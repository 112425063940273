import React, {Fragment} from "react";


const InputText = (props) => {

  

  return (
    <h6 className={`inputText ${props.className || ''}`} style={props.style} id={props.id || ''}>{props.children}</h6>
  );

}


export default InputText;
