import React, { useState, Fragment } from "react";

import { white, red, yellow, green, black, darkGrey, greyColor100, lightYellow } from '../../../sass/vars.scss';
import {WarningCircle, CheckCircle} from 'phosphor-react';


const KPInlineAlert = ({ text, link, type, className, style, invert }) => {

  const typeConfig = {
    success : {text: green, icon: 'CheckCircle'},
    danger: {text: red, icon: 'WarningCircle'},
    neutral: {text: black, icon: 'WarningCircle'},
    neutralInactive: {text: darkGrey, icon: 'WarningCircle'}
  }

  const icons = {
    WarningCircle,
    CheckCircle
  }

  let Icon = icons[typeConfig[type].icon];

  return (
    <h5 className={`h6 medium inlineAlert ${className}`} style={style}>
      <div className='inlineAlert__icon'>
        <Icon 
          strokeWidth="0"
          size={20}
          color={typeConfig[type].text}
        />
      </div>
      <span className='inlineAlert__text' style={{color: typeConfig[type].text}}>{text}</span>
      { link && <a style={{paddingLeft: '1rem', textDecoration: 'underline', color: invert ? white : greyColor100}} href={link.url} target="_blank">{link.text}</a>}
    </h5>
  );
}

export default KPInlineAlert
