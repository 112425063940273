import React, { useState, useEffect, Fragment } from "react";
import PropTypes from 'prop-types';

import { KPTextInput } from '../../inputs/KPInputs';
import {KPBtn} from '../../generalUI/KPButtons';
import ToggleBar from '../../generalUI/ToggleBar';
import ModalShell from '../../modals/ModalShell';
import ModalHeader from '../../modals/ModalHeader';
import Loader from '../../generalUI/Loader';
import KPInternalResSelect from '../KPInternalResSelect';
import KPExternalResSelect from '../KPExternalResSelect';


//config
import { _GetContentTypeConfig } from '../../../_configs/contentTypes/config';
import { _ProfileTypes } from '../../../_configs/profileTypes/config';
//utils
import { set_getValOffQueryString } from '../../../utils/general';

const KPResourceSelectPopUp = ({
  id,
  allowNestedPublishing,
  cardDisplayBlockId,
  onConfirm : parentOnConfirm,
  selectedAry : parentSelectedAry,
  onCloseModal,
  contentTypeEmbedOptions,
  profileTypeEmbedOptions,
  linkTypesToShow,
  maxSelectLimit
}) => {

  const namesOfLinkTypes = {
    internal : 'Internal Link',
    external : 'External Link'
  }

  const getToggleOps = () => {
    return linkTypesToShow.map(type => {
      return { value: type, name : namesOfLinkTypes[type] }
    })
  }

  const [activeTab, setActiveTab] = useState(linkTypesToShow[0])
  const handleToggle = value => setActiveTab(value)

  const genToggleBar = () => (
    <ToggleBar
      className='kp-res-select-popup__link-type-toggle'
      options={getToggleOps()}
      onChange={ (key, value) => handleToggle(value) }
      />
  )

  return (
    <ModalShell>
      <ModalHeader className='kp-res-select-pop-up__header' title = 'Insert Link' onClose = {onCloseModal} />
    { getToggleOps().length > 1 && genToggleBar()}
    { activeTab === 'internal'
      ? <KPInternalResSelect
          id = {id}
          allowNestedPublishing = {allowNestedPublishing}
          cardDisplayBlockId = {cardDisplayBlockId}
          onConfirm = {parentOnConfirm}
          selectedAry = {parentSelectedAry}
          onCloseModal = {onCloseModal}
          contentTypeEmbedOptions =  {contentTypeEmbedOptions}
          profileTypeEmbedOptions =  {profileTypeEmbedOptions}
          maxSelectLimit = {maxSelectLimit}
        />
      : <KPExternalResSelect
          onConfirm = {parentOnConfirm}
          onCloseModal = {onCloseModal}
          linkTypesToShow = {linkTypesToShow}
          /> }
    </ModalShell>
  )
}

KPResourceSelectPopUp.defaultProps = {
  linkTypesToShow : ['internal', 'external']
}

export default KPResourceSelectPopUp;
